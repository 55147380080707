import getCurrentLocale from "@/utils/getCurrentLocale.js"
import { getLocalizedHeaders } from "./servicesUtils.js"
import { API_V2 } from "@/utils/HttpUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { MODERATION_PREFIX } from "@/constants/apiPrefix.js"

export const fetchAttributes = ({ queryParams, requestParams, signal }) => {
  const query = getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)

  return API_V2.get(
    `${MODERATION_PREFIX}/attributes/${query}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchAttributesMeta = async ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MODERATION_PREFIX}/attributes/meta/${getFormatedQueryParams(queryParams)}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const createAttribute = ({ payload }) => {
  return API_V2.post(`${MODERATION_PREFIX}/attributes/`, payload, getLocalizedHeaders("ru"))
}

export const fetchAttribute = ({ code, language = "ru" }) => {
  return API_V2.get(`${MODERATION_PREFIX}/attributes/${code}/`, getLocalizedHeaders(language))
}

export const fetchLocalizedAttribute = async ({ code }) => {
  try {
    const [responseRu, responseUk, responseEn] = await Promise.all([
      fetchAttribute({ code }),
      fetchAttribute({ code, language: "uk" }),
      fetchAttribute({ code, language: "en" })
    ])

    const data = responseRu.data
    data.public_name = {
      ru: responseRu?.data?.public_name || "",
      uk: responseUk?.data?.public_name || "",
      en: responseEn?.data?.public_name || ""
    }
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateAttribute = ({ code, payload, language }) => {
  return API_V2.put(
    `${MODERATION_PREFIX}/attributes/${code}/`,
    payload,
    getLocalizedHeaders(language)
  )
}

export const updateLocalizedAttribute = async ({ code, payload }) => {
  const ruPayload = { ...payload, public_name: payload.public_name.ru }

  try {
    const [responseRu, responseUk, responseEn] = await Promise.all([
      updateAttribute({ code, payload: ruPayload }),
      updateAttribute({ code, payload: { public_name: payload.public_name.uk }, language: "uk" }),
      updateAttribute({ code, payload: { public_name: payload.public_name.en }, language: "en" })
    ])

    const data = responseRu.data
    data.default_unit = responseRu.data?.default_unit ? { oid: responseRu.data.default_unit } : null
    data.public_name = {
      ru: responseRu?.data?.public_name || "",
      uk: responseUk?.data?.public_name || "",
      en: responseEn?.data?.public_name || ""
    }
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchAttributeUnits = (code) => {
  return API_V2.get(
    `${MODERATION_PREFIX}/attributes/${code}/possible-units/`,
    getLocalizedHeaders(getCurrentLocale())
  )
}

export const fetchAttributeAttachedTypes = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MODERATION_PREFIX}/attributes/${
      requestParams.attributeCode
    }/product-types/${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const updateAttributeAttachedType = ({ attributeCode, typeCode, payload }) => {
  return API_V2.put(
    `${MODERATION_PREFIX}/attributes/${attributeCode}/product-types/${typeCode}/`,
    payload,
    getLocalizedHeaders("ru")
  )
}

export const unattachTypeFromAttribute = ({ attributeCode, typeCode }) => {
  return API_V2.delete(
    `${MODERATION_PREFIX}/attributes/${attributeCode}/product-types/${typeCode}/`
  )
}

export const attachTypeToAttribute = ({ attributeCode, payload }) => {
  return API_V2.post(
    `${MODERATION_PREFIX}/attributes/${attributeCode}/product-types/`,
    payload,
    getLocalizedHeaders("ru")
  )
}
