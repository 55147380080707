import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"

export const getRoles = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `/admin/roles/${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchRole = ({ id }) => {
  return API_V2.get(`/admin/roles/${id}/`)
}
export const fetchRolePermissions = ({ id, queryParams }) => {
  return API_V2.get(`/admin/roles/${id}/permissions/${getFormatedQueryParams(queryParams)}`)
}

export const updateRole = ({ id, payload }) => {
  return API_V2.put(`/admin/roles/${id}/`, payload)
}

export const updateRolePermissions = ({ id, payload }) => {
  return API_V2.post(`/admin/roles/${id}/permissions/`, payload)
}

export const createRole = (payload) => {
  return API_V2.post("/admin/roles/", payload)
}

export const deleteRole = (id) => {
  return API_V2.delete(`/admin/roles/${id}/`)
}

export const fetchPermissionApps = () => {
  return API_V2.get("/admin/permissions/?limit=100&parent_id=")
}

export const fetchPermissionChildrens = ({ queryParams }) => {
  return API_V2.get(`/admin/permissions/${getFormatedQueryParams(queryParams)}`)
}

export const addPermissionToRole = ({ role, payload }) => {
  return API_V2.post(`/admin/roles/${role}/permissions/`, payload)
}

export const removePermissionFromRole = ({ role, payload }) => {
  return API_V2.post(`/admin/roles/${role}/permissions/delete/`, payload)
}
