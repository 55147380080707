<template>
  <div>
    <PageHeader :title="$t('roles')">
      <template #filters>
        <a-input-search
          v-model="filteredInfo.name"
          :placeholder="$t('name')"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>
      </template>

      <template #actions>
        <a-button
          type="primary"
          icon="plus"
          @click="openCreateModal()"
        >
          {{ $t("create") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      rowKey="id"
      :columns="columns"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #isActiveRenderer="isActive">
        <div :class="[isActive ? 'success-text' : 'secondary-text']">
          {{ isActive ? $t("active") : $t("inactive") }}
        </div>
      </template>

      <template #actionsRenderer="record">
        <a-button
          icon="edit"
          shape="circle"
          type="dashed"
          @click="openEditModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="createModalIsOpened"
      :title="$t('createRole')"
      :width="1100"
      destroyOnClose
      @close="closeCreateModal"
    >
      <CreateRole
        @onCreate="onCreateRole"
        @close="closeCreateModal"
      />
    </a-drawer>

    <a-drawer
      :visible="editModalIsOpened"
      :title="$t('editRole')"
      :width="1100"
      destroyOnClose
      @close="closeEditModal"
    >
      <EditRole
        :role="editedRole"
        @onUpdate="onUpdateRole"
        @close="closeEditModal"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"

import CreateRole from "./components/CreateRole.vue"
import EditRole from "./components/EditRole.vue"
import PageHeader from "@/ant-components/PageHeader/index.js"

import { getRoles } from "@/modules/UsersManagement/services/rolesService.js"

import useAntTableQuery from "@/composables/useAntTableQuery"
import useRolesColumns from "./useRolesColumns"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange
} = useAntTableQuery({
  queryFunction: getRoles,
  requestParams: { changeRouterQuery: true }
})

const columns = useRolesColumns({ sortedInfo: computed(() => sortedInfo.value) })

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const createModalIsOpened = ref(false)

const editModalIsOpened = ref(false)
const editedRole = ref()

const openCreateModal = () => {
  createModalIsOpened.value = true
}

const closeCreateModal = () => {
  createModalIsOpened.value = false
}

const openEditModal = (row) => {
  editedRole.value = row
  editModalIsOpened.value = true
}

const closeEditModal = () => {
  editModalIsOpened.value = false
  editedRole.value = undefined
}

const onUpdateRole = () => {
  onTableChange()
  closeEditModal()
}

const onCreateRole = (record) => {
  closeCreateModal()
  onTableChange()
  openEditModal(record)
}

onMounted(() => {
  setupTable({
    defaultSorter: {
      columnKey: "created_at",
      order: "ascend"
    },
    sortKey: "sort_by"
  })
  onTableChange()
})
</script>

<style scoped lang="scss">
.success-text {
  color: $green-color;
}
.secondary-text {
  opacity: 0.45;
}
</style>
