<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="padding: 0 32px"
  >
    <a-form-model-item
      :label="$t('reason')"
      prop="reason"
    >
      <a-input v-model="form.reason" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('sum')"
      prop="amount"
    >
      <a-input-number
        v-model="form.amount"
        :min="1"
        :precision="0"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('applySum')"
      prop="amount_confirm"
    >
      <a-input-number
        v-model="form.amount_confirm"
        :min="0"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('comment')"
      prop="comment"
    >
      <a-textarea
        v-model="form.description"
        :autoSize="{ minRows: 4, maxRows: 10 }"
      />
    </a-form-model-item>

    <div style="display: flex; justify-content: flex-end; gap: 16px">
      <a-button
        :disabled="isSubtracting"
        @click="handleResetForm"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isSubtracting"
        @click="handleSubtract"
      >
        {{ $t("writeOff") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import useForm from "@/composables/useForm.js"
import { sellerSubtract } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(["handleUpdateBillingInfo"])

const formRef = ref()
const form = reactive({
  reason: "",
  amount: undefined,
  amount_confirm: undefined,
  description: ""
})

const formRules = computed(() => ({
  reason: [requiredRule.value, maxLengthRule.value],
  description: [maxLengthRule.value],
  amount_confirm: [{ validator: sumValidator }]
}))
const maxLengthRule = computed(() => ({
  max: 1000,
  message: i18n.t("maxLengthError", { length: 1000 })
}))
const sumValidator = (_, value, callback) => {
  if (!value) return callback(new Error(i18n.t("emptyError")))
  if (form.amount !== form.amount_confirm) return callback(new Error(i18n.t("mustBeSameError")))
  callback()
}
const { isValidForm, validateForm } = useForm(formRef)

const isSubtracting = shallowRef(false)
const handleSubtract = async () => {
  await validateForm()
  if (!isValidForm.value) return

  const payload = { ...form }

  isSubtracting.value = true

  if (!form.description) {
    delete payload.description
  }
  try {
    await sellerSubtract(props.prototype.uuid, payload)

    notification.success({ message: i18n.t("commissionDebited") })
    emit("handleUpdateBillingInfo")
    handleResetForm()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubtracting.value = false
  }
}

const handleResetForm = () => {
  formRef.value.resetFields()
}
</script>
