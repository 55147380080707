<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="padding: 0 32px"
  >
    <a-form-model-item
      :label="$t('date')"
      prop="processed_at"
    >
      <a-date-picker
        showTime
        v-model="form.processed_at"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('sum')"
      prop="amount"
    >
      <a-input-number
        v-model="form.amount"
        :min="1"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('applySum')"
      prop="amount_confirm"
    >
      <a-input-number
        v-model="form.amount_confirm"
        :min="0"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('comment')"
      prop="description"
    >
      <a-textarea
        v-model="form.description"
        :autoSize="{ minRows: 4, maxRows: 10 }"
      />
    </a-form-model-item>

    <div style="display: flex; justify-content: flex-end; gap: 16px; width: 100%">
      <a-button
        :disabled="isFetching"
        @click="clearForm"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="handleReplenish"
      >
        {{ $t("topUp") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import i18n from "@/i18n"

import { sellerReplenish } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import useForm from "@/composables/useForm"

const props = defineProps({
  sellerUuid: {
    type: String,
    required: true
  }
})

const emit = defineEmits(["handleUpdateBillingInfo"])

const formRef = ref()
const form = reactive({})
const formRules = computed(() => ({
  processed_at: [{ required: true, message: i18n.t("fillFields") }],
  amount: [{ required: true, message: i18n.t("fillFields") }],
  amount_confirm: [{ validator: sumValidator }],
  description: [maxLengthRule.value]
}))

const maxLengthRule = computed(() => ({
  max: 1000,
  message: i18n.t("maxLengthError", { length: 1000 })
}))
const sumValidator = (_, value, callback) => {
  if (!value) return callback(new Error(i18n.t("emptyError")))
  if (form.amount !== form.amount_confirm) return callback(new Error(i18n.t("mustBeSameError")))
  callback()
}
const { isValidForm, validateForm } = useForm(formRef)

const isFetching = shallowRef(false)
const handleReplenish = async () => {
  await validateForm()
  if (!isValidForm.value) return

  isFetching.value = true

  let data = {
    operation_type: "REPLENISHMENT_BY_ADMINISTRATION",
    amount: form.amount,
    processed_at: form.processed_at.toISOString()
  }
  if (form.description) {
    data.description = form.description
  }

  try {
    await sellerReplenish(props.sellerUuid, data)

    clearForm()
    emit("handleUpdateBillingInfo")
    notification.success({ message: i18n.t("balanceReplenished") })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const clearForm = () => {
  formRef.value.resetFields()
}
</script>
