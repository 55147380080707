import { MPA_PREFIX } from "@/constants/apiPrefix.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { API_V2 } from "@/utils/HttpUtils.js"

export const fetchOfferLabels = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/offer-labels${getFormatedQueryParams(queryParams)}`, { signal })
}

export const removeOfferLabel = (payload) => {
  return API_V2.delete(`${MPA_PREFIX}/offer-labels/${payload.id}`)
}

export const createOfferLabel = (data) => {
  return API_V2.post(`${MPA_PREFIX}/offer-labels`, data)
}
export const updateOfferLabel = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/offer-labels/${id}`, data)
}
