import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const attributesTableSearchFilters = computed(() => [
    {
      key: "code",
      placeholder: i18n.t("attributeCode")
    },
    {
      key: "name_contains",
      placeholder: i18n.t("workName")
    },
    {
      key: "public_name",
      placeholder: i18n.t("publicName")
    }
  ])

  const attributeTypeOptions = [
    {
      value: "NUMERIC",
      label: "Numeric"
    },
    {
      value: "STRING",
      label: "String"
    }
  ]

  return {
    attributesTableSearchFilters,
    attributeTypeOptions
  }
}
