import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"

export const getUsers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `/admin/users/${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const createUser = (payload) => {
  return API_V2.post("/admin/users/", payload)
}

export const updateUser = ({ userId, payload }) => {
  return API_V2.put(`/admin/users/${userId}/`, payload)
}
export const updateUserPassword = ({ userId, payload }) => {
  return API_V2.put(`/admin/users/${userId}/password/`, payload, {
    headers: { "Content-Type": "application/json" }
  })
}
export const setupUserAuthentication = (payload) => {
  return API_V2.post(`/admin/auth/2fa/setup/`, payload, { responseType: "arraybuffer" })
}

export const fetchUserRoles = ({ userId, queryParams }) => {
  return API_V2.get(`/admin/users/${userId}/roles/${getFormatedQueryParams(queryParams)}`)
}

export const addUserRoles = ({ userId, payload }) => {
  return API_V2.post(`/admin/users/${userId}/roles/`, payload)
}
export const removeUserRoles = ({ userId, payload }) => {
  return API_V2.post(`/admin/users/${userId}/roles/delete/`, payload)
}

export const getUserSessions = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `/admin/users/${requestParams.id}/sessions/${getFormatedQueryParams(queryParams)}`,
    { signal }
  )
}
export const getUserActiveSessions = ({ requestParams, signal }) => {
  return API_V2.get(`/admin/users/${requestParams.id}/sessions/active/`, { signal })
}

export const deactivateSession = ({ userId, sessionId }) => {
  return API_V2.post(`/admin/users/${userId}/sessions/${sessionId}/deactivate/`)
}
