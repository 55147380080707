<template>
  <div class="locale-switcher">
    <a-radio-group v-model="$root.$i18n.locale">
      <a-radio-button
        v-for="lang in locales"
        :key="`locale-${lang.label}`"
        :value="lang.value"
        @click.prevent="changeLanguage(lang.value)"
      >
        {{ lang.label }}
      </a-radio-button>
    </a-radio-group>
  </div>
</template>

<script setup>
import locales from "@/constants/locales.js"
import i18n from "@/i18n"
import Cookies from "js-cookie"

const changeLanguage = (value) => {
  i18n.locale = value
  Cookies.set("language", value)
}
</script>

<style>
.locale-switcher {
  margin-left: auto;
  margin-right: 24px;
}
</style>
