<template>
  <div>
    <a-spin
      :spinning="isFetching"
      class="return-drawer"
    >
      <ReturnItems
        :items="returnItems"
        :promoCode="selectedReturn.promo_code"
      />

      <div class="return-info">
        <ReturnHistory
          class="return-info__item"
          :dataHistory="dataHistory"
          :selectedReturn="fetchedSelectedReturn"
        />

        <ReturnDetails
          class="return-info__item"
          :fetchedSelectedReturn="fetchedSelectedReturn"
        />

        <a-form-model
          class="return-info__item--thin"
          style="width: 20%; margin-top: 64px"
          :model="actionData"
        >
          <a-form-model-item
            :label="$t('returnStatus')"
            prop="status"
          >
            <a-select
              :options="returnStatuses"
              v-model="actionData.status"
              style="width: 100%"
            />
          </a-form-model-item>

          <a-form-model-item
            :label="$t('returnPoint')"
            prop="aim"
          >
            <a-select
              style="width: 100%"
              :options="returnAims"
              v-model="actionData.aim"
            />
          </a-form-model-item>

          <a-form-model-item
            :label="$t('reason')"
            prop="reason"
          >
            <a-select
              style="width: 100%"
              :options="returnReasonsToSelect"
              v-model="actionData.reason"
            />
          </a-form-model-item>
        </a-form-model>
      </div>

      <template v-if="fetchedSelectedReturn?.images?.length">
        <div class="return-info__item">
          <span class="return-label">{{ $t("attachedPhotos") }}</span>

          <div class="images-wrap">
            <img
              v-for="(img, index) in fetchedSelectedReturn.images"
              :key="`${index}-img`"
              :src="getImageUrl(img)"
              @click="openGallery"
            />
          </div>
        </div>
      </template>
    </a-spin>

    <div class="drawer-footer">
      <a-button
        :disabled="isSaving"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isSaving"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import ReturnHistory from "./components/ReturnHistory.vue"
import ReturnDetails from "./components/ReturnDetails.vue"
import ReturnItems from "./components/ReturnItems.vue"

import {
  fetchReturnItem,
  fetchAllReasons,
  updateReturn
} from "@/modules/MPAdmin/services/returnsService.js"

import { IMAGES_CDN } from "@/constants/common.js"

import getImagePath from "@/utils/getImagePath.js"
import getCurrentLocale from "@/utils/getCurrentLocale.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  selectedReturn: {
    type: Object,
    default: () => ({})
  },
  returnStatuses: {
    type: Array,
    required: true
  },
  returnAims: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(["updateReturn", "close"])

const handleOpenGallery = inject("handleOpenGallery", () => {})

const actionData = reactive({
  status: "",
  reason: "",
  aim: "",
  created_by: ""
})

const returnReasons = ref([])
const fetchedSelectedReturn = ref({})
const returnItems = ref([])
const dataHistory = ref([])

const returnReasonsToSelect = computed(() => {
  return returnReasons.value.map((item) => ({
    label: item.name ? item.name[getCurrentLocale()] : item.help,
    value: item.uuid
  }))
})

const getImageUrl = (path) => {
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(path, "full")}`
}

const openGallery = () => {
  handleOpenGallery({ images: fetchedSelectedReturn.value.images })
}

const isFetching = shallowRef(false)
const getReturn = async () => {
  isFetching.value = true

  try {
    const { data } = await fetchReturnItem(props.selectedReturn.uuid)

    returnItems.value = data.items.map(({ item_in_order, ...item }) => {
      return {
        ...item,
        images_of_product: item_in_order.offer.product.images,
        images_of_offer: item_in_order.images,
        item_in_order
      }
    })
    actionData.status = data.status
    actionData.aim = data.aim
    actionData.reason = data.reason?.uuid || ""

    fetchedSelectedReturn.value = data

    dataHistory.value = data.history
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const isSaving = shallowRef(false)
const onSave = async () => {
  isSaving.value = true

  if (actionData.reason === "") {
    delete actionData.reason
  }

  try {
    await updateReturn(props.selectedReturn.uuid, actionData)

    notification.success({ message: i18n.t("success") })
    emit("updateReturn")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSaving.value = false
  }
}

const getReasons = async () => {
  try {
    const { data } = await fetchAllReasons()
    returnReasons.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onClose = () => emit("close")

onMounted(() => {
  getReturn()
  getReasons()
})
</script>

<style lang="scss" scoped>
.return-drawer {
  padding-bottom: 50px;
}

.return-label {
  font-weight: bold;
  font-size: 16px;
  color: $font-default;

  margin-top: 16px;
}

.return-info {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  &__item {
    width: 40%;

    margin-top: 16px;

    &--thin {
      width: 24.5%;

      & * {
        margin: 0;
      }
    }
  }
}

.images-wrap {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  margin-top: 16px;

  & img {
    width: 150px;
    aspect-ratio: 1;
    object-fit: contain;

    padding: 4px;

    border: 1px solid $border-color;
    border-radius: 2px;

    cursor: pointer;
  }
}
</style>
