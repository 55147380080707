<template>
  <div class="type-attributes">
    <div class="type-attributes__header">
      <a-button
        type="primary"
        @click="onOpenTetherModal"
      >
        {{ $t("toTetherAttribute") }}
      </a-button>
    </div>

    <a-table
      :dataSource="dataSource"
      rowKey="code"
      :columns="columns"
      :pagination="false"
      :components="components"
      :loading="isFetching"
    >
      <template #isMainTitle>
        <a-tooltip
          placement="topRight"
          arrowPointAtCenter
          :title="$t('attributeIsMain')"
        >
          Main
        </a-tooltip>
      </template>

      <template #isRequiredTitle>
        <a-tooltip
          placement="topRight"
          arrowPointAtCenter
          :title="$t('attributeIsRequired')"
        >
          Required
        </a-tooltip>
      </template>

      <template #handleRenderer>
        <div class="handle">
          <a-icon type="menu" />
        </div>
      </template>

      <template #isMainRenderer="_, record">
        <a-checkbox
          :checked="record.is_main"
          @click="handleAttributeChange(record, 'is_main')"
        />
      </template>

      <template #isRequiredRenderer="_, record">
        <a-checkbox
          :checked="record.is_required"
          @click="handleAttributeChange(record, 'is_required')"
        />
      </template>

      <template #actions="record">
        <div class="type-attributes__actions">
          <a-tooltip :title="$t('lookUp')">
            <a-button
              icon="eye"
              type="dashed"
              shape="circle"
              @click="goToAttribute(record)"
            />
          </a-tooltip>
          <a-tooltip :title="$t('toUntie')">
            <a-button
              icon="delete"
              type="dashed"
              shape="circle"
              ghost
              class="ant-btn-danger"
              @click="onRemoveAttribute(record)"
            />
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="showTetherModal"
      :title="$t('tetherAttributeToType')"
      :width="600"
      destroyOnClose
      @close="onCloseTetherModal"
    >
      <CreateAttributeModal
        :type="typeInfo"
        :totalOrderLength="dataSource.length"
        @close="onCloseTetherModal"
        @created="onAttributeCreated"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { watch, ref, onMounted, provide } from "vue"
import i18n from "@/i18n"
import { notification, Modal } from "ant-design-vue"

import TableGraggableWrapper from "@/ant-components/TableGraggableWrapper"
import { CreateAttributeModal } from "./index.js"

import notifyResponseError from "@/utils/notifyResponseError"
import useTypesColumns from "./useTypeAttributesColumns.js"
import {
  fetchAttributesMeta,
  unattachTypeFromAttribute,
  updateAttributeAttachedType
} from "@/modules/Moderation/services/attributesService.js"
import { fetchAttributesByType } from "@/modules/Moderation/services/moderationProductsService.js"
import router from "@/router/index.js"
import getCurrentLocale from "@/utils/getCurrentLocale.js"

const components = {
  body: {
    wrapper: TableGraggableWrapper
  }
}

const props = defineProps({
  typeInfo: {
    type: Object,
    default: () => ({})
  }
})

const { columns } = useTypesColumns()

const isFetching = ref(false)
const dataSource = ref([])
const getAttributes = async () => {
  if (!props.typeInfo.code) {
    dataSource.value.length = 0
    return
  }
  try {
    isFetching.value = true
    const queryParams = { order_by: "order" }

    const { data } = await fetchAttributesByType({
      code: props.typeInfo.code,
      queryParams,
      language: getCurrentLocale()
    })

    if (data.results.length) {
      const { data: metaData } = await fetchAttributesMeta({
        queryParams: { codes: data.results.map(({ code }) => code) },
        requestParams: { language: { value: getCurrentLocale() } }
      })

      data.results.forEach((item) => {
        item.value_count =
          metaData.results.find((meta) => meta.code === item.code)?.value_count || 0
      })
    }

    dataSource.value.length = 0
    dataSource.value.push(...data.results)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const showTetherModal = ref(false)
const onAttributeCreated = () => {
  getAttributes()
}

const goToAttribute = (record) => {
  const route = router.resolve({ name: "EditAttribute", params: { attributeCode: record.code } })
  window.open(route.href, "_blank")
}

const onRemoveAttribute = (row) => {
  const params = {
    attributeCode: row.code,
    typeCode: props.typeInfo.code
  }

  Modal.confirm({
    title: i18n.t("removeAttributeFromTypeConfirmation", { attribute: row.name }),
    okText: i18n.t("toUntie"),
    okType: "danger",
    cancelText: i18n.t("cancel"),
    onOk: () => handleRemoveAttribute(params)
  })
}

const handleRemoveAttribute = async (params) => {
  try {
    await unattachTypeFromAttribute(params)

    notification.success({ message: i18n.t("removeAttributeFromTypeSuccess") })
    getAttributes()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const handleAttributeChange = async (record, field) => {
  try {
    isFetching.value = true

    const payload = {
      [field]: Boolean(!record[field])
    }

    await updateAttributeAttachedType({
      attributeCode: record.code,
      typeCode: props.typeInfo.code,
      payload
    })

    notification.success({ message: i18n.t("updated") })
    record[field] = Boolean(!record[field])
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("updateAttributeError") })
  } finally {
    isFetching.value = false
  }
}
const onSortAttrs = async (event) => {
  try {
    isFetching.value = true

    const changedRecord = dataSource.value[event.oldIndex]
    if (!changedRecord) return
    dataSource.value.splice(event.oldIndex, 1)
    dataSource.value.splice(event.newIndex, 0, changedRecord)

    await updateAttributeAttachedType({
      attributeCode: changedRecord.code,
      typeCode: props.typeInfo.code,
      payload: {
        order: event.newIndex
      }
    })

    notification.success({ message: i18n.t("updateAttributesOrderSuccess") })
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("updateAttributesOrderError") })
  } finally {
    isFetching.value = false
  }
}

const onOpenTetherModal = () => {
  showTetherModal.value = true
}
const onCloseTetherModal = () => {
  showTetherModal.value = false
}

onMounted(getAttributes)
watch(() => props.typeInfo.code, getAttributes)
provide("appData", () => ({ dataSource: dataSource.value }))
provide("onSort", onSortAttrs)
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.type-attributes {
  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
  }

  &__actions {
    display: flex;
    gap: 16px;
  }
}
</style>
