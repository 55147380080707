<template>
  <a-table
    :columns="columns"
    :data-source="items"
    row-key="id"
    :pagination="false"
  >
    <template #photoRenderer="text, record">
      <TableRendererPhotoGallery :images="record.images" />
    </template>

    <template #codeTitleRenderer>
      <div>CS-code</div>
      <div>P-code</div>
    </template>

    <template #codeRenderer="text, record">
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <div :style="{ width: '90px' }">{{ record.code }}</div>
        <a-icon
          :style="{ marginRight: '10px', color: '#1890FF' }"
          type="copy"
          @click="handleCopy(record.code)"
        />
        <a
          :style="{ color: '#1890FF' }"
          :href="getCsCodeLink(record)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <div :style="{ width: '90px' }">{{ record.product.p_code }}</div>
        <a-icon
          :style="{ marginRight: '10px', color: '#1890FF' }"
          type="copy"
          @click="handleCopy(record.product.p_code)"
        />
        <a
          :style="{ color: '#1890FF' }"
          :href="getPCodeLink(record)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>
    </template>

    <template #productNameRenderer="text, record">
      <div>{{ record.product.name }}</div>
      <div>
        {{ record.product.category.name }},
        {{ getOfferConditionString(record.condition_key, record.condition_value) }}
        <template
          v-if="
            record.condition_key !== 'w_undetermined' &&
            record.warranty &&
            record.warranty.length > 0
          "
        >
          , {{ $tc("monthFull", +record.warranty, { count: record.warranty }) }}
        </template>
      </div>
    </template>
  </a-table>
</template>

<script setup>
import { message } from "ant-design-vue"
import i18n from "@/i18n"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery"

import { useOfferCondition } from "@/composables/useOfferCondition"
import useOrderItemsColumns from "./columns/useOrderItemsColumns"

import { MARKETPLACE } from "@/constants/common.js"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const columns = useOrderItemsColumns()
const { getOfferConditionString } = useOfferCondition()

const handleCopy = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}
const getCsCodeLink = (record) => {
  return `${MARKETPLACE}/product/${record.product.slug}/${record.product.p_code}/${record.code}`
}
const getPCodeLink = (record) => {
  return `${MARKETPLACE}/product/${record.product.slug}/${record.product.p_code}`
}
</script>
