import { render, staticRenderFns } from "./EditSellerReview.vue?vue&type=template&id=4119b146&scoped=true"
import script from "./EditSellerReview.vue?vue&type=script&setup=true&lang=js"
export * from "./EditSellerReview.vue?vue&type=script&setup=true&lang=js"
import style0 from "./EditSellerReview.vue?vue&type=style&index=0&id=4119b146&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4119b146",
  null
  
)

export default component.exports