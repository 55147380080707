var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.event.events.length)?_c('a-popover',{attrs:{"placement":"left","trigger":"click"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"event-describe",class:{ 'event-describe--wide': _setup.showBigPopUp }},[_c('a-table',{attrs:{"dataSource":_vm.event.events,"columns":_setup.columns,"size":"small","scroll":{ y: 350 },"pagination":false,"rowKey":(record, index) => record.time + index},scopedSlots:_vm._u([{key:"iconRenderer",fn:function(icon, _, __, column){return [(
              (column.key === 'view_icon' && icon === 'view') ||
              (column.key === 'action_icon' && icon !== 'view')
            )?_c('a-icon',{staticClass:"event-describe__icon",attrs:{"type":_setup.eventIcons[icon]}}):_vm._e()]}},{key:"descriptionRenderer",fn:function(_, record, __, column){return [(
              (column.key === 'view_description' && record.event_type === 'view') ||
              (column.key === 'action_description' && record.event_type !== 'view')
            )?_c('div',[_vm._v(" "+_vm._s(_setup.getTooltip(record))+" ")]):_vm._e()]}},{key:"deltaRenderer",fn:function(value){return [_vm._v(" "+_vm._s(_setup.getTimeFromStart(value))+" ")]}}],null,false,1534727472)})],1)]},proxy:true}],null,false,3493609019)},[_c('div',{staticClass:"event",class:_setup.getEventClass(_vm.event)})]):_c('div',{staticClass:"event",class:_setup.getEventClass(_vm.event)})
}
var staticRenderFns = []

export { render, staticRenderFns }