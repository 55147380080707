<template>
  <div>
    <PageHeader title="">
      <template #filters>
        <a-button
          type="primary"
          icon="plus"
          block
          ghost
          @click="() => handleAddCodesModalOpen()"
        >
          {{ $t("addNewCodes") }}
        </a-button>

        <a-input-search
          :placeholder="`P / CS-${$t('code')}, ${$t('name')}`"
          v-model="filteredInfo.search"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-spin :spinning="isCategoriesFetching">
          <a-tree-select
            v-model="filteredInfo.category_path"
            :treeData="categoriesTree"
            :placeholder="$t('category')"
            treeNodeFilterProp="title"
            showSearch
            allowClear
            :replaceFields="{ value: 'path' }"
            style="width: 100%"
            @change="handleTableFilterChange()"
          />
        </a-spin>
      </template>

      <template #actions>
        <a-button
          v-if="expandedRowKeys.length > 0"
          class="table-header__thin-item"
          icon="edit"
          @click="handleEditOffersModalOpen()"
        >
          {{ $t("change") }} ({{ expandedRowKeys.length }})
        </a-button>

        <a-button
          v-if="expandedRowKeys.length > 0"
          class="table-header__thin-item"
          icon="delete"
          @click="showDeleteConfirmation()"
        >
          {{ $t("delete") }} ({{ expandedRowKeys.length }})
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :columns="columns"
      :row-selection="{
        onChange: onRowChange,
        selectedRowKeys: expandedRowKeys,
        fixed: true
      }"
      :dataSource="dataSource"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      :loading="isFetching"
      rowKey="code"
      style="margin-top: 24px"
      @change="onTableChange"
    >
      <template #ratingTitle>
        <a-tooltip>
          <template #title>
            {{ $t("tagRatingTip") }}
          </template>
          <div style="display: flex; justify-content: center; align-items: center; gap: 8px">
            {{ $t("tagRating") }}
            <a-icon
              type="info-circle"
              theme="twoTone"
            />
          </div>
        </a-tooltip>
      </template>

      <template #rating="record">
        <TableEditableCellInput
          :text="record.tags[slug].sort"
          inputHtmlType="number"
          @change="(value, closeEdit) => editOfferSortNumber(record, value, closeEdit)"
        />
      </template>

      <template #product="{ product, code, condition_key }">
        <div class="product-info">
          <div class="offer-image">
            <img
              v-if="product.images.length > 0"
              :src="getImageUrl(product.images[0])"
              alt=" "
            />
          </div>
          <span>
            <div class="product-info__name">
              {{ getLocalizedValue(product.name) }}
            </div>

            <div class="product-info__additionals">
              {{ product.p_code }} | {{ code }} |
              {{ $t(`condition_${condition_key}`) }}
            </div>
          </span>
        </div>
      </template>

      <template #category_name="text">{{ getLocalizedValue(text) }}</template>

      <template #sum="value">
        {{ getFormatedSum(value, true) }}
      </template>

      <template #status="text">
        <div :class="[text ? 'green-text' : 'red-text']">
          {{ text ? $t("selling") : $t("notSelling") }}
        </div>
      </template>

      <template #details="{ description, images, description_mlang }">
        <div style="display: flex; gap: 8px">
          <div
            v-if="images.length > 0"
            class="offer-image"
          >
            <img
              :src="getImageUrl(images[0])"
              alt=" "
            />
          </div>

          <div>
            <a-popover placement="topLeft">
              <template
                v-if="description_mlang?.uk || description_mlang?.ru"
                #content
              >
                <div
                  v-for="(value, key) in description_mlang"
                  :key="key"
                  style="max-width: 450px"
                >
                  {{ key.toUpperCase() + ": " + value }}
                </div>
              </template>

              <template
                v-else
                #content
              >
                <div style="max-width: 450px">
                  {{ description }}
                </div>
              </template>

              <div
                v-if="description"
                class="clamped-text"
              >
                {{ description }}
              </div>

              <div
                v-else-if="description_mlang?.uk || description_mlang?.ru"
                class="clamped-text"
              >
                {{ description_mlang?.uk || description_mlang?.ru }}
              </div>
            </a-popover>
          </div>
        </div>
      </template>
    </a-table>

    <a-drawer
      :title="`${$t('massiveInput')} Cs-code`"
      :visible="addCodesModalVisible"
      :width="700"
      destroyOnClose
      @close="handleAddCodesModalOpen()"
    >
      <MassiveCSCodesModal
        @onCsCodesAdded="onTableChange()"
        @handleModal="handleAddCodesModalOpen()"
      />
    </a-drawer>

    <a-drawer
      :title="$t('changeTagRating')"
      :visible="editModalVisible"
      :width="700"
      destroyOnClose
      @close="handleEditOffersModalClose()"
    >
      <EditSequenceNumberModal
        :expandedRowKeys="expandedRowKeys"
        @submit="onChangeOffers()"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue"
import router from "@/router"
import i18n from "@/i18n"
import { Modal, notification } from "ant-design-vue"

import EditSequenceNumberModal from "./EditSequenceNumberModal.vue"
import MassiveCSCodesModal from "./MassiveCsCodesModal.vue"
import TableEditableCellInput from "@/ant-components/TableEditableCellInput/"
import PageHeader from "@/ant-components/PageHeader"

import {
  deleteOffersFromTag,
  fetchTagOffers,
  editTagOffers
} from "@/modules/MPAdmin/services/tagsService.js"

import { IMAGES_CDN } from "@/constants/common.js"
import getCurrentLocale from "@/utils/getCurrentLocale.js"
import getImagePath from "@/utils/getImagePath.js"
import getFormatedSum from "@/utils/getFormatedSum.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

import useTagsPageColumns from "@/modules/MPAdmin/pages/Tags/useTagsPageColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useCategories from "@/composables/useCategories"

const props = defineProps({
  slug: {
    type: String,
    required: true
  }
})

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange
} = useAntTableQuery({
  queryFunction: fetchTagOffers,
  requestParams: { changeRouterQuery: true, uuid: router.currentRoute.params.uuid }
})

const { tagOffersColumns: columns } = useTagsPageColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  expandedRowKeys.value = []
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const expandedRowKeys = ref([])

const editModalVisible = ref(false)
const addCodesModalVisible = ref(false)

const handleEditOffersModalOpen = () => {
  editModalVisible.value = true
}

const handleEditOffersModalClose = () => {
  editModalVisible.value = false
}

const onChangeOffers = () => {
  expandedRowKeys.value = []
  onTableChange()
  handleEditOffersModalClose()
}

const handleAddCodesModalOpen = () => {
  addCodesModalVisible.value = !addCodesModalVisible.value
}

const getLocalizedValue = (value) => {
  return value && value[getCurrentLocale()]
}

const onRowChange = (selected) => {
  expandedRowKeys.value = selected
}

const onDeleteOffers = async () => {
  try {
    await deleteOffersFromTag({
      uuid: router.currentRoute.params.uuid,
      csCodes: expandedRowKeys.value
    })

    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const showDeleteConfirmation = () => {
  Modal.confirm({
    title: i18n.t("confirmToDeleteOffers"),
    okText: i18n.t("yes"),
    okType: "danger",
    cancelText: i18n.t("no"),
    onOk: onDeleteOffers
  })
}

const editOfferSortNumber = async (record, value, closeEdit) => {
  closeEdit()

  try {
    await editTagOffers(router.currentRoute.params.uuid, [
      {
        offer: record.code,
        sort: value
      }
    ])

    notification.success({
      message: i18n.t("updated")
    })

    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const getImageUrl = (image) => {
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(image, "300x300")}`
}

// Categories
const { categoriesTree, isCategoriesFetching, getCategories } = useCategories({
  selectable: "all"
})

onMounted(() => {
  setupTable({
    defaultSorter: {
      order: "ascend",
      columnKey: "tag_sort"
    }
  })
  getCategories()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.green-text {
  color: $green-color;
}

.red-text {
  color: $red-color;
}

.offer-image {
  height: 42px;
  aspect-ratio: 1;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
}

.product-info {
  display: flex;
  gap: 8px;

  &__name {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__additionals {
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;

    width: 100%;

    opacity: 0.45;
  }
}

.clamped-text {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
