import { render, staticRenderFns } from "./PageEditorModal.vue?vue&type=template&id=0d34ae02&scoped=true"
import script from "./PageEditorModal.vue?vue&type=script&setup=true&lang=js"
export * from "./PageEditorModal.vue?vue&type=script&setup=true&lang=js"
import style0 from "./PageEditorModal.vue?vue&type=style&index=0&id=0d34ae02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d34ae02",
  null
  
)

export default component.exports