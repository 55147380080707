<template>
  <div>
    <a-collapse>
      <a-collapse-panel
        v-for="(alert, index) in notEmptyAlerts"
        :key="`alert-${index}`"
        :header="`${alert.campaign_label}`"
      >
        <a-list
          item-layout="horizontal"
          :data-source="alert.alerts"
          :row-key="(record) => record.uuid"
        >
          <template #renderItem="item">
            <a-list-item>
              <template #actions>
                <a-button
                  :loading="deleteIds.findIndex((uuid) => item.uuid === uuid) !== -1"
                  @click="handleDeleteAlert(item.uuid)"
                >
                  <a-icon type="delete" />
                </a-button>
              </template>
              <a-list-item-meta :description="campaignAlertStatuses[item.message]">
                <template #title>
                  <TableRendererDateTime :date="item.created_at" />
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script setup>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"

import { fetchCPCAlerts, deleteAlert } from "@/modules/ADS/services/CPCServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useAdsComposables from "@/modules/ADS/useAdsComposables.js"
import { computed, onMounted, ref, shallowRef } from "vue"

const { campaignAlertStatuses } = useAdsComposables()

const alerts = ref([])
const isFetching = shallowRef(false)
const deleteIds = ref([])

const notEmptyAlerts = computed(() => {
  return alerts.value.filter((item) => Boolean(item.alerts.length))
})

const getAlerts = async () => {
  isFetching.value = true

  try {
    const { data } = await fetchCPCAlerts()

    alerts.value = data
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleDeleteAlert = async (alertUuid) => {
  deleteIds.value.push(alertUuid)

  try {
    await deleteAlert(alertUuid)

    getAlerts()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    deleteIds.value = [...deleteIds.value].filter((item) => item !== alertUuid)
  }
}

onMounted(getAlerts)
</script>
