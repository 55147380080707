<template>
  <div class="login-wrapper">
    <a-card class="login-wrapper__card">
      <template #title>
        <div class="login-wrapper__card__title">
          <h1>{{ $t("synthetic") }}</h1>
          <h2>{{ $t("authorization") }}</h2>
        </div>
      </template>

      <a-form-model
        ref="formRef"
        :model="formModel"
        :rules="loginRules"
        hideRequiredMark
        @submit.prevent="handleLogin()"
      >
        <div>
          <div v-show="isCodeRequested">
            <a-form-model-item
              v-if="showQrModal"
              :label="$t('qrModalTitle')"
            >
              <img
                :src="qrImageUrl"
                class="user-qr-image"
              />
            </a-form-model-item>

            <a-form-model-item
              prop="two_fa_code"
              :label="$t('enterTwoFaCode')"
            >
              <a-input
                v-model.trim="formModel.two_fa_code"
                size="large"
              />
            </a-form-model-item>
          </div>

          <div v-show="!isCodeRequested">
            <a-form-model-item
              prop="username"
              :label="$t('login')"
            >
              <a-input
                v-model="formModel.username"
                size="large"
              />
            </a-form-model-item>

            <a-form-model-item
              prop="password"
              :label="$t('password')"
            >
              <a-input-password
                v-model="formModel.password"
                size="large"
              />
            </a-form-model-item>
          </div>

          <a-form-model-item>
            <a-button
              type="primary"
              size="large"
              block
              :loading="isLoading"
              htmlType="submit"
            >
              {{ isCodeRequested ? $t("apply") : $t("signIn") }}
            </a-button>
          </a-form-model-item>

          <a-button
            v-show="isCodeRequested"
            size="large"
            block
            :disabled="isLoading"
            @click="onCancel"
          >
            {{ $t("cancel") }}
          </a-button>
        </div>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import { computed, reactive, ref, shallowRef } from "vue"
import { mapActions, mapState } from "vuex"
import i18n from "@/i18n"

import useTwoFactorAuth from "@/composables/useTwoFactorAuth"
import notifyResponseError from "@/utils/notifyResponseError"
import useForm from "@/composables/useForm"

import { requiredRule } from "@/utils/validations"

export default {
  name: "Login",
  setup() {
    const isCodeRequested = shallowRef(false)

    const formRef = ref()
    const formModel = reactive({
      username: "",
      password: "",
      two_fa_code: undefined
    })
    const loginRules = computed(() => ({
      username: [requiredRule.value],
      password: [requiredRule.value],
      two_fa_code: isCodeRequested.value
        ? [requiredRule.value, { len: 6, message: i18n.t("invalidCodeFormat"), trigger: "submit" }]
        : []
    }))
    const { isValidForm, validateForm, resetFields } = useForm(formRef)

    const isSubmiting = shallowRef(false)

    const onCancel = () => {
      resetFields()
      isCodeRequested.value = false
    }

    const { getTwoFactorQr, qrImageUrl, isQrFetching, resetQrImage } = useTwoFactorAuth()

    const showQrModal = computed(() => Boolean(qrImageUrl.value))

    const handleSetupTwoFactorAuth = async () => {
      try {
        await getTwoFactorQr({
          login: formModel.username,
          password: formModel.password
        })
        isCodeRequested.value = true
      } catch (error) {
        notifyResponseError({ error })
      }
    }

    const handleSubmitQr = () => {
      resetQrImage()
      isCodeRequested.value = true
    }

    return {
      formRef,
      formModel,
      loginRules,
      isValidForm,
      validateForm,

      isSubmiting,

      isCodeRequested,
      onCancel,

      qrImageUrl,
      showQrModal,
      isQrFetching,
      handleSetupTwoFactorAuth,
      resetQrImage,
      handleSubmitQr
    }
  },

  computed: {
    ...mapState("Auth", {
      isLogged: (state) => state.isLogged,
      loginRequest: (state) => state.loginRequest
    }),

    isLoading() {
      return this.loginRequest || this.isSubmiting || this.isQrFetching
    }
  },

  mounted() {
    if (this.isLogged) {
      this.redirectFromLogin()
    }
  },

  methods: {
    ...mapActions("Auth", ["login", "redirectFromLogin"]),

    async handleLogin() {
      await this.validateForm()
      if (!this.isValidForm) return

      try {
        this.isSubmiting = true

        await this.login(this.formModel)
      } catch (error) {
        if (this.isCodeRequested && error.response?.data?.code === "E000004") {
          this.$message.error({ content: this.$t("invalidCodeInput"), key: "E000004" })
        } else if (error.response?.data?.code === "E000004") {
          this.isCodeRequested = true
        } else if (error.response?.data?.code === "E000005") {
          this.handleSetupTwoFactorAuth()
        } else if (error.response?.data?.code === "E000003") {
          notifyResponseError({ error })
        } else {
          this.$message.error({ content: this.$t("loginError"), key: "common-error" })
        }
      } finally {
        this.isSubmiting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  height: calc(100vh - 64px - 30px - 40px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__card {
    width: 100%;
    max-width: 400px;
    height: fit-content;
    box-shadow: 4px 4px 16px $border-color;

    &__title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
    }
  }
}

.user-qr-image {
  width: 100%;
  aspect-ratio: 1;
  margin: 0 auto;
}
</style>
