import i18n from "@/i18n"

import { MODERATION_PREFIX } from "@/constants/apiPrefix.js"
import { getLocalizedHeaders } from "./servicesUtils.js"

import { API_V2 } from "@/utils/HttpUtils"
import notifyResponseError from "@/utils/notifyResponseError.js"
import simpleBuildQuery from "@/utils/simpleBuildQuery.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import buildTableQueryParams from "@/utils/buildTableQueryParams.js"

export const notifyCreateProductError = (error) => {
  if (error?.response?.status === 409) {
    notifyResponseError({ error, message: i18n.t("productAlreadyExistWithThisName") })
  } else {
    notifyResponseError({ error, message: i18n.t("creationProductFail") })
  }
}

export const getTranslatedText = async (text, from, to) => {
  // Потрібен роут для Google translate
  return ""
}

export const fetchProducts = ({ queryParams, requestParams, signal }) => {
  const query = getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)
  return API_V2.get(
    `${MODERATION_PREFIX}/products/${query}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductTypes = async ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MODERATION_PREFIX}/product-types/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,

    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductBrands = ({ queryParams, requestParams, signal }) => {
  const query = getFormatedQueryParams(queryParams)

  return API_V2.get(
    `${MODERATION_PREFIX}/brands/${query}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductRu = ({ code, signal }) => {
  return API_V2.get(`${MODERATION_PREFIX}/products/${code}/`, getLocalizedHeaders("ru", signal))
}

export const fetchProductLocalized = ({ code, language = "uk", signal }) => {
  return API_V2.get(`${MODERATION_PREFIX}/products/${code}/`, getLocalizedHeaders(language, signal))
}

export const createProduct = (data) => {
  return API_V2.post(`${MODERATION_PREFIX}/products/`, data, getLocalizedHeaders("ru"))
}

export const updateProductRu = ({ code, product }) => {
  return API_V2.put(`${MODERATION_PREFIX}/products/${code}/`, product, getLocalizedHeaders("ru"))
}

export const updateProductLocalized = ({ code, product, language = "uk" }) => {
  return API_V2.put(
    `${MODERATION_PREFIX}/products/${code}/`,
    product,
    getLocalizedHeaders(language)
  )
}

export const exportProduct = (productCode) => {
  return API_V2.post(`${MODERATION_PREFIX}/products/export/?code=${productCode}`)
}

export const duplicateProduct = (productCode) => {
  return API_V2.post(`${MODERATION_PREFIX}/products/${productCode}/duplicate/`)
}

export const fetchAttributesByType = ({ code, queryParams, language }) => {
  return API_V2.get(
    `${MODERATION_PREFIX}/product-types/${code}/attributes/${simpleBuildQuery(queryParams)}`,
    getLocalizedHeaders(language)
  )
}

export const approveProductTrustStatus = ({ code }) => {
  return API_V2.put(
    `${MODERATION_PREFIX}/products/${code}/`,
    { trust: "TRUSTED" },
    getLocalizedHeaders("ru")
  )
}

export const changeProductQualityMark = ({ code, qualityMark }) => {
  return API_V2.put(
    `${MODERATION_PREFIX}/products/${code}/`,
    { quality_mark: qualityMark },
    getLocalizedHeaders("ru")
  )
}

export const bulkReplaceAttributes = (form) => {
  const payload = new FormData()
  payload.append("update_file", form.files[0])

  return API_V2.post(
    `${MODERATION_PREFIX}/products/replace-attributes/`,
    payload,
    getLocalizedHeaders("ru")
  )
}

export const bulkReplaceProductAttributes = async (form) => {
  const payload = new FormData()
  payload.append("attribute_replace_action", form.choice)
  payload.append("update_file", form.files[0])

  if (form.product_type_id) {
    payload.append("product_type_id", form.product_type_id)
  }

  const { data } = await API_V2.post(
    `${MODERATION_PREFIX}/products/attributes-management/import/`,
    payload,
    getLocalizedHeaders("ru")
  )
  handleCheckImportStatus(data.task_id)
}

export const handleCheckImportStatus = async (task_id) => {
  try {
    const { data } = await API_V2.get(`${MODERATION_PREFIX}/products/results/${task_id}/`)
    if (data.state === "SUCCESS") return getImportResponse(data.url)
    if (data.state === "PENDING") return setTimeout(() => handleCheckImportStatus(task_id), 5000)
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const getImportResponse = async (url) => {
  const link = document.createElement("a")
  link.href = url
  link.download = `attribute_change_result.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const exportProductAttributes = ({ pagination, filters, sorter }) => {
  const queryParams = buildTableQueryParams({
    pagination,
    filters,
    sorter,
    sorterKey: "order_by"
  })
  return API_V2.get(
    `${MODERATION_PREFIX}/products/attributes-management/export/${getFormatedQueryParams(queryParams)}`,
    getLocalizedHeaders("ru")
  )
}

export const uploadImages = (formData) => {
  return API_V2.post(`${MODERATION_PREFIX}/images/`, formData)
}

export const bulkUploadImages = async (files) => {
  const imagePromises = [...files].map((file) => {
    const formData = new FormData()
    formData.append("file", file)
    return uploadImages(formData)
  })

  return Promise.all(imagePromises)
}
