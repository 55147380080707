export default {
  openBillingError: "Can't open seller billing",
  lastOption: "Last option",
  messagesModeration: "Messages moderation",
  firstUser: "First user name / id",
  secondUser: "Second user name / id",
  formatError: "Invalid file format. Allowed formats",

  chats: "Chats",
  message: "Message",
  attachments: "Attachments",
  today: "Today",
  yesterday: "Yesterday",
  no_chats: "Chats not found"
}
