<template>
  <a-drawer
    :width="700"
    :title="$t('additionalButtons.addToBlackList')"
    :visible="addBlackListVisible"
    @close="handleClose"
  >
    <a-radio-group
      :options="options"
      @change="onChangePointBlackList"
    />

    <div class="drawer-footer">
      <a-button @click="handleClose">
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        @click="handleConfirm"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { computed, shallowRef } from "vue"

const props = defineProps({
  addBlackListVisible: {
    type: Boolean,
    default: false
  },
  selectedCsCodes: {
    type: Number,
    default: 1
  },
  selectedPCodes: {
    type: Number,
    default: 1
  }
})
const emit = defineEmits(["submit", "close"])

const codeKind = shallowRef("cs_code")

const options = computed(() => [
  {
    label: `CS_code (${props.selectedCsCodes})`,
    value: "cs_code"
  },
  {
    label: `P_code (${props.selectedPCodes})`,
    value: "p_code"
  }
])
const onChangePointBlackList = (e) => {
  codeKind.value = e.target.value
}
const handleConfirm = () => emit("submit", codeKind.value)
const handleClose = () => emit("close")
</script>
