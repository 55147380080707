import i18n from "@/i18n"
import { computed } from "vue"

const isLowerCase = (str) => str === str.toLowerCase()
const hasWhiteSpace = (str) => str.indexOf(" ") >= 0

export const validateUppercase = (rule, value, callback) => {
  if (isLowerCase(value)) {
    callback(new Error(i18n.t("lowerCaseError")))
  } else {
    callback()
  }
}

export const validateWhiteSpace = (rule, value, callback) => {
  if (hasWhiteSpace(value)) {
    callback(new Error(i18n.t("whiteSpaseError")))
  } else {
    callback()
  }
}

export const requiredRule = computed(() => ({
  required: true,
  message: i18n.t("emptyError")
}))

export const arrayValidator = (_, value, callback) => {
  if (!value.length) return callback(new Error(i18n.t("emptyError")))
  callback()
}

export const emailRule = computed(() => ({
  required: true,
  type: "email",
  message: i18n.t("invalidEmailError")
}))

export const csCodesValidator = (_, value, callback) => {
  if (value.trim() === "") {
    return callback(new Error(i18n.t("thisFieldMustBeFilled")))
  }
  const splitedCodes = value.split(/[\r\n, ]+/).filter((item) => item.trim() !== "")

  for (const code of splitedCodes) {
    if (!code.startsWith("CS") || code.toUpperCase() !== code) {
      return callback(new Error(i18n.t("invalidCsCode")))
    }
    if (code.length < 9 || code.length > 10) {
      return callback(new Error(i18n.t("invalidCsCode")))
    }
  }
  callback()
}
