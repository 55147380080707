<template>
  <a-form-model
    class="import-values"
    ref="formRef"
    :model="form"
    :rules="formRules"
  >
    <a-button
      type="dashed"
      icon="download"
      block
      :disabled="isFetching"
      @click="downloadExample"
    >
      {{ $t("downloadExample") }}
    </a-button>

    <a-form-model-item
      :label="$t('selectFiles')"
      prop="files"
    >
      <a-upload-dragger
        :beforeUpload="(e) => onChooseFile(e)"
        accept="text/csv"
        :fileList="form.files"
        :remove="() => (form.files = [])"
        :disabled="isFetching"
      >
        <div class="upload-field">
          <a-icon
            type="inbox"
            class="upload-field__icon"
          />

          {{ $t("chooseOrDragForUpload") }}
        </div>
      </a-upload-dragger>
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import { productAttributesFileExample } from "./atributesChangeFileExamples"
import { bulkReplaceProductAttributes } from "@/modules/Moderation/services/moderationProductsService"

import { downloadCsv } from "@/utils/downloadCsv"
import { arrayValidator, requiredRule } from "@/utils/validations"
import notifyResponseError from "@/utils/notifyResponseError"
import useForm from "@/composables/useForm"

const emit = defineEmits(["close"])

const formRef = ref()
const form = reactive({
  files: []
})
const formRules = computed(() => ({
  files: [{ validator: arrayValidator }]
}))
const { isValidForm, validateForm } = useForm(formRef)

const downloadExample = () => {
  downloadCsv(productAttributesFileExample, "product-attributes-import-example")
}

const onChooseFile = (file) => {
  if (file.name.split(".")?.at(-1) !== "csv") {
    notification.error({ message: `${i18n.t("formatError")}: .csv` })
    return false
  }
  form.files.length = 0
  form.files.push(file)
  return false
}

const isFetching = shallowRef(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    await bulkReplaceProductAttributes(form)
    notification.info({ message: i18n.t("massiveChangeWithFileStarted") })
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>

<style lang="scss" scoped>
.import-values {
  padding-bottom: 50px;
}
.upload-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;

  font-size: 16px;

  &__icon {
    color: $primary-color;
    font-size: 36px;
  }
}
</style>
