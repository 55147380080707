<template>
  <a-form-model-item :label="$t('permissions')">
    <div class="permissions">
      <div class="permissions__block-list">
        <PermissionCheckbox
          v-for="application in applicationDataSource"
          :key="application.id"
          :permission="application"
          :selectedPermission="selectedApplication"
          :selectedPermissionList="selectedPermissions"
          :roleId="props.roleId"
          @select="toggleApplication(application.id)"
          @add="onPermissionAdded(application)"
          @remove="onPermissionRemoved(application)"
        />
        <a-spin v-if="isApplicationsFetching" />
      </div>

      <div
        v-if="componentsDataSource.length || isComponentsFetching"
        class="permissions__block-list"
      >
        <PermissionCheckbox
          v-for="component in componentsDataSource"
          :key="component.id"
          :permission="component"
          :selectedPermission="selectedComponent"
          :selectedPermissionList="selectedPermissions"
          :roleId="props.roleId"
          @select="toggleComponent(component.id)"
          @add="onPermissionAdded(component)"
          @remove="onPermissionRemoved(component)"
        />
        <a-spin v-if="isComponentsFetching" />
      </div>

      <div
        v-if="viewsDataSource.length || isViewsFetching"
        class="permissions__block-list"
      >
        <PermissionCheckbox
          v-for="view in viewsDataSource"
          :key="view.id"
          :permission="view"
          :selectedPermission="selectedView"
          :selectedPermissionList="selectedPermissions"
          :roleId="props.roleId"
          @select="toggleView(view.id)"
          @add="onPermissionAdded(view)"
          @remove="onPermissionRemoved(view)"
        />
        <a-spin v-if="isViewsFetching" />
      </div>
    </div>
  </a-form-model-item>
</template>

<script setup>
import { onMounted, ref } from "vue"
import PermissionCheckbox from "./PermissionCheckbox.vue"
import notifyResponseError from "@/utils/notifyResponseError"
import {
  fetchPermissionApps,
  fetchPermissionChildrens
} from "@/modules/UsersManagement/services/rolesService"

const props = defineProps({
  permissions: {
    type: Array,
    required: true
  },
  roleId: {
    type: Number,
    required: false
  }
})

// select
const selectedPermissions = ref(props.permissions?.map(({ id }) => id))
const onPermissionAdded = (permission) => {
  selectedPermissions.value.push(permission.id)
  props.permissions.push(permission)
}
const onPermissionRemoved = (permission) => {
  const permissionIndex = selectedPermissions.value.findIndex((item) => item === permission.id)
  if (permissionIndex < 0) return
  selectedPermissions.value.splice(permissionIndex, 1)
  props.permissions.splice(permissionIndex, 1)
}

// applications
const isApplicationsFetching = ref(false)
const applicationDataSource = ref([])

const getApplications = async () => {
  try {
    applicationDataSource.value.length = 0
    isApplicationsFetching.value = true

    const { data } = await fetchPermissionApps()
    applicationDataSource.value.push(...data.results)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isApplicationsFetching.value = false
  }
}
const selectedApplication = ref()
const toggleApplication = (code) => {
  selectedApplication.value = selectedApplication.value === code ? undefined : code
  selectedComponent.value = undefined
  viewsDataSource.value.length = 0
  getComponents()
}

// components
const isComponentsFetching = ref(false)
const componentsDataSource = ref([])
const getComponents = async () => {
  componentsDataSource.value.length = 0
  if (!selectedApplication.value) return

  try {
    isComponentsFetching.value = true

    const { data } = await fetchPermissionChildrens({
      queryParams: { parent_id: selectedApplication.value, pageSize: 100 }
    })

    componentsDataSource.value.push(...data.results)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isComponentsFetching.value = false
  }
}

const selectedComponent = ref(null)
const toggleComponent = (code) => {
  selectedComponent.value = selectedComponent.value === code ? undefined : code
  getViews()
}

// views
const isViewsFetching = ref(false)
const viewsDataSource = ref([])

const getViews = async () => {
  viewsDataSource.value.length = 0
  if (!selectedComponent.value) return

  try {
    isViewsFetching.value = true

    const { data } = await fetchPermissionChildrens({
      queryParams: { parent_id: selectedComponent.value, pageSize: 100 }
    })

    viewsDataSource.value.push(...data.results)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isViewsFetching.value = false
  }
}
const selectedView = ref()
const toggleView = (code) => {
  selectedView.value = selectedView.value === code ? undefined : code
}

onMounted(getApplications)
</script>

<style lang="scss" scoped>
.permissions {
  display: flex;
  gap: 24px;

  &__block-list {
    width: calc(100% / 3 - 16px);
    height: fit-content;
    background-color: $light-border;
    padding: 1px;
    display: flex;
    flex-flow: column nowrap;
    gap: 1px;

    border-radius: 2px;
    overflow: hidden;

    & > :first-child {
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }

    & > :last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}
</style>
