<template>
  <div>
    <div class="table-header__footer">
      <div class="table-header__footer__filters">
        <a-input-search
          v-model="filteredInfo.code"
          :placeholder="$t('code')"
          allowClear
          :disabled="isValuesChanged"
          @change="onFilterChange('code', Boolean($event?.target?.value))"
          @pressEnter="onFilterChange('code')"
        >
          <template #enterButton>
            <a-button
              icon="search"
              :disabled="isValuesChanged"
              @focus="onFilterChange('code')"
            />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.value_str"
          :placeholder="$t('value')"
          allowClear
          :disabled="isValuesChanged"
          @change="onFilterChange('value_str', Boolean($event?.target?.value))"
          @pressEnter="onFilterChange('value_str')"
        >
          <template #enterButton>
            <a-button
              icon="search"
              :disabled="isValuesChanged"
              @focus="onFilterChange('value_str')"
            />
          </template>
        </a-input-search>

        <InfiniteScrollSelect
          v-if="props.attribute.value_type === 'NUMERIC'"
          :value="filteredInfo.unit"
          :customRequest="fetchUnits"
          labelKey="name"
          valueKey="oid"
          :placeholder="$t('unit')"
          :isLoading="isMetaFetching"
          :isDisabled="isValuesChanged"
          :defaultOptions="defaultUnitOptions"
          @change="onUnitFilterChange"
        />

        <a-select
          v-model="filteredInfo.trust"
          :options="trustOptions"
          :placeholder="$t('status')"
          allowClear
          :disabled="isValuesChanged"
          @change="onFilterChange('status')"
        />
      </div>

      <div class="table-header__footer__actions">
        <a-button
          icon="undo"
          :disabled="isValuesChanged"
          @click="onResetFilters()"
        >
          {{ $t("resetFilters") }}
        </a-button>

        <a-dropdown :disabled="isValuesChanged">
          <a-button icon="file">{{ $t("import") }} / {{ $t("export") }}</a-button>

          <template #overlay>
            <a-menu>
              <a-menu-item @click="onOpenImportModal">
                {{ $t("import") }}
              </a-menu-item>
              <a-menu-item
                :loading="isExporting"
                :disabled="!paginationInfo.total"
                @click="onExportValues"
              >
                {{ $t("export") }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <a-dropdown
          v-if="attribute.value_type === 'STRING'"
          :disabled="isValuesChanged"
        >
          <a-button icon="global">{{ $t("importAttrValuesLocales") }}</a-button>

          <template #overlay>
            <a-menu>
              <a-menu-item @click="onOpenImportLocalesModal">
                {{ $t("importLocales") }}
              </a-menu-item>
              <a-menu-item
                :loading="isExportingLocales"
                :disabled="!paginationInfo.total"
                @click="onExportLocalesValues"
              >
                {{ $t("exportLocales") }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <a-button
          type="primary"
          icon="plus"
          :disabled="isValuesChanged"
          @click="onOpenAddModal"
        >
          {{ $t("addValue") }}
        </a-button>
      </div>
    </div>

    <a-table
      :columns="attribute.value_type === 'NUMERIC' ? numericColumns : columns"
      :dataSource="dataSource"
      :pagination="{ ...paginationInfo, disabled: isValuesChanged }"
      :loading="isFetching || isMetaFetching"
      rowKey="code"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #defaultRenderer="value">
        <div class="default-cell">
          {{ value }}
        </div>
      </template>

      <template #valueNumericRenderer="record">
        <a-input-number
          v-model="record.value"
          style="width: 100%"
          @change="onValueChange(record)"
        />
      </template>

      <template #valueUaRenderer="record">
        <a-input
          v-model="record.value.uk"
          @change="onValueChange(record)"
        />
      </template>

      <template #valueRuRenderer="record">
        <a-input
          v-model="record.value.ru"
          @change="onValueChange(record)"
        />
      </template>

      <template #valueEnRenderer="record">
        <a-input
          v-model="record.value.en"
          @change="onValueChange(record)"
        />
      </template>

      <template #unitRenderer="record">
        <InfiniteScrollSelect
          ref="tableUnitRef"
          :value="record.unit?.oid"
          :customRequest="fetchUnits"
          labelKey="name"
          valueKey="oid"
          :defaultOptions="getDefaultUnitOptions(record.unit)"
          style="width: 100%; max-width: 490px"
          @change="onUnitValueChange($event, record)"
        />
      </template>

      <template #trustRenderer="record">
        <span :class="record.trust">{{ $t(`moderationTrustStatus.${record.trust}`) }}</span>
      </template>

      <template #originRenderer="record">
        <span>{{ $t(`originOptions.${record.origin}`) }}</span>
      </template>

      <template #actionsRenderer="record">
        <div class="default-cell actions-cell">
          <a-tooltip
            v-if="record.trust === 'UNTRUSTED'"
            :title="$t('changeStatus')"
          >
            <a-button
              type="dashed"
              shape="circle"
              icon="check"
              class="UNTRUSTED"
              :disabled="isValuesChanged"
              @click="onTrustValue(record)"
            />
          </a-tooltip>

          <a-tooltip :title="$t('products')">
            <a-button
              type="dashed"
              shape="circle"
              icon="unordered-list"
              :disabled="isValuesChanged"
              @click="onOpenProductsModal(record)"
            />
          </a-tooltip>

          <a-tooltip :title="$t('delete')">
            <a-button
              icon="delete"
              type="dashed"
              shape="circle"
              ghost
              :class="{ 'ant-btn-danger': !isValuesChanged }"
              :disabled="isValuesChanged"
              @click="onDeleteValue(record)"
            />
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="showAddModal"
      destroyOnClose
      :width="500"
      :title="$t('addValue')"
      @close="onCloseAddModal"
    >
      <AddValueForm
        :attributeCode="attribute.code"
        :attributeType="attribute.value_type"
        :attributeUnit="attribute.default_unit"
        :defaultUnits="defaultUnitOptions"
        @close="onCloseAddModal"
        @submit="onTableChange()"
      />
    </a-drawer>

    <a-drawer
      :visible="showImportModal"
      destroyOnClose
      :width="600"
      :title="$t('import')"
      @close="onCloseImportModal"
    >
      <ImportAttributeValues @close="onCloseImportModal" />
    </a-drawer>

    <a-drawer
      :visible="showImportLocalesModal"
      destroyOnClose
      :width="600"
      :title="$t('importLocales')"
      @close="onCloseImportLocalesModal"
    >
      <ImportAttributeValues
        isLocalisedModal
        @close="onCloseImportLocalesModal"
      />
    </a-drawer>

    <a-drawer
      :visible="showProductsModal"
      destroyOnClose
      width="50%"
      :title="productsModalTitle"
      @close="onCloseProductsModal"
    >
      <ProductsModal
        :selectedValue="selectedValue"
        @close="onCloseProductsModal"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, shallowRef, watch } from "vue"
import { Modal } from "ant-design-vue"
import i18n from "@/i18n"

import AddValueForm from "./AddValueForm.vue"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import ImportAttributeValues from "./ImportAttributeValues.vue"
import ProductsModal from "./ProductsModal.vue"

import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useAttributeValuesColumns from "../../composables/useAttributeValuesColumns.js"
import useModerationOptions from "@/modules/Moderation/composables/useModerationOptions.js"
import useDebounce from "@/composables/useDebounce"

import {
  deleteValueForAttribute,
  exportAttributeValues,
  exportLocalizedAttributeValues,
  fetchLocalisedAttributeValuesList,
  updateLocalizedValueForAttribute,
  updateValueForAttribute
} from "../../../services/attributeValuesService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { fetchAttributeUnits } from "@/modules/Moderation/services/attributesService.js"
import { fetchUnits } from "@/modules/Moderation/services/unitsService.js"

const props = defineProps({
  attribute: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(["onValuesStateChanged"])

const { columns, numericColumns } = useAttributeValuesColumns()
const { trustOptions } = useModerationOptions()
const {
  dataSource,
  paginationInfo,
  filteredInfo,
  isFetching,
  handleTableFilterChange,
  setFilteredInfo,
  fetchTableInfo,
  setupTable
} = useAntTableQuery({
  queryFunction: fetchLocalisedAttributeValuesList,
  requestParams: { valueType: props.attribute.value_type, changeRouterQuery: true }
})
const { debounce, resetAllDebounces } = useDebounce()

const onTableChange = (pagination = paginationInfo.value) => {
  resetAllDebounces()
  fetchTableInfo({ pagination, filters: filteredInfo.value })
}
const onFilterChange = (key, delayRequest = false) => {
  resetAllDebounces()
  if (delayRequest) return debounce(key, handleTableFilterChange, 500)
  handleTableFilterChange()
}

const onResetFilters = () => {
  const defaultFilters = {
    attribute: props.attribute.code || ""
  }

  setFilteredInfo(defaultFilters)
  onTableChange()
}

const getPossibleUnits = async () => {
  if (!props.attribute?.code) return
  if (props.attribute?.value_type !== "NUMERIC") return
  try {
    isMetaFetching.value = true

    const { data } = await fetchAttributeUnits(props.attribute.code)
    defaultUnitOptions.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isMetaFetching.value = false
  }
}

// ADD VALUE
const showAddModal = ref(false)

const onOpenAddModal = () => {
  showAddModal.value = true
}

const onCloseAddModal = () => {
  showAddModal.value = false
}

// IMPORT
const showImportModal = shallowRef(false)

const onOpenImportModal = () => {
  showImportModal.value = true
}

const onCloseImportModal = () => {
  showImportModal.value = false
}

// EXPORT
const isExporting = shallowRef(false)
const onExportValues = async () => {
  try {
    isExporting.value = true

    const queryParams = {
      attribute: props.attribute.code,
      pageSize: paginationInfo.value.total
    }
    await exportAttributeValues({ queryParams })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isExporting.value = false
  }
}
// IMPORT LOCALES
const showImportLocalesModal = shallowRef(false)
const onOpenImportLocalesModal = () => {
  showImportLocalesModal.value = true
}
const onCloseImportLocalesModal = () => {
  showImportLocalesModal.value = false
}

// EXPORT LOCALES
const isExportingLocales = shallowRef(false)
const onExportLocalesValues = async () => {
  try {
    isExportingLocales.value = true

    const queryParams = {
      attribute: props.attribute.code,
      pageSize: paginationInfo.value.total
    }
    await exportLocalizedAttributeValues({ queryParams })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isExportingLocales.value = false
  }
}

// DELETE VALUE
const onDeleteValue = (record) => {
  Modal.confirm({
    title: i18n.t("confirmToDeleteAttributeValue"),
    okText: i18n.t("yes"),
    okType: "danger",
    cancelText: i18n.t("no"),
    onOk: () => handleDeleteValue(record)
  })
}

const handleDeleteValue = async (record) => {
  try {
    await deleteValueForAttribute(record)
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onUnitFilterChange = (value) => {
  resetAllDebounces()
  filteredInfo.value.unit = value
  handleTableFilterChange()
}

// CHANGES
const changedValues = ref([])
const isValuesChanged = computed(() => Boolean(changedValues.value?.length))

const getDefaultUnitOptions = (unit) => {
  if (!unit) return defaultUnitOptions.value
  if (defaultUnitOptions.value.some((item) => item.oid === unit.oid))
    return defaultUnitOptions.value
  return [unit, ...defaultUnitOptions.value]
}

const onValueChange = (record) => {
  resetAllDebounces()

  debounce("valueChange", saveChangedValues, 2500)

  if (changedValues.value.includes(record.code)) return
  changedValues.value.push(record.code)
}

const tableUnitRef = ref()
const onUnitValueChange = (value, record) => {
  const unit = tableUnitRef.value?.selectOptions?.find((item) => item.oid === value)

  record.unit = unit || null
  onValueChange(record)
}

const onResetChanges = () => {
  changedValues.value = []
  onTableChange()
}

const getChangedRows = () => {
  const payload = changedValues.value
    .map((value) => {
      return dataSource.value.find((item) => item.code === value)
    })
    .filter(Boolean)

  return payload
}

const saveChangedValues = async () => {
  const newValues = getChangedRows()

  resetAllDebounces()

  try {
    emit("changeFetchingState", true)

    if (props.attribute.value_type === "NUMERIC") {
      await Promise.all(
        newValues.map(async ({ value, code, unit }) => {
          await updateValueForAttribute({
            payload: { value, attribute: props.attribute.code, unit: unit?.oid || null },
            code
          })
        })
      )
    } else {
      await Promise.all(
        newValues.map(async ({ value, code, trust }) => {
          await updateLocalizedValueForAttribute({
            payload: { value, attribute: props.attribute.code, trust },
            code
          })
        })
      )
    }
    changedValues.value = []
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    emit("changeFetchingState", false)
  }
}

const onTrustValue = async (record) => {
  try {
    await updateValueForAttribute({
      payload: { trust: "TRUSTED", attribute: props.attribute.code },
      code: record.code
    })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

// PRODUCTS
const showProductsModal = shallowRef(false)
const selectedValue = ref()

const productsModalTitle = computed(() => {
  if (!selectedValue.value) return ""
  return `${i18n.t("productListWithValue")}: ${selectedValue.value}`
})

const onOpenProductsModal = (record) => {
  selectedValue.value = record.code
  showProductsModal.value = true
}
const onCloseProductsModal = () => {
  showProductsModal.value = false
  selectedValue.value = undefined
}

// SETUP
const isMetaFetching = ref(false)
const defaultUnitOptions = ref([])

onMounted(() => {
  setupTable({
    defaultFilters: { attribute: props.attribute.code || "" }
  })
  getPossibleUnits()
  onTableChange()
})

watch(
  () => changedValues.value.length,
  (value) => {
    emit("onValuesStateChanged", Boolean(value))
  }
)

defineExpose({
  getChangedRows,
  onResetChanges,
  saveChangedValues,
  getPossibleUnits
})
</script>

<style lang="scss" scoped>
.table-filters {
  display: flex;
  gap: 16px;
  padding-bottom: 30px;

  & > * {
    width: 220px;
  }
}

.default-cell {
  min-height: 33px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions-cell {
  justify-content: flex-end;
}
</style>
