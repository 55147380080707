import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      width: 80,
      scopedSlots: {
        customRender: "photoRenderer"
      }
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: `P ${i18n.t("code")}`,
      width: 100
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("firstName"),
      width: 800
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model"),
      width: 170
    }
  ])

  return columns
}
