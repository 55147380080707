<template>
  <a-table
    :columns="columns"
    :data-source="items"
    row-key="id"
    :pagination="false"
    :style="{ maxWidth: '900px' }"
  >
    <template #dateRenderer="text">
      <TableRendererDateTime :date="text" />
    </template>

    <template #senderRanderer="text, record">
      <template v-if="record.sender_data.seller.name">
        <div>{{ record.sender_data.seller.name }}</div>
        <div :style="{ opacity: '0.45' }">+{{ record.sender_data.mobile_phone }}</div>
      </template>
      <template v-else>
        <div>
          {{ record.sender_data.first_name }} {{ record.sender_data.last_name }}
          {{ record.sender_data.middle_name }}
        </div>
        <div :style="{ opacity: '0.45' }">+{{ record.sender_data.mobile_phone }}</div>
      </template>
    </template>

    <template #receiverRanderer="text, record">
      <template v-if="record.receiver_data.seller.name">
        <div>{{ record.receiver_data.seller.name }}</div>
        <div :style="{ opacity: '0.45' }">+{{ record.receiver_data.mobile_phone }}</div>
      </template>
      <template v-else>
        <div>
          {{ record.receiver_data.first_name }} {{ record.receiver_data.last_name }}
          {{ record.receiver_data.middle_name }}
        </div>
        <div :style="{ opacity: '0.45' }">+{{ record.receiver_data.mobile_phone }}</div>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import useOrderTransactionsColumns from "./columns/useOrderTransactionsColumns"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})
const columns = useOrderTransactionsColumns()
</script>
