<template>
  <div>
    <a-layout-header class="header">
      <div
        v-if="loginRequest || fetching"
        class="header__loader"
      >
        <a-spin size="large" />
      </div>
      <template v-else-if="isLogged">
        <Navigation />

        <div style="margin-left: auto">
          <!-- <LangSwitcher /> -->
          <LoginNav
            :profile="profile"
            @logout="logout(false)"
          />
        </div>
      </template>
    </a-layout-header>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Navigation from "./Navigation/Navigation"
import LoginNav from "@/modules/Auth/components/LoginNav"
import LangSwitcher from "@/components/LangSwitcher.vue"

export default {
  name: "Header",
  components: {
    Navigation,
    LoginNav,
    LangSwitcher
  },
  props: {
    fetching: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("Auth", {
      isLogged: (state) => state.isLogged,
      profile: (state) => state.profile,
      loginRequest: (state) => state.loginRequest
    })
  },
  methods: {
    ...mapActions("Auth", ["logout"])
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  position: "fixed";
  z-index: 1;
  width: "100%";
  background-color: #fff;
  align-items: center;

  &__loader {
    text-align: center;
    width: 100%;
  }
}
</style>
