<template>
  <div>
    <div
      v-for="note in items"
      :style="{ marginBottom: '5px' }"
    >
      <span :style="{ fontWeight: 500, marginRight: '10px' }">{{
        getFormatedDateTime(note.created_at)
      }}</span>
      <template v-if="note.type === 'MISSED_CALL'">
        {{ $t("failedCall") }}
      </template>
      <template v-if="note.type === 'ORDER_CANCELLED'">
        {{ canceledText }} {{ note.body }}
      </template>
      <template v-else>
        {{ note.body }}
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"
import i18n from "@/i18n"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  orderStatus: {
    type: String,
    required: true
  },
  rejectReason: {
    type: String,
    default: ""
  }
})

const canceledText = computed(() => {
  if (props.orderStatus === "CANCELED_BY_SELLER") {
    return `${i18n.t("orderStatuses.canceled_by_seller")}. ${props.rejectReason}`
  }
  if (props.orderStatus === "CANCELED_BY_SUPERVISOR") {
    return `${i18n.t("orderStatuses.canceled_by_supervisor")}. ${props.rejectReason}`
  }
  if (props.orderStatus === "CANCELED_BY_CUSTOMER") {
    return `${i18n.t("orderStatuses.canceled_by_customer")}. ${props.rejectReason}`
  }

  return props.rejectReason
})
</script>
