<template>
  <div>
    <PageHeader :title="$t('users')">
      <template #filters>
        <a-input-search
          v-for="field in searchFields"
          v-model="filteredInfo[field.key]"
          :key="field.key"
          :placeholder="field.label"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>
      </template>

      <template #actions>
        <a-button
          icon="undo"
          :loading="isFetching"
          @click="handleTableFiltersReset()"
        >
          {{ $t("resetFilters") }}
        </a-button>
        <a-button
          type="primary"
          icon="plus"
          @click="openCreateModal()"
        >
          {{ $t("createUser") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      rowKey="id"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="paginationInfo"
      :loading="isFetching"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #contactsRenderer="email">
        <UserContacts :email="email" />
      </template>

      <template #isActiveRenderer="isActive">
        <div :class="[isActive ? 'success-text' : 'secondary-text']">
          {{ isActive ? $t("active") : $t("inactive") }}
        </div>
      </template>

      <template #actionsRenderer="record">
        <div class="table-row-actions">
          <a-button
            type="dashed"
            :disabled="isMetaFetching"
            @click="openRolesModal(record)"
          >
            {{ $t("roles") }}
          </a-button>
          <a-button
            type="dashed"
            shape="circle"
            icon="edit"
            @click="openEditModal(record)"
          />
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="createModalIsOpened"
      :title="$t('createUser')"
      :width="500"
      destroyOnClose
      @close="closeCreateModal"
    >
      <CreateModal @onCreate="onCreateUser" />
    </a-drawer>

    <a-drawer
      :visible="editModalIsOpened"
      :title="$t('editUser')"
      :width="500"
      destroyOnClose
      @close="closeEditModal"
    >
      <EditModal
        v-if="editModalIsOpened"
        :user="chosenUser"
        :roles="roles"
        :defaultTab="defaultTab"
        @onUpdateUser="onUpdateUser"
        @close="closeEditModal"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, shallowRef } from "vue"
import UserContacts from "@/modules/MPAdmin/pages/renderers/UserContacts.vue"
import CreateModal from "./components/CreateUserModal.vue"
import EditModal from "./components/EditUserModal.vue"

import PageHeader from "@/ant-components/PageHeader/index.js"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"

import { getRoles } from "@/modules/UsersManagement/services/rolesService.js"
import { getUsers } from "@/modules/UsersManagement/services/usersService.js"

import useUsersColumns from "./useUsersColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import i18n from "@/i18n"
import useDebounce from "@/composables/useDebounce"

const { debounce, resetDebounce } = useDebounce()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: getUsers,
  requestParams: { changeRouterQuery: true }
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}
const columns = useUsersColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const searchFields = computed(() => [
  {
    key: "username",
    label: i18n.t("userName")
  }
])

const editModalIsOpened = shallowRef(false)
const createModalIsOpened = shallowRef(false)
const chosenUser = ref()
const roles = ref([])
const isMetaFetching = ref(false)

const getMeta = async () => {
  try {
    isMetaFetching.value = true

    const { data } = await getRoles({ queryParams: { pageSize: 100 } })

    roles.value = data.results
    isMetaFetching.value = false
    resetDebounce("users_meta")
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      isMetaFetching.value = false
      return
    }

    debounce("users_meta", getMeta, 5000)
  }
}
const openCreateModal = () => {
  createModalIsOpened.value = true
}

const closeCreateModal = () => {
  createModalIsOpened.value = false
}

const defaultTab = shallowRef()
const openRolesModal = (row) => {
  defaultTab.value = "roles"
  openEditModal(row)
}

const openEditModal = (row) => {
  chosenUser.value = { ...row }
  editModalIsOpened.value = true
}

const closeEditModal = () => {
  editModalIsOpened.value = false
  chosenUser.value = undefined
  defaultTab.value = undefined
}

const onUpdateUser = () => {
  onTableChange()
}

const onCreateUser = () => {
  closeCreateModal()
  onTableChange()
}

onMounted(() => {
  getMeta()

  setupTable({
    defaultSorter: {
      columnKey: "date_joined",
      order: "ascend"
    },
    sortKey: "sort_by"
  })
  onTableChange()
})
</script>

<style scoped lang="scss">
.table-row-actions {
  display: flex;
  gap: 32px;
}

.success-text {
  color: $green-color;
}
.secondary-text {
  opacity: 0.45;
}

.user-qr-image {
  width: 400px;
  height: 400px;
  margin: 0 auto;
}
</style>
