<template>
  <div
    class="category-list"
    :class="`category-list__${depth + 1}`"
  >
    <a-spin :spinning="isFetching">
      <Draggable
        v-model="categoriesList"
        :animation="200"
        draggable=".category-list__item"
        @end="onDragEnd"
      >
        <div
          v-for="category in categoriesList"
          :key="category.id"
          class="category-list__item"
          :class="{
            'category-list__item--disabled': !category.is_active,
            'category-list__item--opened': chosenCategory?.id === category.id
          }"
          @click.stop="chooseCategory(category)"
          @dblclick.stop="goToEditCategory(category.id)"
        >
          <a-icon
            type="menu"
            class="category-list__item__grab-icon"
          />
          <a-icon
            type="setting"
            theme="filled"
            class="category-list__item__icon"
            @click.stop="goToEditCategory(category.id)"
          />
          <a-icon
            v-if="!category?.children_count"
            type="sync"
            class="category-list__item__icon"
            @click.stop="handleReindex(category.id)"
          />
          <span>
            {{ category.name.ru ? category.name.ru : category.name }}
          </span>
          <a-badge
            :count="category.children_count"
            :number-style="{
              backgroundColor: 'grey',
              color: 'white',
              boxShadow: 'none'
            }"
          >
          </a-badge>
        </div>

        <template
          #footer
          v-if="depth < 4"
        >
          <a-button
            block
            icon="plus"
            class="category-list__add-button"
            @click="createCategory()"
          >
            {{ $t("addCategory") }}
          </a-button>
        </template>
      </Draggable>
    </a-spin>

    <CategoriesList
      v-if="chosenCategory?.id"
      :parent="chosenCategory.id"
      :depth="depth + 1"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue"
import { notification, message } from "ant-design-vue"
import router from "@/router"

import Draggable from "vuedraggable"
import i18n from "@/i18n"

import {
  updateCategoryOrdering,
  fetchChildrenCategories,
  reindexCategory
} from "@/modules/MPAdmin/services/categoriesService.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  categories: {
    type: Array,
    default: () => []
  },
  isShow: {
    type: Boolean,
    default: false
  },
  moduleName: {
    type: String
  },
  depth: {
    type: Number,
    default: 0
  },
  parent: {
    type: Number,
    default: null
  }
})

const categoriesList = ref([])
const chosenCategory = ref()
const isFetching = ref(false)

const onDragEnd = async ({ newIndex, oldIndex }) => {
  if (newIndex === oldIndex) return

  try {
    isFetching.value = true
    const category = categoriesList.value[newIndex]

    await updateCategoryOrdering({ ordering: newIndex, parent: category.parent }, category.id)
    notification.success({
      message: i18n.t("categoriesUpdatedSuccess")
    })
  } catch (error) {
    notifyResponseError({ error })
    getCategoriesList()
  } finally {
    isFetching.value = false
  }
}

const createCategory = () => {
  const parent = props.parent || undefined
  router.push({ name: "CreateCategory", query: { parent: parent } })
}

const chooseCategory = (category) => {
  if (props.depth === 3) return

  chosenCategory.value = category
}

const getCategoriesList = async () => {
  try {
    chosenCategory.value = undefined
    isFetching.value = true
    const { data } = await fetchChildrenCategories(props.parent || null)

    categoriesList.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const goToEditCategory = (id) => {
  router.push({ name: "EditCategory", params: { id } })
}

const handleReindex = async (id) => {
  try {
    await reindexCategory(id)

    message.success(i18n.t("reindexSuccess"))
  } catch {
    message.error(i18n.t("reindexFailure"))
  }
}

onMounted(getCategoriesList)

watch(() => props.parent, getCategoriesList)
</script>

<style lang="scss" scoped>
.category-list {
  display: flex;

  &__1 {
    width: 100%;

    & > * {
      width: calc(100% / 4);
    }
  }

  // calculate width for nested categories
  @for $i from 2 through 4 {
    &__#{$i} {
      width: calc(100% / #{6 - $i} * #{5 - $i});

      & > * {
        width: calc(100% / #{5 - $i});
      }
    }
  }

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;
    position: relative;

    padding: 8px 24px;
    background: $light-green;
    border: 1px solid $light-border;
    border-top: none;
    color: $icon-color;

    &--disabled {
      background: $light-red;
    }

    &--opened {
      background-color: #1890ff;
      color: #fff;
    }

    & * {
      cursor: default;
    }

    &__icon {
      cursor: pointer;
    }

    &__grab-icon {
      cursor: grab;
    }
  }

  &__add-button {
    border-radius: 0;
    height: fit-content;
    justify-content: center;
    border: 1px solid $light-border;
    border-top: none;
    padding: 8px 24px;
  }
}
</style>
