import { render, staticRenderFns } from "./CategoriesList.vue?vue&type=template&id=20a910f6&scoped=true"
import script from "./CategoriesList.vue?vue&type=script&setup=true&lang=js"
export * from "./CategoriesList.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CategoriesList.vue?vue&type=style&index=0&id=20a910f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a910f6",
  null
  
)

export default component.exports