<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="unit-modal"
  >
    <a-form-model-item
      :label="$t('description')"
      prop="description"
    >
      <a-textarea
        v-model="form.description"
        :autoSize="{ minRows: 1, maxRows: 4 }"
      />

      <template
        #extra
        v-if="!props.unit?.code"
      >
        {{ $t("descriptionExample") }}
      </template>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('synonyms')"
      prop="synonyms"
      required
    >
      <a-textarea
        v-model="form.synonyms"
        :autoSize="{ minRows: 1, maxRows: 4 }"
      />
      <template
        #extra
        v-if="!props.unit?.code"
      >
        {{ $t("synomymsExample") }}
      </template>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('unitBaseValue')"
      prop="name"
    >
      <a-input v-model="form.name" />

      <template
        #extra
        v-if="!props.unit?.code"
      >
        {{ $t("unitExample") }}
      </template>
    </a-form-model-item>

    <template v-if="props.unit?.code">
      <a-form-model-item
        :label="$t('baseUnit')"
        prop="base"
      >
        <InfiniteScrollSelect
          :value="form.base"
          :customRequest="fetchUnits"
          :defaultOptions="unitOptions"
          :customRequestParams="{ base: 'null' }"
          labelKey="name"
          valueKey="oid"
          onFocusFetch
          :placeholder="$t('baseUnit')"
          @change="onBaseValueChange"
        />
      </a-form-model-item>

      <a-form-model-item
        v-if="form.base"
        :label="$t('coefficient')"
        prop="coef"
      >
        <a-input-number
          v-model="form.coef"
          style="width: 100%"
        />
      </a-form-model-item>
    </template>

    <div class="drawer-footer">
      <a-button @click="onClose">
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :icon="props.unit?.code ? false : 'plus'"
        :loading="isSubmitting"
        @click="onSubmit"
      >
        {{ props.unit?.code ? $t("save") : $t("add") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import { createUnit, updateUnit, fetchUnits } from "@/modules/Moderation/services/unitsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  unit: {
    type: Object,
    default: () => ({})
  },
  currentLanguage: {
    type: String,
    default: "ru"
  }
})
const emit = defineEmits(["updateUnit", "close"])

const formRef = ref()
const form = reactive({
  name: "",
  synonyms: "",
  description: "",
  coef: undefined,
  base: undefined
})
const formRules = computed(() => ({
  name: [requiredRule.value, { max: 10, message: i18n.t("maxLengthError", { length: 10 }) }],
  synonyms: { validator: validateSynonyms },
  description: [requiredRule.value, { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }],
  coef: [requiredRule.value]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const validateSynonyms = (_, value, callback) => {
  const synonyms = value.split(/[ ,\n]/).filter(Boolean)

  if (!synonyms.length) {
    callback(new Error(i18n.t("thisFieldMustBeFilled")))
  } else if (synonyms.length > 50) {
    callback(new Error(i18n.t("maxValueQuantityError", { quantity: 50 })))
  } else if (synonyms.some((item) => item.length > 10)) {
    callback(new Error(i18n.t("maxLengthError", { length: 10 })))
  } else {
    callback()
  }
}

const unitOptions = ref([])
const onBaseValueChange = (value) => {
  form.base = value
}

const isSubmitting = ref(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isSubmitting.value = true
    const { synonyms, ...payload } = form
    payload.synonyms = synonyms.split(/[\r\n, ]+/gm).filter(Boolean)
    payload.base = form.base || null
    payload.coef = form.base ? form.coef : null
    payload.name = form.name.trim()

    if (props.unit.code) {
      const { data } = await updateUnit({
        payload,
        code: props.unit.code,
        language: props.currentLanguage
      })
      notification.success({ message: i18n.t("updated") })
      emit("updateUnit", data)
    } else {
      const { data } = await createUnit({ payload })
      notification.success({ message: i18n.t("created") })
      emit("createUnit", data)
    }
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmitting.value = false
  }
}

const onClose = () => emit("close")

const setForm = () => {
  if (!props.unit.code) return resetFields()

  form.name = props.unit.name
  form.synonyms = props.unit.synonyms?.join(", ")
  form.description = props.unit.description
  form.coef = props.unit.coef

  if (props.unit.base) {
    form.base = props.unit.base.oid || null
    unitOptions.value = [props.unit.base]
  }
}

onMounted(setForm)

watch(() => props.unit.code, setForm)
</script>

<style lang="scss" scoped>
.unit-modal {
  padding-bottom: 50px;
}
</style>
