<template>
  <Draggable
    ghostClass="ghost"
    tag="tbody"
    handle=".handle"
    :list="appData.dataSource"
    @end="onSort"
  >
    <slot></slot>
  </Draggable>
</template>

<script setup>
import { inject } from "vue"
import Draggable from "vuedraggable"
const appData = inject("appData", {})
const onSort = inject("onSort", () => {})
</script>
