<template>
  <div>
    <a-button
      type="dashed"
      icon="download"
      block
      style="margin-bottom: 16px"
      :disabled="isFetching"
      @click="downloadExample"
    >
      {{ $t("downloadTagsImportExample") }}
    </a-button>

    <a-upload-dragger
      :beforeUpload="onChooseFile"
      accept="text/csv"
      :fileList="currentFiles"
      :remove="() => (currentFiles = [])"
      :disabled="isFetching"
    >
      <div class="upload-field">
        <a-icon
          type="inbox"
          class="upload-field__icon"
        />

        {{ $t("chooseOrDragForUpload") }}
      </div>
    </a-upload-dragger>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="$emit('close')"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        :disabled="!currentFiles.length"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import {
  checkOffersTagsImportTask,
  checkTagsImportTask,
  importOffersTagsFile,
  importTagsFile
} from "@/modules/MPAdmin/services/tagsService"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import { ref, shallowRef } from "vue"
import useTagsConstants from "../../useTagsConstants"
import i18n from "@/i18n"
import { downloadCsv } from "@/utils/downloadCsv"

const props = defineProps({
  isOffersModal: { type: Boolean, default: false }
})
const emit = defineEmits(["close"])
const { tagsImportCsvContent, offersTagsImportCsvContent } = useTagsConstants()

const currentFiles = ref([])
const isFetching = shallowRef(false)

const onChooseFile = (file) => {
  if (file.name.split(".")[1] !== "csv") {
    notification.error({ message: `${i18n.t("formatError")}: .csv` })
    return false
  }

  currentFiles.value = [file]
  return false
}

const downloadExample = () => {
  const content = props.isOffersModal ? offersTagsImportCsvContent : tagsImportCsvContent

  downloadCsv(content, `${props.isOffersModal ? "offers_tag" : "tag"}_import_example`)
}

const onSubmit = async () => {
  try {
    isFetching.value = true

    if (props.isOffersModal) {
      const { data } = await importOffersTagsFile(currentFiles.value[0])

      notification.info({ message: i18n.t("offersImportStarted") })
      checkOffersTagsImportTask(data.task)
    } else {
      const { data } = await importTagsFile(currentFiles.value[0])

      notification.info({ message: i18n.t("tagsImportStarted") })
      checkTagsImportTask(data.task)
    }
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.upload-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;

  font-size: 16px;

  &__icon {
    color: $primary-color;
    font-size: 36px;
  }
}
</style>
