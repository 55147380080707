<template>
  <a-spin
    :spinning="isFetching"
    style="max-width: 1420px; margin: 0 auto"
  >
    <!-- ADD NEW FILTER -->
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      layout="vertical"
      class="add-filter"
      hideRequiredMark
    >
      <template v-for="lang in languages">
        <a-form-model-item
          :label="`${$t('filterName')} ${lang.label}`"
          :prop="lang.value"
        >
          <a-input v-model="form[lang.value]" />
        </a-form-model-item>
      </template>
      <a-form-model-item
        :label="$t('filterType')"
        prop="type"
      >
        <a-select
          v-model="form.type"
          :options="filterTypeOptions"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          icon="plus"
          @click="handleAddNewFilter"
        >
          {{ $t("add") }} {{ $t("filter").toLowerCase() }}
        </a-button>
      </a-form-model-item>
    </a-form-model>

    <!-- TABLE -->
    <a-table
      :dataSource="filters"
      :columns="columns"
      :components="components"
      :rowKey="(record, index) => `${record.uuid}_${index}`"
      :pagination="false"
      :scroll="{ x: true }"
      class="filters-table"
    >
      <template #handleRenderer>
        <div class="handle">
          <a-icon type="menu" />
        </div>
      </template>

      <template
        v-for="columnKey in languages"
        #[columnKey.value]="record, _, index"
      >
        <TableEditableCellInput
          :text="record.name[columnKey.value]"
          :key="columnKey.value + record.name[columnKey.value] + index"
          :class="{
            'red-text':
              checkIsNameDuplicate(columnKey.value, record.name[columnKey.value]) ||
              !record.name[columnKey.value].trim()
          }"
          @change="(value, closeEdit) => editCell(columnKey.value, value, record, index, closeEdit)"
        />
      </template>

      <template #filterTypeRender="record, _, index">
        <a-select
          style="width: 100%"
          :value="record.type"
          :options="filterTypeOptions"
          @change="(e) => onFieldChange(e, index, 'type')"
        />
      </template>

      <template #rangeRender="values, record">
        <a-button
          v-if="record.type === 'custom_range_filter'"
          class="custom-range-input"
          :class="{ 'red-text': !values || !values.length }"
          @click="editCustomRangeFilter(record.id)"
        >
          {{ values?.length || 0 }}
          <a-icon type="edit" />
        </a-button>
      </template>

      <template #rangeCalculating="record">
        <div :class="{ 'red-text': missingOffers[record.id] }">
          {{
            missingOffers[record.id] !== undefined ? missingOffers[record.id] : $t("noDataShorted")
          }}
        </div>
      </template>

      <template #actions="record, _, index">
        <div class="action-buttons">
          <a-tooltip :title="$t('calculateCount')">
            <a-button
              type="dashed"
              shape="circle"
              @click="calculateOffers(record)"
            >
              <a-icon type="undo" />
            </a-button>
          </a-tooltip>
          <a-tooltip :title="$t('downloadFileWithOffers')">
            <a-button
              type="dashed"
              shape="circle"
              :disabled="!missingOffers[record.id]"
              @click="downloadFilterOffers(record)"
            >
              <a-icon type="download" />
            </a-button>
          </a-tooltip>
          <a-popconfirm
            :title="$t('deletingConfirmation')"
            @confirm="handleDeleteFilter(record, index)"
          >
            <a-button
              type="dashed"
              shape="circle"
            >
              <a-icon type="delete" />
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>

    <!-- CALCULATE ALL FITERS(No api for this now) -->

    <!-- <div class="total-missing">
      {{ $t("notInAllFilters") }}:
      <span class="total-missing__counter">
        {{ totalMissingCount !== undefined ? totalMissingCount : $t("noDataShorted") }}
      </span>
      <a-button
        style="margin-left: 86px"
        type="dashed"
        shape="circle"
        @click="calculateAllOffers()"
      >
        <a-icon type="undo" />
      </a-button>
      <a-button
        type="dashed"
        shape="circle"
        :disabled="totalMissingCount === undefined || totalMissingCount === 0"
        @click="downloadAllFilterOffers()"
      >
        <a-icon type="download" />
      </a-button>
    </div> -->
  </a-spin>
</template>

<script setup>
import { computed, provide, reactive, ref, watch } from "vue"
import { message, notification } from "ant-design-vue"
import i18n from "@/i18n"
import router from "@/router"

import TableGraggableWrapper from "@/ant-components/TableGraggableWrapper"
import TableEditableCellInput from "@/ant-components/TableEditableCellInput"

import useMPAConst from "@/modules/MPAdmin/constants.js"

import {
  fetchOffersMissingFilter,
  deleteFilterById
} from "@/modules/MPAdmin/services/categoriesService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import useFiltersColumns from "./useFiltersColumns"
import useForm from "@/composables/useForm"
import { requiredRule } from "@/utils/validations"

const props = defineProps({
  languages: {
    type: Array,
    required: true
  },
  filters: {
    type: Array,
    default: () => []
  },
  filtersIsChanged: {
    type: Boolean,
    default: false
  },
  isFetching: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits([
  "filtersChanged",
  "handleFieldChange",
  "handleAddNewFilter",
  "deleteFilter",
  "downloadFilterOffers",
  "onSort"
])

const { filterTypeOptions } = useMPAConst()
const columns = useFiltersColumns()

const components = {
  body: {
    wrapper: TableGraggableWrapper
  }
}

const formRef = ref()
const form = reactive({
  uk: undefined,
  en: undefined,
  ru: undefined,
  type: "checkbox"
})
const formRules = computed(() => ({
  uk: [requiredRule.value],
  en: [requiredRule.value],
  ru: [requiredRule.value],
  type: [requiredRule.value]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const onFieldChange = (value, index, field) => {
  emit("handleFieldChange", { value, index, field })
}
// const totalMissingCount = ref()

const missingOffers = ref({})
const calculateOffers = async (rowItem) => {
  if (rowItem.id) {
    try {
      const response = await fetchOffersMissingFilter(router.currentRoute.params.id, rowItem.id)
      missingOffers.value = { ...missingOffers.value, [rowItem.id]: response.data.count }

      message.success(i18n.t("calculatingIsSuccess"))
    } catch {
      message.error(i18n.t("calculatingFailed"))
    }
  }
}

const calculateAllOffers = () => {}

const downloadFilterOffers = (rowItem) => {
  emit("downloadFilterOffers", rowItem)
}

const downloadAllFilterOffers = () => {}

const editCell = (key, value, rowItem, index, closeEdit) => {
  closeEdit()
  onFieldChange({ ...rowItem.name, [key]: value }, index, "name")
}

const handleAddNewFilter = async () => {
  await validateForm()
  if (!isValidForm.value) return

  const { type, ...name } = form
  const newFilter = {
    id: null,
    name: name,
    type: type
  }

  emit("handleAddNewFilter", newFilter)
  resetFields()
}

const handleDeleteFilter = async (record, index) => {
  if (record.id) {
    try {
      await deleteFilterById(router.currentRoute.params.id, record.id)
      notification.success({
        message: i18n.t("deleted")
      })
      emit("deleteFilter", index)
    } catch (error) {
      notifyResponseError({ error })
    }
  } else {
    emit("deleteFilter", index)
  }
}

const editCustomRangeFilter = (id) => {
  if (props.filtersIsChanged) {
    notification.warning({
      message: i18n.t("editCustomRangeFilterWarning")
    })
  } else {
    router.push({
      name: "EditFilterCustomRanges",
      params: { id: router.currentRoute.params.id, filterId: id }
    })
  }
}

const checkIsNameDuplicate = (key, value) => {
  const encounteredValues = new Set()

  for (const row of props.filters) {
    const currentValue = row.name?.[key]
    if (currentValue === value) {
      if (encounteredValues.has(value)) {
        return true
      }
      encounteredValues.add(value)
    }
  }

  return false
}

provide("appData", {
  dataSource: props.filters
})
provide("onSort", (event) => emit("onSort", event))
watch(
  () => props.filters,
  () => {
    if (props.isFetching) return
    emit("filtersChanged")
  }
)
</script>

<style lang="scss" scoped>
.filters-table {
  min-height: 400px;
}
.action-buttons {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
}

.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
.custom-range-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background-color;
  border: 1px solid $border-color;
  border-radius: 5px;
  padding: 4px 12px;
}

.total-missing {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 16px 56px;
  gap: 8px;
  color: $grey-font;
  &__counter {
    min-width: 50px;
    color: $font-default;
    text-align: right;
  }
}
.add-filter {
  display: flex;
  margin-left: 66px;
  gap: 16px;
  & > * {
    width: 224px;
    font-weight: 500;
  }
  & > :last-child {
    display: flex;
    align-items: flex-end;
  }
}
.red-text {
  color: $red-color;
  border-color: $red-color;
}
</style>
