import { computed } from "vue"
import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"

export default () => {
  const columns = computed(() => [
    {
      key: "index",
      dataIndex: "index",
      title: "№",
      width: 30,
      customRender: (_, __, index) => index + 1
    },
    {
      key: "product.images",
      dataIndex: "product.images",
      title: i18n.t("productImages"),
      width: 80,
      scopedSlots: {
        customRender: "photoRenderer"
      }
    },
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("offerImages"),
      width: 80,
      scopedSlots: {
        customRender: "photoRenderer"
      }
    },
    {
      key: "code",
      dataIndex: "code",
      width: 160,
      scopedSlots: {
        title: "codeTitleRenderer",
        customRender: "codeRenderer"
      }
    },
    {
      key: "product",
      dataIndex: "product",
      title: i18n.t("orderProductInfo"),
      scopedSlots: {
        customRender: "productNameRenderer"
      }
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: `${i18n.t("countShorted")}, ${i18n.t("pieces")}`,
      width: 110,
      align: "center"
    },
    {
      key: "price",
      dataIndex: "price",
      title: `${i18n.t("price")}, ${i18n.t("uah")}`,
      width: 110,
      align: "center",
      customRender: (data) => getFormatedSum(data, true)
    },
    {
      key: "price_total",
      dataIndex: "price_total",
      title: `${i18n.t("sum")}, ${i18n.t("uah")}`,
      width: 110,
      align: "center",
      customRender: (data) => getFormatedSum(data, true)
    },
    {
      key: "bonuses_amount",
      dataIndex: "bonuses_amount",
      title: `${i18n.t("bonusTitle")}, ${i18n.t("uah")}`,
      width: 110,
      align: "center",
      customRender: (data) => getFormatedSum(data, true)
    },
    {
      key: "promo_code_amount",
      dataIndex: "promo_code_amount",
      title: `${i18n.t("promoCodeTitle")}, ${i18n.t("uah")}`,
      width: 130,
      align: "center",
      customRender: (data) => getFormatedSum(data, true)
    },
    {
      key: "sumToPayment",
      dataIndex: "sumToPayment",
      title: `${i18n.t("sumToPayment")}, ${i18n.t("uah")}`,
      width: 170,
      align: "center",
      customRender: (data, record) =>
        getFormatedSum(record.price_total - record.bonuses_amount - record.promo_code_amount, true)
    }
  ])
  return columns
}
