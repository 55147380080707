<template>
  <a-form-model
    ref="formRef"
    :rules="formRules"
    :model="form"
    class="edit-locality-drawer"
    hideRequiredMark
    :colon="false"
  >
    <a-form-model-item
      :label="$t('chooseTypeForEdit')"
      prop="type"
    >
      <a-select
        :options="localityTypes"
        v-model="form.type"
      />
    </a-form-model-item>

    <a-form-model-item
      v-if="showKoatuu"
      :label="$t('koatuuLabel')"
      prop="koatuu"
    >
      <a-input v-model="form.koatuu" />
    </a-form-model-item>

    <template v-if="form.type">
      <a-form-model-item
        v-for="lang in languages"
        :label="labelForName(lang.label)"
        :key="`name-${lang.label}`"
        :prop="`name.${lang.value}`"
      >
        <a-input v-model="form.name[lang.value]" />
      </a-form-model-item>
    </template>

    <div class="drawer-footer">
      <a-button
        type="danger"
        :disabled="isSaving"
        :loading="isDeleting"
        ghost
        @click="onDelete()"
      >
        {{ $t("delete") }}
      </a-button>

      <a-button
        type="primary"
        :disabled="!form.type || isDeleting"
        :loading="isSaving"
        @click="onConfirm()"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import { updateLocation } from "@/modules/MPAdmin/services/locationsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const languages = [
  {
    label: "UA",
    value: "uk"
  },
  {
    label: "RU",
    value: "ru"
  },
  {
    label: "EN",
    value: "en"
  }
]

const props = defineProps({
  location: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(["onUpdate"])

const isSaving = ref(false)
const isDeleting = ref(false)
const localityTypes = ref([])
const currentType = ref()

const formRef = ref()
const form = reactive({
  type: "",
  koatuu: "",
  name: {
    uk: "",
    en: "",
    ru: ""
  }
})
const formRules = computed(() => ({
  type: requiredRule.value,
  name: {
    uk: requiredRule.value,
    en: requiredRule.value,
    ru: requiredRule.value
  }
}))
const { isValidForm, validateForm } = useForm(formRef)

const showKoatuu = computed(() => {
  return (
    currentType.value &&
    currentType.value !== "COUNTRY" &&
    currentType.value !== "STATE" &&
    currentType.value !== "DISTRICT"
  )
})

const labelForName = (lang) => {
  const title = localityTypes.value.find(({ value }) => value === form.type).title

  return `${title} (${lang})`
}

const onConfirm = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isSaving.value = true

    const payload = {
      name: form.name
    }

    if (showKoatuu.value) {
      payload.koatuu = form.koatuu
    }

    await updateLocation({ uuid: form.type, payload })

    notification.success({
      message: i18n.t("updated")
    })
    emit("onUpdate")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSaving.value = false
  }
}

const onDelete = async () => {
  try {
    await updateLocation({
      uuid: props.location.uuid,
      payload: {
        is_deleted: true
      }
    })
  } catch (error) {
    notifyResponseError({ error })
  }
}

const getLocationTypes = (location) => {
  if (!location.parent) return
  if (location.parent.type === "COUNTRY") return

  localityTypes.value.push({
    value: location.parent.uuid,
    title: i18n.t(`locationTypes.${location.parent.type}`)
  })

  getLocationTypes(location.parent)
}

const setNameByType = (type, location) => {
  if (location.uuid === type) {
    currentType.value = location.type
    form.name = location.name
    form.koatuu = location?.koatuu || ""

    return
  }

  setNameByType(type, location.parent)
}

onMounted(() => {
  const { location } = props

  localityTypes.value = [{ value: location.uuid, title: i18n.t(`locationTypes.${location.type}`) }]
  form.type = location.uuid

  getLocationTypes(location, localityTypes)
})

watch(
  () => form.type,
  (type) => {
    setNameByType(type, props.location)
  }
)
</script>

<style lang="scss">
.edit-locality-drawer {
  padding-bottom: 50px;
}
</style>
