import { getModerationToken, getUnreadMessagesCount } from "../messengerService"

export default {
  namespaced: true,

  state: {
    unreadMessages: null,
    unreadMessagesCheckInterval: null,
    messengerToken: undefined
  },

  mutations: {
    SET_UNREAD_MESSAGES(state, count) {
      state.unreadMessages = count
    },

    SET_UNREAD_INTERVAL(state, interval) {
      state.unreadMessagesCheckInterval = setInterval(() => interval(), 10000)
    },

    RESET_UNREAD_INTERVAL(state) {
      clearInterval(state.unreadMessagesCheckInterval)
      state.unreadMessagesCheckInterval = null
    },

    SET_MSG_TOKEN(state, token) {
      state.messengerToken = token
    }
  },

  actions: {
    setUnreadMessages({ commit }, count) {
      commit("SET_UNREAD_MESSAGES", count)
    },

    setUnreadMessagesTimeout({ dispatch, state, commit }) {
      if (state.unreadMessagesCheckInterval) {
        commit("RESET_UNREAD_INTERVAL")
      }
      dispatch("checkUnreadMessages")
      commit("SET_UNREAD_INTERVAL", () => dispatch("checkUnreadMessages"))
    },

    resetUnreadMessagesTimeout({ state, commit }) {
      if (state.unreadMessagesCheckInterval) {
        commit("RESET_UNREAD_INTERVAL")
      }
    },

    async checkMessengerTokenStorage({ dispatch }) {
      return dispatch("Auth/getAuthTokenFromstorage", "messenger_token_mpa", {
        root: true
      })
    },

    async updateMessengerToken({ dispatch, commit }) {
      const token = await dispatch("checkMessengerTokenStorage")

      if (token) {
        commit("SET_MSG_TOKEN", token)
        return Promise.resolve(token)
      }

      const { data } = await getModerationToken()
      commit("SET_MSG_TOKEN", data.token)

      const accessTokenExpiry = new Date().getTime() + 3600 * 1000 // Час зберігання токену

      localStorage.setItem(
        "messenger_token_mpa",
        JSON.stringify({ token: data.token, expiry: accessTokenExpiry })
      )
      return Promise.resolve(data.token)
    },

    async checkUnreadMessages({ dispatch, state }) {
      try {
        if (!state.messengerToken) {
          await dispatch("updateMessengerToken")
        }

        const response = await getUnreadMessagesCount(state.messengerToken)

        if (!response.count) return

        if (state.unreadMessages !== response.count) {
          dispatch("setUnreadMessages", response.count)
        }
      } catch (error) {
        if (!state.unreadMessagesCheckInterval) return
        dispatch("resetUnreadMessagesTimeout")
      }
    }
  }
}
