<template>
  <div>
    <PageHeader
      :title="$t('existedTypesList')"
      showLanguageSelect
      :currentLanguage="languageForFetch"
      @onChangeLanguage="onChangeLanguage"
    >
      <template #filters>
        <div class="date-filter">
          <div>{{ $t("creatingDate") }}:</div>
          <a-range-picker
            v-model="filteredInfo.created_at"
            :disabled="isChangeStarted"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 3px"
            @change="onFilterChange('created_at')"
          >
            <template #suffixIcon>
              <a-icon type="clock-circle" />
            </template>
          </a-range-picker>
        </div>

        <a-input-search
          v-for="field in searchInputFields"
          :key="field.key"
          v-model="filteredInfo[field.key]"
          :disabled="isChangeStarted"
          :placeholder="field.label"
          allowClear
          @change="onFilterChange(field.key, Boolean($event?.target?.value))"
          @pressEnter="onFilterChange(field.key)"
        >
          <template #enterButton>
            <a-button
              icon="search"
              :disabled="isChangeStarted"
              @focus="onFilterChange(field.key)"
            />
          </template>
        </a-input-search>
      </template>

      <template #actions>
        <a-button
          icon="undo"
          :disabled="isSaving"
          @click="onResetFilters()"
        >
          {{ $t("resetFilters") }}
        </a-button>

        <a-button
          type="primary"
          icon="plus"
          @click="onShowCreateModal()"
        >
          {{ $t("creatingNewType") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching || isSaving"
      rowKey="code"
      :columns="columns"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #trustRenderer="trust">
        <div :class="trust || 'UNTRUSTED'">
          {{ $t(`moderationTrustStatus.${trust || "UNTRUSTED"}`) }}
        </div>
      </template>

      <template #categoriesRender="categories">
        <a-tooltip :title="formatCategories(categories)">
          <div class="categories-cell">
            {{ formatCategories(categories) }}
          </div>
        </a-tooltip>
      </template>

      <template #noteRenderer="record">
        <a-textarea
          v-model="record.note"
          :disabled="isSaving"
          :rows="2"
          @change="onNoteChange(record)"
        />
      </template>

      <template #actions="record">
        <a-button
          icon="edit"
          type="dashed"
          shape="circle"
          @click.stop="handleEditType(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showCreateModal"
      :title="$t('creatingNewType')"
      :width="600"
      destroyOnClose
      @close="showCreateModal = false"
    >
      <CreateTypeModal @submit="onCreateType" />
    </a-drawer>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, shallowRef, inject } from "vue"
import { notification } from "ant-design-vue"
import router from "@/router"
import i18n from "@/i18n"

import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"
import { CreateTypeModal } from "./components/index.js"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/TableRendererDateTime.vue"

import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useTypesColumns from "./useTypesColumns.js"
import useDebounce from "@/composables/useDebounce.js"

import { bulkTypesUpdate, fetchTypes } from "../../services/typesService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const languageForFetch = ref("ru")
const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchTypes,
  requestParams: { changeRouterQuery: true, language: computed(() => languageForFetch.value) }
})
const { columns } = useTypesColumns({
  sortedInfo: computed(() => sortedInfo.value),
  language: computed(() => languageForFetch.value)
})

const { debounce, resetAllDebounces } = useDebounce()

const searchInputFields = [
  {
    label: i18n.t("code"),
    key: "code"
  },
  {
    label: i18n.t("typeName"),
    key: "full_name"
  },
  {
    label: i18n.t("tag._note"),
    key: "note"
  }
]
const showCreateModal = ref(false)

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  resetAllDebounces()
  resetChangedRecords()
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const onFilterChange = (key, delayRequest = false) => {
  resetAllDebounces()
  resetChangedRecords()
  if (delayRequest) return debounce(key, handleTableFilterChange, 500)
  handleTableFilterChange()
}

const onResetFilters = () => {
  resetAllDebounces()
  resetChangedRecords()
  handleTableFiltersReset()
}

const handleEditType = (record) => {
  router.push({ name: "EditType", params: { code: record.code } })
}

const onShowCreateModal = () => {
  showCreateModal.value = true
}

const onCreateType = (record) => {
  router.push({ name: "EditType", params: { code: record.code } })
}

const onChangeLanguage = ({ language }) => {
  languageForFetch.value = language || "ru"
  onTableChange()
}

// NOTE
const isSaving = shallowRef(false)
const changedRecords = ref([])
const isChangeStarted = computed(() => Boolean(changedRecords.value.length))

const onNoteChange = (record) => {
  resetAllDebounces()

  debounce("notesChange", handleSaveChangedRecords, 1000)

  if (changedRecords.value.includes(record.code)) return
  changedRecords.value.push(record.code)
}
const resetChangedRecords = () => {
  changedRecords.value = []
}

const handleSaveChangedRecords = async () => {
  const notValidRecords = dataSource.value.filter((record) => record.note?.length > 500)

  if (notValidRecords.length) {
    return notification.error({
      message: i18n.t("typeNoteLengthExceeded"),
      description: notValidRecords.map((record) => record.code).join(", ")
    })
  }

  try {
    isSaving.value = true

    await bulkTypesUpdate({ types: changedRecords.value, dataSource: dataSource.value })
    resetChangedRecords()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSaving.value = false
  }
}

// CATEGORIES
const categoriesTree = inject("categoriesTree")
const flattenArray = (arr) => {
  return arr.flatMap(({ children, ...rest }) =>
    children ? [rest, ...flattenArray(children)] : [rest]
  )
}
const categoriesList = computed(() => flattenArray(categoriesTree.value))

const formatCategories = (codes = []) => {
  return codes
    .map((code) => categoriesList.value?.find((item) => item.value === code)?.title || code)
    .join(", ")
}

onMounted(async () => {
  // TODO: Place categories to store in MPA Catalog API update
  // Use wrapper for options api state if needed

  setupTable({
    defaultSorter: {
      columnKey: "timestamp",
      order: "ascend"
    },
    sortKey: "order_by"
  })
  onTableChange()
})
</script>

<style lang="scss" scoped>
.date-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 350px;

  & > :first-child {
    white-space: nowrap;
    color: $dark;
  }
}

.categories-cell {
  max-width: 340px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
