<template>
  <div>
    <PageHeader :title="$t('products')">
      <template #filters>
        <a-form-model-item
          v-for="field in searchFields"
          style="margin: 0"
          :key="field"
        >
          <a-input-search
            :placeholder="$t(field)"
            v-model="filteredInfo[field]"
            allowClear
            @search="onFilterChange()"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </a-form-model-item>

        <a-form-model-item style="margin-top: -4px">
          <a-input-number
            :placeholder="$t('listing')"
            style="width: 100%"
            :min="0"
            :precision="0"
            v-model="filteredInfo.listing"
            @pressEnter="onFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item
          :extra="$t('rating')"
          style="margin: 0"
        >
          <a-input-group compact>
            <a-input-number
              :placeholder="$t('from')"
              :precision="0"
              :min="0"
              :max="5"
              v-model="filteredInfo.rating_min"
              @pressEnter="onFilterChange()"
            />

            <a-input-number
              :placeholder="$t('to')"
              :precision="0"
              :min="0"
              :max="5"
              v-model="filteredInfo.rating_max"
              @pressEnter="onFilterChange()"
            />

            <a-button
              icon="search"
              @click="onFilterChange()"
            />
          </a-input-group>
        </a-form-model-item>

        <a-form-model-item style="margin: 0">
          <a-select
            v-model="filteredInfo.has_offers"
            :options="[{ label: $t('yes'), value: 'true' }]"
            :placeholder="$t('withOffers')"
            style="width: 100%"
            allowClear
            @change="onFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item style="margin: 0">
          <a-select
            v-model="filteredInfo.label"
            allowClear
            :placeholder="$t('label')"
            style="width: 100%"
            @change="onFilterChange()"
          >
            <a-select-option
              v-for="label in labels"
              :key="label.id"
              :value="label.id"
            >
              {{ label.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item style="margin: 0">
          <a-tree-select
            v-model="filteredInfo.category_id"
            :treeData="categoriesTree"
            treeNodeFilterProp="title"
            :placeholder="$t('category')"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            allowClear
            showSearch
            style="width: 100%"
            :replaceFields="{ value: 'id' }"
            @change="onFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item
          :help="$t('dateCreated')"
          style="margin: 0"
        >
          <a-range-picker
            valueFormat="YYYY-MM-DD"
            format="DD.MM.YYYY"
            v-model="filteredInfo.created_at"
            style="margin-top: 7px"
            @change="onFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item style="margin: 0">
          <a-select
            v-model="filteredInfo.moderation_status"
            :options="moderationStatusesOptions"
            :placeholder="$t('moderationStatus')"
            style="width: 100%"
            allowClear
            @change="onFilterChange()"
          />
        </a-form-model-item>
      </template>

      <template #actions>
        <div class="table-header__footer__actions">
          <a-button
            icon="undo"
            @click="onFiltersReset()"
          >
            {{ $t("resetFilters") }}
          </a-button>

          <a-upload
            name="file"
            :show-upload-list="false"
            :before-upload="() => false"
            @change="handleChangeUploadFile"
          >
            <a-button icon="upload">
              {{ $t("changeRating") }}
            </a-button>
          </a-upload>

          <a-button
            type="primary"
            icon="sync"
            :disabled="!selectedRows?.length"
            @click="handleSyncProducts()"
          >
            {{ $t("sync") }}
          </a-button>

          <a-dropdown :disabled="!selectedRowKeys.length">
            <a-button
              type="primary"
              icon="setting"
              ghost
            >
              {{ $t("listing") }}
              <template>({{ selectedRows.length }})</template>
            </a-button>

            <template #overlay>
              <a-menu>
                <a-menu-item
                  icon="plus"
                  :disabled="selectedRowsHaveListing"
                  @click="handleOpenModal('showCreateListing')"
                >
                  {{ $t("createListing") }}
                </a-menu-item>

                <a-menu-item
                  icon="ordered-list"
                  :disabled="!selectedRows?.length"
                  @click="handleOpenModal('showEditingListing')"
                >
                  {{ $t("add") }}/{{ $t("editListing") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>

          <a-button
            type="primary"
            icon="edit"
            ghost
            :disabled="!selectedRows?.length"
            @click="handleOpenModal('showEditingProduct')"
          >
            {{ $t("edit") }} {{ $t("products").toLowerCase() }}
            <template> ({{ selectedRows.length }})</template>
          </a-button>
        </div>
      </template>
    </PageHeader>

    <div class="table-wrapper">
      <a-table
        :dataSource="dataSource"
        :loading="isFetching"
        :columns="columns"
        :scroll="{ x: true }"
        rowKey="id"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: (keys, rows) => {
            selectedRowKeys = keys
            setSelectedRows(rows)
          }
        }"
        :pagination="paginationInfo"
        @change="onTableChange"
      >
        <template #photoRenderer="images">
          <TableRendererPhotoGallery
            v-if="images?.length"
            :images="images"
          />
        </template>

        <template #categoryRenderer="value, record">
          <div
            class="modal-emiter"
            @click="handleOpenModal('showEditingProduct', record)"
          >
            {{ value?.name || $t("notSet") }}
          </div>
        </template>

        <template #ratingRenderer="value, record">
          <div
            class="modal-emiter"
            @click="handleOpenModal('showRatingModal', record)"
          >
            {{ value.toFixed() }}
          </div>
        </template>

        <template #labelRender="value">
          <LabelRender
            v-if="value"
            :value="value"
          />
        </template>

        <template #listing="value, record">
          <a-button
            v-if="value"
            type="link"
            @click="handleOpenModal('showEditingListing', record)"
          >
            {{ `${value?.id} (${value?.items_count})` }}
          </a-button>
        </template>

        <template #statusRender="value, record">
          <a-badge
            style="cursor: pointer"
            :status="moderationStatuses[value].badgeStatus"
            :text="moderationStatuses[value].label"
            @click="handleOpenModal('showEditingProduct', record)"
          />
        </template>

        <template #date="value">
          <div>{{ getFormatedDate(value) }}</div>
          <div :style="{ opacity: '0.45' }">{{ getFormatedTime(value) }}</div>
        </template>

        <template #actions="record">
          <div class="table-actions">
            <a-tooltip
              :title="`${$t('edit')} ${$t('product').toLowerCase()}`"
              placement="topLeft"
            >
              <a-button
                icon="edit"
                shape="circle"
                type="dashed"
                @click="handleOpenModal('showEditingProduct', record)"
              />
            </a-tooltip>

            <a-tooltip
              :title="`${$t('edit')} ${$t('rating').toLowerCase()}`"
              placement="topLeft"
            >
              <a-button
                icon="line-chart"
                shape="circle"
                type="dashed"
                @click="handleOpenModal('showRatingModal', record)"
              />
            </a-tooltip>
          </div>
        </template>
      </a-table>
    </div>

    <!-- MODALS -->

    <template>
      <a-drawer
        :visible="showRatingModal"
        :width="800"
        destroyOnClose
        @close="handleCloseModal('showRatingModal')"
      >
        <template #title>
          {{ $t("edit") }} {{ $t("rating").toLowerCase() }}: {{ rowData[0]?.name }}</template
        >
        <UpdateRatingModal
          :currentRow="rowData[0]"
          @close="handleCloseModal('showRatingModal', true)"
        />
      </a-drawer>

      <a-drawer
        :visible="showEditingProduct"
        :width="1400"
        destroyOnClose
        @close="handleCloseModal('showEditingProduct')"
      >
        <template #title>{{ $t("edit") }} {{ $t("products").toLowerCase() }}:</template>
        <EditProductModal
          :dataSource="rowData"
          :categoriesTree="categoriesTree"
          :moderationStatuses="moderationStatusesOptions"
          :labels="labels"
          @close="handleCloseModal('showEditingProduct', true)"
        />
      </a-drawer>

      <a-drawer
        :visible="showEditingListing"
        :title="$t('editListingAndVariations')"
        :width="1200"
        destroyOnClose
        @close="handleCloseModal('showEditingListing')"
      >
        <UpdateListing
          :data="rowData"
          :showModal="showEditingListing"
          :listing="rowData[0]?.listing?.id"
          @close="handleCloseModal('showEditingListing', true)"
        />
      </a-drawer>

      <a-drawer
        :visible="showCreateListing"
        :title="$t('createListing')"
        :width="1000"
        destroyOnClose
        @close="handleCloseModal('showCreateListing')"
      >
        <CreateListingAndVariations
          :data="rowData"
          @close="handleCloseModal('showCreateListing', true)"
        />
      </a-drawer>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import moment from "moment"

import LabelRender from "./components/LabelRender/"
import EditProductModal from "./components/EditProduct/"
import UpdateListing from "./components/ListingsAndVariations/UpdateListingModal.vue"
import CreateListingAndVariations from "./components/ListingsAndVariations/CreateListingAndVariations.vue"
import UpdateRatingModal from "./components/UpdateRatingModal/"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import {
  changeRatingWithFile,
  fetchProducts,
  fetchLabelsChoises,
  syncProducts
} from "@/modules/MPAdmin/services/productsService"

import { getFormatedDate, getFormatedTime } from "@/utils/getFormatedDateTime"

import useProductListColumns from "./useProductListColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import notifyResponseError from "@/utils/notifyResponseError"
import PageHeader from "@/ant-components/PageHeader"

const categoriesTree = inject("categoriesTree")
const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchProducts,
  requestParams: { changeRouterQuery: true }
})

const columns = useProductListColumns({ sortedInfo: computed(() => sortedInfo.value) })

const showRatingModal = ref(false)
const showEditingProduct = ref(false)
const showEditingListing = ref(false)
const showCreateListing = ref(false)
const modalRefs = {
  showRatingModal,
  showEditingProduct,
  showEditingListing,
  showCreateListing
}

const rowData = ref([])

const selectedRowKeys = ref([])
const selectedRows = ref([])

const labels = ref([])
const searchFields = ref(["p_code", "name", "model"])

const moderationStatuses = computed(() => ({
  EXPECTS_MODERATION: {
    badgeStatus: "processing",
    label: i18n.t("EXPECTS_MODERATION"),
    value: "EXPECTS_MODERATION"
  },
  MODERATION_IN_PROCESS: {
    badgeStatus: "warning",
    label: i18n.t("MODERATION_IN_PROCESS"),
    value: "MODERATION_IN_PROCESS"
  },
  MODERATION_FAILED: {
    badgeStatus: "error",
    label: i18n.t("MODERATION_FAILED"),
    value: "MODERATION_FAILED"
  },
  MODERATION_SUCCESS: {
    badgeStatus: "success",
    label: i18n.t("MODERATION_SUCCESS"),
    value: "MODERATION_SUCCESS"
  }
}))

const selectedRowsHaveListing = computed(
  () => !selectedRows.value?.length || selectedRows.value.some(({ listing }) => listing?.id)
)

const moderationStatusesOptions = computed(() => Object.values(moderationStatuses.value))

const setSelectedRows = (rows) => {
  selectedRows.value = rows
}
const resetSelectedRows = () => {
  setSelectedRows([])
  selectedRowKeys.value = []
}

const onFilterChange = () => {
  resetSelectedRows()
  handleTableFilterChange()
}
const onFiltersReset = () => {
  resetSelectedRows()
  handleTableFiltersReset()
}

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
  resetSelectedRows()
}

const getLabels = async () => {
  const { data } = await fetchLabelsChoises()

  labels.value = data.results.map(({ name, id }) => ({
    name: name.uk || name.ru,
    id: `${id}`
  }))
}

const handleSyncProducts = async () => {
  isFetching.value = true

  try {
    await syncProducts(selectedRows.value)

    notification.success({ message: i18n.t("syncSuccess") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleOpenModal = (modalVisibleValue, row) => {
  modalRefs[modalVisibleValue].value = true
  rowData.value = row?.id ? [row] : selectedRows.value
}
const handleCloseModal = (modalVisibleValue, fetchProducts = false) => {
  modalRefs[modalVisibleValue].value = false

  if (!fetchProducts) return
  onTableChange()
}

const handleChangeUploadFile = async (info) => {
  const formData = new FormData()

  formData.append("file", info.file)

  try {
    const { data } = await changeRatingWithFile(formData)

    const blob = new Blob([data], { type: "text/csv;charset=utf-8" })
    const url = URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.download = `change-rating-results_${moment().format("DD-MM-YYYY_HH-mm-ss")}.csv`

    link.click()
    URL.revokeObjectURL(url)

    notification.success({
      message: i18n.t("products_rating_successfully_changed")
    })
  } catch (error) {
    notifyResponseError({ error })
  }
}

onMounted(() => {
  setupTable()
  getLabels()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.table-header__footer__actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 16px;
}

.table-actions {
  display: flex;
  gap: 6px;
}
.modal-emiter {
  cursor: pointer;
  color: $primary-color;
  min-width: 36px;
  line-height: 24px;
  width: fit-content;
}

.EXPECTS_MODERATION {
  color: $warning-color;
}
.MODERATION_IN_PROCESS {
  color: $primary-color;
}
.MODERATION_FAILED {
  color: $red-color;
}
.MODERATION_SUCCESS {
  color: $green-color;
}
</style>
