<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
  >
    <a-form-model-item
      :label="$t('enterCSCodes')"
      prop="codes"
    >
      <a-textarea
        :rows="10"
        v-model="form.codes"
      />
    </a-form-model-item>

    <a-form-model-item
      v-if="props.action === 'add'"
      :label="$t('label')"
      prop="label_id"
    >
      <a-select v-model="form.label_id">
        <a-select-option
          v-for="label in props.labels"
          :key="label.id"
          :value="label.id"
        >
          {{ label.name[getCurrentLocale()] }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import useForm from "@/composables/useForm"
import { bulkChangeOfferLabel } from "@/modules/MPAdmin/services/propositionsService"
import getCurrentLocale from "@/utils/getCurrentLocale"
import notifyResponseError from "@/utils/notifyResponseError"
import { requiredRule, csCodesValidator } from "@/utils/validations"

const props = defineProps({
  action: {
    type: "add" | "remove",
    default: "add"
  },
  labels: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["close"])

const formRef = ref()
const form = reactive({
  codes: "",
  label_id: undefined
})
const formRules = computed(() => ({
  codes: [{ validator: csCodesValidator, trigger: "submit" }],
  label_id: [requiredRule.value]
}))
const { isValidForm, validateForm } = useForm(formRef)

const isFetching = shallowRef(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    await bulkChangeOfferLabel({
      codes: form.codes.split(/[\r\n\t, ]+/).filter(Boolean),
      label_id: props.action === "add" ? form.label_id : null
    })

    notification.success({ message: i18n.t("updated") })
    emit("submit")
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>
