import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "images",
      title: i18n.t("photo"),
      width: 65,
      scopedSlots: {
        customRender: "offerImages"
      }
    },
    {
      key: "code",
      dataIndex: "code",
      title: "CS Code",
      width: 100
    },
    {
      key: "condition_key",
      dataIndex: "condition_key",
      title: i18n.t("state"),
      scopedSlots: {
        customRender: "condition"
      },
      width: 100
    },
    {
      key: "description",
      dataIndex: "description",
      scopedSlots: {
        customRender: "clampedText"
      },
      title: i18n.t("description")
    }
  ])

  return columns
}
