<template>
  <div class="edit-tag-modal">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      class="edit-tag-modal__form"
    >
      <a-form-model-item
        prop="name"
        :label="$t('name')"
      >
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item
        prop="color"
        :label="$t('color')"
      >
        <a-dropdown :trigger="['click']">
          <a-button
            style="
              display: flex;
              align-items: center;
              width: 150px;
              justify-content: space-between;
              margin-top: 4px;
            "
          >
            <ColorPreview
              :color="form.color.hex"
              size="small"
            />

            <a-icon type="down" />
          </a-button>

          <template #overlay>
            <a-menu>
              <a-menu-item
                style="width: 452px; padding: 0; overflow: hidden"
                disabled
              >
                <Sketch
                  :disableAlpha="true"
                  v-model="form.color"
                />
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-form-model-item>

      <a-form-model-item
        prop="is_active"
        :label="$t('activity')"
      >
        <a-radio-group v-model="form.is_active">
          <a-radio
            class="activity-item"
            :value="true"
          >
            {{ $t("yes") }}
          </a-radio>

          <a-radio
            class="activity-item"
            :value="false"
          >
            {{ $t("no") }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>

    <QuickReplies
      ref="quickRepliesRef"
      :tag="chosenTag"
      @update="onUpdateReplies"
    />

    <div class="drawer-footer">
      <a-button
        :disabled="isSubmitting"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isSubmitting"
        @click="onSubmit"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { Sketch } from "vue-color"

import ColorPreview from "./ColorPreview.vue"
import QuickReplies from "./QuickReplies.vue"

import notifyResponseError from "@/utils/notifyResponseError.js"
import { updateTag } from "@/modules/MPAdmin/services/messengerTagsService.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  chosenTag: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["close", "submit", "update"])

const quickRepliesRef = ref()

const formRef = ref()
const form = reactive({
  name: props.chosenTag.name || "",
  color: {
    hex: props.chosenTag.color || "#000000"
  },
  is_active: Boolean(props.chosenTag.is_active)
})
const formRules = computed(() => ({
  name: [requiredRule.value],
  color: [requiredRule.value],
  is_active: [requiredRule.value]
}))
const { isValidForm, validateForm } = useForm(formRef)

const onClose = () => emit("close")

const isSubmitting = shallowRef(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  if (quickRepliesRef.value && quickRepliesRef.value.checkIsRepliesChanged()) {
    const isSaved = await quickRepliesRef.value.handleSaveChangedReplies()
    if (!isSaved) return
  }

  try {
    isSubmitting.value = true

    const { data } = await updateTag(props.chosenTag.uuid, { ...form, color: form.color?.hex })

    emit("submit", data)
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmitting.value = false
  }
}

const onUpdateReplies = (reply_count) => {
  emit("update", { ...props.chosenTag, reply_count })
}
</script>

<style lang="scss" scoped>
.edit-tag-modal {
  padding-bottom: 50px;

  &__form {
    display: flex;
    gap: 24px;

    & > :first-child {
      width: 55%;
    }
  }

  & .activity-item {
    display: block;
    line-height: 30px;

    &:first-child {
      color: $green-color;
    }
    &:nth-child(2) {
      color: $red-color;
    }
  }
}
</style>
