import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      scopedSlots: {
        customRender: "photoRenderer"
      },
      width: 65
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: `P ${i18n.t("code")}`,
      width: 100
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name")
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model")
    },
    {
      key: "cover",
      title: i18n.t("cover"),
      width: 60,
      scopedSlots: {
        customRender: "cover"
      }
    },
    {
      key: "listing",
      dataIndex: "listing",
      title: i18n.t("listing"),
      scopedSlots: {
        customRender: "listing"
      },
      width: 100
    }
  ])

  return columns
}
