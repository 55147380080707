<template>
  <a-drawer
    :visible="showModal"
    :title="$t('bonusCreating')"
    :width="500"
    @close="$emit('close')"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      style="margin-bottom: 40px"
    >
      <a-form-model-item
        :label="$t('enterSum')"
        prop="amount"
      >
        <a-input-number
          style="width: 100px"
          v-model="form.amount"
          :min="1"
          :precision="0"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('applySum')"
        prop="amountConfirm"
      >
        <a-input-number
          style="width: 100px"
          v-model="form.amountConfirm"
          :min="1"
          :precision="0"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        :disabled="isSaving"
        @click="$emit('close')"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isSaving"
        @click="onSave"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { ref, computed, watch, reactive, shallowRef } from "vue"
import { message, notification } from "ant-design-vue"
import i18n from "@/i18n"

import { createBonus } from "@/modules/MPAdmin/services/bonusesService"
import { requiredRule } from "@/utils/validations"
import useForm from "@/composables/useForm"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["handleRefreshData", "close"])

const formRef = ref()
const form = reactive({
  amount: null,
  amountConfirm: null
})

const formRules = computed(() => ({
  amount: [requiredRule.value],
  amountConfirm: [requiredRule.value]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const isSaving = shallowRef(false)
const onSave = async () => {
  await validateForm()
  if (!isValidForm.value) return

  if (form.amount !== form.amountConfirm) {
    message.error(i18n.t("mustBeSameError"))
    return
  }

  isSaving.value = true

  try {
    await createBonus({ amount: form.amount })

    notification.success({
      message: `${form.amount} ${i18n.tc("someBonusesCreated", form.amount)}`
    })
    emit("handleRefreshData")
    emit("close")
  } finally {
    isSaving.value = false
  }
}

watch(
  () => props.showModal,
  (val) => {
    if (!val || !formRef.value) return
    resetFields()
  }
)
</script>
