import { render, staticRenderFns } from "./UnitForm.vue?vue&type=template&id=1f4325bf&scoped=true"
import script from "./UnitForm.vue?vue&type=script&setup=true&lang=js"
export * from "./UnitForm.vue?vue&type=script&setup=true&lang=js"
import style0 from "./UnitForm.vue?vue&type=style&index=0&id=1f4325bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f4325bf",
  null
  
)

export default component.exports