import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"
import { computed } from "vue"

export default () => {
  const getStatusText = (value) => {
    switch (value) {
      case "NEW":
        return i18n.t("orderStatuses.new")
      case "CANCELED":
        return i18n.t("canceledStatus")
      case "CLOSED":
        return i18n.t("orderStatuses.closed")
      default:
        ""
    }
  }

  const columns = computed(() => [
    {
      key: "index",
      dataIndex: "index",
      title: "№",
      width: 30,
      customRender: (_, __, index) => index + 1
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("editDate"),
      width: 120,
      scopedSlots: { customRender: "dateRenderer" }
    },
    {
      key: "sender_data",
      dataIndex: "sender_data",
      title: i18n.t("sender"),
      scopedSlots: { customRender: "senderRanderer" }
    },
    {
      key: "receiver_data",
      dataIndex: "receiver_data",
      title: i18n.t("receiver"),
      scopedSlots: { customRender: "receiverRanderer" }
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: "P-code"
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: `${i18n.t("sum")}, ${i18n.t("uah")}`,
      customRender: (data) => getFormatedSum(data, true)
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("status"),
      customRender: (data) => getStatusText(data)
    }
  ])
  return columns
}
