import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"
import { API_V2 } from "@/utils/HttpUtils.js"

export const fetchPromoCampaigns = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/promo-campaigns${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSinglePromoCampaign = (uuid) => {
  return API_V2.get(`${MPA_PREFIX}/promo-campaigns/${uuid}`)
}

export const createPromoCampaign = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/promo-campaigns`, payload)
}

export const updatePromoCampaign = ({ uuid, payload }) => {
  return API_V2.put(`${MPA_PREFIX}/promo-campaigns/${uuid}`, payload)
}

export const fetchPromoCampaignOffers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/promo-campaigns/${requestParams?.promoUuid}/offers${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const addOffersToPromoCampaign = ({ uuid, payload }) => {
  return API_V2.post(`${MPA_PREFIX}/promo-campaigns/${uuid}/offers`, payload)
}

export const removeOfferFromPromoCampaign = ({ promoUuid, offerUuid }) => {
  return API_V2.delete(`${MPA_PREFIX}/promo-campaigns/${promoUuid}/offers/${offerUuid}`)
}
