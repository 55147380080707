<template>
  <div style="padding-bottom: 70px">
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :loading="isFetching"
      :pagination="false"
      rowKey="id"
    >
      <template #ordering="value, record">
        <TableEditableCellInput
          inputHtmlType="number"
          :text="value"
          @change="(value, closeEdit) => handleChangeOrdering(value, record, closeEdit)"
        />
      </template>

      <template
        v-for="lang in languanges"
        #[`name-${lang}`]="value, record"
      >
        <TableEditableCellInput
          :text="value"
          :key="lang"
          @change="(value, closeEdit) => handleChangeCategoryName(value, lang, record, closeEdit)"
        />
      </template>

      <template #actions="record">
        <a-button
          type="danger"
          shape="circle"
          icon="delete"
          @click="handleDeletePageCategory(record)"
        />
      </template>
    </a-table>

    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-divider>{{ $t("addCategory") }}</a-divider>
      <a-form-model-item :label="$t('sorting')">
        <a-input-number
          v-model="form.ordering"
          :min="1"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('categoryName')}, RU`"
        prop="name.ru"
      >
        <a-input v-model="form.name.ru" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('categoryName')}, UA`"
        prop="name.uk"
      >
        <a-input v-model="form.name.uk" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('categoryName')}, EN`"
        prop="name.en"
      >
        <a-input v-model="form.name.en" />
      </a-form-model-item>

      <a-button
        type="primary"
        :loading="isAddingCategory"
        @click="addItem"
      >
        {{ $t("add") }}
      </a-button>
    </a-form-model>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue"
import { message, notification } from "ant-design-vue"
import i18n from "@/i18n"
import TableEditableCellInput from "@/ant-components/TableEditableCellInput/"

import {
  fetchPageCategoryItems,
  createPageCategoryItem,
  deletePageCategoryItem,
  updatePageCategoryItem
} from "@/modules/MPAdmin/services/pageBuilderService.js"

import usePageBuilderColumns from "@/modules/MPAdmin/pages/PageBuilder/usePageBuilderColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { requiredRule } from "@/utils/validations"
import useForm from "@/composables/useForm"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: () => false
  }
})

const { pageCategoryColumns: columns } = usePageBuilderColumns()

const {
  dataSource,
  isFetching,

  fetchTableInfo
} = useAntTableQuery({
  queryFunction: fetchPageCategoryItems,
  requestParams: { changeRouterQuery: true }
})

const isAddingCategory = ref(false)

const languanges = ["ru", "uk", "en"]

const formRef = ref()
const form = reactive({
  name: { ru: "", uk: "", en: "" },
  ordering: 1
})
const formRules = computed(() => ({
  name: {
    uk: categoryNameRules.value,
    ru: categoryNameRules.value,
    en: categoryNameRules.value
  },
  ordering: [requiredRule.value]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const categoryNameRules = computed(() => {
  return [
    requiredRule.value,
    { min: 2, message: i18n.t("minLengthError", { length: 2 }) },
    { max: 250, message: i18n.t("maxLengthError", { length: 250 }) }
  ]
})

const onTableChange = () => {
  fetchTableInfo({})
}

const addItem = async () => {
  await validateForm()
  if (!isValidForm.value) return message.error(i18n.t("fillFields"))

  try {
    isAddingCategory.value = true

    await createPageCategoryItem({ ...form, ordering: form.ordering.toString() })

    notification.success({ message: i18n.t("categoryCreated") })
    resetFields()
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isAddingCategory.value = false
  }
}

const handleDeletePageCategory = async (record) => {
  try {
    await deletePageCategoryItem(record.id)

    notification.success({ message: i18n.t("deleted") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const handleChangeCategoryName = async (value, lang, record, closeEdit) => {
  closeEdit()
  try {
    const name = { ...record.name, [lang]: value }

    await updatePageCategoryItem({ name }, record.id)

    notification.success({ message: i18n.t("updated") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const handleChangeOrdering = async (value, record, closeEdit) => {
  if (value && value > 0) {
    closeEdit()

    try {
      await updatePageCategoryItem({ ordering: value }, record.id)

      notification.success({ message: i18n.t("updated") })
      onTableChange()
    } catch (error) {
      notifyResponseError({ error })
    }
  } else {
    notification.error({ message: i18n.t("minNumberError", { min: 0 }) })
  }
}

onMounted(onTableChange)
</script>

<style lang="scss" scoped>
.category__form {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
