import { jwtDecode } from "jwt-decode"
import Cookies from "js-cookie"
import router from "@/router"

import { pathForComponents } from "@/components/Layout/Navigation/navigationComponents"

import {
  getProfileInfo,
  handleLogin,
  resetApiAuthHeader,
  sendLogoutRequest,
  setApiAuthHeader,
  updateAccessToken
} from "./authService"

const auth = {
  namespaced: true,

  state: {
    profile: {},
    permissions: [],
    isLogged: false,
    loginRequest: false,
    profileRequest: false,
    permissionsRequest: false
  },

  mutations: {
    loginRequest(state) {
      state.loginRequest = true
    },

    loginSuccess(state, payload) {
      state.loginRequest = false
      state.isLogged = true
      state.profile = payload
    },

    loginFailure(state) {
      state.loginRequest = false
      state.isLogged = false
    },

    logoutSuccess(state) {
      state.profile = {}
      state.isLogged = false
    },

    getProfileRequest(state) {
      state.profileRequest = true
    },

    getProfileSuccess(state, payload) {
      state.profileRequest = false
      state.profile = payload
      state.isLogged = true
    },

    getProfileFailure(state) {
      state.profileRequest = false
      state.isLogged = false
    },

    getPermissionsRequest(state) {
      state.permissionsRequest = true
    },

    getPermissionsSuccess(state, permissions) {
      state.permissionsRequest = false
      state.permissions = permissions
    },

    getPermissionsFailure(state) {
      state.permissionsRequest = false
      state.permissions = []
    }
  },

  actions: {
    async login({ commit, dispatch }, authParams) {
      commit("loginRequest")

      try {
        const { data } = await handleLogin({
          login: authParams.username,
          password: authParams.password,
          two_fa_code: authParams.two_fa_code
        })

        setApiAuthHeader("Bearer " + data.access_token)

        await dispatch("setTokens", data)
        await dispatch("redirectFromLogin")

        const user = getProfileInfo()
        commit("loginSuccess", user)
        return Promise.resolve(data)
      } catch (error) {
        commit("loginFailure")
        resetApiAuthHeader()
        dispatch("clearTokens")
        return Promise.reject(error)
      }
    },

    async redirectFromLogin({ dispatch }) {
      const permissions = await dispatch("getUserPermissions")

      if (!permissions.length) {
        router.push({ path: "/mp-admin/categories/" })
        return Promise.resolve()
      }

      if (router.currentRoute.query.next && router.currentRoute.query.next !== "/") {
        router.push({ path: router.currentRoute.query.next })
      } else {
        const firstCode = permissions.find((code) => pathForComponents[code])

        if (firstCode) {
          router.push({ path: pathForComponents[firstCode] })
        } else {
          router.push({ path: "/mp-admin/categories/" })
        }
      }

      return Promise.resolve()
    },

    async logout({ commit, dispatch }, redirect = true) {
      await sendLogoutRequest()

      resetApiAuthHeader()
      dispatch("clearTokens")
      commit("logoutSuccess")

      if (router.currentRoute.path !== "/login") {
        dispatch("goToLoginPage", redirect)
      }
    },

    getAuthTokenFromstorage({}, key) {
      const storedToken = JSON.parse(localStorage.getItem(key))
      const now = new Date().getTime()
      if (!storedToken || now > storedToken.expiry) {
        localStorage.removeItem(key)
        return null
      }

      return storedToken.token
    },

    async getProfile({ commit, dispatch }) {
      commit("getProfileRequest")

      let access = await dispatch("getAuthTokenFromstorage", "access_token_mpa")
      if (!access) {
        try {
          const { access_token } = await dispatch("sendRefreshToken")
          access = access_token
        } catch {
          dispatch("logout")
          return commit("getProfileFailure")
        }
      }

      setApiAuthHeader("Bearer " + access)

      try {
        const user = getProfileInfo()

        await dispatch("getUserPermissions")
        commit("getProfileSuccess", user)
      } catch {
        if (router.currentRoute.path !== "/login") {
          dispatch("goToLoginPage")
        }
        commit("getProfileFailure")
      }
    },

    async sendRefreshToken({ dispatch }) {
      return new Promise(async (res, rej) => {
        try {
          const refresh = await dispatch("getAuthTokenFromstorage", "refresh_token_mpa")
          if (!refresh) return rej("Not authorized")

          const { data } = await updateAccessToken(refresh)

          await dispatch("setTokens", data)
          res(data)
        } catch (e) {
          rej(e)
        }
      })
    },

    setTokens({}, data) {
      return new Promise((res, rej) => {
        try {
          const { access_token, refresh_token } = data

          if (!access_token) {
            rej(new Error("Access token is invalid"))
            return
          }

          const now = new Date()
          const accessTokenExpiry = now.getTime() + 1 * 60 * 60 * 1000 // Час зберігання токену
          const refreshTokenExpiry = now.getTime() + 8 * 60 * 60 * 1000

          localStorage.setItem(
            "access_token_mpa",
            JSON.stringify({ token: access_token, expiry: accessTokenExpiry })
          )
          localStorage.setItem(
            "refresh_token_mpa",
            JSON.stringify({ token: refresh_token, expiry: refreshTokenExpiry })
          )

          res(data)
        } catch (e) {
          rej(e)
        }
      })
    },

    clearTokens({}) {
      localStorage.removeItem("access_token_mpa")
      localStorage.removeItem("refresh_token_mpa")
    },

    async getUserPermissions({ commit, dispatch }) {
      commit("getPermissionsRequest")

      let accessToken = await dispatch("getAuthTokenFromstorage", "access_token_mpa")

      if (!accessToken) {
        try {
          const { access_token } = await dispatch("sendRefreshToken")
          accessToken = access_token
        } catch {
          return commit("getPermissionsFailure")
        }
      }

      const { permissions } = jwtDecode(accessToken)
      if (!permissions) {
        if (router.currentRoute.path !== "/login") {
          dispatch("goToLoginPage")
        }
        return commit("getPermissionsFailure")
      }

      commit("getPermissionsSuccess", permissions)
      return permissions
    },

    goToLoginPage({}, redirect = true) {
      const query = {
        next: router.currentRoute.path
      }

      router.push({
        path: "/login",
        query: redirect ? query : {}
      })
    }
  },

  getters: {
    permissions: (state) => {
      return state.permissions
    }
  }
}

export default auth
