import i18n from "@/i18n"

export const usersMeta = {
  permissionCode: "UMGMT/USRCMP",
  code: "USRCMP",
  title: i18n.t("users")
}
export const rolesMeta = {
  permissionCode: "UMGMT/RLSCMP",
  code: "RLSCMP",
  title: i18n.t("roles")
}
