<template>
  <div class="promo-codes-list">
    <PageHeader :title="$t('promoCodesPageTitle')">
      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset()"
        >
          {{ $t("clearFilters") }}
        </a-button>

        <a-button
          type="primary"
          icon="plus"
          @click="handleShowCreateModal()"
        >
          {{ $t("createPromoCode") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="uuid"
      :columns="columns"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #isActiveRenderer="isActive">
        <span :class="[isActive ? 'active' : 'inactive']">
          {{ isActive ? $t("isPromoActive") : $t("isPromoInactive") }}
        </span>
      </template>

      <template #actions="record">
        <a-row>
          <a-col :span="12">
            <a-button
              type="dashed"
              shape="circle"
              icon="edit"
              @click="goToEditPage(record)"
            />
          </a-col>
          <a-col :span="12">
            <a-popconfirm
              :title="$t('promoCodeDeleteConfirm')"
              :ok-text="$t('yes')"
              :cancel-text="$t('no')"
              placement="topRight"
              @confirm="handleDeletePromocode(record.uuid)"
            >
              <a-button
                type="danger"
                ghost
                shape="circle"
                icon="delete"
                :loading="isDeletingUuids.find((item) => item === record.uuid) !== undefined"
              />
            </a-popconfirm>
          </a-col>
        </a-row>
      </template>
    </a-table>

    <a-drawer
      :width="500"
      :title="$t('createPromoCode')"
      :visible="isOpenedCreateModal"
      destroyOnClose
      @close="handleHideCreateModal()"
    >
      <CreatePromoCodeModal
        @close="handleHideCreateModal()"
        @submit="goToEditPage"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"
import useAntTableQuery from "@/composables/useAntTableQuery"
import { fetchPromoCodes, deletePromoCode } from "@/modules/MPAdmin/services/promoCodesService"
import { computed, onMounted, shallowRef } from "vue"
import router from "@/router"
import usePromoCodesColumns from "./usePromoCodesColumns"
import CreatePromoCodeModal from "./components/CreatePromoCodeModal.vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/TableRendererDateTime.vue"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchPromoCodes,
  requestParams: { changeRouterQuery: true }
})

const isDeletingUuids = shallowRef([])

const columns = usePromoCodesColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const isOpenedCreateModal = shallowRef(false)

const handleShowCreateModal = () => {
  isOpenedCreateModal.value = true
}
const handleHideCreateModal = () => {
  isOpenedCreateModal.value = false
}

const goToEditPage = (record) => {
  router.push({ name: "PromoCodesEdit", params: { uuid: record.uuid } })
}

const handleDeletePromocode = async (uuid) => {
  try {
    isDeletingUuids.value = [...isDeletingUuids.value, uuid]

    await deletePromoCode(uuid)
    notification.success({ message: i18n.t("deleted") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isDeletingUuids.value = isDeletingUuids.value.filter((item) => item !== uuid)
  }
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.promo-codes-list {
  &__date-picker {
    display: flex;
    align-items: center;
    gap: 8px;

    width: 340px;

    & > :first-child {
      white-space: nowrap;
      color: $dark;
    }
  }
}

.active {
  color: $green-color;
}

.inactive {
  color: $dark;
}
</style>
