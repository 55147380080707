import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { API_V2 } from "@/utils/HttpUtils"
import { getLocalizedHeaders } from "./servicesUtils.js"
import { MODERATION_PREFIX } from "@/constants/apiPrefix.js"

export const fetchTypes = async ({ queryParams, requestParams, signal }) => {
  try {
    const { data } = await API_V2.get(
      `${MODERATION_PREFIX}/product-types/${getFormatedQueryParams(
        queryParams,
        requestParams?.changeRouterQuery
      )}`,
      getLocalizedHeaders(requestParams?.language?.value, signal)
    )

    if (data.results.length) {
      const { data: metaData } = await fetchTypesMeta({
        queryParams: {
          codes: data.results.map((item) => item.code)
        },
        requestParams,
        signal
      })

      data.results.forEach((item) => {
        item.attribute_count =
          metaData.results.find(({ code }) => code === item.code)?.attribute_count || 0
      })
    }

    return Promise.resolve({ data })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchTypesMeta = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MODERATION_PREFIX}/product-types/meta/${getFormatedQueryParams(queryParams)}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchType = ({ code, language = "ru" }) => {
  return API_V2.get(`${MODERATION_PREFIX}/product-types/${code}/`, getLocalizedHeaders(language))
}

export const fetchLocalizedType = async ({ code }) => {
  try {
    const ruResponse = await fetchType({ code, language: "ru" })
    const ukResponse = await fetchType({ code, language: "uk" })
    const enResponse = await fetchType({ code, language: "en" })

    const data = { ...ruResponse.data }
    data.full_name = {
      ru: ruResponse.data.full_name,
      uk: ukResponse.data.full_name,
      en: enResponse.data.full_name
    }
    return Promise.resolve({ data })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createType = ({ payload, language }) => {
  return API_V2.post(`${MODERATION_PREFIX}/product-types/`, payload, getLocalizedHeaders(language))
}

export const updateType = ({ code, payload, language = "ru" }) => {
  return API_V2.put(
    `${MODERATION_PREFIX}/product-types/${code}/`,
    payload,
    getLocalizedHeaders(language)
  )
}

export const updateLocalizedType = async ({ code, payload }) => {
  try {
    const response = await updateType({
      code,
      payload: { ...payload, full_name: payload.full_name.ru, note: payload.note },
      language: "ru"
    })
    await updateType({
      code,
      payload: { full_name: payload.full_name.uk },
      language: "uk"
    })
    await updateType({
      code,
      payload: { full_name: payload.full_name.en },
      language: "en"
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const bulkTypesUpdate = async ({ types, dataSource }) => {
  try {
    const typesPromises = types.map((code) => {
      const record = dataSource.find((item) => item.code === code)
      if (!record) return null
      return updateType({ code, payload: { note: record.note } })
    })

    await Promise.all(typesPromises)

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
