<template>
  <div class="user-session">
    <TableRendererDateTime :date="props.session.logged_at" />

    <div>
      {{ props.session.user_agent }}
    </div>

    <a-button
      v-if="props.showTerminateButton"
      type="dashed"
      shape="circle"
      icon="delete"
      @click="onTerminate"
    />
  </div>
</template>

<script setup>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/TableRendererDateTime.vue"

const props = defineProps({
  session: {
    type: Object,
    default: () => ({})
  },
  showTerminateButton: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["terminate"])

const onTerminate = () => {
  emit("terminate")
}
</script>

<style lang="scss" scoped>
.user-session {
  display: flex;
  gap: 16px;

  & > :nth-child(3) {
    border-color: $red-color;
    color: $red-color;
    margin-left: auto;
  }
}
</style>
