import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("dateCreated"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
      fixed: "left",
      width: 160
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name"),
      width: 430
    },
    {
      key: "description",
      dataIndex: "description",
      title: i18n.t("description"),
      width: 1000
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "isActiveRenderer"
      },
      fixed: "right",
      width: 110
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      fixed: "right",
      width: 65
    }
  ])

  return columns
}
