import simpleBuildQuery from "@/utils/simpleBuildQuery.js"
import router from "@/router/index.js"

export default (queryParams, changeRouter = false) => {
  const { page = 1, pageSize: limit = 10, ...otherQueryParams } = queryParams

  const prepareRouterQuery = (params) => {
    const newRouteQuery = { ...params }

    if (newRouteQuery.page === 1) delete newRouteQuery.page
    if (newRouteQuery.pageSize === 10) delete newRouteQuery.pageSize

    if (newRouteQuery.page) newRouteQuery.page = String(newRouteQuery.page)
    if (newRouteQuery.pageSize) newRouteQuery.pageSize = String(newRouteQuery.pageSize)

    return newRouteQuery
  }

  if (changeRouter) {
    const newRouteQuery = prepareRouterQuery(queryParams)

    const currentQuery = router.currentRoute.query
    const isQueryDifferent = Object.keys(currentQuery).some((key) => !(key in newRouteQuery))
    const isQueryChanged = Object.keys(newRouteQuery).some(
      (key) => String(newRouteQuery[key]) !== String(currentQuery[key])
    )

    if (isQueryDifferent || isQueryChanged) {
      router.replace({ query: newRouteQuery })
    }
  }

  return simpleBuildQuery({
    ...otherQueryParams,
    limit,
    offset: (page - 1) * limit
  })
}
