<template>
  <div>
    <a-form-model
      ref="formRef"
      :model="formModel"
      :rules="formRules"
    >
      <a-form-model-item
        prop="feed_logic"
        class="feed-logic"
      >
        <a-switch
          :checked="formModel.feed_logic.ga === 'FULL'"
          @change="(value) => handleChangeFeedLogic(value, 'ga')"
        />
        {{ $t("feedLogic") }}
      </a-form-model-item>

      <a-form-model-item
        prop="feed_logic.fb"
        class="feed-logic"
      >
        <a-switch
          :checked="formModel.feed_logic.fb === 'FULL'"
          @change="(value) => handleChangeFeedLogic(value, 'fb')"
        />
        {{ $t("feedLogicFacebook") }}
      </a-form-model-item>

      <a-form-model-item
        prop="feed_logic"
        class="feed-logic"
      >
        <a-switch
          :checked="formModel.feed_logic.ek === 'FULL'"
          @change="(value) => handleChangeFeedLogic(value, 'ek')"
        />
        {{ $t("feedLogicEkatalog") }}
      </a-form-model-item>

      <a-form-model-item
        prop="ga_condition_logic"
        class="feed-logic"
      >
        <a-switch
          :checked="formModel.ga_condition_logic === 'NEW'"
          @change="handleChangeConditionLogic"
        />
        {{ $t("feedConditionLogicLabel") }}
      </a-form-model-item>

      <a-form-model prop="label_exist">
        <a-radio-group
          v-model="formModel.label_exists"
          :options="labelExistOptions"
        />
      </a-form-model>

      <a-form-model-item
        :labelCol="{ span: 2 }"
        :wrapperCol="{ span: 22 }"
        label="Custom label"
        prop="default_label"
        class="default-label"
      >
        <div class="autocomplete">
          <a-auto-complete
            v-model="formModel.default_label"
            :dataSource="dataSource"
            placeholder="Custom label"
            @change="onLabelSearch"
            @blur="checkLabelExist()"
            @select="checkLabelExist()"
          />

          <a-button
            :disabled="!seller.default_label"
            @click="removeLabel()"
          >
            {{ $t("delete") }} C-label
          </a-button>
        </div>
      </a-form-model-item>

      <a-form-model-item
        :label="$t('note')"
        prop="comment"
      >
        <a-textarea
          v-model="formModel.comment"
          class="padding-bottom"
          allowClear
          :autoSize="{ minRows: 6, maxRows: 6 }"
        />
      </a-form-model-item>

      <div class="form-row">
        <a-form-model-item
          :label="$t('advertising')"
          prop="cpa_kind"
        >
          <a-select
            v-model="formModel.cpa_kind"
            :options="cpaKindOptions"
          />
        </a-form-model-item>

        <a-form-model-item
          label="CPA Status"
          prop="cpa_status"
        >
          <a-select
            v-model="formModel.cpa_status"
            :options="cpaStatusOptions"
          />
        </a-form-model-item>

        <a-form-model-item
          v-show="formModel.cpa_kind === 'REGULAR'"
          label="CPA %"
          prop="cpa_percent"
        >
          <a-input-number
            v-model="formModel.cpa_percent"
            :precision="2"
            :min="0"
            :max="100"
          />
        </a-form-model-item>

        <a-form-model-item
          label="Ref"
          prop="is_ref"
        >
          <a-switch v-model="formModel.is_ref" />
        </a-form-model-item>

        <a-form-model-item
          label="% Ref"
          prop="ref_percent"
        >
          <a-input-number
            v-model="formModel.ref_percent"
            :precision="2"
            :min="0"
            :max="100"
          />
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import i18n from "@/i18n"

import useAdsComposables from "@/modules/ADS/useAdsComposables.js"
import useSellerConsts from "@/modules/ADS/pages/Sellers/useSellerConsts.js"

import notifyResponseError from "@/utils/notifyResponseError.js"
import { searchCustomLabel } from "@/modules/ADS/services/merchantServices.js"
import useCancellableRequest from "@/composables/useCancellableRequest"
import useForm from "@/composables/useForm"

const props = defineProps({
  seller: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(["onRemoveLabel"])

const { makeRequest } = useCancellableRequest(searchCustomLabel)
const { cpaKindFilterList } = useAdsComposables()
const { cpaStatusOptions, labelExistOptions } = useSellerConsts()

const dataSource = ref([])

const formRef = ref()
const formModel = reactive({
  label_exists: Boolean(props.seller?.default_label),
  default_label: props.seller?.default_label || "",
  comment: props.seller?.comment || "",
  cpa_kind: props.seller?.cpa_kind || "CATEGORICAL",
  cpa_status: props.seller?.cpa_status || "ACTIVE",
  cpa_percent: props.seller?.cpa_percent,
  is_ref: Boolean(props.seller?.is_ref),
  ref_percent: props.seller?.ref_percent || 0,
  feed_logic: {
    ga: props.seller?.feed_logic?.ga || "BASE",
    fb: props.seller?.feed_logic?.fb || "BASE",
    ek: props.seller?.feed_logic?.ek || "BASE"
  },
  ga_condition_logic: props.seller.ga_condition_logic
})
const formRules = {
  default_label: { max: 100, message: i18n.t("maxLengthError", { length: 100 }) },
  comment: { max: 500, message: i18n.t("maxLengthError", { length: 500 }) }
}
const { isValidForm, validateForm } = useForm(formRef)

const cpaKindOptions = computed(() => cpaKindFilterList.value.slice(1))

const onLabelSearch = async (search) => {
  if (!search) return

  try {
    const { data } = await makeRequest({ params: { search } })

    dataSource.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  }
}

const checkLabelExist = () => {
  if (
    dataSource.value.includes(formModel.default_label) ||
    props.seller.default_label === formModel.default_label
  ) {
    formModel.label_exists = true
    return
  }

  formModel.label_exists = false
}

const handleChangeFeedLogic = (value, field) => {
  formModel.feed_logic[field] = value ? "FULL" : "BASE"
}

const handleChangeConditionLogic = (value) => {
  formModel.ga_condition_logic = value ? "NEW" : "USED"
}

const removeLabel = () => {
  emit("onRemoveLabel")
}

const onValidate = async () => {
  await validateForm()
  return isValidForm.value ? formModel : null
}

defineExpose({
  onValidate
})
</script>

<style lang="scss" scoped>
.form-row {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;

  & > * {
    width: 10%;
  }

  & > :nth-child(2) {
    width: 25%;
  }

  & > :nth-child(4) {
    margin-left: auto;
    width: fit-content;
  }
}

.feed-logic {
  margin-bottom: 4px;
}

.default-label {
  margin-top: 8px;
}

.autocomplete {
  display: flex;
  align-items: center;
  gap: 16px;

  margin-top: 4px;
}
</style>
