import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchPropositions = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/propositions${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchPropositionsMeta = () => {
  return API_V2.get(`${MPA_PREFIX}/propositions/meta`)
}

export const fetchPropositionsActionsData = () => {
  return API_V2.get(`${MPA_PREFIX}/propositions/meta`)
}

export const updateProposition = (data, id) => {
  return API_V2.patch(`${MPA_PREFIX}/propositions/${id}`, data)
}

export const getDeliveryPolitics = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/delivery-politics/${requestParams.id.value}/${getFormatedQueryParams(
      queryParams
    )}`,
    { signal }
  )
}

export const bulkChangeOfferLabel = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/offers/actions/assign-labels/`, payload)
}
