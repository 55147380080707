import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"
import { API_V2 } from "@/utils/HttpUtils.js"

export const fetchPromoCodes = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/promo-codes${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSinglePromoCode = (uuid) => {
  return API_V2.get(`${MPA_PREFIX}/promo-codes/${uuid}`)
}

export const createPromoCode = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/promo-codes`, payload)
}

export const updatePromoCode = ({ uuid, payload }) => {
  return API_V2.put(`${MPA_PREFIX}/promo-codes/${uuid}`, payload)
}

export const deletePromoCode = (uuid) => {
  return API_V2.delete(`${MPA_PREFIX}/promo-codes/${uuid}`)
}

export const fetchPromoCodeOffers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/promo-codes/${requestParams?.promoUuid}/offers${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const addOffersToPromoCode = ({ uuid, payload }) => {
  return API_V2.post(`${MPA_PREFIX}/promo-codes/${uuid}/offers`, payload)
}

export const removeOfferFromPromoCode = ({ promoUuid, offerUuid }) => {
  return API_V2.delete(`${MPA_PREFIX}/promo-codes/${promoUuid}/offers/${offerUuid}`)
}
