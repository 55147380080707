import { computed } from "vue"
import i18n from "@/i18n"
import { requiredRule } from "@/utils/validations"

export default () => {
  const allowedFormats = ["jpg", "jpeg", "png"]
  const allowedFormatsString = allowedFormats.join(", ")

  const nameRules = [
    requiredRule.value,
    { max: 250, message: i18n.t("maxLengthError", { length: 250 }) }
  ]

  const linkRules = [
    requiredRule.value,
    { max: 500, message: i18n.t("maxLengthError", { length: 500 }) }
  ]

  return {
    requiredRule,
    nameRules,
    linkRules,
    allowedFormats,
    allowedFormatsString
  }
}
