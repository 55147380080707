<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :style="{ marginTop: '50px' }"
  >
    <a-form-model-item
      :label="$t('orderStatus')"
      prop="status"
    >
      <a-select
        style="width: 100%"
        v-model="form.status"
      >
        <a-select-option
          v-for="status in orderStatuses"
          :key="status.value"
        >
          <div :style="{ color: status.color }">{{ status.title }}</div>
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      v-if="showRejectSelect"
      :label="$t('rejectReason')"
      prop="reject_reason"
    >
      <a-select
        style="width: 100%"
        v-model="form.reject_reason"
      >
        <a-select-option
          v-for="reason in rejectReasons"
          :key="reason.id"
        >
          {{ reason.reason.uk }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('moderationStatus')"
      prop="moderation_status"
    >
      <a-select
        style="width: 100%"
        v-model="form.moderation_status"
      >
        <a-select-option
          v-for="status in orderModerationStatuses"
          :key="status.value"
        >
          <div :style="{ color: status.color }">{{ status.title }}</div>
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-popconfirm
      v-if="canCancelPayment"
      placement="topLeft"
      :ok-text="$t('cancelPayment')"
      :cancel-text="$t('cancel')"
      @confirm="handleCancelPayment"
    >
      <template slot="title">
        <p>{{ $t("refundConfirmation") }}</p>
        <p>{{ $t("refundConfirmation2") }}</p>
      </template>
      <a-button :loading="changePaymentStatusProcessing">{{ $t("cancelPayment") }}</a-button>
    </a-popconfirm>
  </a-form-model>
</template>

<script setup>
import { useStatuses } from "@/composables/useStatuses"
import { computed, reactive, ref } from "vue"

const props = defineProps({
  rejectReasonsBuyer: {
    type: Array,
    default: () => []
  },
  rejectReasonsModerator: {
    type: Array,
    default: () => []
  },
  rejectReasonsSeller: {
    type: Array,
    default: () => []
  },
  orderStatus: {
    type: String,
    required: true
  },
  orderPaymentStatus: {
    type: String,
    required: true
  },
  orderCode: {
    type: String,
    required: true
  },
  changePaymentStatusProcessing: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits(["on-cancel-payment"])

const canceledStatuses = ["CANCELED_BY_SELLER", "CANCELED_BY_CUSTOMER", "CANCELED_BY_SUPERVISOR"]
const { orderStatuses, orderModerationStatuses } = useStatuses()

const formRef = ref()
const form = reactive({
  status: undefined,
  reject_reason: undefined,
  moderation_status: undefined
})
const setFormFields = (payload) => {
  Object.keys(payload).forEach((key) => {
    form[key] = payload[key]
  })
}
const getFormFields = () => form
const resetFields = () => {
  formRef.value.resetFields()
}

const rejectReasons = computed(() => {
  if (form.status === "CANCELED_BY_SELLER") return props.rejectReasonsSeller
  if (form.status === "CANCELED_BY_SUPERVISOR") return props.rejectReasonsModerator
  return props.rejectReasonsBuyer
})

const showRejectSelect = computed(() => canceledStatuses.indexOf(form.status) !== -1)

const canCancelPayment = computed(() => {
  return ["PAID", "REFUND_MANUALLY", "REFUND_CANCELED"].includes(props.orderPaymentStatus)
})

const handleCancelPayment = () => {
  emit("on-cancel-payment")
}

defineExpose({
  setFormFields,
  getFormFields,
  resetFields
})
</script>
