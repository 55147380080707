import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchCancellationReasons = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/order-reject-reasons${getFormatedQueryParams(queryParams)}`, {
    signal
  })
}

export const createCancellationReason = (data) => {
  return API_V2.post(`${MPA_PREFIX}/order-reject-reasons`, data)
}

export const fetchCancellationReasonsMeta = () => {
  return API_V2.get(`${MPA_PREFIX}/order-reject-reasons/meta`)
}

export const updateCancellationReason = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/order-reject-reasons/${id}`, data)
}
