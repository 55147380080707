<template>
  <a-form-model class="import-values">
    <a-button
      type="dashed"
      icon="download"
      block
      style="margin-bottom: 16px"
      :disabled="isFetching"
      @click="downloadExample"
    >
      {{ $t("downloadTagsImportExample") }}
    </a-button>

    <a-upload-dragger
      :beforeUpload="onChooseFile"
      accept="text/csv"
      :fileList="currentFiles"
      :remove="() => (currentFiles = [])"
      :disabled="isFetching"
    >
      <div class="upload-field">
        <a-icon
          type="inbox"
          class="upload-field__icon"
        />

        {{ $t("chooseOrDragForUpload") }}
      </div>
    </a-upload-dragger>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="$emit('close')"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        :disabled="disableSubmit"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import notifyResponseError from "@/utils/notifyResponseError.js"
import {
  importAttributeLocalizedValuesFile,
  importAttributeValuesFile
} from "../../../services/attributeValuesService.js"
import { downloadCsv } from "@/utils/downloadCsv.js"

const props = defineProps({
  isLocalisedModal: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["close"])

const currentFiles = ref([])
const disableSubmit = computed(() => !currentFiles.value.length)

const onChooseFile = (file) => {
  if (file.name.split(".")?.at(-1) !== "csv") {
    notification.error({ message: `${i18n.t("formatError")}: .csv` })
    return false
  }

  currentFiles.value = [file]
  return false
}

const isFetching = shallowRef(false)
const onSubmit = async () => {
  try {
    isFetching.value = true

    if (props.isLocalisedModal) {
      await importAttributeLocalizedValuesFile({ file: currentFiles.value[0] })
    } else {
      await importAttributeValuesFile({ file: currentFiles.value[0] })
    }

    notification.info({ message: i18n.t("importStarted") })
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const downloadExample = () => {
  const content = props.isLocalisedModal
    ? [
        ["code", "en", "uk"],
        ["VLD-123", "Eng", "Укр"]
      ]
    : [
        ["attribute", "value", "unit", "code"],
        ["AND-00002", "1080x1920", "", ""],
        ["AND-00003", "120", "", ""]
      ]

  downloadCsv(content, `${props.isLocalisedModal ? "localization-" : ""}values-import-example`)
}
</script>

<style lang="scss" scoped>
.import-values {
  padding-bottom: 50px;
}
.upload-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;

  font-size: 16px;

  &__icon {
    color: $primary-color;
    font-size: 36px;
  }
}
</style>
