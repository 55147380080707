<template>
  <a-table
    :columns="columns"
    :dataSource="props.items"
    row-key="id"
    :pagination="false"
  >
    <template #dateRenderer="text">
      <TableRendererDateTime :date="text" />
    </template>

    <template #codeRenderer="text">
      <div
        :style="{ color: '#1890FF', cursor: 'pointer' }"
        @click="handleCopy(text)"
      >
        {{ text }}
        <a-icon type="copy" />
      </div>
    </template>
  </a-table>
</template>

<script setup>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import i18n from "@/i18n"
import { message } from "ant-design-vue"
import useOrderReturnsColumns from "./columns/useOrderReturnsColumns"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})
const columns = useOrderReturnsColumns()

const handleCopy = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}
</script>
