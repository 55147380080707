<template>
  <div class="create-role-modal">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('name')"
        prop="name"
      >
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('description')"
        prop="description"
      >
        <a-input v-model="form.description" />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n.js"

import { createRole } from "@/modules/UsersManagement/services/rolesService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  apps: {
    type: Array,
    default: () => []
  },
  appsFetching: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["onCreate", "close"])

const formRef = ref()
const form = reactive({
  name: "",
  description: ""
})
const formRules = computed(() => ({
  name: [requiredRule.value],
  description: [requiredRule.value]
}))

const { isValidForm, validateForm } = useForm(formRef)

const isFetching = shallowRef(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    const { data } = await createRole(form)

    notification.success({ message: i18n.t("roleCreatedSuccessfully") })
    emit("onCreate", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style scoped lang="scss">
.create-role-modal {
  display: flex;
  flex-flow: column nowrap;

  padding-bottom: 50px;
}
</style>
