import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchSearchesList = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/products/search-keywords${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSearchById = ({ uuid }) => {
  return API_V2.get(`${MPA_PREFIX}/products/search-keywords/${uuid}`)
}

export const updateSearchKeywords = ({ uuid, payload }) => {
  return API_V2.put(`${MPA_PREFIX}/products/search-keywords/${uuid}`, payload)
}

export const importSearchesCsv = ({ formData }) => {
  return API_V2.post(`${MPA_PREFIX}/products/search-keywords/import`, formData)
}

export const checkImportFileResult = ({ task }) => {
  return API_V2.get(`${MPA_PREFIX}/products/search-keywords/import/${task}`)
}

export const getImportFileResult = async ({ url }) => {
  try {
    window.open(url, "_blank")

    notification.success({ message: i18n.t("success") })
  } catch {
    notification.error({
      message: i18n.t("fileLoadingError")
    })
  }
}

export const checkProductKeywordScore = ({ queryParams, code }) => {
  return API_V2.get(`${MPA_PREFIX}/products/search-keywords/check/${code}?q=${queryParams.q}`)
}

export const fetchKeywordProducts = ({ queryParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/products/search-keywords/check${getFormatedQueryParams(queryParams)}`,
    {
      signal
    }
  )
}
