<template>
  <a-table
    :dataSource="dataSource"
    :loading="isFetching"
    :columns="columns"
    rowKey="id"
    :scroll="{ y: 600 }"
    :pagination="false"
  />
</template>

<script setup>
import { onMounted, computed } from "vue"

import { getDeliveryPolitics } from "@/modules/MPAdmin/services/propositionsService.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useDeliveryPoliticsColumns from "./useDeliveryPoliticsColumns"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object
  }
})

const {
  dataSource,
  isFetching,

  setupTable,
  fetchTableInfo
} = useAntTableQuery({
  queryFunction: getDeliveryPolitics,
  requestParams: { id: computed(() => props.data.delivery.id) }
})

const columns = useDeliveryPoliticsColumns()

const onTableChange = () => {
  fetchTableInfo({})
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>
