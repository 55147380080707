export default {
  directoryOfGoods: "Довідник товарів",
  createProduct: "Створити товар",
  verification: "Верифікація",
  searchByTitleForPublication: "Пошук за назвою для публікації",
  productType: "Тип товару",
  fullProductType: "Тип товару (повний)",
  titleForPublication: "Назва для публ.",
  editingProductLocalization: "Редагування локалізації товару",
  editingProductLocalizationDescription:
    "Додавання локалізації можливе тільки після проходження товаром верифікації (статус 'VERIFIED' і 'TRANSFERRED')",
  makeDuplicate: "Зробити дублікат",
  productEditing: "Редагування товару",
  productCreating: "Створення нового товару",
  productCreatingWarning: "Ви хочете скасувати створення товару?",
  productEditingWarning: "Ви хочете скасувати редагування товару?",
  exportSuccessWithErrors: "Експорт успішний але з помилками",
  exportSuccess: "Експорт товару успішно додано в чергу",
  exportFailed: "Помилка при експорті",
  exportError: "Помилка експорту",
  errorInTask: "Помилка в таску",
  loadAttributesByTypeError: "Помилка завантаження атрибутів за типом товару",
  generalProductFormFilledWithErrors: 'Форма у вкладці "Загальне" заповнена з помилками',
  createProductSuccess: "Товар успішно створено",
  creationProductFail: "Товар не створено",
  productAlreadyExistWithThisName: "Товар із таким найменуванням чи моделлю вже існує",
  productLoadError: "Помилка завантаження товару",
  productsLoadError: "Помилка завантаження товарів",
  loadAttributesError: "Помилка завантаження атрибутів",
  loadMainAttributesError: "Помилка завантаження основних атрибутів",
  mainAttributesIsEmpty: "Основні атрибути відсутні у цьому типі",
  typeAttributesIsEmpty: "Атрибути відсутні у цьому типі",
  duplicateAttributeError: "Такий атрибут вже доданий",
  fullProductTitle: "Повне найменування товару",
  fullType: "Тип (повний)",
  shortType: "Тип (короткий)",
  enterProductType: "Введіть тип товару",
  seriesName: "Найменування (серія)",
  enterProductBrand: "Введіть brand товару",
  publicationTitle: "Назва для публікації",
  currentCategory: "Поточна категорія",
  newCategory: "Нова категорія",
  categoryId: "ID категорії",
  categoryCanNotContainProducts: "Ця категорія не може містити товарів",
  categoryNotFound: "Категорія не знайдена",
  shortProductDescription: "Короткий опис товару",
  productDescription: "Опис товару",
  gtinLengthError: "Повинно бути 8, 12, 13 або 14 числових значень",
  saveOrdering: "Зберегти сортування",
  orderingFull: "Сортування",
  imagesChangesSaved: "Зміни зображень збережені",
  imagesChangesFailed: "Помилка оновлення зображень",
  imageEditingFailed: "Помилка редагування зображення",
  imagesAdded: "Зображення додані",
  imageEditing: "Редагування зображення",
  preview: "Попередній перегляд",
  imageUpdated: "Зображення оновлено",
  addAttribute: "Додати атрибут",
  errorInAttributes: "Помилки в артибутах",
  copyFromProduct: "Скопіювати з товару",
  orderSameAsType: "Сортувати як в типі",
  addMains: "Додати основні",
  addAllAttributesByType: "Додати усі атрибути за типом",
  deleteBlank: "Видалити пусті",
  unit: "Одиниця виміру",
  units: "Одиниці виміру",
  characteristics: "Характеристики",
  listValues: "Спискові значення",
  valueProcessing: "Обробка значень",
  unitShorted: "О.в.",
  nameForPublish: "Назва для публікації",
  deleteAttributeConfirm: "Ви хочете видалити атрибут",
  contextSearchByAttribute: "Контекстний пошук по назві атрибуту",
  attributeSearch: "Пошук атрибуту",
  attributeValue: "Значення атрибуту",
  productLocalizationEditing: "Редагування локалізації товару",
  translationTitleForPublish: "Переклад назви для публікації",
  nameTranslation: "Переклад найменування",
  setValueInMainLocale: "Задайте значення для цього поля в основній локалізації (ru)",
  descriptionTranslate: "Переклад опису",
  publicTitleUpdated: "Значення public_title для {code} успішно оновлено",
  valueUpdated: "Значення 'value' для {code} успішно оновлено",
  publicTitleNotUpdated: "Значення public_title не оновлено",
  valueNotUpdated: "Значення 'value' не оновлено",
  localizationEditingWarning: "Ви хочете скасувати редагування локалізації товару?",
  shortDescriptionTranslation: "Переклад короткого опису товару",
  productDublicateError: "Помилка дублювання товару",
  naming: "Найменування",
  fillAttributesValueError: "Будь ласка, заповніть всі значення атрибутів",
  closeAttributesValueError: "Будь ласка, завершіть редагування атрибутів",
  public_title: {
    ru: "Назва (RU)",
    uk: "Назва (UA)"
  },
  moderationTrustStatus: {
    TRUSTED: "Перевірено",
    UNTRUSTED: "Очікує"
  },
  productQualityMark: {
    PERFECT: "Найкраща якість",
    GOOD: "Висока якість",
    AVERAGE: "Середня якість",
    FAIR: "Нормальна якість",
    POOR: "Низька якість",
    VERY_POOR: "Дуже погана якість"
  },
  originOptions: {
    SELLER_MANUAL: "Ручне створення",
    SYNTHETIC_DEFAULT: "Інші"
  },
  unitIsEmptyPlaceholder: "Відсутня",
  postModerationStatus: "Статус пост-модерації",
  origin: "Походження",
  addNewValue: "Додати нове значення",
  productAttributesChangeOptions: {
    VALUE_ONLY_REPLACEMENT: "Заміна значень (в межах атрибуту)",
    FULL_ATTR_REPLACEMENT: "Заміна значень (різні атрибути)",
    VALUE_REMOVAL: "Видалення значень",
    ATTR_REMOVAL: "Видалення атрибутів"
  },
  importExportAttributes: "Імпорт/Експорт атрибутів",
  importAttributes: "Імпорт атрибутів",

  // Export
  exportPageTitle: "Експорт товарів",
  exportHistoryTitle: "Історія екпортів",
  exportProductsToDBTitle: "Експорт товарів в центральну БД",
  exportSellerSlug: "Slug продавця",
  exportExecutor: "Користувач",
  exportTotalProducts: "Всього товарів",
  exportSuccessProducts: "Вивантажено успішно",
  exportErrorProducts: "Помилки при вивантажені",
  exportDateEnd: "Дата закінчення",
  exportStatusStarted: "Виконується",
  exportStatusSuccess: "Завершено",
  exportStatusRevorked: "Відмінено",
  exportStatusFailute: "Помилка",
  exportStatusReceived: "В черзі",
  exportStatusPending: "В черзі (ближче)",
  exportStatusFilter: "Статус експорту",
  exportProductStatus: "Статус товара",
  exportStartConfirm: "Запустити експорт вибраних товарів?",
  exportStartSuccess: "Експорт успішно запущено",
  exportStartError: "Експорт не запустився",
  exportFailedStoped: "Експорт не зупинено",
  exportSuccessStoped: "Експорт зупинено",
  exportErrors: "Помилки експорту",

  // Units
  unitEditing: "Редагування одиниці виміру",
  baseUnit: "Базова один. вимір.",
  synomymsExample: "Наприклад: байт, byte, Byte",
  unitExample: "Наприклад: ГБ",
  descriptionExample: "Наприклад: одиниця вимірювання кількості цифрової інформації",
  possibleSynonymsList: "Список можливих синонімів",
  unitBaseValue: "Значення одиниці виміру",
  creatingProductValue: "Створення значень",
  editingProductValue: "Редагування значення",
  addedLists: "Додані списки",
  approveVLDMessage: 'Ви впевнені, що хочете змінити статус на "APPROVED"?',
  chosenAttribute: "Вибраний атрибут",
  addUnit: "Додати одиницю виміру",
  addUnitShorted: "Додати один. виміру",
  editUnit: "Редагувати одиницю виміру",
  withMainUnit: "З основною одиницею",

  // List values
  valueDeleted: "Значення видалено",
  editList: "Редагувати список",
  deletionWarning: "Підтвердіть видалення",
  chosenList: "Вибраний список",
  deletionValue: "Значення для видалення",
  productsThatUsesValue: "Товари, що використовують значення",
  creatingNewValues: "Створення нових значень",
  newValuesCreate: "Нові значення створені",
  searchByValue: "Пошук по значенню",
  searchListsThatContainsValue: "Пошук всіх списків, які містять значення",
  untieAttributeFromList: "Відв'язати атрибут від списку",
  attributesThatUseList: "Атрибути, що використовують список",
  typesByAttribute: "Типи по атрибуту",
  addAttributeToList: "Додати атрибут до списку",
  exportErrors: "Помилки експорту",
  otherValuesDetectedWarning: "Увага! Виявлено інші значення",
  otherValuesInOtherProductsWarning:
    "Були знайдені товари зі значеннями для обраного атрибута, яких немає в списку. Попередньо потрібно змінити значення в товарах або додати їх до списку вручну.",

  // Attributes
  attributeEditing: "Редагування атрибуту",
  toTether: "Прив'язати",
  toTetherAttribute: "Прив'язати атрибут",
  tethered: "Прив'язано",
  toUntie: "Відв'язати",
  existedAttributesList: "Список існуючих атрибутів",
  chooseList: "Виберіть список",
  attributeIsMain: "Основний атрибут",
  attributeIsRequired: "Обов'язковий атрибут",
  chosenListValues: "Значення вибраного списку",
  listNotChosen: "Список не обраний",
  addingNewAttribute: "Додання нового атрибуту",
  availableValues: "Доступні значення",
  attributeValuesList: "Список значень",
  attributesByType: "Атрибути по типу",
  updateAttributeError: "Помилка оновлення атрибуту",
  updateAttributeSuccess: "Атрибут успішно оновлено",
  updateAttributesOrderSuccess: "Порядок атрибутів успішно оновлено",
  updateAttributesOrderError: "Не вдалось оновити порядок артибутів",
  chooseType: "Виберіть тип",
  chosenList: "Вибраний список",
  addingNewValues: "Додання нових значень",
  listSeparatedByComa: "Перелік значень через кому, пробіл або Enter",
  typesTetheredToAttribute: "Типи, прив'язані до атрибуту",
  editAttribute: "Редагувати атрибут",
  attributeCode: "Код атрибуту",
  workName: "Робоча назва",
  publicName: "Публічна назва",
  valueType: "Тип значення",
  addValue: "Додати значення",
  allowMultipleValues: "Дозволити декілька значень",
  attachedTypes: "Прив'язані типи",
  editAttributes: "Редагувати атрибути",
  valuesList: "Список значень",
  confirmToDeleteAttributeValue: "Ви впевнені що хочете видалити значення?",
  confirmToUnattachType: "Ви впевнені, що хочете відв'язати тип?",
  primarySettings: "Основні налаштування",
  importStarted: "Імпорт стартував",
  productListWithValue: "Товари зі значенням",
  importAttrValuesLocales: "Імпорт / Експорт перекладів",
  importLocales: "Імпорт перекладів",
  exportLocales: "Експорт перекладів",

  // Types
  existedTypesList: "Довідник типів",
  typeEditing: "Редагування типу",
  typeName: "Назва типу",
  attributeName: "Назва атрибуту",
  attachedAttributesCount: "К-сть привʼязаних атрибутів",
  valuesCountInAttribute: "К-сть значень в атрибута",
  editType: "Редагувати тип",
  removeAttributeFromTypeConfirmation: "Ви впевнені, що хочете відв'язати атрибут: {attribute}?",
  removeAttributeFromTypeSuccess: "Атрибут успішно відв'язано",
  removeAttributeFromTypeError: "Увага, знайдені інші значення",
  atrributeAndTypeIsUsedByProductsError:
    "Увага! Щоб видалити, спочатку відредагуйте картки товарів, які мають зв'язку значень цього атрибуту і типу",
  createTypeError: "Помилка створення типу",
  chooseTypeForCopy: "Виберіть тип для копіювання",
  typeCopying: "Копіювання типу",
  tetherAttributeToType: "Прив'язати атрибут до типу",
  chooseAttribute: "Виберіть атрибут",
  newAttribute: "Новий атрибут",
  tetheredAttributes: "Прив'язані атрибути",
  creatingNewType: "Створити тип",
  typeNoteLengthExceeded:
    "Помилка при збереженні, деякі типи мають довжину нотатки більшу за дозволену",
  attachedCategories: "Прив'язані категорії",

  // Brands
  creatingBrand: "Створення нового бренду",
  editingBrand: "Редагування бренду",
  editBrand: "Редагувати бренд",
  chosenBrand: "Вибраний бренд",
  findedInTypes: "Знайдений в типах",
  searchBrandTypes: "Search for product types by brand",
  brandName: "Назва бренду",
  brandCode: "Код бренду",
  addBrand: "Додати бренд"
}
