import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchProductReviews = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/products-reviews${getFormatedQueryParams(
      queryParams,

      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchProductReview = (id) => {
  return API_V2.get(`${MPA_PREFIX}/products-reviews/${id}`)
}

export const updateProductReview = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/products-reviews/${id}`, data)
}
