<template>
  <div
    class="messages-display"
    ref="messagesListRef"
  >
    <div
      v-if="topMessagesLoader"
      class="msg-chat-loader msg-chat-loader--top"
      style="top: 0"
    />

    <div
      class="messages-display__item"
      v-for="(message, index) in messages"
      :key="message.date"
    >
      <PageSource
        v-if="message.type === 'PAGE_SOURCE'"
        :message="message"
      />

      <MessageItem
        v-else
        :message="message"
        :lastMessage="lastMessage"
        :index="index"
        @openGallery="onOpenGallery"
        @showHistory="handleShowMessageHistory(message)"
      />
    </div>

    <div
      v-if="bottomMessagesLoader"
      class="msg-chat-loader"
      style="bottom: 0"
    />
  </div>
</template>

<script setup>
import { computed, inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue"

import PageSource from "./PageSource.vue"
import MessageItem from "./MessageItem.vue"

import notifyResponseError from "@/utils/notifyResponseError.js"
import { getChatMessages } from "@/modules/Messenger/messengerService.js"

const props = defineProps({
  currentChat: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["openGallery"])

const handleOpenGallery = inject("handleOpenGallery", () => {})

const messagesListRef = ref()

const scrollHeightBeforeGetPagination = ref(null)
const olderMessagesUnsubscribe = ref(null)
const messages = ref([])
const bottomMessagesLoader = ref(false)
const topMessagesLoader = ref(false)
const totalMessages = ref()
const lastMessage = computed(() => (messages.value.length ? messages.value.at(-1) : null))

const resetData = () => {
  scrollHeightBeforeGetPagination.value = null
  olderMessagesUnsubscribe.value = null
  messages.value = []
  totalMessages.value = undefined
}

const scrollToBottom = () => {
  nextTick(() => {
    const element = messagesListRef.value

    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight
    }
  })
}

const setPreviousScrollAfterGetOldMessages = () => {
  const scrollPosition = messagesListRef.value.scrollHeight - scrollHeightBeforeGetPagination.value

  messagesListRef.value.scrollTop = scrollPosition
  scrollHeightBeforeGetPagination.value = scrollPosition
}
const scrollHandler = () => {
  const { scrollTop, clientHeight, scrollHeight } = messagesListRef.value

  const isScrolledToTop = scrollTop === 0
  const hasScroll = scrollHeight !== clientHeight

  if (isScrolledToTop && hasScroll) {
    getMessages()
  }
}

const getMessages = async () => {
  if (totalMessages.value && messages.value.length >= totalMessages.value) return

  try {
    if (messages.value.length) {
      topMessagesLoader.value = true
    } else {
      bottomMessagesLoader.value = true
    }

    const { data } = await getChatMessages({
      chatUuid: props.currentChat.uuid,
      limit: 15,
      offset: messages.value.length
    })

    totalMessages.value = data.count

    setMessages({
      chat_uuid: props.currentChat.uuid,
      results: data.results,
      offset: messages.value.length
    })
  } catch (error) {
    notifyResponseError({ error })
    resetData()
  } finally {
    topMessagesLoader.value = false
    bottomMessagesLoader.value = false
  }
}

const setMessages = ({ results }) => {
  if (!results?.length) return

  scrollHeightBeforeGetPagination.value =
    messagesListRef.value.scrollHeight - messagesListRef.value.scrollTop

  if (messages.value.length) {
    messages.value.unshift(...results.reverse())
  } else {
    messages.value = results.reverse()
  }

  if (messages.value.length === results.length) {
    nextTick(scrollToBottom)
  } else {
    nextTick(setPreviousScrollAfterGetOldMessages)
  }
}

const onOpenGallery = (payload) => {
  handleOpenGallery({
    images: payload.media.filter(({ type }) => type === "image").map((media) => media.file),
    startIndex: payload.index
  })
}

const handleShowMessageHistory = (message) => {
  emit("showEditHistory", {
    message
  })
}

watch(
  () => props.currentChat?.uuid,
  () => {
    resetData()
    if (!props.currentChat?.uuid) return
    getMessages()
  }
)

onMounted(() => {
  if (messages.value?.length > 0) {
    scrollToBottom()
  }

  messagesListRef.value.addEventListener("scroll", scrollHandler)

  if (props.currentChat?.uuid) {
    resetData()
    getMessages()
  }
})

onBeforeUnmount(() => {
  messagesListRef.value.removeEventListener("scroll", scrollHandler)
})
</script>

<style lang="scss" scoped>
.messages-display {
  padding: 16px;
  overflow-y: auto;
  background: #fafafa;

  display: flex;
  flex-direction: column;
  gap: 16px;

  position: relative;

  &__item:first-child {
    margin-top: auto;
  }
}
</style>
