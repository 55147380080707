export default () => {
  const timeouts = new Map()
  const intervals = new Map()

  const debounce = (key, fn, delay = 300, ...args) => {
    if (timeouts.has(key)) {
      clearTimeout(timeouts.get(key))
    }
    const timeout = setTimeout(() => {
      fn(...args)
      timeouts.delete(key)
    }, delay)
    timeouts.set(key, timeout)
  }

  const debounceInterval = (key, fn, delay = 1000, ...args) => {
    if (intervals.has(key)) {
      clearInterval(intervals.get(key))
    }
    const interval = setInterval(() => {
      fn(...args)
    }, delay)
    intervals.set(key, interval)
  }

  const resetDebounce = (key) => {
    if (!timeouts.has(key)) return

    clearTimeout(timeouts.get(key))
    timeouts.delete(key)
  }

  const resetAllDebounces = () => {
    timeouts.forEach((interval) => {
      clearTimeout(interval)
    })
    timeouts.clear()
  }

  const resetInterval = (key) => {
    if (!intervals.has(key)) return

    clearInterval(intervals.get(key))
    intervals.delete(key)
  }

  const resetAllIntervals = () => {
    timeouts.forEach((interval) => {
      clearTimeout(interval)
    })
    timeouts.clear()
  }

  return {
    debounce,
    debounceInterval,
    resetDebounce,
    resetInterval,
    resetAllDebounces,
    resetAllIntervals
  }
}
