<template>
  <div class="create-user">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        prop="username"
        :label="$t('userName')"
      >
        <a-input v-model="form.username" />
      </a-form-model-item>

      <a-form-model-item
        prop="first_name"
        :label="$t('firstName')"
      >
        <a-input v-model="form.first_name" />
      </a-form-model-item>

      <a-form-model-item
        prop="last_name"
        :label="$t('lastName')"
      >
        <a-input v-model="form.last_name" />
      </a-form-model-item>

      <a-form-model-item
        prop="email"
        label="Email"
        required
      >
        <a-input v-model="form.email" />
      </a-form-model-item>

      <a-form-model-item
        prop="password"
        :label="$t('password')"
      >
        <a-input
          v-model="form.password"
          type="password"
        />
      </a-form-model-item>

      <a-form-model-item
        prop="passwordConfirm"
        :label="$t('passwordConfirm')"
      >
        <a-input
          v-model="form.passwordConfirm"
          type="password"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        :loading="isFetching"
        type="primary"
        @click="onSubmit"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n.js"

import { createUser } from "@/modules/UsersManagement/services/usersService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useForm from "@/composables/useForm.js"
import { requiredRule, emailRule } from "@/utils/validations.js"

const emit = defineEmits(["onCreate"])

const formRef = ref()
const form = reactive({
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  passwordConfirm: ""
})

const validatePass = (_, value, callback) => {
  if (!value?.trim()) {
    callback(new Error(i18n.t("emptyError")))
  } else if (value !== form.password) {
    callback(new Error(i18n.t("passwordsNotMatchError")))
  } else {
    callback()
  }
}
const formRules = computed(() => ({
  username: [requiredRule.value],
  email: [emailRule.value],
  password: [requiredRule.value, { min: 4, message: i18n.t("minLengthError", { length: 4 }) }],
  passwordConfirm: [{ validator: validatePass, trigger: "blur" }]
}))
const { isValidForm, validateForm } = useForm(formRef)

const isFetching = shallowRef(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true
    const { passwordConfirm, ...payload } = form

    await createUser(payload)

    notification.success({ message: i18n.t("userCreatedSuccessfully") })
    emit("onCreate")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.create-user {
  padding-bottom: 50px;
}
</style>
