<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    layout="horizontal"
    style="padding-bottom: 70px"
  >
    <a-divider>{{ $t("editRatingFactor") }}</a-divider>

    <div class="factor-rating-form">
      <a-form-model-item>
        <div>{{ $t("currentValues") }}:</div>
        <div>{{ $t("newValues") }}:</div>
      </a-form-model-item>

      <a-form-model-item
        label="SHORT"
        prop="rf_s"
      >
        <div>
          {{ getFixedFloat(currentRow.rf_s) }}
        </div>
        <a-input-number
          v-model="form.rf_s"
          :min="0"
          :max="99999"
        />
      </a-form-model-item>

      <a-form-model-item
        label="MIDDLE"
        prop="rf_m"
      >
        <div>
          {{ getFixedFloat(currentRow.rf_m) }}
        </div>
        <a-input-number
          v-model="form.rf_m"
          :min="0"
          :max="99999"
        />
      </a-form-model-item>

      <a-form-model-item
        label="LONG"
        prop="rf_l"
      >
        <div>
          {{ getFixedFloat(currentRow.rf_l) }}
        </div>
        <a-input-number
          v-model="form.rf_l"
          :min="0"
          :max="99999"
        />
      </a-form-model-item>

      <a-form-model-item :label="$t('total')">
        <div>
          {{ getFixedFloat(currentRow.rf) }}
        </div>
        <a-input
          disabled
          :value="newRating"
        />
      </a-form-model-item>
    </div>

    <a-divider>{{ $t("editRating") }}</a-divider>

    <a-form-model-item :label="$t('rating')">
      <a-input-number
        v-model="form.rating"
        :min="0"
        :max="5"
        :precision="0"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="closeModal"
      >
        {{ $t("save") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSaveRating"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import useForm from "@/composables/useForm"
import i18n from "@/i18n"
import { updateProductsBulk } from "@/modules/MPAdmin/services/productsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { requiredRule } from "@/utils/validations"
import { notification } from "ant-design-vue"
import { computed, reactive, ref, shallowRef } from "vue"

const props = defineProps({
  currentRow: {
    type: Object,
    default: () => ({})
  },

  showModal: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["close"])

const formRef = ref()
const form = reactive({
  rf_s: props.currentRow.rf_s || 0,
  rf_m: props.currentRow.rf_m || 0,
  rf_l: props.currentRow.rf_l || 0,
  rating: props.currentRow.rating || 0
})
const formRules = computed(() => ({
  rf_s: [requiredRule.value],
  rf_m: [requiredRule.value],
  rf_l: [requiredRule.value]
}))
const { isValidForm, validateForm } = useForm(formRef)

const newRating = computed(() => Number(form.rf_s) + Number(form.rf_m) + Number(form.rf_l) || "")

const getFixedFloat = (val) => (val ? Number(val).toFixed(2) : "")

const isFetching = shallowRef(false)
const onSaveRating = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    const { data } = await updateProductsBulk([{ id: props.currentRow.id, ...form }])

    if (!data?.meta?.errors?.length) {
      notification.success({
        message: i18n.t("updated")
      })
      closeModal()
    } else {
      notifyResponseError({ error: { response: { data: data.meta.errors } } })
    }
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const closeModal = () => emit("close")
</script>

<style lang="scss" scoped>
.factor-rating-form {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;

  & > :first-child {
    width: 200px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    color: $font-default;
  }
}
</style>
