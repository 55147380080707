<template>
  <a-drawer
    width="730px"
    :title="`${$t('editCampaign')} ${sellerName}`"
    placement="right"
    :closable="true"
    :visible="isVisible"
    @close="handleClose"
  >
    <div class="edit-campaign-modal">
      <a-form-model
        ref="formRef"
        :model="form"
        :rules="formRules"
      >
        <a-form-model-item
          :label="$t('editCampaignCL')"
          prop="label"
        >
          <a-input v-model="form.label" />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('campaignStatus')"
          prop="status"
        >
          <a-select v-model="form.status">
            <a-select-option
              v-for="item in campaignStatuses"
              :key="item.value"
              :value="item.value"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('balance')"
          prop="balance"
        >
          <a-input
            v-model="form.balance"
            :suffix="$t('uah').toUpperCase()"
          />
        </a-form-model-item>
      </a-form-model>

      <h3>{{ $t("debitHistory") }}</h3>

      <a-table
        :columns="columns"
        :dataSource="dataSource"
        :pagination="paginationInfo"
        :loading="isFetching"
        rowKey="day"
        @change="onTableChange"
      />
    </div>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="handleClose"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isSubmiting"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { onMounted, ref, watch, computed, reactive } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import { editCampaign, getCampaignExpences } from "@/modules/ADS/services/CPCServices.js"
import notifyResponseError from "@/utils/notifyResponseError"

import useCPCColumns from "../useCPCColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"
import useAdsComposables from "@/modules/ADS/useAdsComposables.js"
import { requiredRule } from "@/utils/validations.js"
import useForm from "@/composables/useForm.js"

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  campaign: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(["onSuccess", "close"])

const { campaignStatuses } = useAdsComposables()
const { expensesColumns: columns } = useCPCColumns()
const {
  dataSource,
  isFetching,
  paginationInfo,

  setupTable,
  fetchTableInfo
} = useAntTableQuery({
  queryFunction: getCampaignExpences,
  requestParams: { uuid: computed(() => props.campaign?.uuid) }
})

const formRef = ref()
const form = reactive({
  label: "",
  balance: 0,
  status: undefined
})
const formRules = computed(() => ({
  label: [requiredRule.value],
  status: [requiredRule.value],
  balance: [requiredRule.value]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const sellerName = computed(() => props.campaign?.seller?.name)
const isSubmiting = ref(false)

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination, filters: {}, sorter: {} })
}

const handleClose = () => {
  emit("close")
  resetFields()
}

const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isSubmiting.value = true

    const { data } = await editCampaign(props.campaign.uuid, form)

    notification.success({ message: i18n.t("updated") })
    emit("onSuccess", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmiting.value = false
  }
}

const setupForm = () => {
  if (!props.campaign.uuid) return
  const { label, balance, status } = props.campaign

  form.label = label
  form.balance = balance
  form.status = status

  onTableChange()
}

watch(() => props.campaign?.uuid, setupForm)

onMounted(() => {
  setupTable()
  setupForm()
})
</script>

<style lang="scss" scoped>
.edit-campaign-modal {
  padding-bottom: 50px;
  position: relative;
}
</style>
