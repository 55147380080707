<template>
  <a-form-model
    ref="formRef"
    :model="form"
    class="seo-form"
    :colon="false"
  >
    <!-- SEO TITLE START -->
    <a-form-model-item
      v-for="lang in languages"
      :key="`seo_h1_${lang}`"
      :prop="`seo_h1.${lang}`"
    >
      <template #label>
        {{ tagFieldsOptions.seo_h1.title }}, {{ lang.toUpperCase() }} (<span
          :class="
            checkIsLengthCorrect(
              form.seo_h1[lang].length,
              tagFieldsOptions.seo_h1.min,
              tagFieldsOptions.seo_h1.max
            )
          "
          >{{ form.seo_h1[lang].length }}</span
        >/{{ tagFieldsOptions.seo_h1.max }})
      </template>
      <a-input v-model="form.seo_h1[lang]" />
    </a-form-model-item>
    <!-- SEO H1 END -->

    <!-- SEO TITLE START -->
    <a-form-model-item
      v-for="lang in languages"
      :key="`seo_title_${lang}`"
      :prop="`seo_title.${lang}`"
    >
      <template #label>
        {{ tagFieldsOptions.seo_title.title }}, {{ lang.toUpperCase() }} (<span
          :class="
            checkIsLengthCorrect(
              form.seo_title[lang].length,
              tagFieldsOptions.seo_title.min,
              tagFieldsOptions.seo_title.max
            )
          "
          >{{ form.seo_title[lang].length }}</span
        >/{{ tagFieldsOptions.seo_title.min }}-{{ tagFieldsOptions.seo_title.max }})
      </template>
      <a-input v-model="form.seo_title[lang]" />
    </a-form-model-item>
    <!-- SEO TITLE END -->

    <!-- SEO DESCRIPTION START -->
    <a-form-model-item
      v-for="lang in languages"
      :key="`seo_description_${lang}`"
      :prop="`seo_description.${lang}`"
    >
      <template #label>
        {{ tagFieldsOptions.seo_description.title }}, {{ lang.toUpperCase() }} (<span
          :class="
            checkIsLengthCorrect(
              form.seo_description[lang].length,
              tagFieldsOptions.seo_description.min,
              tagFieldsOptions.seo_description.max
            )
          "
          >{{ form.seo_description[lang].length }}</span
        >/{{ tagFieldsOptions.seo_description.min }}-{{ tagFieldsOptions.seo_description.max }})
      </template>
      <a-textarea
        v-model="form.seo_description[lang]"
        :autoSize="{ minRows: 4, maxRows: 6 }"
      />
    </a-form-model-item>
    <!-- SEO DESCRIPTION END -->

    <!-- SEO TEXT START -->
    <a-collapse
      :bordered="false"
      style="background: transparent"
    >
      <a-collapse-panel
        v-for="lang in languages"
        :key="lang"
        style="border: none"
        forceRender
      >
        <template #header>SEO-text, {{ lang.toUpperCase() }}</template>

        <a-form-model-item>
          <EditorHtml
            v-model="form.seo_text[lang]"
            :id="`seo_text_${lang}`"
          />
        </a-form-model-item>
      </a-collapse-panel>
    </a-collapse>
  </a-form-model>
  <!-- SEO TEXT END -->
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue"
import EditorHtml from "@/components/Editor/EditorHtml.vue"

const props = defineProps({
  tag: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["setEdited"])

const formRef = ref()
const form = reactive({
  seo_h1: {
    uk: "",
    en: "",
    ru: "",
    ...props.tag.seo_h1
  },
  seo_title: {
    uk: "",
    en: "",
    ru: "",
    ...props.tag.seo_title
  },
  seo_description: {
    uk: "",
    en: "",
    ru: "",
    ...props.tag.seo_description
  },
  seo_text: {
    uk: "",
    en: "",
    ru: "",
    ...props.tag.seo_text
  }
})

const languages = ["uk", "ru", "en"]

const tagFieldsOptions = ref({
  seo_h1: { title: "H1", min: 0, max: 100 },
  seo_title: { title: "Title", min: 30, max: 65 },
  seo_description: { title: "Descriptions", min: 120, max: 320 }
})

const checkIsLengthCorrect = (length, min, max) => {
  return length > min && length < max ? "primary-text" : "error-text"
}

const getSeoFormValues = () => form
const formWatcher = ref()
const setWatcher = () => {
  formWatcher.value?.()
  formWatcher.value = watch(
    () => form,
    () => emit("setEdited"),
    { deep: true }
  )
}

onMounted(setWatcher)

defineExpose({
  setWatcher,
  getSeoFormValues
})
</script>

<style lang="scss" scoped>
.seo-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 30px;

  & > * {
    flex-basis: calc(100% / 3 - 20px);
  }

  & > :last-child {
    flex-basis: 100%;
  }
}
.primary-text {
  color: $primary-color;
}
.error-text {
  color: $red-color;
}
</style>
