<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="general-form"
  >
    <a-form-model-item
      :label="$t('workName')"
      prop="name"
    >
      <a-input v-model:value="form.name" />
    </a-form-model-item>

    <div>
      <a-form-model-item
        :label="`${$t('publicName')} (UA)`"
        prop="public_name.uk"
      >
        <a-input v-model="form.public_name.uk" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('publicName')} (RU)`"
        prop="public_name.ru"
      >
        <a-input v-model="form.public_name.ru" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('publicName')} (EN)`"
        prop="public_name.en"
      >
        <a-input v-model="form.public_name.en" />
      </a-form-model-item>
    </div>

    <div>
      <a-form-model-item
        :label="$t('valueType')"
        prop="value_type"
      >
        <a-select v-model="form.value_type">
          <a-select-option
            v-for="item in attributeTypeOptions"
            :value="item.value"
            :key="item.value"
          >
            <span style="text-transform: capitalize">
              {{ item.label.toLowerCase() }}
            </span>
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        v-show="form.value_type === 'NUMERIC'"
        :label="$t('unit')"
        prop="default_unit"
      >
        <InfiniteScrollSelect
          :value="form.default_unit"
          :customRequest="fetchUnits"
          labelKey="name"
          valueKey="oid"
          :defaultOptions="unitOptions"
          @change="onUnitChange"
        />
      </a-form-model-item>
    </div>

    <a-form-model-item style="padding-top: 40px">
      <a-switch v-model="form.allow_multiple" />
      <span style="margin-left: 16px">
        {{ $t("allowMultipleValues") }}
      </span>
    </a-form-model-item>

    <a-form-model-item :label="$t('status')">
      <a-select
        v-model="form.trust"
        :disabled="disableChangeTrustStatus"
      >
        <a-select-option
          v-for="option in trustOptions"
          :key="option.key"
          :value="option.key"
        >
          <span :style="{ color: option.color }">
            {{ option.label }}
          </span>
        </a-select-option>
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>

<script setup>
import { computed, nextTick, reactive, ref, shallowRef, watch } from "vue"
import { notification } from "ant-design-vue"
import router from "@/router/index.js"
import i18n from "@/i18n.js"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import useProductAttributeComposables from "../../composables/useProductAttributeComposables.js"
import useForm from "@/composables/useForm.js"

import {
  fetchLocalizedAttribute,
  updateLocalizedAttribute
} from "../../../services/attributesService.js"
import { fetchUnits } from "../../../services/unitsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useModerationOptions from "@/modules/Moderation/composables/useModerationOptions.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  attribute: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["changeFetchingState", "formStateChanged", "submit"])

const { attributeTypeOptions } = useProductAttributeComposables()
const { trustOptions } = useModerationOptions()

const formRef = ref()
const form = reactive({
  name: "",
  public_name: {
    ru: "",
    uk: "",
    en: ""
  },
  value_type: "STRING",
  default_unit: undefined,
  allow_multiple: false,
  trust: "UNTRUSTED"
})
const isFormChanged = shallowRef(false)

const formRules = computed(() => ({
  name: [requiredRule.value, { max: 500, message: i18n.t("maxLengthError", { length: 500 }) }],
  public_name: {
    uk: [requiredRule.value, { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }],
    ru: [requiredRule.value, { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }],
    en: [requiredRule.value, { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }]
  },
  value_type: requiredRule.value
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const disableChangeTrustStatus = shallowRef(false)

const setFormValues = (data) => {
  form.name = data.name || ""
  form.public_name = { ...form.public_name, ...data.public_name }
  form.value_type = data.value_type || "STRING"
  form.allow_multiple = data.allow_multiple || false
  form.trust = data.trust || "UNTRUSTED"

  if (data?.default_unit) {
    form.default_unit = data.default_unit.oid || null
    unitOptions.value = [data.default_unit]
  }

  disableChangeTrustStatus.value = data.trust === "TRUSTED"
  nextTick(() => {
    isFormChanged.value = false
  })
}

const getFormValues = () => {
  return form
}

const onResetForm = () => {
  resetFields()
  setFormValues(props.attribute)
}

const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    emit("changeFetchingState", true)

    if (form.value_type !== "NUMERIC") {
      form.default_unit = undefined
    }

    await updateLocalizedAttribute({
      code: router.currentRoute.params.attributeCode,
      payload: { ...form, default_unit: form.default_unit || null }
    })

    notification.success({ message: i18n.t("updated") })
    const data = await fetchLocalizedAttribute({
      code: router.currentRoute.params.attributeCode
    })
    emit("submit", data)
    isFormChanged.value = false
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    emit("changeFetchingState", false)
  }
}

// Unit
const unitOptions = ref([])

const onUnitChange = (value) => {
  form.default_unit = value
}

watch(
  form,
  () => {
    if (isFormChanged.value) return
    isFormChanged.value = true
  },
  {
    deep: true
  }
)

watch(
  () => isFormChanged.value,
  () => {
    emit("formStateChanged", isFormChanged.value)
  }
)

defineExpose({
  getFormValues,
  setFormValues,
  onResetForm,
  onSubmit
})
</script>

<style lang="scss" scoped>
.general-form {
  width: 100%;
  display: flex;
  gap: 30px;

  & > * {
    flex: 450px;
  }

  & > :nth-child(3) {
    flex: 230px;
  }
  & > :nth-child(4) {
    flex: 280px;
  }
}
</style>
