<template>
  <PageSourceMessage
    :message="props.message"
    useAsWrapper
  >
    <template #image>
      <div class="msg-ps-message__item__image">
        <img :src="getImgUrl" />
      </div>
    </template>

    <template #productLink>
      <a
        v-if="hasCorrectOfferCode"
        :href="offerLink"
        target="_blank"
      >
        {{ parsedBody?.code }}
      </a>
      <a
        v-else
        :href="productLink"
        target="_blank"
      >
        {{ parsedBody?.p_code }}
      </a>
    </template>
  </PageSourceMessage>
</template>

<script setup>
import { PageSourceMessage } from "@synthetic_ua/messenger-front/legacy"

import { IMAGES_CDN, MARKETPLACE } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"
import { computed } from "vue"

const props = defineProps({
  message: {
    type: Object,
    required: true
  }
})

const parsedBody = JSON.parse(props.message.body)

const getImgUrl = computed(() => {
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(parsedBody.image, "100x100")}`
})

const productLink = computed(() => {
  return `${MARKETPLACE}/product/${parsedBody.slug}/${parsedBody.p_code}`
})
const offerLink = computed(() => {
  return `${MARKETPLACE}/product/${parsedBody.slug}/${parsedBody.p_code}/${parsedBody.code}`
})
const hasCorrectOfferCode = computed(() => {
  return parsedBody.code && parsedBody.code !== parsedBody.p_code
})
</script>
