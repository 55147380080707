import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchBillingTariffs = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/billing/tariffs${getFormatedQueryParams(queryParams)}`, {
    signal
  })
}

export const addTariffConfigs = (id, data) => {
  return API_V2.post(`${MPA_PREFIX}/billing/tariffs/${id}/configs`, data)
}

export const updateTariffConfig = async (id, idConfig, data) => {
  return API_V2.put(`${MPA_PREFIX}/billing/tariffs/${id}/configs/${idConfig}`, data)
}

export const createTariff = (data) => {
  return API_V2.post(`${MPA_PREFIX}/billing/tariffs`, data)
}

export const updateTariff = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/billing/tariffs/${id}`, data)
}

export const setNewOrdering = (list, offset) => {
  const sendList = list.map((item, index) => {
    return {
      id: item.id,
      ordering: index + offset
    }
  })

  return API_V2.put(`${MPA_PREFIX}/billing/tariffs/bulk`, sendList)
}
