<template>
  <a-drawer
    :visible="isOpened"
    width="85%"
    @close="handleCancel"
    :body-style="{ paddingBottom: '80px' }"
  >
    <template #title>
      {{ $t("viewOrder") }}
      <template v-if="orderData !== null">
        {{ orderData?.code }}
        <span :style="{ fontWeight: 'normal', marginLeft: '20px' }">
          {{ getFormatedDateTime(orderData.created_at) }}
        </span>
        <span :style="{ fontWeight: 'normal', marginLeft: '20px' }">
          {{ orderData.seller.name }}
        </span>
      </template>
    </template>

    <a-spin :spinning="isFetching">
      <OrderItems :items="orderData?.items_in_order || []" />

      <a-row
        :style="{ marginTop: '10px' }"
        :gutter="24"
      >
        <a-col :span="10">
          <a-divider>
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <a-icon
                type="history"
                :style="{ marginRight: '10px' }"
              />
              {{ $t("changeHistory") }}
            </div>
          </a-divider>

          <OrderHistory :items="orderHistory || []" />
        </a-col>
        <a-col :span="10">
          <a-divider>
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <a-icon
                type="shopping-cart"
                :style="{ marginRight: '10px' }"
              />
              {{ $t("infoAboutOrder") }}
            </div>
          </a-divider>

          <OrderInformation
            v-if="orderData !== null"
            :orderData="orderData"
            ref="orderInformationRef"
          />
        </a-col>
        <a-col
          v-if="orderData !== null"
          :span="4"
        >
          <OrderStatuses
            ref="orderStatusesRef"
            :reject-reasons-buyer="ordersMeta.reject_reasons_buyer"
            :reject-reasons-moderator="ordersMeta.reject_reasons_moderator"
            :reject-reasons-seller="ordersMeta.reject_reasons_seller"
            :order-status="orderData.status"
            :order-payment-status="orderData.payment_status || ''"
            :order-code="orderData.code"
            :change-payment-status-processing="changePaymentStatusProcessing"
            @on-cancel-payment="handleCancelPayment"
          />
        </a-col>
      </a-row>

      <a-collapse
        :style="{ marginTop: '24px' }"
        :bordered="false"
      >
        <a-collapse-panel
          v-if="orderNotes && orderNotes.length > 0"
          key="1"
          :header="$t('sellerNotes')"
        >
          <OrderNotes
            :items="orderNotes"
            :order-status="orderData.status"
            :reject-reason="orderData.reject_reason?.reason.uk"
          />
        </a-collapse-panel>
        <a-collapse-panel
          v-if="orderReturns && orderReturns.length > 0"
          key="2"
          :header="$t('ordersReturns')"
        >
          <OrderReturns :items="orderReturns" />
        </a-collapse-panel>
        <a-collapse-panel
          v-if="orderTransactions && orderTransactions.length > 0"
          key="3"
          :header="$t('bonusTransactions')"
        >
          <OrderTransactions :items="orderTransactions" />
        </a-collapse-panel>
      </a-collapse>
    </a-spin>

    <div class="drawer-footer">
      <a-button
        key="back"
        @click="handleCancel"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-popconfirm
        :visible="showTtnWarning"
        :title="$t('invalidTtnWarnign')"
        placement="topRight"
        @cancel="showTtnWarning = false"
        @confirm="handleUpdateOrder(true)"
      >
        <a-button
          key="submit"
          type="primary"
          :loading="updateProcessing || showTtnWarning"
          @click="handleUpdateOrder()"
        >
          {{ $t("save") }}
        </a-button>
      </a-popconfirm>
    </div>
  </a-drawer>
</template>

<script setup>
// This component used in ADS/CPA
import { nextTick, ref, shallowRef, watch } from "vue"
import { message, notification } from "ant-design-vue"
import i18n from "@/i18n"

import OrderItems from "./components/OrderItems.vue"
import OrderHistory from "./components/OrderHistory.vue"
import OrderNotes from "./components/OrderNotes.vue"
import OrderTransactions from "./components/OrderTransactions.vue"
import OrderReturns from "./components/OrderReturns.vue"
import OrderInformation from "./components/OrderInformation.vue"
import OrderStatuses from "./components/OrderStatuses.vue"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import notifyResponseError from "@/utils/notifyResponseError"
import {
  fetchOrderById,
  fetchOrderHistory,
  fetchOrderNotes,
  fetchOrderReturns,
  fetchOrderTransactions,
  updateOrder,
  cancelPayment,
  fetchOrdersMeta
} from "@/modules/MPAdmin/services/ordersService.js"

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true
  },
  orderCode: {
    type: String,
    default: null
  }
})
const emit = defineEmits(["close", "onUpdate"])

const ordersMeta = ref({
  status_choices_allowed: [],
  status_choices: [],
  moderation_status_choices: [],
  reject_reasons: [],
  reject_reasons_seller: [],
  reject_reasons_moderator: [],
  reject_reasons_buyer: []
})
const getMeta = async () => {
  if (ordersMeta.reject_reasons?.length) return

  try {
    const { data } = await fetchOrdersMeta()

    Object.keys(ordersMeta.value).forEach((key) => {
      ordersMeta.value[key] = data[key] || []
    })
  } catch (error) {
    notifyResponseError({ error })
  }
}

const orderStatusesRef = ref()
const orderInformationRef = ref()

const isFetching = shallowRef(false)
const updateProcessing = shallowRef(false)
const changePaymentStatusProcessing = shallowRef(false)
const orderData = ref(null)
const orderHistory = ref(null)
const orderNotes = ref(null)
const orderReturns = ref(null)
const orderTransactions = ref(null)
const showTtnWarning = shallowRef(false)

const getOrderInfo = async () => {
  isFetching.value = true

  try {
    const [
      orderResults,
      orderHistoryResults,
      orderNotesResults,
      orderReturnsResults,
      orderTransactionsResults
    ] = await Promise.all([
      fetchOrderById(props.orderCode),
      fetchOrderHistory(props.orderCode),
      fetchOrderNotes(props.orderCode),
      fetchOrderReturns(props.orderCode),
      fetchOrderTransactions(props.orderCode)
    ])
    orderData.value = orderResults.data
    orderHistory.value = orderHistoryResults.data.results
    orderNotes.value = orderNotesResults.data.results
    orderReturns.value = orderReturnsResults.data.results
    orderTransactions.value = orderTransactionsResults.data.results
    // Set form values
    nextTick(() => {
      orderStatusesRef.value.setFormFields({
        status: orderResults.data.status,
        moderation_status: orderResults.data.moderation_status
      })
    })
    if (orderResults.data.reject_reason !== null) {
      // Wait render because this input has condition for render
      setTimeout(() => {
        orderStatusesRef.value.setFormFields({
          reject_reason: orderResults.data.reject_reason.id
        })
      }, 1)
    }
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleUpdateOrder = async (forseTTN = false) => {
  showTtnWarning.value = false

  const isEditingTtn = orderInformationRef.value.isEditingTtn

  if (isEditingTtn) {
    return message.warning(i18n.t("confirmTtn"))
  }

  const fields = orderStatusesRef.value.getFormFields()
  const ttn = orderInformationRef.value.newTtn

  updateProcessing.value = true

  let payload = {
    status: fields.status
  }

  const ALLOWED_STATUSES = ["CANCELED_BY_SELLER", "CANCELED_BY_CUSTOMER", "CANCELED_BY_SUPERVISOR"]

  if (~ALLOWED_STATUSES.indexOf(fields.status)) {
    payload = {
      ...payload,
      reject_reason: fields.reject_reason
    }
  }

  if (fields.moderation_status) {
    payload = {
      ...payload,
      moderation_status: fields.moderation_status
    }
  }

  if (forseTTN) {
    payload.ttn = ttn
    payload.ignore_ttn_format_validation = true
  } else if (ttn !== orderData.value.ttn) {
    payload.ttn = ttn
  }

  try {
    const { data } = await updateOrder({ code: props.orderCode, payload })

    emit("onUpdate", data)
    emit("close")

    notification.success({ message: i18n.t("updated") })
  } catch (error) {
    if (error?.response?.data?.code === "EB00002") {
      showTtnWarning.value = true
      return
    }
    notifyResponseError({ error, message: i18n.t("notUpdated") })
  } finally {
    updateProcessing.value = false
  }
}

const handleCancelPayment = async () => {
  try {
    changePaymentStatusProcessing.value = true

    const { data } = await cancelPayment(props.orderCode)

    orderData.value.payment_status = data.payment_status
    emit("onUpdate", orderData.value)
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("notUpdated") })
  } finally {
    changePaymentStatusProcessing.value = false
  }
}

const handleCancel = () => {
  showTtnWarning.value = false
  emit("close")
}

watch(
  () => props.isOpened,
  (newValue) => {
    if (newValue) {
      getOrderInfo()

      // Fetch orderMeta if reject reasons empty
      // Need for ADS/CPA
      if (ordersMeta.value.reject_reasons.length === 0) {
        getMeta()
      }
    } else {
      isFetching.value = false
      orderData.value = null
      orderHistory.value = null
      orderNotes.value = null
      orderReturns.value = null
      orderTransactions.value = null

      orderStatusesRef.value.resetFields()
    }
  }
)
</script>
