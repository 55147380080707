import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchPromotionsActionsData = () => {
  return API_V2.get(`${MPA_PREFIX}/main-promotions/actions-data`)
}

export const fetchPromotions = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/main-promotions${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSinglePromotion = (id) => {
  return API_V2.get(`${MPA_PREFIX}/main-promotions/${id}`)
}

export const createPromotion = (data) => {
  return API_V2.post(`${MPA_PREFIX}/main-promotions`, data)
}

export const updatePromotion = (data, id) => {
  return API_V2.patch(`${MPA_PREFIX}/main-promotions/${id}`, data)
}

export const updatePromotionImage = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/main-promotions/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
