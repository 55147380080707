<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="edit-cpa-form"
  >
    <div>
      {{ $t("dateOfferUpdate") }}:<b style="padding-left: 8px">{{
        getFormatedDateTime(order.updated_at)
      }}</b>
    </div>

    <div v-if="order.cancel_reason">
      {{ $t("reasonDeclineOffer") }}:<b style="padding-left: 8px">{{ order.cancel_reason }}</b>
    </div>

    <a-form-model-item
      :label="$t('changeCommissionStatus')"
      style="width: 180px; margin: 18px 0 0 0"
      prop="status"
    >
      <a-select
        v-model="form.status"
        :options="comissionStatuses"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('reasonCommissionDecline')"
      prop="cancel_reason"
    >
      <a-textarea
        v-model="form.cancel_reason"
        allow-clear
      />
      <div class="input-counter">
        <span :class="{ 'input-counter--red': form.cancel_reason?.length > 100 }">
          {{ form.cancel_reason?.length || 0 }}
        </span>
        / 100
      </div>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('commentFromManager')"
      prop="comment"
    >
      <a-textarea
        v-model="form.comment"
        allow-clear
        :autoSize="{ minRows: 6, maxRows: 6 }"
      />
      <div class="input-counter">
        <span :class="{ 'input-counter--red': form.comment?.length > 500 }">
          {{ form.comment?.length || 0 }}
        </span>
        / 500
      </div>
    </a-form-model-item>

    <div style="display: flex; align-items: flex-end; gap: 12px">
      <a-form-model-item
        :label="$t('commission')"
        prop="kind"
      >
        <a-select
          style="width: 180px"
          v-model="form.kind"
          option-filter-prop="children"
        >
          <a-select-option value="DISABLED">
            {{ $t("cpaStatus.nocpa") }}
          </a-select-option>
          <a-select-option value="REGULAR"> CPA </a-select-option>
          <a-select-option value="CATEGORICAL"> CPA-2 </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        v-show="form.kind === 'REGULAR'"
        label="% CPA"
        prop="percent"
      >
        <a-input-number
          v-model="form.percent"
          :min="0"
        />
      </a-form-model-item>
    </div>

    <div class="drawer-footer">
      <a-button @click="handleClose">
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        @click="handleConfirm"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import useForm from "@/composables/useForm"
import i18n from "@/i18n"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import { reactive, ref, computed } from "vue"

const props = defineProps({
  order: {
    type: Object,
    default: () => ({})
  },
  comissionStatuses: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["submit", "close"])

const formRef = ref()
const form = reactive({
  status: props.order.cpa.status || null,
  cancel_reason: props.order.cpa.cancel_reason || "",
  comment: props.order.cpa.comment || "",
  kind: props.order.cpa.kind || null,
  percent: props.order.cpa.percent || null
})
const formRules = computed(() => ({
  cancel_reason: [{ max: 100, message: i18n.t("maxLengthError", { length: 100 }) }],
  comment: [{ max: 500, message: i18n.t("maxLengthError", { length: 500 }) }]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const handleConfirm = async () => {
  await validateForm()
  if (!isValidForm.value) return

  const { kind, percent, ...payload } = form

  if (kind) {
    payload.kind = kind

    if (percent && kind === "REGULAR") {
      payload.percent = percent
    } else {
      payload.percent = 0
    }
  }

  emit("submit", payload)
}

const handleClose = () => {
  emit("close")
  resetFields()
}
</script>

<style lang="scss" scoped>
.edit-cpa-form {
  padding-bottom: 50px;
  color: $font-default;
}

.input-counter {
  position: absolute;
  right: 0;
  top: 18px;
  color: $grey-font;

  &--red {
    color: $red-color;
  }
}
</style>
