import { API_V2 } from "@/utils/HttpUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { ADS_PREFIX } from "@/constants/apiPrefix.js"

export const fetchCPAOrders = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/orders/${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const editOrderCpa = ({ uuid, data }) => {
  return API_V2.patch(`${ADS_PREFIX}/orders/${uuid}/cpa/`, data)
}

export const editOrderRef = ({ uuid, data }) => {
  return API_V2.patch(`${ADS_PREFIX}/orders/${uuid}/ref/`, data)
}

export const searchSellerByName = ({ queryParams, signal }) => {
  return API_V2.get(`${ADS_PREFIX}/sellers/seller_search/?term=${queryParams.name}`, {
    signal
  })
}
