import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo, language } = {}) => {
  const columns = computed(() => [
    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: i18n.t("dateCreated"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      width: 150,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "timestamp" && sortedInfo?.value?.order,
      fixed: "left"
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("code"),
      width: 120,
      fixed: "left"
    },
    {
      key: "full_name",
      dataIndex: "full_name",
      title: `${i18n.t("typeName")} (${language?.value ? language.value.toUpperCase() : "RU"})`
    },
    {
      key: "related_categories",
      dataIndex: "related_categories",
      title: i18n.t("attachedCategories"),
      scopedSlots: {
        customRender: "categoriesRender"
      },
      width: 370
    },
    {
      key: "attribute_count",
      dataIndex: "attribute_count",
      title: i18n.t("attachedAttributesCount"),
      align: "center",
      width: 220
    },
    // {
    //   key: "trust",
    //   dataIndex: "trust",
    //   title: i18n.t("status"),
    //   scopedSlots: {
    //     customRender: "trustRenderer"
    //   },
    //   width: 160
    // },
    {
      key: "note",
      title: i18n.t("tag._note"),
      scopedSlots: {
        customRender: "noteRenderer"
      },
      width: 370
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      width: 64,
      fixed: "right"
    }
  ])

  return { columns }
}
