import { ADS_PREFIX } from "@/constants/apiPrefix.js"
import { API_V2 } from "@/utils/HttpUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"

export const fetchBlackListKinds = () => {
  return API_V2.get(`${ADS_PREFIX}/blacklists/count-kinds/`)
}

export const fetchBlackLists = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/blacklists/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const addToBlackListBulk = (data) => {
  return API_V2.put(`${ADS_PREFIX}/blacklists/bulk/`, data)
}

export const removeFromBlackList = (data) => {
  return API_V2.post(`${ADS_PREFIX}/blacklists/bulk-delete/`, data)
}
