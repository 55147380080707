<template>
  <a-drawer
    width="730px"
    :title="$t('creatingCampaign')"
    placement="right"
    :closable="true"
    :visible="props.isVisible"
    @close="handleClose"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('seller')"
        prop="seller_slug"
      >
        <InfiniteScrollSelect
          :value="form.seller_slug"
          :customRequest="fetchSellers"
          labelKey="name"
          valueKey="slug"
          onFocusFetch
          @change="handleChangeSelectedSeller"
        />
      </a-form-model-item>

      <a-form-model-item
        label="Custom label"
        prop="label"
      >
        <a-input v-model="form.label" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('balance')"
        prop="balance"
      >
        <a-input
          v-model="form.balance"
          :suffix="$t('uah').toUpperCase()"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        :disabled="isFetching"
        @click="handleClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="handleCreateCampaing"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import { createCampaign } from "@/modules/ADS/services/CPCServices.js"
import { fetchSellers } from "@/modules/ADS/services/sellerServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { requiredRule } from "@/utils/validations.js"
import useForm from "@/composables/useForm"

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["onSuccess", "close"])

const formRef = ref()
const form = reactive({
  seller_slug: undefined,
  label: "",
  balance: 0
})
const formRules = computed(() => ({
  seller_slug: [requiredRule.value],
  label: [requiredRule.value],
  balance: [requiredRule.value]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const isFetching = shallowRef(false)
const handleCreateCampaing = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    await createCampaign(form)

    notification.success({ message: i18n.t("created") })
    handleClose()
    emit("onSuccess")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleClose = () => {
  emit("close")
  resetFields()
}

const handleChangeSelectedSeller = (value) => {
  form.seller_slug = value
}
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 24px 16px;

  &-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.padding-bottom {
  padding-bottom: 16px;
}
</style>
