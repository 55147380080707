<template>
  <div
    class="permission-item"
    :class="{ 'permission-item--selected': isSelected }"
    @click="$emit('select')"
  >
    <a-checkbox
      :checked="isChecked"
      :disabled="isFetching"
      @change="onChange"
      @click.stop
    />
    <a-spin :spinning="isFetching">
      <div class="permission-item-text">
        {{ props.permission.display_name }}
      </div>
    </a-spin>
  </div>
</template>

<script setup>
import { computed, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import {
  addPermissionToRole,
  removePermissionFromRole
} from "@/modules/UsersManagement/services/rolesService"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  permission: {
    type: Object,
    required: true
  },
  selectedPermission: {
    type: Number,
    required: false
  },
  selectedPermissionList: {
    type: Array,
    default: () => []
  },
  roleId: {
    type: Number,
    required: false
  }
})
const emit = defineEmits(["add", "remove"])

const isSelected = computed(() => props.permission.id === props.selectedPermission)
const isChecked = computed(() => props.selectedPermissionList.includes(props.permission.id))

const isFetching = shallowRef(false)
const onChange = async () => {
  try {
    isFetching.value = true

    if (isChecked.value) {
      await removePermissionFromRole({
        role: props.roleId,
        payload: { permissions: [props.permission.id] }
      })
      emit("remove")
    } else {
      await addPermissionToRole({
        role: props.roleId,
        payload: { permissions: [props.permission.id] }
      })
      emit("add")
    }
    notification.success({ message: i18n.t("updated"), key: "permission-update" })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.permission-item {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 0 8px;
  height: 30px;

  cursor: pointer;
  background-color: $background-color;

  &--selected {
    background-color: color-mix(in srgb, $primary-hover-color 7.5%, white);
  }

  & > :nth-child(2) {
    width: calc(100% - 24px);
  }
}
.permission-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
