<template>
  <transition name="gallery-mask">
    <div
      class="gallery-wrapper"
      v-if="showGallery"
      @mousedown.prevent
      @click="closeGallery"
    >
      <span
        class="close-button"
        @click.stop="closeGallery"
      >
        ×
      </span>

      <a-icon
        class="navigation-btn next"
        type="arrow-right"
        @click.stop="next"
        v-if="getCurrentImage"
        @mousedown.prevent
      >
      </a-icon>

      <a-icon
        class="navigation-btn prev"
        type="arrow-left"
        @click.stop="prev"
        v-if="getCurrentImage && previews.length"
        @mousedown.prevent
      >
      </a-icon>

      <span
        class="status-bar"
        v-if="getCurrentImage"
        @click.stop
      >
        {{ currentIndex + 1 }} / {{ images?.length || 0 }}
      </span>

      <div class="gallery-relative">
        <a-icon
          class="preview-navigation prev"
          type="arrow-left"
          @click.stop="prevPage"
          v-if="previews.length > 5"
        >
        </a-icon>

        <a-icon
          class="preview-navigation next"
          type="arrow-right"
          @click.stop="nextPage"
          v-if="previews.length > 5"
        >
        </a-icon>

        <div class="cover-wrapper">
          <div
            class="cover"
            @click.stop
            v-show="getCurrentImage"
          >
            <img
              :src="getCurrentImage"
              @error.once="changeImageSize"
              :key="currentIndex"
            />
          </div>

          <div
            class="error"
            v-show="!getCurrentImage"
          >
            No images
          </div>
        </div>

        <div
          class="previews-wrapper"
          v-if="getCurrentImage"
        >
          <div class="box">
            <ul
              class="list"
              :style="{
                left: `-${offset}px`
              }"
            >
              <li
                v-for="(image, index) in previews"
                :key="image + index"
              >
                <span
                  @click.stop="setCurrentIndex(index)"
                  class="preview"
                  :class="{
                    active: index == currentIndex
                  }"
                >
                  <img :src="image" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import getImagePath from "utils/getImagePath"
import { IMAGES_CDN } from "@/constants/common"
import { computed, ref, shallowRef } from "vue"

const PREVIEW_WIDTH = 84
const PER_PAGE = 5
const STEP_SIZE = PER_PAGE * PREVIEW_WIDTH

const images = ref([])
const previews = ref([])
const offset = shallowRef(0)
const currentPage = shallowRef(0)
const imagesStrings = ref([])

const getCurrentImage = computed(() => {
  if (!images.value.length || currentIndex.value === undefined) return ""
  return images.value[currentIndex.value]
})

const changeImageSize = (e) => {
  e.target.src = getFullSizeCdnUrl(imagesStrings.value[currentIndex.value])
}

const getCdnUrls = (array, size) => {
  return array.map((str) => {
    if (~str.indexOf(IMAGES_CDN) || str.indexOf("http") === 0) return str
    return `${IMAGES_CDN}/media/assets/images/${getImagePath(str, size)}`
  })
}
const getFullSizeCdnUrl = (str) => {
  if (~str.indexOf(IMAGES_CDN) || str.indexOf("http") === 0) return str
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(str, "full")}`
}

const setActualOffset = () => {
  const steps = Math.floor(currentIndex.value / PER_PAGE).toFixed(0)
  const newOffset = STEP_SIZE * steps

  currentPage.value = steps
  offset.value = newOffset
}

const keyDownListener = (e) => {
  if (e.keyCode == 37) prev()
  if (e.keyCode == 39) next()
}

const currentIndex = shallowRef()
const setCurrentIndex = (index) => {
  if (typeof index !== "number") {
    console.error("index is not a Number")
  } else if (index > images.value.length - 1 || index < 0) {
    console.error("index out of bounds array")
  } else {
    currentIndex.value = index
    setActualOffset()
  }
}

const next = () => {
  if (currentIndex.value + 1 < images.value.length) {
    setCurrentIndex(currentIndex.value + 1)
  } else {
    setCurrentIndex(0)
  }
}

const prev = () => {
  if (currentIndex.value - 1 >= 0) {
    setCurrentIndex(currentIndex.value - 1)
  } else {
    setCurrentIndex(images.value.length - 1)
  }
}

const nextPage = () => {
  const nextPage = +currentPage.value + 1 //2

  if ((nextPage + 1) * PER_PAGE - PER_PAGE < images.value.length) {
    offset.value = nextPage * PER_PAGE * PREVIEW_WIDTH
    currentPage.value = nextPage
  } else {
    offset.value = 0
    currentPage.value = 0
  }
}

const prevPage = () => {
  const prevPage = +currentPage.value - 1 //2

  if (prevPage >= 0) {
    offset.value -= STEP_SIZE
    currentPage.value = prevPage
  } else {
    let pagesCount = Math.ceil(images.value.length / PER_PAGE) - 1
    offset.value = pagesCount * STEP_SIZE
    currentPage.value = pagesCount
  }
}

const showGallery = shallowRef(false)
const handleOpenGallery = (obj) => {
  let imagesArray = []
  let previewsArray = []
  let index = 0

  if (typeof obj === "array") {
    imagesArray = obj
  } else if (typeof obj === "object") {
    if ("images" in obj) {
      imagesArray = obj.images
    }

    if ("startIndex" in obj) {
      index = obj.startIndex
    }
  }

  imagesStrings.value = imagesArray

  previewsArray = [...imagesArray]
  previewsArray = getCdnUrls(imagesArray, "300x300")

  imagesArray = imagesArray.filter((str) => typeof str === "string")
  imagesArray = getCdnUrls(imagesArray, "full")

  images.value = imagesArray
  previews.value = previewsArray
  currentIndex.value = index

  showGallery.value = true
  setCurrentIndex(currentIndex.value)
  listenKeyDown()
  setActualOffset()
}

const listenKeyDown = () => {
  document.addEventListener("keydown", keyDownListener)
}
const removeKeyDownListener = () => {
  document.removeEventListener("keydown", keyDownListener)
}

const closeGallery = () => {
  showGallery.value = false

  removeKeyDownListener()
}

defineExpose({
  handleOpenGallery
})
</script>

<style lang="scss">
.gallery-wrapper {
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  content: "";
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  display: inline-block;

  & .navigation-btn {
    z-index: 99999;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    height: 120px;
    width: 60px;
    line-height: 120px;
    text-align: center;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.next {
      top: calc(50% - 60px);
      right: 10px;
    }

    &.prev {
      top: calc(50% - 60px);
      left: 10px;
    }
  }

  & .close-button {
    z-index: 99999;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  & .status-bar {
    cursor: default;
    z-index: 999;
    position: absolute;
    text-align: center;
    height: 20px;
    line-height: 20px;
    padding: 0 12px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 11px;
    font-weight: 700;
  }

  & .gallery-relative {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    content: "";

    & .preview-navigation {
      position: absolute;
      height: 60px;
      width: 60px;
      content: "";
      display: inline-block;
      z-index: 9999;
      cursor: pointer;
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      font-weight: 700;
      color: #fff;

      &.next {
        left: calc(50% + 220px);
        bottom: 15px;
      }

      &.prev {
        right: calc(50% + 220px);
        bottom: 15px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    & .cover-wrapper {
      position: absolute;
      width: calc(100% - 160px);
      height: calc(100% - 100px);
      left: 80px;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      & .cover {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        line-height: 0;

        & img {
          display: inline-block !important;
          max-width: 100%;
          max-height: calc(100vh - 100px);
        }
      }

      & .error {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        line-height: 100%;
        text-align: center;
        font-size: 12px;
        color: #fff;
      }
    }

    & .previews-wrapper {
      position: absolute;
      width: calc(100% - 160px);
      padding: 0;
      content: "";
      height: 90px;
      bottom: 0;
      left: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      & .box {
        width: calc(84px * 5);
        height: 80px;
        position: relative;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
      }

      & ul.list {
        position: absolute;
        top: 0;
        transition: left ease-in-out 0.2s;
        left: 0;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 10000px;
        height: 80px;

        li {
          padding: 0;
          margin: 0;
          display: inline;
          margin-right: 2px;
          margin-left: 2px;

          & .preview {
            border: 1px solid rgba(0, 0, 0, 0);
            background-color: transparent;
            overflow: hidden;
            width: 80px;
            height: 80px;
            display: inline-block;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            transition: opacity ease-in-out 0.2s;
            opacity: 0.8;

            &.active {
              border: 1px solid #357cf1;
              opacity: 1;
            }

            &:hover {
              opacity: 1;
            }

            & img {
              max-width: 80px;
              max-height: 80px;
              position: absolute;
              top: calc(50%);
              left: calc(50%);
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}

.gallery-mask-enter {
  opacity: 0;
}

.gallery-mask-leave-active {
  opacity: 0;
}
</style>
