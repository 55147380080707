<template>
  <a-drawer
    :width="720"
    :body-style="{ paddingBottom: '80px' }"
    :visible="isOpened"
    :title="$t('sellerCreating')"
    @close="onClose"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      :label-col="{ span: 7 }"
      :wrapper-col="{ span: 12 }"
    >
      <a-form-model-item
        :label="$t('companyName')"
        prop="name"
      >
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('owner')"
        prop="owner"
      >
        <InfiniteScrollSelect
          :value="form.owner"
          :customRequest="fetchBuyers"
          :customRequestParams="{
            is_seller_owner: false,
            is_active: true
          }"
          valueKey="uuid"
          searchKey="mobile_phone"
          :placeholder="$t('enterPhoneNumber')"
          @change="onOwnerChange"
        >
          <template #option="{ option }">
            {{ option.mobile_phone }}
            <template v-if="option.username || option.first_name || option.last_name">
              ({{ option.username ? `${option.username} / ` : "" }}{{ option.first_name }}
              {{ option.last_name }})
            </template>
          </template>
        </InfiniteScrollSelect>
      </a-form-model-item>

      <a-form-model-item
        :label="$t('sellerLogo')"
        :extra="$t('recommendedSize', { size: '100x100' })"
        prop="small_logo"
      >
        <a-upload
          name="small_logo"
          listType="picture-card"
          :showUploadList="false"
          :beforeUpload="() => false"
          accept="image/*"
          @change="onImageChange"
        >
          <a-avatar
            v-if="form.small_logo"
            :src="logoPreviewUrl"
            alt="avatar"
            shape="square"
            :size="100"
          />
          <div
            v-else
            class="ant-upload-text"
          >
            {{ $t("upload") }}
          </div>
        </a-upload>
      </a-form-model-item>
      <div class="drawer-footer">
        <a-button
          key="back"
          @click="onClose"
        >
          {{ $t("cancel") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isFetching"
          @click="handleCreateSeller"
        >
          {{ $t("create") }}
        </a-button>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import { createSeller } from "@/modules/MPAdmin/services/sellersService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { fetchBuyers } from "@/modules/MPAdmin/services/buyersService.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits(["close", "created"])

const formRef = ref()
const form = reactive({
  name: undefined,
  owner: undefined,
  small_logo: undefined
})
const formRules = computed(() => ({
  name: [requiredRule.value],
  owner: [requiredRule.value],
  small_logo: [requiredRule.value]
}))
const { isValidForm, validateForm, resetFields, resetValidation } = useForm(formRef)

const logoPreviewUrl = computed(() => {
  if (!form.small_logo) return ""
  return URL.createObjectURL(form.small_logo)
})

const onImageChange = (event) => {
  if (event.file.type.startsWith("image")) {
    form.small_logo = event.file
  } else {
    notification.error({ message: i18n.t("unsupported_format") })
  }
}

const onOwnerChange = (value) => {
  form.owner = value
}

const isFetching = ref(false)
const handleCreateSeller = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    const formData = new FormData()

    formData.append("owner", form.owner)
    formData.append("name", form.name)
    formData.append("small_logo", form.small_logo)
    formData.append("is_active", false)

    const { data } = await createSeller(formData)

    notification.success({
      message: i18n.t("sellerCreatingSuccess")
    })
    emit("created", data)
    onClose()
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("sellerCreatingError") })
  } finally {
    isFetching.value = false
  }
}

const onClose = () => {
  resetFields()
  resetValidation()
  emit("close")
}
</script>

<style lang="scss" scoped>
.ant-upload-text {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
