import { API_V2 } from "@/utils/HttpUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { ADS_PREFIX } from "@/constants/apiPrefix.js"

export const fetchRefferers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/referrers/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const subtractRef = (referralCode) => {
  return API_V2.post(`${ADS_PREFIX}/referrers/${referralCode}/commit-ref/`)
}
