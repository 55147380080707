import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => [
    {
      key: "images",
      dataIndex: "images",
      title: i18n.t("photo"),
      scopedSlots: {
        customRender: "photoRenderer"
      },
      fixed: "left",
      width: 80
    },
    {
      key: "p_code",
      dataIndex: "p_code",
      title: `P ${i18n.t("code")}`,
      fixed: "left",
      width: 110
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name"),
      width: 350
    },
    {
      key: "category",
      dataIndex: "category",
      title: i18n.t("category"),
      scopedSlots: {
        customRender: "categoryRenderer"
      },
      width: 150
    },
    {
      key: "model",
      dataIndex: "model",
      title: i18n.t("model"),
      width: 160
    },
    {
      key: "rating",
      dataIndex: "rating",
      title: i18n.t("ratingShorted"),
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "rating" && sortedInfo.value.order,
      align: "center",
      width: 75
    },
    {
      key: "label",
      dataIndex: "label",
      title: i18n.t("label"),
      scopedSlots: {
        customRender: "labelRender"
      },
      width: 140
    },
    {
      key: "listing_id",
      dataIndex: "listing",
      title: i18n.t("listing"),
      scopedSlots: {
        customRender: "listing"
      },
      width: 120,
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "listing_id" && sortedInfo.value.order
    },
    {
      key: "rf_s",
      dataIndex: "rf_s",
      title: `${i18n.t("ratingShorted")} S`,
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "rf_s" && sortedInfo.value.order,
      width: 100
    },
    {
      key: "rf_m",
      dataIndex: "rf_m",
      title: `${i18n.t("ratingShorted")} M`,
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "rf_m" && sortedInfo.value.order,
      width: 100
    },
    {
      key: "rf_l",
      dataIndex: "rf_l",
      title: `${i18n.t("ratingShorted")} L`,
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "rf_l" && sortedInfo.value.order,
      width: 100
    },
    {
      key: "rf",
      dataIndex: "rf",
      title: i18n.t("finalRating"),
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo.value.columnKey === "rf" && sortedInfo.value.order,
      width: 100
    },
    {
      key: "moderation_status",
      dataIndex: "moderation_status",
      title: i18n.t("moderationStatus"),
      fixed: "right",
      scopedSlots: {
        customRender: "statusRender"
      },
      width: 180
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("created"),
      width: 110,
      scopedSlots: {
        customRender: "date"
      },
      fixed: "right"
    },
    {
      key: "actions",
      width: 110,
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right"
    }
  ])

  return columns
}
