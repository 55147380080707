<template>
  <div style="margin-bottom: 40px">
    <DataTableWrapper
      :columns="columns"
      :data="tableData"
      :removeItem="removeTableItem"
      :currentCover="currentCover"
      @changeCover="handleChangeCover"
    />

    <a-form-model-item>
      <template #label>
        {{ data.length ? $t("productSearchByCategory", { category: data[0].category?.name }) : "" }}
      </template>

      <a-auto-complete
        v-model="searchProduct"
        :dataSource="searchResults"
        :placeholder="$t('startPrint')"
        style="width: 100%"
        @search="(e) => handleSearch(e)"
        @select="selectProduct"
      />
    </a-form-model-item>

    <a-form-model-item :label="$t('listingNumber')">
      <a-input
        :disabled="true"
        :value="nextListing"
      />
    </a-form-model-item>

    <DataTableWrapper
      :columns="columns"
      :data="searchTable"
      :removeItem="removeSearchTableItem"
      :currentCover="currentCover"
      @changeCover="handleChangeCover"
    />

    <a-form-model-item :label="$t('variations')">
      <a-select
        v-model="attrsValue"
        :options="getAllAttrs"
        :placeholder="$t('startPrint')"
        style="width: 100%"
        showSearch
        :disabled="!searchTable?.length"
        @select="selectVariation"
      />
    </a-form-model-item>

    <a-alert
      type="error"
      v-if="!selectedItemsContainCover"
      :message="$t('chooseProductCover')"
      showIcon
    />

    <a-alert
      type="error"
      class="variation-error"
      v-if="!selectedAttrs.length"
      :message="$t('chooseCharacteristic')"
      showIcon
    />

    <a-alert
      class="variation-error"
      type="error"
      v-for="error in getAttrsErrors"
      :key="error"
      :message="error"
      showIcon
    />

    <div v-show="selectedAttrs.length">
      <VariationsList
        :label="$t('chosenCharForVar')"
        :data="selectedAttrs"
        @change="changeVariations"
      />
    </div>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :disabled="isDisabled"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("createListing") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import DataTableWrapper from "./components/DataTableWrapper"
import VariationsList from "./components/VariationsList"

import {
  createListing,
  getLastListing,
  fetchProducts
} from "@/modules/MPAdmin/services/productsService.js"

import useListingColumns from "./useListingColumns.js"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["close"])

const columns = useListingColumns()

const tableData = ref(props.data || [])
const searchTable = ref([])
const searchProduct = ref("")
const searchResults = ref([])
const selectedAttrs = ref([])
const attrsValue = ref()
const nextListing = ref("")
const isFetching = shallowRef(false)
const currentCover = ref(null)

const handleSearch = async (value) => {
  if (value) {
    const { data } = await fetchProducts({
      queryParams: { name: value, category_id: tableData.value[0].category.id }
    })

    searchResults.value = data.results.map((info) => ({
      value: `${info.id}`,
      text: info.name,
      info
    }))
  } else {
    searchResults.value = []
  }
}

const getNextListing = async () => {
  const { data } = await getLastListing()

  if (data && data.count) {
    nextListing.value = `${data.count + 1}`
  }
}

const handleChangeCover = (id) => {
  currentCover.value = id
}

const removeTableItem = (item) => {
  tableData.value = tableData.value.filter((j) => j.id !== item.id)
}

const removeSearchTableItem = (item) => {
  searchTable.value = searchTable.value.filter((j) => j.id !== item.id)
}

const selectProduct = (id) => {
  addItemToSearchTable(searchResults.value.find((item) => item.value === id).info)

  searchProduct.value = ""
  searchResults.value = []
}

const selectVariation = (variation) => {
  handleAddVariation(variation)
  attrsValue.value = undefined
}

const changeVariations = (arr) => {
  selectedAttrs.value = arr
}

const handleAddVariation = (value) => {
  let newAttr = {}

  searchTable.value.forEach(({ attrs }) => {
    newAttr = attrs.find((attribute) => attribute.name.ru === value)
  })

  if (!newAttr) {
    return notification.error({
      message: i18n.t("error"),
      description: i18n.t("attributeNotFound")
    })
  }

  selectedAttrs.value = [...selectedAttrs.value, { ...newAttr, name: newAttr?.name?.ru }]
}

const addItemToSearchTable = (item) => {
  if (
    searchTable.value.some((j) => j.id === item.id) ||
    tableData.value.some((j) => j.id === item.id)
  ) {
    notification.info({ message: i18n.t("productIsChosen") })
    return
  }

  searchTable.value = [
    ...searchTable.value,
    { ...item, listing: item?.listing?.id || i18n.t("no") }
  ]
}

const onSave = async () => {
  isFetching.value = true

  const payload = {
    cover_product: currentCover.value,
    products: [],
    variations: selectedAttrs.value.map((item) => item.name)
  }

  tableData.value.forEach((item) => payload.products.push(item.id))
  searchTable.value.forEach((item) => payload.products.push(item.id))

  try {
    await createListing(payload)

    notification.success({ message: i18n.t("created") })

    emit("close")
  } catch (error) {
    if (error?.response?.data?.detail?.length && error.response.data.detail[0]?.message) {
      error.response.data.detail = error.response.data.detail.map(({ message }) => message)

      notifyResponseError({ error })
    } else {
      notifyResponseError({ error })
    }
  } finally {
    isFetching.value = false
  }
}

const getAttrsErrors = computed(() => {
  const errors = []

  selectedAttrs.value.forEach((attr) => {
    const { name: attrName } = attr

    const notContainAttrItems = []

    notContainAttrItems.push(
      ...searchTable.value.filter((item) => {
        return item.attrs.every((attr) => {
          const localAttrName = attr.name.ru

          return localAttrName !== attrName
        })
      })
    )

    notContainAttrItems.push(
      ...tableData.value.filter((item) => {
        return item.attrs.every((attr) => {
          const localAttrName = attr.name.ru

          return localAttrName !== attrName
        })
      })
    )

    if (notContainAttrItems.length) {
      let productsPCodes = notContainAttrItems.map((item) => item.p_code).join(", ")
      let message = `${i18n.t("inNextProducts", [productsPCodes])} «${attrName}»`
      errors.push(message)
    }
  })

  return errors
})

const selectedItemsContainCover = computed(() => {
  let containCover = false

  for (let i = 0; i < searchTable.value.length; i++) {
    if (searchTable.value[i].id == currentCover.value) {
      containCover = true
      break
    }
  }

  if (containCover) return containCover

  for (let i = 0; i < tableData.value.length; i++) {
    if (tableData.value[i].id == currentCover.value) {
      containCover = true
      break
    }
  }

  return containCover
})

const isDisabled = computed(() => {
  return Boolean(
    getAttrsErrors.value.length ||
      !selectedAttrs.value.length ||
      currentCover.value === null ||
      !selectedItemsContainCover.value
  )
})

const getAllAttrs = computed(() => {
  const attrs = {}

  searchTable.value.forEach((item) => {
    if (item.attrs && item.attrs.length) {
      item.attrs.forEach((attr) => {
        const key = attr.name.ru

        attrs[key] = true
      })
    }
  })

  return Object.keys(attrs).map((item) => ({
    name: item,
    value: item,
    label: item
  }))
})

onMounted(() => {
  getNextListing()
})
</script>

<style lang="scss" scoped>
.max-table-height {
  max-height: 400px;
}

.variation-error {
  margin-top: 8px;
}
</style>
