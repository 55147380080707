import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "handle",
      title: "",
      scopedSlots: {
        customRender: "handleRenderer"
      },
      width: 5
    },
    {
      key: "uk",
      title: "UA",
      scopedSlots: {
        customRender: "uk"
      },
      width: 160
    },
    {
      key: "ru",
      title: "RU",
      scopedSlots: {
        customRender: "ru"
      },
      width: 160
    },
    {
      key: "en",
      title: "EN",
      scopedSlots: {
        customRender: "en"
      },
      width: 160
    },
    {
      key: "lookup",
      title: i18n.t("filterCondition"),
      scopedSlots: {
        customRender: "conditionRender"
      },
      width: 160
    },
    {
      key: "value",
      slots: { title: "valueTitle" },
      scopedSlots: {
        customRender: "baseUnitRender"
      },
      width: 270
    },
    {
      key: "count",
      dataIndex: "count",
      title: i18n.t("offersInRange"),
      scopedSlots: {
        customRender: "offersInRangeRender"
      },
      width: 160
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      width: 60
    }
  ])

  return columns
}
