<template>
  <div class="edit-type">
    <a-page-header
      :title="pageTitle"
      class="edit-type__header"
      @back="onReturnBack"
    >
      <template #extra>
        <a-button
          :disabled="!isChangedGeneralForm || isFetching"
          @click="onResetForm"
        >
          {{ $t("cancel") }}
        </a-button>
        <a-button
          type="primary"
          :loading="isFetching"
          :disabled="!isChangedGeneralForm"
          @click="onSubmit"
        >
          {{ $t("save") }}
        </a-button>
      </template>
    </a-page-header>

    <a-spin :spinning="isFetching">
      <GeneralTab
        ref="generalTabRef"
        :typeInfo="typeInfo"
        @change="onFormChanged"
      />
    </a-spin>

    <AttributeTab :typeInfo="typeInfo" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, shallowRef } from "vue"
import i18n from "@/i18n"
import router from "@/router"

import notifyResponseError from "@/utils/notifyResponseError"
import { fetchLocalizedType, updateLocalizedType, updateType } from "../../services/typesService"
import GeneralTab from "./components/GeneralTab.vue"
import { notification } from "ant-design-vue"
import AttributeTab from "./components/AttributeTab.vue"

const generalTabRef = ref()
const isChangedGeneralForm = shallowRef(false)

const pageTitle = computed(
  () =>
    `${i18n.t("typeEditing")}: ${typeInfo.value?.full_name?.ru ? `${typeInfo.value?.full_name?.ru} (${typeInfo.value.code})` : i18n.t("type")}`
)

const typeInfo = ref()
const isFetching = shallowRef(false)

const getTypeInfo = async () => {
  try {
    isFetching.value = true

    const { data } = await fetchLocalizedType({ code: router.currentRoute.params.code })
    typeInfo.value = data
    generalTabRef.value?.setupForm(data)
    isChangedGeneralForm.value = false
  } catch (error) {
    notifyResponseError({ error })
    router.push({ name: "TypesList" })
  } finally {
    isFetching.value = false
  }
}

const onReturnBack = () => {
  if (/types\/?\?/.test(router.currentRoute.meta.previous || "")) {
    router.push(router.currentRoute.meta.previous)
  } else {
    router.push({ name: "TypesList" })
  }
}

const onFormChanged = () => {
  isChangedGeneralForm.value = true
}

const onResetForm = () => {
  getTypeInfo()
  isChangedGeneralForm.value = false
}

const onSubmit = async () => {
  try {
    isFetching.value = true
    const payload = await generalTabRef.value.getFormValues()
    if (!payload) {
      return notification.error({ message: i18n.t("fillFields") })
    }

    await updateLocalizedType({ code: typeInfo.value.code, payload })
    notification.success({ message: i18n.t("updated") })
    onResetForm()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

onMounted(getTypeInfo)
</script>

<style lang="scss" scoped>
.edit-type {
  max-width: 1600px;
  margin: 0 auto;

  &__header {
    margin: 0;
    padding: 16px 0;
  }
}
</style>
