import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => {
    return [
      {
        key: "delivery_method",
        dataIndex: "delivery_method",
        title: i18n.t("deliveryMethods")
      },
      {
        key: "shipment_time_in_days",
        dataIndex: "shipment_time_in_days",
        title: i18n.t("shipmentTime")
      },
      {
        key: "delivery_time_in_days_min",
        dataIndex: "delivery_time_in_days_min",
        title: i18n.t("deliveryTimeMin")
      },
      {
        key: "delivery_time_in_days_max",
        dataIndex: "delivery_time_in_days_max",
        title: i18n.t("deliveryTimeMax")
      },
      {
        key: "shipment_city",
        dataIndex: "shipment_city",
        title: i18n.t("shipmentCity")
      },
      {
        key: "payment_methods",
        dataIndex: "payment_methods",
        title: i18n.t("paymentMethods")
      }
    ]
  })

  return columns
}
