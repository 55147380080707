import { shallowRef } from "vue"

export default (formRef) => {
  const isValidForm = shallowRef(false)
  const formErrors = shallowRef()

  const validateForm = async () => {
    if (!formRef?.value || typeof formRef.value.validate !== "function") {
      console.warn("Ant Form reference is invalid or missing 'validate' method.")
      return false
    }

    await formRef.value.validate((valid, errors) => {
      formErrors.value = errors && Object.keys(errors)?.length ? errors : undefined
      isValidForm.value = Boolean(valid)
    })

    return isValidForm.value
  }

  const resetFields = () => {
    if (!formRef.value || typeof formRef.value.resetFields !== "function") {
      console.warn("Ant Form reference is invalid or missing 'resetFields' method.")
      return
    }
    formRef.value.resetFields()
    resetValidation()
  }

  const resetValidation = async () => {
    if (!formRef.value || typeof formRef.value.clearValidate !== "function") {
      console.warn("Ant Form reference is invalid or missing 'clearValidate' method.")
      return
    }
    formRef.value.clearValidate()
  }

  return {
    isValidForm,
    formErrors,
    validateForm,
    resetFields,
    resetValidation
  }
}
