<template>
  <a-drawer
    :visible="showModal"
    :title="$t('transferBonuses')"
    :width="700"
    @close="$emit('close')"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('enterSum')"
        prop="amount"
      >
        <a-input-number
          v-model="form.amount"
          :min="1"
          :precision="0"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('recipientSearch')"
        prop="receiver"
      >
        <InfiniteScrollSelect
          ref="userSearchRef"
          :value="form.receiver"
          :customRequest="fetchBuyers"
          :customRequestParams="{ is_confirmed_mobile_phone: true, is_active: true }"
          labelKey="receiver"
          valueKey="uuid"
          :placeholder="$t('phoneNumber')"
          @change="handleChooseUser"
        >
          <template #option="{ option }">
            {{ option.mobile_phone }}
            {{ option.username ? `(${option.username})` : "" }}
            {{ `${option.first_name} ${option.middle_name} ${option.last_name}` }}
          </template>
        </InfiniteScrollSelect>
      </a-form-model-item>

      <a-descriptions
        v-if="currentChoosedUser"
        :column="1"
        bordered
        size="small"
        :title="$t('recipient')"
      >
        <a-descriptions-item label="Username">
          {{ currentChoosedUser.username }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('firstName')">
          {{ currentChoosedUser.first_name }} {{ currentChoosedUser.middle_name }}
          {{ currentChoosedUser.last_name }}
        </a-descriptions-item>

        <a-descriptions-item :label="$t('phoneNumber')">
          {{ currentChoosedUser.mobile_phone }}
        </a-descriptions-item>

        <a-descriptions-item label="UUID">
          {{ currentChoosedUser.uuid }}
        </a-descriptions-item>
      </a-descriptions>

      <a-form-model-item
        :label="$t('comment')"
        prop="comment_sender"
      >
        <a-textarea
          v-model="form.comment_sender"
          :autoSize="{ minRows: 4, maxRows: 10 }"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="$emit('close')"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, computed, watch } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import { transactBonus } from "@/modules/MPAdmin/services/bonusesService.js"
import { fetchBuyers } from "@/modules/MPAdmin/services/buyersService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { requiredRule } from "@/utils/validations"
import useForm from "@/composables/useForm"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["handleRefreshData", "close"])

const formRef = ref()
const form = reactive({
  amount: 1,
  receiver: undefined,
  comment_sender: ""
})
const isFetching = ref(false)
const currentChoosedUser = ref(null)

const formRules = computed(() => ({
  amount: [requiredRule.value],
  receiver: [requiredRule.value],
  comment_sender: [
    requiredRule.value,
    { max: 500, message: i18n.t("maxLengthError", { length: 500 }) }
  ]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const onSave = async () => {
  await validateForm()
  if (!isValidForm.value) return

  isFetching.value = true

  try {
    await transactBonus(form)

    notification.success({
      message: `${form.amount} ${i18n.tc("someBonusesTransfered", form.amount)}`
    })
    handleChooseUser(null)

    emit("handleRefreshData")
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const userSearchRef = ref()

const handleChooseUser = (uuid) => {
  form.receiver = uuid
  if (!uuid) {
    currentChoosedUser.value = null
    return
  }

  currentChoosedUser.value = userSearchRef.value?.selectOptions?.find((user) => user.uuid === uuid)
}

watch(
  () => props.showModal,
  () => {
    if (!formRef.value) return
    resetFields()
  }
)
</script>
