<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="padding-bottom: 50px"
  >
    <a-form-model-item
      :label="$t('name')"
      prop="name"
    >
      <a-input v-model="form.name" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('url')"
      prop="link"
    >
      <a-input v-model="form.link" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('promoText')"
      prop="promo_text"
    >
      <a-textarea
        :autoSize="{ minRows: 2, maxRows: 6 }"
        v-model="form.promo_text"
      />
    </a-form-model-item>

    <template v-if="prototype?.id">
      <a-form-model-item
        :label="$t('state')"
        prop="is_active"
      >
        <a-select
          v-model="form.is_active"
          :options="isActiveOptions"
          @change="handleChangeActivity"
        />
      </a-form-model-item>

      <a-form-model-item
        v-show="form.is_active === 'true'"
        :label="$t('sorting')"
        prop="rating"
      >
        <a-input-number
          v-model="form.rating"
          :min="0"
          :max="999"
          :precision="0"
        />
      </a-form-model-item>
    </template>

    <a-alert
      v-else
      type="warning"
      :message="$t('sliderCreatingAlert')"
      showIcon
    />

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isSubmitting"
        @click="onSubmit"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { ref, computed, reactive } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import {
  createPromotion,
  updatePromotion
} from "@/modules/MPAdmin/services/mainPromotionsService.js"

import useMPAConsts from "@/modules/MPAdmin/constants.js"
import usePromotionsConstants from "../usePromotionsConstants.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { requiredRule } from "@/utils/validations.js"
import useForm from "@/composables/useForm.js"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["updateImages"])

const { isActiveOptions } = useMPAConsts()
const { nameRules, linkRules } = usePromotionsConstants()

const formRef = ref()
const form = reactive({
  name: props.prototype?.name,
  link: props.prototype?.link,
  promo_text: props.prototype?.promo_text,
  rating: props.prototype?.rating,
  is_active: `${props.prototype?.is_active}`
})
const formRules = computed(() => ({
  name: nameRules,
  link: linkRules,
  promo_text: [{ max: 500, message: i18n.t("maxLengthError", { length: 500 }) }],
  is_active: [requiredRule.value]
}))
const { isValidForm, validateForm } = useForm(formRef)

const isSubmitting = ref(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isSubmitting.value = true

    const payload = {
      name: form.name,
      link: form.link,
      is_active: form.is_active
    }

    if (form.rating && form.is_active === "true") {
      payload.rating = form.rating
    }
    if (form.promo_text) {
      payload.promo_text = form.promo_text
    }

    if (props.prototype?.id) {
      const { data } = await updatePromotion(payload, props.prototype.id)
      emit("updateBanner", data)
    } else {
      await createPromotion(payload)
      emit("createBanner")
    }

    notification.success({
      message: props.prototype?.id ? i18n.t("updated") : i18n.t("created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmitting.value = false
  }
}

const handleChangeActivity = (value) => {
  if (value === "true" && !form.rating) {
    form.rating = 1
  }
}
</script>
