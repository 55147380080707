<template>
  <a-form-model
    ref="formRef"
    :model="form"
    class="seo-settings"
  >
    <!-- TITLE -->
    <a-form-model-item
      v-for="lang in languages"
      :key="`title-${lang.value}`"
    >
      <template #label>
        Title, {{ lang.label.toUpperCase() }} (<span
          :class="{
            'red-text':
              form.seo_title[lang.value].length < 30 || form.seo_title[lang.value].length > 65
          }"
          >{{ form.seo_title[lang.value].length }}</span
        >/{{ titleMaxLength.join("-") }})
      </template>

      <a-input v-model="form.seo_title[lang.value]" />
    </a-form-model-item>

    <!-- DESCRIPTION -->
    <a-form-model-item
      v-for="lang in languages"
      :key="`description-${lang.value}`"
    >
      <template #label>
        Description, {{ lang.label.toUpperCase() }} (<span
          :class="{
            'red-text':
              form.seo_description[lang.value].length < 120 ||
              form.seo_description[lang.value].length > 320
          }"
          >{{ form.seo_description[lang.value].length }}</span
        >/{{ descriptionMaxLength.join("-") }})
      </template>

      <a-textarea
        v-model="form.seo_description[lang.value]"
        :autoSize="{ minRows: 5, maxRows: 5 }"
      />
    </a-form-model-item>

    <!-- KEYWORDS -->
    <a-form-model-item
      v-for="lang in languages"
      :key="`keyword-${lang.value}`"
    >
      <template #label>
        Keywords, {{ lang.label.toUpperCase() }} (<span
          :class="{
            'red-text':
              !form.seo_keywords[lang.value].length || form.seo_keywords[lang.value].length > 200
          }"
          >{{ form.seo_keywords[lang.value].length }}</span
        >/{{ keywordsMaxLength }})
      </template>

      <a-textarea
        v-model="form.seo_keywords[lang.value]"
        :autoSize="{ minRows: 5, maxRows: 5 }"
      />
    </a-form-model-item>

    <!-- SEO TEXT -->
    <a-collapse
      style="width: 100%; background-color: inherit"
      accordion
      :bordered="false"
    >
      <a-collapse-panel
        v-for="lang in languages"
        :key="`text-${lang.value}`"
        :forceRender="true"
        style="border: none"
      >
        <template #header>SEO-text, {{ lang.label.toUpperCase() }}</template>
        <a-form-model-item>
          <EditorHtml
            :id="`editor-${lang.value}`"
            v-model="form.seo_text[lang.value]"
            :headers="[false, 4, 5, 6]"
          />
        </a-form-model-item>
      </a-collapse-panel>
    </a-collapse>
  </a-form-model>
</template>

<script setup>
import EditorHtml from "@/components/Editor/EditorHtml.vue"
import { onMounted, reactive, ref, watch } from "vue"

const props = defineProps({
  languages: {
    type: Array,
    required: true
  },
  seo_title: {
    type: Object,
    default: () => ({})
  },
  seo_description: {
    type: Object,
    default: () => ({})
  },
  seo_keywords: {
    type: Object,
    default: () => ({})
  },
  seo_text: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["formChanged"])

const formRef = ref()
const form = reactive({
  seo_title: {
    uk: "",
    en: "",
    ru: "",
    ...props.seo_title
  },
  seo_description: {
    uk: "",
    en: "",
    ru: "",
    ...props.seo_description
  },
  seo_keywords: {
    uk: props.seo_keywords?.uk?.join(", ") || "",
    en: props.seo_keywords?.en?.join(", ") || "",
    ru: props.seo_keywords?.ru?.join(", ") || ""
  },
  seo_text: {
    uk: "",
    en: "",
    ru: "",
    ...props.seo_text
  }
})

const formWatcher = ref()
const setWatcher = () => {
  formWatcher.value = watch(form, onValuesChange, { deep: true })
}
const onValuesChange = () => {
  emit("formChanged")
  formWatcher.value()
}

const titleMaxLength = [30, 65]
const descriptionMaxLength = [120, 320]
const keywordsMaxLength = 200

const getFields = () => {
  const { seo_keywords, ...payload } = form
  payload.seo_keywords = {
    uk: seo_keywords.uk.split(/[\r\n, ]+/gm).filter(Boolean),
    en: seo_keywords.en.split(/[\r\n, ]+/gm).filter(Boolean),
    ru: seo_keywords.ru.split(/[\r\n, ]+/gm).filter(Boolean)
  }
  return payload
}

onMounted(setWatcher)

defineExpose({
  setWatcher,
  getFields
})
</script>

<style lang="scss" scoped>
.seo-settings {
  width: 100%;
  max-width: 1420px;

  margin: 0 auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 16px;

  & > * {
    width: 31%;
  }
}

.red-text {
  color: $red-color;
}
</style>
