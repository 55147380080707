import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import { h } from "vue"

const getRenderedList = (list) => list.map((item) => h("div", {}, item))

const notifyArrayError = ({ message, description, duration = 4.5 }) => {
  notification.error({
    message,
    description: () => h("div", {}, [getRenderedList(description)]),
    duration
  })
}

const notifyStringError = ({ message, description, duration = 4.5 }) => {
  notification.error({ message, description, duration })
}

const notifyObjectError = ({ message, description, duration = 4.5 }) => {
  // Check array error
  if (Array.isArray(description)) return notifyArrayError({ message, description })
  // Handle empty error
  if (!description || (typeof description === "object" && Object.keys(description).length === 0)) {
    return notifyStringError({ message })
  }
  // Check string error
  if (typeof description === "string") return notifyStringError({ message, description })

  // Handle general error
  if (description?._general && Array.isArray(description._general)) {
    return notifyArrayError({ message, description: description._general })
  } else if (description?._general) {
    return notifyStringError({ message, description: description._general })
  }

  // Transform object to "{key}: {value}" format and render as list of errors
  notifyArrayError({
    message,
    description: Object.entries(description).map(
      ([key, value]) => `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
    ),
    duration
  })
}

export default ({ error, message = i18n.t("error"), duration = 4.5 }) => {
  const description =
    error?.response?.data?.details || error?.response?.data?.detail || error?.response?.data
  if (!description) return notifyStringError({ message, duration })
  return notifyObjectError({ message, description, duration })
}
