import notifyResponseError from "@/utils/notifyResponseError"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchTags = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/tags${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    {
      signal
    }
  )
}

export const createTag = (data) => {
  return API_V2.post(`${MPA_PREFIX}/tags`, data)
}

export const fetchSingleTag = (uuid) => {
  return API_V2.get(`${MPA_PREFIX}/tags/${uuid}`)
}

export const updateTag = (data, uuid) => {
  return API_V2.patch(`${MPA_PREFIX}/tags/${uuid}`, data)
}

export const fetchTagOffers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/tags/${requestParams.uuid}/offers${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const addOffersToTag = (uuid, csCodes) => {
  return API_V2.post(`${MPA_PREFIX}/tags/${uuid}/offers/set`, csCodes)
}

export const deleteOffersFromTag = ({ uuid, csCodes }) => {
  return API_V2.post(`${MPA_PREFIX}/tags/${uuid}/offers/clean`, csCodes)
}

export const editTagOffers = (uuid, csCodes) => {
  return API_V2.post(`${MPA_PREFIX}/tags/${uuid}/offers/update`, csCodes)
}

export const editTagActivity = (data, uuid) => {
  return API_V2.put(`${MPA_PREFIX}/tags/${uuid}`, data)
}

export const importTagsFile = (file) => {
  const payload = new FormData()
  payload.append("file", file)

  return API_V2.post(`${MPA_PREFIX}/tags/import`, payload)
}

export const checkTagsImportTask = async (task_id) => {
  try {
    const { data } = await API_V2.get(`${MPA_PREFIX}/tags/import-status/${task_id}`)
    if (data.state === "PENDING") return setTimeout(checkTagsImportTask, 2500, task_id)
    if (data.state === "SUCCESS") {
      notification.success({ message: i18n.t("tagsImportFinished"), duration: 0 })

      const link = document.createElement("a")
      link.href = data.url
      link.setAttribute("download", "tag-import-results.csv")
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    }
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const importOffersTagsFile = (file) => {
  const payload = new FormData()
  payload.append("file", file)

  return API_V2.post(`${MPA_PREFIX}/tags/import/offers/import`, payload)
}

export const checkOffersTagsImportTask = async (task_id) => {
  try {
    const { data } = await API_V2.get(`${MPA_PREFIX}/tags/import/offers/import-status/${task_id}`)

    if (data.state === "PENDING") return setTimeout(checkTagsImportTask, 2500, task_id)
    if (data.state === "SUCCESS") {
      notification.success({ message: i18n.t("offersImportFinished"), duration: 0 })

      const link = document.createElement("a")
      link.href = data.url
      link.setAttribute("download", "tag-import-results.csv")
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    }
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const checkSessionCookieExpired = () => {
  const storedToken = JSON.parse(localStorage.getItem("refresh_token_mpa"))
  const now = new Date().getTime()
  if (!storedToken || now > storedToken.expiry) {
    localStorage.removeItem("refresh_token_mpa")
    return true
  }

  return false
}
