<template>
  <a-list :header="props.label">
    <a-list-item
      v-for="item in items"
      :key="item.key"
    >
      {{ item.name }}

      <template #actions>
        <a-button
          type="danger"
          shape="circle"
          size="small"
          icon="delete"
          @click="removeItem(item)"
        />
      </template>
    </a-list-item>
  </a-list>
</template>

<script setup>
import { onMounted, ref, watch } from "vue"

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },

  label: {
    type: String,
    default: ""
  }
})
const emit = defineEmits(["change"])

const items = ref([])

const removeItem = (item) => {
  emit("change", [...items.value.filter((val) => val !== item)])
}

watch(
  () => props.data,
  (newData) => {
    items.value.length = 0
    items.value.push(...newData)
  },
  { deep: true }
)

onMounted(() => {
  items.value.length = 0
  items.value.push(...props.data)
})
</script>
