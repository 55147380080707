<template>
  <div>
    <div class="wrapper">
      <div
        v-if="fetching"
        class="wrapper__loader"
      >
        <a-spin size="large" />
      </div>

      <a-form-model
        v-else
        ref="formRef"
        :model="form"
        layout="vertical"
        hide-required-mark
      >
        <a-row :gutter="24">
          <a-col :span="15">
            <a-form-model-item :label="$t('nameForPublish')">
              <a-input
                :value="product.public_title"
                disabled
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item :label="$t('naming')">
              <a-input
                :value="product.name"
                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="15">
            <a-form-model-item
              :label="`${$t('translationTitleForPublish')} (${locale})`"
              :help="!hasDefaultPublicTitle ? $t('setValueInMainLocale') : ''"
              prop="public_title"
            >
              <a-input
                v-model="form.public_title"
                :disabled="!hasDefaultPublicTitle"
                @change="$emit('formChanged')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item
              :label="`${$t('nameTranslation')} (${locale})`"
              :help="!hasDefaultName ? $t('setValueInMainLocale') : ''"
            >
              <a-input
                v-model="form.name"
                :disabled="!hasDefaultName"
                @change="$emit('formChanged')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item :label="$t('shortProductDescription')">
              <a-textarea
                :rows="5"
                :value="product.short_description"
                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item
              :label="$t('shortDescriptionTranslation')"
              :help="!hasDefaultShortDescription ? $t('setValueInMainLocale') : ''"
            >
              <a-textarea
                :rows="5"
                :disabled="!hasDefaultShortDescription"
                v-model="form.short_description"
                @change="$emit('formChanged')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item
              :label="$t('description')"
              prop="description"
            >
              <a-textarea
                :rows="15"
                :value="product.description"
                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item
              :label="`${$t('descriptionTranslate')} (${locale})`"
              :help="!hasDefaultDescription ? $t('setValueInMainLocale') : ''"
            >
              <EditorHtml
                v-model="form.description"
                :useCustomImageHandler="true"
                :replaceVideoIframe="true"
                :disabled="!hasDefaultDescription"
                id="productEditDescription"
                @change="handleEditorHtml"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue"

import EditorHtml from "@/components/Editor/EditorHtml"

const props = defineProps({
  mpcCode: {
    type: String,
    required: true
  },
  product: {
    type: Object,
    default: () => ({})
  },
  productLocalized: {
    type: Object,
    default: () => ({})
  },
  fetching: {
    type: Boolean,
    default: false
  },
  locale: {
    type: String,
    required: true
  }
})
const emit = defineEmits(["formChanged"])

const formRef = ref()
const form = reactive({
  name: "",
  public_title: "",
  short_description: "",
  description: ""
})

const hasDefaultName = computed(() => Boolean(props.product && props.product.name))
const hasDefaultPublicTitle = computed(() => Boolean(props.product && props.product.public_title))
const hasDefaultShortDescription = computed(() =>
  Boolean(props.product && props.product.short_description)
)
const hasDefaultDescription = computed(() => Boolean(props.product && props.product.description))

const handleEditorHtml = (value) => {
  if (
    props.productLocalized.description !== undefined &&
    props.productLocalized.description !== value
  ) {
    emit("formChanged")
  }
}
const getLocalizedFormValues = () => form

const isEmpty = (value) => {
  if (value == null) return true
  if (typeof value === "object") return !Object.keys(value).length
  if (typeof value === "string" || Array.isArray(value)) return !value.length
  return false
}

watch(
  () => props.productLocalized,
  (value) => {
    if (!isEmpty(value)) {
      // Set form values if product data not empty
      const description = value.description || ""

      form.name = value.name
      form.public_title = value.public_title
      form.short_description = value.short_description
      form.description = description.trim() || props.product.description
    } else {
      formRef.value.resetFields()
    }
  }
)

defineExpose({
  getLocalizedFormValues
})
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 80px;

  &__loader {
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.product-type-name {
  padding-top: 5px;
}

.use-public-title-switch {
  margin-top: 5px;
}

.use-public-title-input {
  margin-left: 16px;
  float: right;
  width: calc(100% - 60px);
}

.product-full-name {
  margin-bottom: 20px;
}

.gtin-add-button {
  cursor: pointer;
}
</style>
