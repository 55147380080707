<template>
  <div style="height: 800px; position: relative">
    <SyntheticMessenger
      ref="syntheticMessengerRef"
      @onSellerBillingClick="onSellerBillingClick"
      @onSellerSettingsClick="onSellerSettingsClick"
      @error="handleError"
      @adminError="handleAdminError"
      @onUnreadChange="onUnreadChange"
      @onOpenGallery="onOpenGallery"
    />

    <a-drawer
      :visible="showBillingModal"
      :title="`${$t('shop')} ${sellerBilling?.name || ''}`"
      :width="1400"
      destroyOnClose
      @close="showBillingModal = false"
    >
      <SellerBillingModal
        :sellerUuid="sellerBilling?.uuid"
        @close="showBillingModal = false"
      />
    </a-drawer>

    <EditSellerModal
      :isOpened="Boolean(sellerEditingInfo?.slug)"
      :defaultSellerSlug="sellerEditingInfo?.slug"
      :defaultSellerName="sellerEditingInfo?.username"
      @close="onCloseSellerEditModal"
    />
  </div>
</template>

<script>
import { inject, onBeforeUnmount, onMounted, ref } from "vue"
import i18n from "@/i18n"
import { mapActions } from "vuex"
import { notification } from "ant-design-vue"

import SyntheticMessenger from "@synthetic_ua/messenger-front/legacy"
import SellerBillingModal from "@/modules/MPAdmin/pages/Billing/SellerBillingModal/index.vue"
import EditSellerModal from "@/modules/MPAdmin/pages/Sellers/components/EditSellerModal"

import { fetchBillingSellerById } from "../MPAdmin/services/billingService"
import { getModerationToken } from "./messengerService"

import {
  IMAGES_CDN,
  MESSENGER_URL_XHR,
  MESSENGER_DOMAIN,
  MARKETPLACE,
  API_BASE_URL
} from "@/constants/common"
import getImagePath from "@/utils/getImagePath"
import notifyResponseError from "@/utils/notifyResponseError"

const base_url = `${MESSENGER_URL_XHR}/api/v2`

export default {
  components: {
    SyntheticMessenger,
    SellerBillingModal,
    EditSellerModal
  },

  setup() {
    const handleOpenGallery = inject("handleOpenGallery", () => {})

    const syntheticMessengerRef = ref()

    const messengerToken = ref()
    const showBillingModal = ref(false)
    const sellerBilling = ref()
    const isBillingFetching = ref(false)
    const sellerEditingInfo = ref()
    const reconnectTimeout = ref()

    const getFullImagePath = ({ filename, size = "full" }) =>
      `${IMAGES_CDN}/media/assets/images/${getImagePath(filename, size)}`

    const connectMessenger = async () => {
      if (reconnectTimeout.value) {
        clearTimeout(reconnectTimeout.value)
        reconnectTimeout.value = undefined
      }

      try {
        await syntheticMessengerRef.value?.install({
          sso_access_token: messengerToken.value,
          role: "moderator",
          language: "uk",
          base_url,
          domain: MESSENGER_DOMAIN,
          cdn_path_function: getFullImagePath,
          marketplace_url: MARKETPLACE
        })

        setupAdminPanel()

        reconnectTimeout.value = undefined
      } catch (error) {
        reconnectTimeout.value = setTimeout(setupMessenger, 2500)
      }
    }

    const setupAdminPanel = () => {
      const storedToken = JSON.parse(localStorage.getItem("access_token_mpa") || "{}")

      syntheticMessengerRef.value.installAdmin({
        mpa_url: API_BASE_URL,
        mpa_access_token: storedToken.token
      })
    }

    const handleError = async (error) => {
      if (error.status !== 401) return

      if (reconnectTimeout.value) return
      reconnectTimeout.value = setTimeout(setupMessenger, 2500)
    }

    const setupMessenger = async () => {
      if (reconnectTimeout.value) {
        clearTimeout(reconnectTimeout.value)
        reconnectTimeout.value = undefined
      }

      try {
        const { data } = await getModerationToken()
        messengerToken.value = data.token

        connectMessenger()
      } catch (error) {
        notifyResponseError({ error })
      }
    }

    const onSellerBillingClick = async (user) => {
      try {
        isBillingFetching.value = true

        const { data } = await fetchBillingSellerById(user.uuid)
        sellerBilling.value = data

        showBillingModal.value = true
      } catch (err) {
        const errors = err.response.data.detail || []
        const errorMessage = errors[0] || i18n.t("openBillingError")

        notification.error({ message: errorMessage })
      } finally {
        isBillingFetching.value = false
      }
    }

    const onSellerSettingsClick = (user) => {
      sellerEditingInfo.value = user
    }

    const onCloseSellerEditModal = () => {
      sellerEditingInfo.value = undefined
    }

    const onOpenGallery = (payload) => {
      handleOpenGallery({
        images: payload.media.filter(({ type }) => type === "image").map((media) => media.file)
      })
    }

    onMounted(setupMessenger)
    onBeforeUnmount(() => {
      syntheticMessengerRef.value?.uninstall?.()
    })

    return {
      syntheticMessengerRef,
      showBillingModal,
      sellerEditingInfo,
      sellerBilling,
      handleError,
      onSellerBillingClick,
      onSellerSettingsClick,
      onCloseSellerEditModal,
      onOpenGallery,
      setupMessenger,
      setupAdminPanel
    }
  },

  methods: {
    ...mapActions("Messenger", ["setUnreadMessages"]),
    ...mapActions("Auth", ["getAuthTokenFromstorage"]),

    onUnreadChange(unread) {
      this.setUnreadMessages(unread)
    },

    handleAdminError(error) {
      if (error.code !== 401) return

      const token = this.getAuthTokenFromstorage("access_token_mpa")
      if (!token) return this.setupMessenger()

      this.setupAdminPanel()
    }
  }
}
</script>

<style scoped>
@import "@synthetic_ua/messenger-front/styles";
</style>
