export default {
  openBillingError: "Не вдалось відкрити білінг продавця",
  lastOption: "Останній варіант",
  messagesModeration: "Модерація повідомлень",
  firstUser: "Ім'я / id користувача 1",
  secondUser: "Ім'я / id користувача 2",
  formatError: "Невалідний формат файлу. Дозволені формати",

  chats: "Чати",
  message: "Повідомлення",
  attachments: "Вкладення",
  today: "Сьогодні",
  yesterday: "Вчора",
  no_chats: "Чати не знайдено"
}
