import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchDeliveryMethods = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/delivery-methods${getFormatedQueryParams(queryParams)}`, {
    signal
  })
}

export const fetchDeliveryMethodsMeta = () => {
  return API_V2.get(`${MPA_PREFIX}/delivery-methods/meta`)
}

export const createDeliveryMethod = (data) => {
  return API_V2.post(`${MPA_PREFIX}/delivery-methods`, data)
}

export const updateDeliveryMethod = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/delivery-methods/${id}`, data)
}
