import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const getTransactions = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/bonuses/transactions${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const getSuperBill = () => {
  return API_V2.get(`${MPA_PREFIX}/bonuses/bills/meta`)
}

export const getBonusRate = () => {
  return API_V2.get(`${MPA_PREFIX}/bonuses/currencies/rates/current`)
}

export const changeBonusRate = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/bonuses/currencies/rates`, payload)
}
export const createBonus = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/bonuses/transactions/super-user-bill`, payload)
}

export const getBonusHistoryRate = ({ queryParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/bonuses/currencies/rates${getFormatedQueryParams(queryParams)}`,
    {
      signal
    }
  )
}

export const transactBonus = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/bonuses/transactions`, payload)
}

export const searchCustomers = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/customers${transformQuery(queryParams)}`, {
    signal
  })
}
