<template>
  <a-drawer
    :width="670"
    :title="getTitle"
    :visible="setLabelVisible"
    @close="handleClose()"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      class="set-label-popup"
    >
      <a-form-model-item
        v-if="!isFacebookLabel"
        prop="label_exists"
        class="set-label-popup__radio"
      >
        <a-radio-group
          :options="options"
          v-model="form.label_exists"
        />
      </a-form-model-item>

      <a-form-model-item
        :prop="labelType"
        :label="`${$t('enterCLabel')}, ${currentLabelServiceName}`"
      >
        <template v-if="isFacebookLabel">
          <a-input
            v-model="form.fb_default_label"
            :placeholder="`${$t('new')} custom label`"
          />
        </template>

        <a-auto-complete
          v-else
          v-model="form.ga_default_label"
          :dataSource="dataSource"
          @change="onChange"
          @blur="checkLabelExist()"
          @select="checkLabelExist()"
        />
      </a-form-model-item>

      <a-form-model-item
        v-show="isFacebookLabel"
        prop="change_fb_pricelist"
        :label="$t('facebookPriceList')"
      >
        <a-radio-group
          :options="changePriceListOptions"
          v-model="form.change_fb_pricelist"
        />
      </a-form-model-item>

      <a-alert
        v-if="isFacebookLabel"
        :message="$t(`facebookPriceListOptions.${form.change_fb_pricelist}`)"
      />
      <div v-else>
        <a-alert
          type="info"
          :message="$t('changeGoogleLabelMessage')"
          showIcon
        />
      </div>

      <div class="drawer-footer">
        <a-button
          :disabled="isSaving"
          @click="handleClose()"
        >
          {{ $t("cancel") }}
        </a-button>

        <a-button
          type="primary"
          :loading="isSaving"
          @click="handleConfirm()"
        >
          {{ $t("apply") }}
        </a-button>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import {
  searchCustomLabel,
  bulkChangeMerchantOffers
} from "@/modules/ADS/services/merchantServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useCancellableRequest from "@/composables/useCancellableRequest"
import useForm from "@/composables/useForm"

const props = defineProps({
  setLabelVisible: {
    type: Boolean,
    default: false
  },
  selectedCsCodes: {
    type: Array,
    default: () => []
  },
  labelType: {
    type: String,
    default: "ga_default_label"
  }
})

const emit = defineEmits(["onChangedLabel", "close"])

const { makeRequest } = useCancellableRequest(searchCustomLabel)

const formRef = ref()
const form = reactive({
  ga_default_label: "",
  fb_default_label: "",
  label_exists: false,
  change_fb_pricelist: "none"
})
const formRules = computed(() => ({
  ga_default_label: [{ max: 50, message: i18n.t("maxLengthError", { length: 50 }) }],
  fb_default_label: [{ max: 50, message: i18n.t("maxLengthError", { length: 50 }) }]
}))
const { isValidForm, validateForm } = useForm(formRef)

const dataSource = ref([])
const isSaving = ref(false)

const changePriceListOptions = computed(() => [
  { label: i18n.t("facebookChangePriceList.none"), value: "none" },
  { label: i18n.t("facebookChangePriceList.add"), value: "add" },
  { label: i18n.t("facebookChangePriceList.remove"), value: "remove" }
])

const options = computed(() => [
  {
    label: `${i18n.t("appoint")} ${i18n.t("existing").toLowerCase()} custom label`,
    value: true
  },
  {
    label: `${i18n.t("create")} ${i18n.t("new").toLowerCase()} custom label`,
    value: false
  }
])

const getTitle = computed(() => {
  return (
    `${i18n.t("appoint")} custom label ` +
    currentLabelServiceName.value +
    `(${props.selectedCsCodes.length} ${i18n.tc("offer", props.selectedCsCodes.length)})`
  )
})

const currentLabelServiceName = computed(() => (isFacebookLabel.value ? "Facebook " : "Google "))

const isFacebookLabel = computed(() => props.labelType === "fb_default_label")

const onChange = async () => {
  try {
    const { data } = await makeRequest({ params: { search: form.ga_default_label } })
    dataSource.value = data.results
  } catch (error) {
    if (error?.message === "canceled") return

    notifyResponseError({ error })
  }
}

const checkLabelExist = () => {
  if (dataSource.value.includes(form.ga_default_label)) {
    form.label_exists = true
    return
  }

  form.label_exists = false
}

const handleConfirm = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isSaving.value = true

    const { ga_default_label, fb_default_label, label_exists, change_fb_pricelist } = form

    const currentCustomLabel = isFacebookLabel.value ? fb_default_label : ga_default_label

    const payload = {
      cs_codes: props.selectedCsCodes,
      [props.labelType]: currentCustomLabel || null
    }

    if (!isFacebookLabel.value) {
      payload.label_exists = label_exists
    } else if (change_fb_pricelist !== "none") {
      payload.is_facebook_ad = change_fb_pricelist === "add" || false
    }

    if (payload.is_facebook_ad && !payload.fb_default_label) {
      notification.error({
        message: i18n.t("facebookPriceListError")
      })
      return
    }

    const { data } = await bulkChangeMerchantOffers(payload)

    notification.success({
      message: `${i18n.t("updated")} ${data.count} custom label`
    })
    emit("onChangedLabel", payload)
    handleClose()
  } catch (e) {
    notifyResponseError(e)
  } finally {
    isSaving.value = false
  }
}

const clearModal = () => {
  if (!formRef.value) return

  form.ga_default_label = ""
  form.fb_default_label = ""
  form.label_exists = false
  form.change_fb_pricelist = "none"
  isSaving.value = false
  dataSource.value = []
}

const handleClose = () => {
  emit("close")
  clearModal()
}
</script>

<style lang="scss" scoped>
.set-label-popup {
  padding-bottom: 50px;

  &__radio {
    margin: 0;
  }
}
</style>
