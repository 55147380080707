import { ref, shallowRef } from "vue"

import { setupUserAuthentication } from "@/modules/UsersManagement/services/usersService"
import notifyResponseError from "@/utils/notifyResponseError"

export default () => {
  const isQrFetching = shallowRef(false)
  const qrImageUrl = ref()

  const getTwoFactorQr = async (payload) => {
    try {
      isQrFetching.value = true

      const { data } = await setupUserAuthentication(payload)

      const blob = new Blob([data], { type: "image/png" })
      qrImageUrl.value = URL.createObjectURL(blob)
    } catch (error) {
      notifyResponseError({ error })
    } finally {
      isQrFetching.value = false
    }
  }

  const resetQrImage = () => {
    qrImageUrl.value = undefined
  }

  return {
    isQrFetching,
    qrImageUrl,
    getTwoFactorQr,
    resetQrImage
  }
}
