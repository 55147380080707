import axios from "axios"
import getCurrentLocale from "./getCurrentLocale.js"
import { API_BASE_URL } from "@/constants/common.js"

export const API_V2 = axios.create({
  baseURL: `${API_BASE_URL}/api/v2`,
  withCredentials: true,
  headers: {
    "Accept-language": getCurrentLocale()
  }
})
