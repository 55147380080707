<template>
  <div>
    <PageHeader
      :title="$t('sellerAccounts')"
      :show-language-select="false"
    >
      <template #filters>
        <a-form-model-item>
          <a-input-search
            v-model="filteredInfo.search"
            :placeholder="$t('search')"
            style="margin-top: 4px"
            allowClear
            @search="handleTableFilterChange()"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </a-form-model-item>

        <a-form-model-item style="width: 150px">
          <a-select
            v-model="filteredInfo.status"
            :placeholder="$t('invoiceStatus')"
            :options="statusOptions"
            style="width: 100%"
            allowClear
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item :help="$t('creatingDate')">
          <a-range-picker
            v-model="filteredInfo.created_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 8px"
            allowClear
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item :help="$t('dateInPdf')">
          <a-range-picker
            v-model="filteredInfo.issued_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 8px"
            allowClear
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item :help="$t('invoiceDateValidUntil')">
          <a-range-picker
            v-model="filteredInfo.expire_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 8px"
            allowClear
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item :help="$t('paymentDate')">
          <a-range-picker
            v-model="filteredInfo.paid_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            style="margin-top: 8px"
            allowClear
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>
      </template>

      <template #actions>
        <a-form-model-item>
          <a-button
            icon="undo"
            @click="handleTableFiltersReset()"
          >
            {{ $t("clearFilters") }}
          </a-button>
        </a-form-model-item>

        <a-form-model-item>
          <a-button
            type="primary"
            icon="plus"
            @click="handleOpenCreateInvoiceModal"
          >
            {{ $t("createInvoice") }}
          </a-button>
        </a-form-model-item>
      </template>
    </PageHeader>

    <div class="table-wrapper">
      <a-table
        :scroll="{ x: true }"
        :columns="columns"
        :dataSource="dataSource"
        :loading="isFetching"
        :pagination="paginationInfo"
        rowKey="uuid"
        @change="onTableChange"
      >
        <template #dateRenderer="value">
          <TableRendererDateTime :date="value" />
        </template>

        <template #payerRenderer="record">
          {{ record.payer }}
          <template v-if="record.payer_factual">
            <a-divider
              style="margin: 4px 0"
              dashed
            />
            {{ record.payer_factual }}
          </template>
        </template>

        <template #invoiceDetails="record">
          <div v-html="getInvoiceDetail(record)" />
        </template>

        <template #statusRenderer="text">
          <StatusRenderer :status="text" />
        </template>

        <template #commentRender="text">
          <div style="margin-right: 24px; max-width: 200px">{{ text }}</div>
        </template>

        <template #actionsRender="record">
          <div style="display: flex; gap: 8px; justify-content: flex-end; align-items: center">
            <a-button
              icon="edit"
              type="dashed"
              shape="circle"
              @click="handleOpenEditInvoiceModal(record)"
            />
            <a-button
              type="dashed"
              @click="handleOpenPdf(record)"
            >
              PDF
            </a-button>
          </div>
        </template>
      </a-table>
    </div>

    <CreateInvoiceModal
      :isOpened="createInvoiceModalIsOpened"
      @close="createInvoiceModalIsOpened = false"
      @onCreated="onTableChange()"
    />

    <EditInvoiceModal
      :isOpened="editInvoiceModalIsOpened"
      :invoiceData="editableInvoiceData"
      @close="handleCloseEditInvoiceModal"
      @onUpdate="onUpdateInvoice"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, shallowRef } from "vue"
import i18n from "@/i18n"
import StatusRenderer from "./components/renderers/StatusRenderer"
import CreateInvoiceModal from "./components/CreateInvoiceModal"
import EditInvoiceModal from "./components/EditInvoiceModal"
import PageHeader from "@/ant-components/PageHeader"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"

import { fetchInvoices } from "../../services/invoicesService.js"

import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useInvoicesColumns from "./useInvoicesColumns.js"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchInvoices,
  requestParams: { changeRouterQuery: true }
})

const { columns } = useInvoicesColumns({ sortedInfo: computed(() => sortedInfo.value) })

const createInvoiceModalIsOpened = shallowRef(false)
const editInvoiceModalIsOpened = shallowRef(false)
const editableInvoiceData = ref()

const statusOptions = computed(() => [
  {
    label: i18n.t("new"),
    value: "NEW"
  },
  {
    label: i18n.t("paid"),
    value: "PAID"
  },
  {
    label: i18n.t("canceledStatus"),
    value: "CANCELED"
  },
  {
    label: i18n.t("deleted"),
    value: "REMOVED"
  }
])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}
const getInvoiceDetail = (record) => {
  const detailArray = [
    record.edrpou,
    record.postal_code,
    record.region_name,
    record.city_name,
    record.street_name,
    record.house_number
      ? `${i18n.t("houseNumber").toLowerCase()}&nbsp;${record.house_number}`
      : null,
    record.cabinet ? `${i18n.t("cabinet").toLowerCase()}&nbsp;${record.cabinet}` : null,
    record.ipn
  ]

  return detailArray.filter((item) => item !== null).join(", ")
}

const handleOpenCreateInvoiceModal = () => {
  createInvoiceModalIsOpened.value = true
}

const handleOpenEditInvoiceModal = (invoiceData) => {
  editInvoiceModalIsOpened.value = true
  editableInvoiceData.value = invoiceData
}

const handleCloseEditInvoiceModal = () => {
  editInvoiceModalIsOpened.value = false
  editableInvoiceData.value = undefined
}

const onUpdateInvoice = (payload) => {
  updateTableDataRecord({ payload, identifier: "uuid" })
}

const handleOpenPdf = (invoice) => {
  window.open(invoice.pdf, "_blank")
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>
