const MPAdminApp = () => import("./MPAdmin") //lazy load

import i18n from "@/i18n.js"

import {
  Categories,
  CategoriesTree,
  EditCategory,
  EditFilterCustomRanges
} from "./pages/Categories/index.js"
import GeneralSettings from "./pages/GeneralSettings"
import Products from "./pages/Products/"
import ModerationPropositions from "./pages/ModerationPropositions"
import Promotions from "./pages/Promotions/"
import SearchKeywords from "./pages/SearchKeywords"
import Orders from "./pages/Orders"
import ProductsReviews from "./pages/ProductsReviews/"
import PageBuilder from "./pages/PageBuilder/"
import ModerationBuyers from "./pages/ModerationBuyers"
import SellerReviews from "./pages/SellerReviews/"
import Sellers from "./pages/Sellers"
import MessengerModeration from "./pages/MessengerModerator/index.js"
import Returns from "./pages/Returns"
import Bonuses from "./pages/Bonuses"
import Tags from "./pages/Tags"
import TagsList from "./pages/Tags/TagsList/"
import EditTag from "./pages/Tags/EditTag/"
import Sync from "./pages/Sync/"
import Billing from "./pages/Billing/"
import Invoices from "./pages/Invoices/"
import LocationList from "./pages/Locations/LocationList/index.js"
import PostOffices from "./pages/PostOffices/index.js"
import {
  EditPromoCampaign,
  PromoCampaigns,
  PromoCampaignsList
} from "./pages/PromoCampaigns/index.js"
import MessengerTags from "./pages/MessengerTags/index.js"

import { PromoCodes, PromoCodesList, PromoCodesEdit } from "./pages/PromoCodes/index.js"

export default {
  path: "/mp-admin",
  meta: {
    permissionType: "application",
    permissionCode: "00MPA",
    code: "00MPA"
  },
  component: MPAdminApp,
  children: [
    {
      path: "categories",
      component: Categories,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X01MPA",
        code: "X01MPA",
        layout: "ant-layout"
      },
      children: [
        {
          path: "",
          name: "categories",
          component: CategoriesTree,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/X01MPA",
            code: "X01MPA",
            layout: "ant-layout",
            title: i18n.t("categories")
          }
        },
        {
          path: "edit/:id",
          name: "EditCategory",
          component: EditCategory,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/X01MPA",
            code: "X01MPA",
            layout: "ant-layout",
            withoutChecking: true,
            title: i18n.t("categoryEditing")
          }
        },
        {
          path: "create",
          name: "CreateCategory",
          component: EditCategory,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/X01MPA",
            code: "X01MPA",
            layout: "ant-layout",
            withoutChecking: true,
            title: i18n.t("categoryCreating")
          }
        },
        {
          path: "edit/:id/filter/:filterId",
          name: "EditFilterCustomRanges",
          component: EditFilterCustomRanges,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/X01MPA",
            code: "X01MPA",
            layout: "ant-layout",
            withoutChecking: true,
            title: i18n.t("editingCustomRangeFilter")
          }
        }
      ]
    },
    {
      path: "general-settings",
      component: GeneralSettings,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X02MPA",
        code: "X02MPA",
        layout: "ant-layout",
        title: i18n.t("generalSettings")
      }
    },
    {
      path: "moderation-products",
      component: Products,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X03MPA",
        code: "X03MPA",
        layout: "ant-layout",
        title: i18n.t("products")
      }
    },
    {
      path: "moderation-propositions",
      component: ModerationPropositions,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X05MPA",
        code: "X05MPA",
        layout: "ant-layout",
        title: i18n.t("offerCount")
      }
    },
    {
      path: "promotions",
      component: Promotions,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X04MPA",
        code: "X04MPA",
        layout: "ant-layout",
        title: i18n.t("advertisingOptions")
      }
    },
    {
      path: "promo-campaigns",
      component: PromoCampaigns,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/PC002",
        code: "PC002",
        layout: "ant-layout",
        title: i18n.t("promoCampaigns")
      },
      children: [
        {
          path: "",
          name: "PromoCampaignsList",
          component: PromoCampaignsList,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/PC002",
            code: "PC002",
            layout: "ant-layout",
            title: i18n.t("promoCampaigns")
          }
        },
        {
          path: "edit/:uuid",
          name: "EditPromoCampaign",
          component: EditPromoCampaign,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/PC002",
            code: "PC002",
            layout: "ant-layout",
            title: i18n.t("promoCampaignEditing")
          }
        }
      ]
    },
    {
      path: "promo-codes",
      component: PromoCodes,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/PCD000",
        code: "PCD000",
        layout: "ant-layout",
        title: i18n.t("promoCampaigns")
      },
      children: [
        {
          path: "",
          name: "PromoCodesList",
          component: PromoCodesList,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/PCD000",
            code: "PCD000",
            layout: "ant-layout",
            title: i18n.t("promoCampaigns")
          }
        },
        {
          path: "edit/:uuid",
          name: "PromoCodesEdit",
          component: PromoCodesEdit,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/PCD000",
            code: "PCD000",
            layout: "ant-layout",
            title: i18n.t("promoCampaignEditing")
          }
        }
      ]
    },
    {
      path: "search-keywords",
      component: SearchKeywords,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/SKD01",
        code: "SKD01",
        layout: "ant-layout",
        title: i18n.t("searchSettings")
      }
    },
    {
      path: "moderation-orders",
      component: Orders,
      name: "Orders",
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X06MPA",
        code: "X06MPA",
        layout: "ant-layout",
        title: i18n.t("orders")
      }
    },
    {
      path: "products-reviews",
      component: ProductsReviews,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X07MPA",
        code: "X07MPA",
        layout: "ant-layout",
        title: i18n.t("productsReviews")
      }
    },
    {
      path: "pages",
      component: PageBuilder,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X10MPA",
        code: "X10MPA",
        layout: "ant-layout",
        title: i18n.t("pageBuilder")
      }
    },
    {
      path: "sellers-reviews",
      component: SellerReviews,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X08MPA",
        code: "X08MPA",
        layout: "ant-layout",
        title: i18n.t("sellersReviews")
      }
    },
    {
      path: "customers",
      name: "Customers",
      component: ModerationBuyers,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/X11MPA",
        code: "X11MPA",
        layout: "ant-layout",
        title: i18n.t("moderationBuyers")
      }
    },
    {
      path: "sellers",
      name: "Sellers",
      component: Sellers,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/00SLS",
        code: "00SLS",
        layout: "ant-layout",
        title: i18n.t("sellerList")
      }
    },
    {
      path: "messenger-moderation",
      component: MessengerModeration,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/MS02",
        code: "MS02",
        title: i18n.t("messengerModeration"),
        layout: "ant-layout"
      }
    },
    {
      path: "returns",
      component: Returns,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/RT01",
        code: "RT01",
        layout: "ant-layout",
        title: i18n.t("ordersReturns")
      }
    },
    {
      path: "bonuses",
      component: Bonuses,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/BS01",
        code: "BS01",
        layout: "ant-layout",
        title: i18n.t("bonusTitle")
      }
    },
    {
      path: "offer-tags",
      component: Tags,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/TAG01",
        code: "TAG01",
        layout: "ant-layout"
      },
      children: [
        {
          path: "",
          component: TagsList,
          name: "TagsList",
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/TAG01",
            code: "TAG01",
            layout: "ant-layout",
            title: i18n.t("tags")
          }
        },
        {
          path: "edit/:uuid",
          component: EditTag,
          props: true,
          meta: {
            permissionType: "component",
            permissionCode: "00MPA/TAG01",
            code: "TAG01",
            withoutChecking: true,
            layout: "ant-layout",
            title: i18n.t("tagEditing")
          }
        }
      ]
    },
    {
      path: "synchronizations",
      component: Sync,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/SYNC01",
        layout: "ant-layout",
        code: "SYNC01",
        title: i18n.t("syncManagment")
      }
    },
    {
      path: "billing",
      component: Billing,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/BL01",
        layout: "ant-layout",
        code: "BL01",
        title: i18n.t("billing")
      }
    },
    {
      path: "invoices",
      component: Invoices,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/INV01",
        code: "INV01",
        layout: "ant-layout",
        title: i18n.t("sellerAccounts")
      }
    },
    {
      path: "locations",
      component: LocationList,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/LOC01",
        code: "LOC01",
        layout: "ant-layout",
        title: i18n.t("locationsSettings")
      }
    },
    {
      path: "post-offices",
      name: "PostOffices",
      component: PostOffices,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/POS01",
        code: "POS01",
        layout: "ant-layout",
        title: i18n.t("postOffices")
      }
    },
    {
      path: "messenger-tags",
      name: "MessengerTags",
      component: MessengerTags,
      meta: {
        permissionType: "component",
        permissionCode: "00MPA/MS04",
        code: "MS04",
        layout: "ant-layout",
        title: i18n.t("messengerTagsTitle")
      }
    }
  ]
}
