import { computed, h } from "vue"
import i18n from "@/i18n"

export default function useBuyersPageColumns() {
  return computed(() => {
    return [
      {
        key: "username",
        title: i18n.t("userName"),
        scopedSlots: {
          customRender: "userName"
        },
        sorter: true,
        fixed: "left",
        width: 450
      },
      {
        key: "avatar_uploaded_at",
        title: i18n.t("photo"),
        scopedSlots: {
          customRender: "avatar"
        },
        sorter: true,
        fixed: "left",
        width: 150
      },
      {
        key: "date_joined",
        dataIndex: "date_joined",
        title: i18n.t("registerDate"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sorter: true,
        width: 110
      },
      {
        key: "last_login",
        dataIndex: "last_login",
        title: i18n.t("lastLogin"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sorter: true,
        width: 110
      },
      {
        key: "contacts",
        title: h("div", ["Email", h("br"), i18n.t("phoneNumber")]),
        scopedSlots: {
          customRender: "contacts"
        },
        width: 275
      },
      {
        key: "orders_count",
        dataIndex: "orders_count",
        title: i18n.t("orderShorted"),
        sorter: true,
        width: 110
      },
      {
        key: "balance_total",
        title: i18n.t("bonusAccount"),
        scopedSlots: {
          customRender: "balance"
        },
        sorter: true,
        width: 300
      },
      {
        key: "product_reviews_count",
        title: i18n.t("reviewsTP"),
        scopedSlots: {
          customRender: "reviews"
        },
        width: 120
      },
      {
        key: "is_blocked",
        dataIndex: "is_blocked",
        title: i18n.t("status"),
        scopedSlots: {
          customRender: "status"
        },
        sorter: false,
        fixed: "right",
        width: 130
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        fixed: "right",
        width: 65
      }
    ]
  })
}
