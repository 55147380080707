import { computed } from "vue"
import i18n from "@/i18n"

import { useStatuses } from "@/composables/useStatuses"

import getFormatedSum from "@/utils/getFormatedSum"

export default () => {
  const { returnProductAims, returnStatuses } = useStatuses()

  const getReturnAim = (value) => {
    return returnProductAims.value.find((item) => item.value === value)?.label || ""
  }
  const getReturnStatus = (status) => {
    return returnStatuses.value.find((item) => item.value === status)?.label || ""
  }

  const columns = computed(() => [
    {
      key: "index",
      dataIndex: "index",
      title: "№",
      width: 30,
      customRender: (_, __, index) => index + 1
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("date"),
      width: 120,
      scopedSlots: { customRender: "dateRenderer" }
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("returnNumber"),
      width: 140,
      scopedSlots: {
        customRender: "codeRenderer"
      }
    },
    {
      key: "aim",
      dataIndex: "aim",
      title: i18n.t("aimReasonReturn"),
      customRender: (data) => getReturnAim(data)
    },
    {
      key: "price_total",
      dataIndex: "price_total",
      title: i18n.t("sum"),
      customRender: (_, record) => {
        return i18n.tc("returnProductCountWithSum", record.goods_quantity, {
          count: record.goods_quantity,
          sum: getFormatedSum(record.price_total, true)
        })
      }
    },
    {
      key: "status",
      dataIndex: "status",
      title: i18n.t("returnStatus"),
      customRender: (data) => getReturnStatus(data)
    }
  ])

  return columns
}
