<template>
  <div style="margin-bottom: 40px">
    <DataTableWrapper
      :columns="columns"
      :data="dataSource"
      :removeItem="removeTableItem"
      :currentCover="currentCover"
      @changeCover="handleChangeCover"
    />

    <a-form-model-item :label="$t('listingSearch')">
      <a-select
        showSearch
        :options="searchResults"
        :placeholder="$t('enterListingNumber')"
        style="width: 100%"
        @search="(e) => onSearchListings(e)"
        @select="selectListing"
      />
    </a-form-model-item>

    <a-spin
      v-if="currentListing !== null"
      :spinning="isFetchingProducts"
    >
      <DataTableWrapper
        :columns="columns"
        :data="searchTable"
        :removeItem="removeSearchTableItem"
        :currentCover="currentCover"
        @changeCover="handleChangeCover"
      />
    </a-spin>

    <div v-if="currentListing != null">
      <a-form-model-item :label="$t('variations')">
        <a-select
          showSearch
          :options="getAllAttrs"
          :placeholder="$t('startPrint')"
          style="width: 100%"
          @select="addAttr"
        />
      </a-form-model-item>

      <a-alert
        type="error"
        v-if="!selectedItemsContainCover"
        :message="$t('chooseProductCover')"
        showIcon
      />
      <a-alert
        type="error"
        class="variation-error"
        v-if="!selectedAttrs.length"
        :message="$t('chooseCharacteristic')"
        showIcon
      />
      <a-alert
        type="error"
        class="variation-error"
        v-for="error in getAttrsErrors"
        :key="error"
        :message="error"
        showIcon
      />
    </div>

    <div v-show="selectedAttrs.length">
      <VariationsList
        :label="$t('chosenCharForVar')"
        :data="selectedAttrs"
        @change="changeSelectedAttrs"
      />
    </div>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :disabled="isDisabled"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { shallowRef, ref, computed, onMounted } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import DataTableWrapper from "./components/DataTableWrapper.vue"
import VariationsList from "./components/VariationsList.vue"

import {
  searchListings,
  getProductsByListing,
  updateListing
} from "@/modules/MPAdmin/services/productsService.js"

import useListingColumns from "./useListingColumns.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  data: {
    type: Array,
    default: () => ({})
  },

  listing: {
    type: Number,
    default: null
  }
})
const emit = defineEmits(["close"])

const columns = useListingColumns()

const dataSource = ref([])

const searchTable = ref([])

const isFetching = shallowRef(false)
const isFetchingProducts = shallowRef(false)

const currentListing = ref(null)
const selectedAttrs = ref([])
const searchResults = ref([])
const currentCover = ref(null)

const removeSearchTableItem = (item) => {
  searchTable.value = searchTable.value.filter((j) => j.id !== item.id)
}

const changeSelectedAttrs = (value) => {
  selectedAttrs.value = value
}

const handleChangeCover = (id) => {
  currentCover.value = id
}

const addAttr = (value) => {
  if (selectedAttrs.value.some((attr) => attr.name === value)) {
    notification.info({ message: i18n.t("variationIsAdded") })
    return
  }

  let newAttr = {}

  dataSource.value.forEach(({ attrs }) => {
    newAttr = attrs.find((attribute) => attribute.name.ru === value)
  })
  if (newAttr) {
    changeSelectedAttrs([...selectedAttrs.value, { ...newAttr, name: newAttr.name.ru }])
  } else {
    notification.error({ message: i18n.t("a") })
  }
}

const selectListing = async (listing) => {
  if (listing && typeof listing === "number") {
    isFetchingProducts.value = true

    const { data } = await getProductsByListing(listing)

    currentListing.value = listing
    searchTable.value = data.results
    searchTable.value.count = data.count

    isFetchingProducts.value = false
  }
}

const removeTableItem = (item) => {
  dataSource.value = dataSource.value.filter((j) => j.id !== item.id)
}

const onSave = async () => {
  isFetching.value = true

  const items = [
    ...new Set([...searchTable.value.map((j) => j.id), ...dataSource.value.map((j) => j.id)])
  ]

  const data = {
    products: items,
    variations: [...new Set(selectedAttrs.value.map((item) => item.name))],
    cover_product: currentCover.value
  }

  try {
    await updateListing(data, currentListing.value)

    notification.success({ message: i18n.t("updated") })

    emit("close")
  } catch (error) {
    let errors = {}
    error?.response?.data?.detail.forEach((item) => (errors = { ...errors, ...item }))

    notifyResponseError({
      error: { response: { data: { details: errors } } }
    })
  } finally {
    isFetching.value = false
  }
}

const setTableData = (data) => {
  dataSource.value = data
}

const onSearchListings = async (value) => {
  if (value) {
    const { data } = await searchListings(`${value}`)

    searchResults.value = data.results.map(({ id }) => ({ value: id, label: id }))
  } else {
    searchResults.value = []
  }
}

const isDisabled = computed(() => {
  return Boolean(
    currentListing.value == null ||
      isFetchingProducts.value ||
      selectedAttrs.value.length == 0 ||
      !selectedItemsContainCover.value ||
      getAttrsErrors.value.length > 0
  )
})

const getAttrsErrors = computed(() => {
  const errors = []

  selectedAttrs.value.forEach((attr) => {
    const { name: attrName } = attr

    const notContainAttrItems = []

    notContainAttrItems.push(
      ...searchTable.value.filter((item) => {
        return item.attrs.every((attr) => {
          return attr.name.ru !== attrName
        })
      })
    )

    notContainAttrItems.push(
      ...dataSource.value.filter((item) => {
        return item.attrs.every((attr) => {
          return attr.name.ru !== attrName
        })
      })
    )

    if (notContainAttrItems.length) {
      let productsPCodes = notContainAttrItems.map((item) => item.p_code).join(", ")
      let message = `${i18n.t("inNextProducts", [productsPCodes])} «${attrName}»`
      errors.push(message)
    }
  })

  return errors
})

const getAllAttrs = computed(() => {
  let attrs = []

  dataSource.value.forEach((item) => {
    if (item.attrs && item.attrs.length) {
      item.attrs.forEach((attr) => {
        const key = attr.name.ru

        attrs = [...attrs, { value: key, label: key }]
      })
    }
  })

  attrs = attrs.filter(
    (item, index) => attrs.findIndex((attr) => attr.value === item.value) === index
  )

  return attrs
})

const selectedItemsContainCover = computed(() => {
  let containCover = false

  for (let i = 0; i < searchTable.value.length; i++) {
    if (searchTable.value[i].id === currentCover.value) {
      containCover = true
      break
    }
  }

  if (containCover) return containCover

  for (let i = 0; i < dataSource.value.length; i++) {
    if (dataSource.value[i].id === currentCover.value) {
      containCover = true
      break
    }
  }

  return containCover
})

onMounted(() => {
  setTableData(props.data)

  if (props.listing && typeof props.listing === "number") {
    selectListing(props.listing)
    currentCover.value = props.data[0].listing.cover_product
    selectedAttrs.value = props.data[0].listing.variations.map((item) => ({ name: item }))
  }
})
</script>

<style lang="scss" scoped>
.variation-error {
  margin-top: 8px;
}
</style>
