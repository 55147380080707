<template>
  <div
    class="message__item"
    :class="{ 'message__item--own': checkSelf }"
  >
    <a-tooltip
      class="message__item__user"
      :title="messageTitle"
      @click="handleSearchUser()"
    >
      <div class="message__item__user__logo">
        <ModeratorAvatar
          v-if="props.message.author?.logo === 'moderator'"
          class="msg-avatar"
        />
        <AvatarPlaceholder
          v-else-if="props.message.author?.logo === 'placeholder'"
          :gender="props.message.author?.gender"
          class="msg-avatar"
        />
        <img
          v-else-if="props.message.author?.logo"
          class="msg-avatar"
          :src="messageLogo"
        />
      </div>
    </a-tooltip>

    <NotificationMessage
      v-if="props.message.type === 'NOTIFICATION'"
      :message="props.message"
      :checkIsCurrentUserMessage="() => checkSelf"
    />

    <SimpleMessage
      v-else
      :message="props.message"
      :checkIsCurrentUserMessage="() => checkSelf"
      :index="props.index"
      useAsWrapper
      :class="{ 'message__item--deleted': props.message.is_removed }"
      @openGallery="handleOpenGallery"
    >
      <template
        #editedMenu
        v-if="props.message.is_edited || props.message.is_removed"
      >
        <div
          v-if="props.message.is_removed"
          style="font-size: 12px"
        >
          {{ $t("deleted") }}
        </div>
        <CommonButton
          v-if="props.message.is_edited"
          type="primary"
          size="small"
          @click="handleShowMessageHistory(message)"
        >
          {{ $t("changeHistory") }}
        </CommonButton>
      </template>
    </SimpleMessage>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { message } from "ant-design-vue"

import {
  AvatarPlaceholder,
  CommonButton,
  ModeratorAvatar,
  NotificationMessage,
  SimpleMessage
} from "@synthetic_ua/messenger-front/legacy"

import { IMAGES_CDN } from "@/constants/common"
import router from "@/router"
import getImagePath from "@/utils/getImagePath"

const props = defineProps({
  message: {
    type: Object,
    default: () => ({})
  },
  lastMessage: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(["openGallery", "showHistory"])

const messageLogo = computed(() => {
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(props.message.author?.logo, "100x100")}`
})

const messageTitle = computed(() => {
  if (props.message.author?.role === "moderator") return "Synthetic"
  return (
    props.message.author?.username ||
    props.message.author?.first_name ||
    props.message.author?.mobile_phone
  )
})

const handleShowMessageHistory = (message) => emit("showHistory")
const handleOpenGallery = (message) => emit("openGallery", message)

const checkSelf = computed(() => {
  return props.message.author?.uuid === props.lastMessage.author?.uuid
})

const handleSearchUser = () => {
  if (props.message.author.role === "moderator") return

  const path = router.resolve({
    name: props.message.author.role === "customer" ? "Customers" : "Sellers",
    query: { search: props.message.author.username || props.message.author.first_name }
  })
  window.open(path.href, "_blank")
}
</script>

<style lang="scss" scoped>
.message__item {
  display: flex;
  gap: 8px;
  align-items: flex-end;

  &__user {
    cursor: pointer;

    &__logo {
      width: 50px;
      height: 50px;
    }
  }

  &--own {
    flex-direction: row-reverse;
  }

  &--deleted {
    border: 2px dashed red;
  }
}

.avatar {
  height: 50px;
  width: 50px;
}
</style>
