var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-form-model',{ref:"formRef",staticClass:"seo-settings",attrs:{"model":_setup.form}},[_vm._l((_vm.languages),function(lang){return _c('a-form-model-item',{key:`title-${lang.value}`,scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title, "+_vm._s(lang.label.toUpperCase())+" ("),_c('span',{class:{
          'red-text':
            _setup.form.seo_title[lang.value].length < 30 || _setup.form.seo_title[lang.value].length > 65
        }},[_vm._v(_vm._s(_setup.form.seo_title[lang.value].length))]),_vm._v("/"+_vm._s(_setup.titleMaxLength.join("-"))+") ")]},proxy:true}],null,true)},[_c('a-input',{model:{value:(_setup.form.seo_title[lang.value]),callback:function ($$v) {_vm.$set(_setup.form.seo_title, lang.value, $$v)},expression:"form.seo_title[lang.value]"}})],1)}),_vm._l((_vm.languages),function(lang){return _c('a-form-model-item',{key:`description-${lang.value}`,scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description, "+_vm._s(lang.label.toUpperCase())+" ("),_c('span',{class:{
          'red-text':
            _setup.form.seo_description[lang.value].length < 120 ||
            _setup.form.seo_description[lang.value].length > 320
        }},[_vm._v(_vm._s(_setup.form.seo_description[lang.value].length))]),_vm._v("/"+_vm._s(_setup.descriptionMaxLength.join("-"))+") ")]},proxy:true}],null,true)},[_c('a-textarea',{attrs:{"autoSize":{ minRows: 5, maxRows: 5 }},model:{value:(_setup.form.seo_description[lang.value]),callback:function ($$v) {_vm.$set(_setup.form.seo_description, lang.value, $$v)},expression:"form.seo_description[lang.value]"}})],1)}),_vm._l((_vm.languages),function(lang){return _c('a-form-model-item',{key:`keyword-${lang.value}`,scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Keywords, "+_vm._s(lang.label.toUpperCase())+" ("),_c('span',{class:{
          'red-text':
            !_setup.form.seo_keywords[lang.value].length || _setup.form.seo_keywords[lang.value].length > 200
        }},[_vm._v(_vm._s(_setup.form.seo_keywords[lang.value].length))]),_vm._v("/"+_vm._s(_setup.keywordsMaxLength)+") ")]},proxy:true}],null,true)},[_c('a-textarea',{attrs:{"autoSize":{ minRows: 5, maxRows: 5 }},model:{value:(_setup.form.seo_keywords[lang.value]),callback:function ($$v) {_vm.$set(_setup.form.seo_keywords, lang.value, $$v)},expression:"form.seo_keywords[lang.value]"}})],1)}),_c('a-collapse',{staticStyle:{"width":"100%","background-color":"inherit"},attrs:{"accordion":"","bordered":false}},_vm._l((_vm.languages),function(lang){return _c('a-collapse-panel',{key:`text-${lang.value}`,staticStyle:{"border":"none"},attrs:{"forceRender":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("SEO-text, "+_vm._s(lang.label.toUpperCase()))]},proxy:true}],null,true)},[_c('a-form-model-item',[_c(_setup.EditorHtml,{attrs:{"id":`editor-${lang.value}`,"headers":[false, 4, 5, 6]},model:{value:(_setup.form.seo_text[lang.value]),callback:function ($$v) {_vm.$set(_setup.form.seo_text, lang.value, $$v)},expression:"form.seo_text[lang.value]"}})],1)],1)}),1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }