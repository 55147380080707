<template>
  <div class="edit-product-drawer">
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="false"
      rowKey="id"
      :scroll="{ y: 400 }"
    >
      <template #photoRenderer="images">
        <TableRendererPhotoGallery
          v-if="images?.length"
          :images="images"
        />
      </template>
    </a-table>

    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      class="edit-product-drawer__form"
    >
      <a-form-model-item
        :label="$t('label')"
        prop="label"
      >
        <a-select
          v-model="form.label"
          :placeholder="$t('notSet')"
          allowClear
        >
          <a-select-option
            v-for="label in labels"
            :key="+label.id"
            :value="+label.id"
          >
            {{ label.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        :label="$t('category')"
        prop="category"
      >
        <a-tree-select
          v-model="form.category"
          :treeData="categoriesTree"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          treeNodeFilterProp="title"
          :placeholder="$t('notSet')"
          showSearch
          allowClear
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('moderationStatus')"
        prop="moderation_status"
      >
        <a-select
          v-model="form.moderation_status"
          :options="moderationStatuses"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('moderComment')"
        prop="moderation_comment"
      >
        <a-textarea
          v-model="form.moderation_comment"
          :autoSize="{ minRows: 4, maxRows: 6 }"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("save") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n.js"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { updateProductsBulk } from "@/modules/MPAdmin/services/productsService.js"

import useEditProductColumns from "./useEditProductColumns.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useForm from "@/composables/useForm.js"

const props = defineProps({
  dataSource: {
    type: Array,
    required: true
  },
  moderationStatuses: {
    type: Array,
    required: true
  },
  labels: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(["close"])

const categoriesTree = inject("categoriesTree")

const columns = useEditProductColumns()

const formRef = ref()
const form = reactive({
  category: props.dataSource[0]?.category?.uuid || undefined,
  label: props.dataSource[0]?.label?.id || undefined,
  moderation_status: props.dataSource[0]?.moderation_status || undefined,
  moderation_comment: props.dataSource[0]?.moderation_comment || undefined
})
const formRules = computed(() => ({
  moderation_comment: [{ max: 1000, message: i18n.t("maxLengthError", { length: 1000 }) }]
}))
const { isValidForm, validateForm } = useForm(formRef)

const isFetching = shallowRef(false)
const onSave = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    const payload = props.dataSource.map((item) => {
      return {
        ...form,
        id: item.id,
        label: form.label || null,
        moderation_comment: form.moderation_comment || undefined
      }
    })

    const { data } = await updateProductsBulk(payload)

    if (!data.meta.errors.length) {
      notification.success({ message: i18n.t("updated") })
      onClose()
    } else {
      notifyResponseError({ error: { response: { data: data.meta.errors } } })
    }
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const onClose = () => emit("close")
</script>

<style lang="scss" scoped>
.edit-product-drawer {
  padding-bottom: 50px;

  &__form > * {
    width: 300px;
  }

  &__form > :last-child {
    width: 100%;
  }
}
</style>
