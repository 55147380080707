<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    :colon="false"
    style="margin-bottom: 50px"
  >
    <a-form-model-item prop="name.uk">
      <template #label>
        {{ $t("name") }}, UA ({{ form.name.uk.length }}/{{ nameMaxLength }})
      </template>

      <a-input v-model="form.name.uk" />
    </a-form-model-item>

    <a-form-model-item prop="name.en">
      <template #label>
        {{ $t("name") }}, EN ({{ form.name.en.length }}/{{ nameMaxLength }})
      </template>

      <a-input v-model="form.name.en" />
    </a-form-model-item>

    <a-form-model-item prop="name.ru">
      <template #label>
        {{ $t("name") }}, RU ({{ form.name.ru.length }}/{{ nameMaxLength }})
      </template>

      <a-input v-model="form.name.ru" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('outputSortOrder')"
      prop="ordering"
      style="margin: 0"
    >
      <a-input-number
        v-model="form.ordering"
        :min="0"
        :max="999"
        :precision="0"
      />
    </a-form-model-item>

    <a-form-model-item prop="description">
      <template #label>
        {{ $t("description") }} ({{ form.description.length }}/{{ descriptionMaxLength }})
      </template>
      <a-textarea v-model="form.description" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('apiIntegration')"
      prop="api_integration"
      style="margin: 0"
    >
      <a-select
        v-model="form.api_integration"
        :placeholder="$t('no')"
        style="width: 120px"
      >
        <a-select-option :value="undefined">
          {{ $t("no") }}
        </a-select-option>

        <a-select-option
          v-for="apiIntegration in PAYMENTS_API_INTEGRATION"
          :key="apiIntegration"
          :value="apiIntegration"
        >
          {{ apiIntegration }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('paymentMethodActivity')"
      prop="is_active"
      style="margin: 0"
    >
      <a-switch
        v-model="form.is_active"
        checkedChildren="ON"
        unCheckedChildren="OFF"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import { PAYMENTS_API_INTEGRATION } from "@/constants/common.js"
import {
  createPaymentMethod,
  updatePaymentMethod
} from "@/modules/MPAdmin/services/generalSettingService/paymentMethodsService.js"

import notifyResponseError from "@/utils/notifyResponseError.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["createMethod", "updateMethod", "close"])

const nameMaxLength = 200
const descriptionMaxLength = 1000

const formRef = ref()
const form = reactive({
  name: {
    uk: "",
    en: "",
    ru: "",
    ...props.prototype.name
  },
  ordering: props.prototype.ordering || 0,
  description: props.prototype.description || "",
  api_integration: props.prototype.api_integration || undefined,
  is_active: Boolean(props.prototype.is_active)
})
const formRules = computed(() => ({
  name: {
    uk: nameRules.value,
    en: nameRules.value,
    ru: nameRules.value
  },
  description: descriptionRules.value
}))
const nameRules = computed(() => [
  requiredRule.value,
  { max: 200, message: i18n.t("maxLengthError", { length: 200 }) }
])
const descriptionRules = computed(() => [
  { max: 1000, message: i18n.t("maxLengthError", { length: 1000 }) }
])
const { isValidForm, validateForm } = useForm(formRef)

const isFetching = shallowRef(false)
const onSave = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    if (props.prototype.id) {
      const { data } = await updatePaymentMethod(
        {
          ...form,
          api_integration: form?.api_integration || null
        },
        props.prototype.id
      )
      emit("updateMethod", data)
    } else {
      await createPaymentMethod({
        ...form,
        api_integration: form?.api_integration || null
      })
      emit("createMethod")
    }

    notification.success({
      message: i18n.t(props.prototype?.id ? "updated" : "created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>
