export default {
  synthetic: "Synthetic Admin",
  authorization: "Авторизація",
  signIn: "Увійти",
  login: "Логін",
  userName: "Ім'я користувача",
  password: "Пароль",
  passwordConfirm: "Підтвердіть пароль",
  loginError: "Помилка входу, перевірте ваші дані та спробуйте ще раз пізніше",
  forgotPass: "Забули пароль?",
  restorePass: "Відновити",
  signOut: "Вийти",
  enterTwoFaCode: "Введіть код двофакторної аутентифікації",
  twoFaCodeOptional: "Код двофакторної аутентифікації (опціонально)",
  qrModalTitle: "Відскануйте qr код в додатку",
  invalidCodeInput: "Невірний код, спробуйте ще раз",
  invalidCodeFormat: "Неправильний формат коду"
}
