<template>
  <div class="edit-role-modal">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
    >
      <a-form-model-item
        :label="$t('name')"
        prop="name"
      >
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('description')"
        prop="description"
      >
        <a-input v-model="form.description" />
      </a-form-model-item>
    </a-form-model>

    <AppPermissions
      v-if="!isPending"
      :permissions="rolePermissions"
      :roleId="props.role.id"
      @change="changePermissions"
    />

    <div class="drawer-footer">
      <a-tooltip>
        <template #title>
          {{ $t("roleDeleteHint") }}. {{ $t("active") }}: {{ activePermissionsCount }}
        </template>
        <a-button
          type="danger"
          ghost
          :disabled="isFetching || Boolean(activePermissionsCount)"
          :loading="isDeleting"
          @click="handleShowDeleteWarning()"
        >
          {{ $t("deleteRole") }}
        </a-button>
      </a-tooltip>

      <a-button
        :disabled="isFetching || isDeleting"
        @click="onClose()"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        :loading="isFetching"
        type="primary"
        @click="onSubmit()"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, onMounted, shallowRef } from "vue"
import { Modal, notification } from "ant-design-vue"
import i18n from "@/i18n.js"

import AppPermissions from "./AppPermissions.vue"

import {
  updateRole,
  fetchRole,
  fetchRolePermissions,
  deleteRole
} from "@/modules/UsersManagement/services/rolesService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const props = defineProps({
  role: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(["onUpdate", "close"])

const formRef = ref()
const form = reactive({
  name: undefined,
  description: undefined
})
const formRules = computed(() => ({
  name: [requiredRule.value],
  description: [requiredRule.value]
}))
const { isValidForm, validateForm } = useForm(formRef)

// PERMISSIONS
const rolePermissions = ref([])
const changePermissions = (permissions) => {
  rolePermissions.value.length = 0
  rolePermissions.value.push(...permissions)
}
const activePermissionsCount = computed(() => rolePermissions.value.length)

// FETCH
const getRolePermissions = async () => {
  const { data } = await fetchRolePermissions({
    id: props.role.id,
    queryParams: {
      pageSize: 100,
      page: Math.floor(rolePermissions.value.length / 100) + 1
    }
  })

  changePermissions([...rolePermissions.value, ...data.results])
  if (data.count <= rolePermissions.value.length) return Promise.resolve(rolePermissions.value)
  return getRolePermissions()
}
const isPending = shallowRef(true)
const getRole = async () => {
  try {
    changePermissions([])
    const roleResponse = await fetchRole({ id: props.role.id })
    form.name = roleResponse.data.name
    form.description = roleResponse.data.description

    await getRolePermissions()
  } catch (error) {
    notifyResponseError({ error })
    onClose()
  } finally {
    isPending.value = false
  }
}

// DELETE
const isDeleting = shallowRef(false)
const handleShowDeleteWarning = () => {
  Modal.confirm({
    title: i18n.t("deletionWarningMessage"),
    cancelText: i18n.t("cancel"),
    okText: i18n.t("delete"),
    okType: "danger",
    onOk: handleDeleteRole
  })
}
const handleDeleteRole = async () => {
  try {
    isDeleting.value = true
    await deleteRole(props.role.id)

    notification.success({ message: i18n.t("deleted") })
    emit("onUpdate")
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isDeleting.value = false
  }
}

// SUBMIT
const isFetching = shallowRef(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    const { data } = await updateRole({
      id: props.role.id,
      payload: {
        name: form.name,
        description: form.description
      }
    })

    notification.success({ message: i18n.t("updated") })
    emit("onUpdate", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const onClose = () => emit("close")

onMounted(getRole)
</script>

<style scoped lang="scss">
.edit-role-modal {
  display: flex;
  flex-flow: column nowrap;

  padding-bottom: 50px;
}

.drawer-footer > :first-child {
  margin-right: auto;
}
</style>
