<template>
  <div class="edit-promo-codes">
    <a-page-header
      :title="pageTitle"
      class="edit-promo-codes__header"
      @back="handleBack()"
    >
      <template #extra>
        <a-button
          :disabled="!isFormChanged || isFetching"
          @click="handleCancel"
        >
          {{ $t("cancel") }}
        </a-button>
        <a-button
          type="primary"
          :disabled="!isFormChanged"
          :loading="isFetching"
          @click="handleSave"
        >
          {{ $t("save") }}
        </a-button>
      </template>
    </a-page-header>

    <a-spin :spinning="isFetching">
      <a-form-model
        ref="formRef"
        :model="form"
        :rules="formRules"
        class="edit-promo-codes__form"
      >
        <a-form-model-item
          :label="$t('technicalName')"
          prop="name"
        >
          <a-input
            v-model="form.name"
            @change="isFormChanged = true"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('promoCodesCode')"
          prop="code"
        >
          <a-input
            v-model="form.code"
            @change="isFormChanged = true"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('promoCodesPercent')"
          prop="discount_percent"
        >
          <a-input-number
            v-model="form.discount_percent"
            :min="1"
            :max="100"
            @change="isFormChanged = true"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('promoCodesStartDate')"
          prop="starts_at"
        >
          <a-date-picker
            v-model="form.starts_at"
            :showTime="{
              defaultValue: defaultTime
            }"
            format="DD.MM.YYYY HH:mm"
            valueFormat="YYYY-MM-DDTHH:mm"
            @change="isFormChanged = true"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('promoCodesEndDate')"
          prop="ends_at"
        >
          <a-date-picker
            v-model="form.ends_at"
            :showTime="{
              defaultValue: defaultTime
            }"
            format="DD.MM.YYYY HH:mm"
            valueFormat="YYYY-MM-DDTHH:mm"
            @change="isFormChanged = true"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-button
            type="primary"
            ghost
            icon="plus"
            @click="handleShowOffersModal"
          >
            {{ $t("addOffers") }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>

    <a-drawer
      :width="700"
      :title="$t('addOffers')"
      :visible="showOffersModal"
      destroyOnClose
      @close="handleHideOffersModal()"
    >
      <AddOffersModal
        :promoCodeUuid="promoCodeUuid"
        @close="handleHideOffersModal()"
        @submit="onOffersAdded"
      />
    </a-drawer>

    <OffersTable
      ref="offersTableRef"
      :promoCodeUuid="promoCodeUuid"
    />
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import { fetchSinglePromoCode, updatePromoCode } from "@/modules/MPAdmin/services/promoCodesService"
import notifyResponseError from "@/utils/notifyResponseError"
import { message, notification } from "ant-design-vue"
import { computed, onMounted, reactive, ref, shallowRef } from "vue"
import AddOffersModal from "./components/AddOffersModal.vue"
import OffersTable from "./components/OffersTable.vue"
import moment from "moment"
import router from "@/router"
import { requiredRule, validateUppercase, validateWhiteSpace } from "@/utils/validations"
import useForm from "@/composables/useForm"

const promoCodeUuid = computed(() => router.currentRoute.params.uuid)

const promoCodeInfo = ref()
const isFetching = shallowRef(false)
const isFormChanged = shallowRef(false)

const pageTitle = computed(
  () => `${i18n.t("promoCodeEditing")}: ${promoCodeInfo.value?.name || ""}`
)

const getPromoCodeInfo = async () => {
  try {
    isFetching.value = true

    const { data } = await fetchSinglePromoCode(promoCodeUuid.value)

    promoCodeInfo.value = {
      ...data,
      created_at: moment(data.created_at).local(),
      finishes_at: moment(data.finishes_at).local()
    }
    setForm()
    isFormChanged.value = false
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const handleBack = () => {
  if (/promo-codes\/?\?/.test(router.currentRoute.meta.previous || "")) {
    router.push(router.currentRoute.meta.previous)
  } else {
    router.push({ name: "PromoCodesList" })
  }
}
const handleCancel = () => getPromoCodeInfo()

const formRef = ref()
const form = reactive({
  name: undefined,
  code: undefined,
  discount_percent: undefined,
  starts_at: undefined,
  ends_at: undefined
})
const formRules = {
  name: [requiredRule.value],
  code: [
    requiredRule.value,
    {
      validator: validateUppercase,
      trigger: "change"
    },
    {
      validator: validateWhiteSpace,
      trigger: "change"
    }
  ],
  discount_percent: [requiredRule.value],
  starts_at: [requiredRule.value],
  ends_at: [requiredRule.value]
}
const { isValidForm, validateForm } = useForm(formRef)

const setForm = (data = promoCodeInfo.value) => {
  Object.keys(form).forEach((key) => {
    form[key] = data[key]
  })
}

const defaultTime = computed(() => {
  return moment("00:00:00", "HH:mm:ss")
})

const handleSave = async () => {
  await validateForm()
  if (!isValidForm.value) return message.error("fillFields")

  try {
    isFetching.value = true
    const { created_at, ...payload } = form

    payload.finishes_at = moment(payload.finishes_at).local()

    await updatePromoCode({ uuid: promoCodeInfo.value.uuid, payload })
    notification.success({ message: i18n.t("updated") })
    getPromoCodeInfo()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const showOffersModal = shallowRef(false)
const handleShowOffersModal = () => {
  showOffersModal.value = true
}
const handleHideOffersModal = () => {
  showOffersModal.value = false
}

const offersTableRef = ref()
const onOffersAdded = () => {
  offersTableRef.value?.handleTableFiltersReset?.()
}

onMounted(getPromoCodeInfo)
</script>

<style lang="scss" scoped>
.edit-promo-codes {
  max-width: 1600px;
  margin: 0 auto;

  &__header {
    padding: 16px 0;
  }

  &__form {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 16px;

    &__name {
      width: 525px;
      max-width: 50%;
    }

    &__activity {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-between;

      padding: 16px 0;
      margin-left: auto;

      border: 1px solid $border-color;
      border-radius: 2px;
    }
  }
}
</style>
