<template>
  <a-table
    :dataSource="props.data"
    :loading="fetching"
    :columns="columns"
    rowKey="id"
    size="middle"
    :scroll="{ y: 300 }"
    :pagination="false"
  >
    <template #photoRenderer="images">
      <TableRendererPhotoGallery
        v-if="images?.length"
        :images="images"
      />
    </template>

    <template #cover="record">
      <a-radio
        :checked="currentCover === record.id"
        @click="handleChangeCover(record.id)"
      />
    </template>

    <template #listing="text, record">
      {{ text?.id }}

      <a-button
        v-if="text?.id"
        icon="delete"
        type="danger"
        shape="circle"
        size="small"
        @click="removeItem(record)"
      />
    </template>
  </a-table>
</template>

<script setup>
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    required: true
  },
  removeItem: {
    type: Function,
    required: false
  },
  fetching: {
    type: Boolean,
    default: false
  },
  currentCover: {
    type: Number | null,
    default: null
  }
})
const emit = defineEmits(["changeCover"])

const handleChangeCover = (id) => {
  emit("changeCover", id)
}
</script>
