<template>
  <div>
    <a-divider>{{ $t("creatingReport") }}</a-divider>

    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      class="report-form"
    >
      <a-form-model-item prop="date">
        <a-select
          v-model="form.date_filter"
          :options="reportDateFilterOptions"
          :disabled="!form.date?.length"
        />
        <a-range-picker
          v-model="form.date"
          format="DD-MM-YYYY"
          valueFormat="YYYY-MM-DD"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('billingCode')"
        :extra="$t('billingCodeSeparatedByComma')"
        prop="billing_codes"
      >
        <a-textarea
          v-model="form.billing_codes"
          :autoSize="{ minRows: 1, maxRows: 4 }"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-button
          :disabled="!reportFieldsIsFilled"
          :loading="reportFetching"
          @click="createReport()"
        >
          {{ $t("createReport") }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import i18n from "@/i18n"

import { getCpaReport } from "@/modules/ADS/services/sellerServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useSellerConsts from "@/modules/ADS/pages/Sellers/useSellerConsts.js"
import useForm from "@/composables/useForm"

const props = defineProps({
  slug: {
    type: String,
    required: true
  }
})

const { reportDateFilterOptions } = useSellerConsts()

const formRef = ref()
const form = reactive({
  date: null,
  date_filter: "WRITE-OFF",
  billing_codes: ""
})
const formRules = computed(() => ({
  billing_codes: { validator: validateCodes }
}))
const { isValidForm, validateForm } = useForm(formRef)

const reportFieldsIsFilled = computed(() => form.billing_codes || form.date?.length > 0)

const validateCodes = (_, value, callback) => {
  const formatedCodes = value.split(/[\r\n, ]+/gm).filter(Boolean)

  if (!formatedCodes.length && !form.date) {
    callback(new Error(i18n.t("billingCodeError")))
  } else if (formatedCodes.some((item) => !item.startsWith("AB") || item.length !== 10)) {
    callback(new Error(i18n.t("billingCodeError")))
  } else {
    callback()
  }
}

const reportFetching = ref(false)
const createReport = async () => {
  await validateForm()
  if (!isValidForm.value) return

  reportFetching.value = true

  const body = {}
  const formatedCodes = form.billing_codes.split(/[\r\n, ]+/).filter(Boolean)

  if (form.date && form.date.length > 0) {
    body.date_from = form.date[0]
    body.date_to = form.date[1]
    body.date_filter = form.date_filter
  }

  if (formatedCodes.length > 0) {
    body.billing_codes = formatedCodes
  }

  try {
    const { data } = await getCpaReport(props.slug, body)

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")

    link.href = url
    link.setAttribute("download", `report-${props.slug}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    const data = JSON.parse(await error.response.data.text())
    notifyResponseError({ error: { response: { data } } })
  } finally {
    reportFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.report-form {
  display: flex;
  flex-flow: row nowrap;

  & > :first-child {
    margin: 20px 24px 0 0;
    width: 30%;
  }

  & > :nth-child(2) {
    width: 55%;
  }

  & > :last-child {
    padding-top: 40px;
    margin-left: auto;
  }
}
</style>
