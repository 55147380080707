<template>
  <a-descriptions
    :column="1"
    bordered
    size="middle"
    class="tableOrderInformation"
  >
    <a-descriptions-item v-if="orderData.delivery_method">
      <template #label>{{ $t("deliveryMethod") }}</template>
      {{ orderData.delivery_method.name.uk }}
    </a-descriptions-item>

    <a-descriptions-item v-if="orderData.payment_method">
      <template #label>{{ $t("payment_method") }}</template>

      <div style="width: 100%; display: flex; justify-content: space-between">
        <div>{{ orderData.payment_method.name.uk }}</div>
        <div
          v-if="orderData.payment_status"
          :style="{ color: paymentStatuses[orderData.payment_status].color }"
        >
          {{ paymentStatuses[orderData.payment_status].title }}&nbsp;<a-tooltip
            v-if="paymentStatuses[orderData.payment_status].tooltip"
            placement="topRight"
          >
            <template slot="title">
              <span>
                {{ paymentStatuses[orderData.payment_status].tooltip }}
                <template v-if="orderData.payment_status === 'REFUND_ACCEPTED'">
                  <template v-if="orderData.payment_method.api_integration === 'MONO'">{{
                    $t("refundTimeOnline")
                  }}</template>
                  <template
                    v-if="orderData.payment_method.api_integration === 'PRIVAT_HIRE_PURCHASE'"
                    >{{ $t("refundTimePrivatParts") }}</template
                  >
                  <template v-if="orderData.payment_method.api_integration === 'MONO_PARTS'">{{
                    $t("refundTimeMonoParts")
                  }}</template>
                </template>
              </span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
        </div>
      </div>
    </a-descriptions-item>

    <a-descriptions-item v-if="orderData.ttn">
      <template #label>{{ $t("ttn") }}</template>
      <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
        <a-input-group
          compact
          :style="{ width: '50%', display: 'flex' }"
        >
          <a-input
            :disabled="!isEditingTtn"
            v-model="newTtn"
          />

          <a-button
            v-if="isEditingTtn"
            type="danger"
            @click="onResetTtn()"
          >
            <a-icon type="undo" />
          </a-button>

          <a-button @click="onEditTtn()">
            <a-icon :type="isEditingTtn ? 'check' : 'edit'" />
          </a-button>
        </a-input-group>

        <div
          v-if="orderData.ttn_added_at"
          :style="{ opacity: '0.45' }"
        >
          {{ $t("added") }} {{ getFormatedDateTime(orderData.ttn_added_at) }}
        </div>
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <template #label>{{ $t("buyerInformation") }}</template>
      <div v-html="buyerInfo" />
    </a-descriptions-item>

    <a-descriptions-item>
      <template #label>{{ $t("receiverInformation") }}</template>
      <div v-html="receiverInfo" />
    </a-descriptions-item>

    <a-descriptions-item>
      <template #label>{{ $t("buyerComment") }}</template>
      {{ orderData.comment_customer }}
    </a-descriptions-item>

    <a-descriptions-item>
      <template #label>{{ $t("promoCodeTitle") }}</template>
      {{ orderData.promo_code }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script setup>
import { computed, shallowRef } from "vue"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import { useStatuses } from "@/composables/useStatuses"
import i18n from "@/i18n"

const props = defineProps({
  orderData: {
    type: Object,
    required: true
  }
})
const { paymentStatuses } = useStatuses()

const newTtn = shallowRef(props.orderData.ttn)
const isEditingTtn = shallowRef(false)

const buyerInfo = computed(() => {
  const fullNameArray = []
  const buyerInfo = []

  fullNameArray.push(props.orderData.customer.first_name)
  fullNameArray.push(props.orderData.customer.middle_name)
  fullNameArray.push(props.orderData.customer.last_name)

  const fullNameString = fullNameArray.filter((s) => s).join(" ")

  // Add name
  if (fullNameString.length) {
    buyerInfo.push(fullNameString)
  }
  // Add phone
  if (props.orderData.customer.mobile_phone) {
    const phone = props.orderData.customer.is_active
      ? props.orderData.customer.mobile_phone
      : props.orderData.receiver_mobile_phone

    buyerInfo.push(`${i18n.t("phoneShorted").toLowerCase()}:&nbsp;${phone}`)
  }
  // Add email
  if (props.orderData.customer && props.orderData.customer.email) {
    buyerInfo.push(`email:&nbsp;${props.orderData.customer.email}`)
  }

  return buyerInfo.join(", ")
})

const receiverInfo = computed(() => {
  const fullNameArray = []

  fullNameArray.push(props.orderData.receiver_first_name)
  fullNameArray.push(props.orderData.receiver_middle_name)
  fullNameArray.push(props.orderData.receiver_last_name)

  const fullNameString = fullNameArray.filter((s) => s).join(" ")
  const receiverInfo = []

  // Add name
  if (fullNameString.length) receiverInfo.push(fullNameString)
  // Add location
  if (props.orderData.location) receiverInfo.push(props.orderData.location)
  // Add phone
  if (props.orderData.receiver_mobile_phone)
    receiverInfo.push(
      `${i18n.t("phoneShorted").toLowerCase()}:&nbsp;${props.orderData.receiver_mobile_phone}`
    )

  return receiverInfo.join(", ")
})

const onResetTtn = () => {
  newTtn.value = props.orderData.ttn
  isEditingTtn.value = !isEditingTtn.value
}

const onEditTtn = () => {
  isEditingTtn.value = !isEditingTtn.value
}
</script>

<style lang="scss">
.tableOrderInformation {
  .ant-descriptions-item-label {
    font-weight: 500;
    padding: 12px !important;
    white-space: nowrap;
    background: transparent;
  }

  .ant-descriptions-item-content {
    padding: 12px !important;
    color: $font-default;
  }
}
</style>
