import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchProducts = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/products${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchProductsMeta = () => {
  return API_V2.get(`${MPA_PREFIX}/products/meta`)
}

export const fetchLabelsChoises = () => {
  return API_V2.get(`${MPA_PREFIX}/product-labels`)
}

export const updateProduct = (id, data) => {
  return API_V2.put(`${MPA_PREFIX}/products/${id}`, data)
}

export const updateProductsBulk = (payload) => {
  return API_V2.put(`${MPA_PREFIX}/products/bulk`, payload)
}

export const syncProducts = async (selectedRows) => {
  const codes = selectedRows.map((item) => item.p_code)

  return API_V2.post(`${MPA_PREFIX}/products/synchronize`, { codes })
}

export const fetchLabelsValues = () => {
  return API_V2.get(`${MPA_PREFIX}/product-labels`)
}

export const searchListings = (id) => {
  return API_V2.get(`${MPA_PREFIX}/listings?id=${id}`)
}

export const getLastListing = () => {
  return API_V2.get(`${MPA_PREFIX}/listings?limit=0`)
}
export const createListing = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/listings`, payload)
}

export const updateListing = (data, listingId) => {
  return API_V2.put(`${MPA_PREFIX}/listings/${listingId}`, data)
}

export const getProductsByListing = (id) => {
  return API_V2.get(`${MPA_PREFIX}/products?listing=${id}`)
}

export const changeRatingWithFile = (formData) => {
  return API_V2.post(`${MPA_PREFIX}/products/imports/rf`, formData)
}
