import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import i18n from "@/i18n"
import { h, ref } from "vue"
import { notification, Progress } from "ant-design-vue"

import { API_V2 } from "@/utils/HttpUtils"

import { MPA_PREFIX } from "@/constants/apiPrefix.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import useDebounce from "@/composables/useDebounce"

const approveSellersInfo = ref({})

const { debounceInterval, resetInterval } = useDebounce()

export const fetchSynchronizations = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/synchronizations${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const activateSynchronizations = (list) => {
  const formatedList = list.map(({ slug }) => slug)

  return API_V2.post(`${MPA_PREFIX}/synchronizations/activate`, { sellers: formatedList })
}

export const deactivateSynchronizations = (list) => {
  const formatedList = list.map(({ slug }) => slug)

  return API_V2.post(`${MPA_PREFIX}/synchronizations/deactivate`, { sellers: formatedList })
}

export const checkSyncTask = (id) => {
  return API_V2.get(`${MPA_PREFIX}/synchronizations/tasks/${id}`)
}

const handleCheckMatchStatus = async ({ task, seller }) => {
  try {
    const { data: syncData } = await checkSyncTask(task)

    if (syncData.state === "SUCCESS") {
      resetInterval(task)
      notifySyncMatchSuccess(seller)
    }
  } catch (error) {
    resetInterval(task)
    notifyResponseError({ error, message: i18n.t("syncMatchError", { seller }) })
  }
}

export const matchSynchronizations = async (list) => {
  const formatedList = list.map(({ slug }) => ({ type: "synthetic_meta", seller: slug }))

  try {
    const { data } = await API_V2.post(`${MPA_PREFIX}/synchronizations/match`, formatedList)

    notification.info({ message: i18n.t("syncMatchStarted") })

    Object.entries(data).forEach(([seller, task]) => {
      debounceInterval(task, handleCheckMatchStatus, 5000, { task, seller })
    })
  } catch (error) {
    notifyResponseError({ error })
  }
}

const handleCheckSyncStatus = async ({ task, seller }) => {
  try {
    const { data } = await checkSyncTask(task)

    if (data.state === "SUCCESS") {
      resetInterval(task)
      approveSellersInfo.value[seller].success += 1
    }
  } catch {
    resetInterval(task)
    approveSellersInfo.value[seller].errors += 1
  } finally {
    notifySyncApproveSuccess(seller)
  }
}

export const approveSynchronizations = async (list) => {
  const formatedList = list.map(({ slug }) => slug)

  try {
    const { data } = await API_V2.post(`${MPA_PREFIX}/synchronizations/approve`, {
      sellers: formatedList
    })
    notification.info({ message: i18n.t("syncApproveStarted") })

    Object.entries(data).forEach(([seller, { task_ids, task_count }]) => {
      approveSellersInfo.value[seller] = { total: task_count, errors: 0, success: 0 }
      if (task_ids && task_ids.length) {
        task_ids.forEach((task) => {
          debounceInterval(task, handleCheckSyncStatus, 5000, { task, seller })
        })
      } else {
        notifySyncApproveSuccess(seller)
      }
    })
  } catch (error) {
    notifyResponseError({ error })
  }
}

const fetchAndDownloadSyncReport = async ({ data, slug }) => {
  const { data: taskData } = await checkSyncTask(data.task)

  if (taskData.state === "SUCCESS") {
    resetInterval(slug)

    try {
      const { data } = await API_V2.get(`${MPA_PREFIX}/synchronizations/report/${taskData.uuid}`)

      window.open(data.url, "_blank")
    } catch (error) {
      resetInterval(slug)
      notifyResponseError({ error })
    }
  }
}

export const getSyncReport = async (slug) => {
  try {
    const { data } = await API_V2.post(`${MPA_PREFIX}/synchronizations/report`, { seller: slug })

    debounceInterval(slug, fetchAndDownloadSyncReport, 1000, { data, slug })
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const deleteSynchronization = (uuid) => {
  return API_V2.delete(`${MPA_PREFIX}/synchronizations/${uuid}`)
}

const handleCheckUpdateStatus = async ({ task, seller }) => {
  try {
    const { data: syncData } = await checkSyncTask(task)

    if (syncData.state === "SUCCESS") {
      resetInterval(task)
      notifySyncUpdateSuccess(seller)
    }
  } catch (syncError) {
    resetInterval(task)
    notifyResponseError({
      error: syncError,
      message: i18n.t("syncUpdateError", { seller })
    })
  }
}

export const updateSynchronization = async (data, seller) => {
  try {
    const {
      data: { task }
    } = await API_V2.post(`${MPA_PREFIX}/synchronizations/start`, data)

    notification.info({ message: i18n.t("syncUpdateStarted", { seller }) })

    debounceInterval(task, handleCheckUpdateStatus, 5000, { task, seller })
  } catch (error) {
    notifyResponseError({ error })
  }
}

const notifySyncApproveSuccess = (seller) => {
  const { total, success, errors } = approveSellersInfo.value[seller]
  const countMessage = `${i18n.t("total")}: ${total},
    ${i18n.t("success")}: ${success}, ${i18n.t("failed")}: ${errors}`

  notification.open({
    key: seller,
    message: i18n.t("syncApproveProgress", { seller }),
    description: () =>
      h("div", [
        h(Progress, {
          props: {
            percent: total ? ((success + errors) / total) * 100 : 100,
            successPercent: total ? (success / total) * 100 : 100,
            strokeColor: "#f5222d",
            status: "normal"
          }
        }),
        total ? h("div", countMessage) : undefined
      ]),
    duration: null
  })
  if (total === success + errors) {
    approveSellersInfo.value[seller] = undefined
  }
}

const notifySyncMatchSuccess = (seller) => {
  notification.success({
    message: i18n.t("syncMatchSuccess", {
      seller
    }),
    duration: null
  })
}

const notifySyncUpdateSuccess = (seller) => {
  notification.success({
    message: i18n.t("syncUpdateSuccess", {
      seller
    }),
    duration: null
  })
}
