export default {
  applications: "Applications",
  components: "Components",
  views: "Views",
  fields: "Fields",
  modelPermissions: "Model permissions",
  createRole: "Create role",
  editRole: "Edit role",
  roles: "Roles",
  users: "Users",
  isSuper: "Is super",
  createUser: "Create user",
  editUser: "Edit user",
  userCreatedSuccessfully: "User created successfully",
  userUpdatedSuccessfully: "User updated successfully",
  roleCreatedSuccessfully: "Role created successfully",
  roleUpdatedSuccessfully: "Role updated successfully",
  permissions: "Permissions",
  twoStepGoogleAuth: "2-step login Google Authenticator",
  deactivateUser: "Deactivate the user",
  deleteRole: "Delete role",
  history: "History",
  roleDeleteHint: "Role deleting available only if it doesn't have permissions",
  removeTwoFaWarning: "Disabling 2fa login reset 2fa key. Continue?",
  deactivateSessionWarning: "You want to deactivate session?",
  sessionDeactivated: "Deactivated"
}
