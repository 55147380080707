<template>
  <div>
    <PageHeader :title="$t('pageBuilder')">
      <template #filters>
        <a-select
          v-model="filteredInfo.category"
          :options="categories"
          allowClear
          :placeholder="$t('category')"
          @change="handleTableFilterChange()"
        />

        <a-input-search
          v-model="filteredInfo.label"
          placeholder="Title"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          mode="multiple"
          v-model="filteredInfo.locations"
          :options="locationChoices"
          allowClear
          :placeholder="$t('location')"
          @change="handleTableFilterChange()"
        />

        <a-select
          v-model="filteredInfo.is_active"
          :options="isActiveOptions"
          allowClear
          :placeholder="$t('state')"
          @change="handleTableFilterChange()"
        />

        <a-form-model-item :help="$t('creatingDate')">
          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            allowClear
            v-model="filteredInfo.created_at__interval"
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>

        <a-form-model-item :help="$t('updatedAt')">
          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            allowClear
            v-model="filteredInfo.updated_at__interval"
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>
      </template>

      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset()"
        >
          {{ $t("clearFilters") }}
        </a-button>

        <a-button
          type="primary"
          icon="plus"
          @click="handleOpenEditPageModal()"
        >
          {{ $t("addPage") }}
        </a-button>

        <a-button
          type="primary"
          @click="handleOpenCategoryEditor()"
        >
          {{ $t("categoryEditor") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="id"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #pageTitle="value">
        {{ value[getCurrentLocale()] }}
      </template>

      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="edit"
          @click="handleOpenEditPageModal(record)"
        />
      </template>

      <template #state="isActive">
        <div :class="[isActive ? 'active' : 'inactive']">
          {{ isActive ? $t("active") : $t("inactive") }}
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="showPageEditorModal"
      :width="1400"
      :title="$t('pageEditor')"
      destroyOnClose
      @close="handleCloseEditPageModal()"
    >
      <PageEditorModal
        :prototype="prototype"
        :categories="categories"
        :locationChoices="locationChoices"
        @updatePage="onPageUpdated"
        @createPage="onPageCreated"
      />
    </a-drawer>

    <a-drawer
      :visible="showCategoryEditorModal"
      :title="$t('categoryEditor')"
      :width="1400"
      destroyOnClose
      @close="handleCloseCategoryEditor"
    >
      <CategoriesEditorModal />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import {
  fetchPages,
  fetchPageCategoryItems,
  fetchPagesActionsData
} from "@/modules/MPAdmin/services/pageBuilderService.js"

import { PageEditorModal, CategoriesEditorModal } from "./components/index.js"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"

import getCurrentLocale from "@/utils/getCurrentLocale.js"
import usePageBuilderColumns from "./usePageBuilderColumns.js"
import useMPAConsts from "@/modules/MPAdmin/constants.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchPages,
  requestParams: { changeRouterQuery: true }
})

const { columns } = usePageBuilderColumns({ sortedInfo: computed(() => sortedInfo.value) })
const { isActiveOptions } = useMPAConsts()

const prototype = ref()
const showPageEditorModal = ref(false)
const showCategoryEditorModal = ref(false)

const categories = ref([])
const locationChoices = ref([])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const getCategories = async () => {
  const { data } = await fetchPageCategoryItems()

  categories.value = data.results.map((category) => ({
    label: category.name[getCurrentLocale()],
    value: category.id
  }))
}

const getMeta = async () => {
  const { data } = await fetchPagesActionsData()

  locationChoices.value = data.locations_choices
}

const handleOpenEditPageModal = (page) => {
  showPageEditorModal.value = true
  prototype.value = page
}

const handleCloseEditPageModal = () => {
  showPageEditorModal.value = false
  prototype.value = undefined
}

const onPageUpdated = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  handleCloseEditPageModal()
}

const onPageCreated = () => {
  handleCloseEditPageModal()
  onTableChange()
}

const handleOpenCategoryEditor = () => {
  showCategoryEditorModal.value = true
}

const handleCloseCategoryEditor = () => {
  showCategoryEditorModal.value = false
}

onMounted(() => {
  setupTable()
  getMeta()
  getCategories()
  onTableChange()
})
</script>

<style scoped lang="scss">
.active {
  color: $green-color;
}
.inactive {
  color: $grey-font;
}
</style>
