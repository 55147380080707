<template>
  <div>
    <a-page-header
      class="table-header"
      :title="`${$t('tagEditing')}: ${title} / Slug: ${slug}`"
      @back="handleReturnToTags"
    >
      <template #extra>
        <a-button
          key="cancel"
          @click="handleReturnToTags"
        >
          {{ $t("cancel") }}
        </a-button>

        <a-button
          key="save"
          type="primary"
          :loading="isLoading"
          @click="onSubmit"
        >
          {{ $t("save") }}
        </a-button>
      </template>

      <template #footer>
        <div>
          <a-tabs
            v-if="tagInfo"
            v-model="activeTab"
          >
            <a-tab-pane
              key="settings"
              forceRender
            >
              <template #tab><a-icon type="setting" />{{ $t("settings") }}</template>

              <Settings
                ref="settingsRef"
                :tag="tagInfo"
                @setEdited="setTagIsEdited"
              />
            </a-tab-pane>

            <a-tab-pane
              key="SEO"
              forceRender
            >
              <template #tab>
                <a-icon type="bar-chart" />
                SEO-description
              </template>

              <SEOdescription
                ref="seoDescriptionRef"
                :tag="tagInfo"
                @setEdited="setTagIsEdited"
              />
            </a-tab-pane>
          </a-tabs>

          <a-spin
            :spinning="activityIsisFetching || isLoading"
            class="activity-card"
          >
            <a-form-model-item
              :label="$t('tagActivity')"
              class="activity-card__item"
            >
              <a-radio-group
                :value="tagInfo?.is_active"
                :disabled="activityIsisFetching"
                @change="onActivityChange"
              >
                <a-radio-button :value="false">{{ $t("inactive") }}</a-radio-button>
                <a-radio-button :value="true">{{ $t("active") }}</a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-spin>
        </div>
      </template>
    </a-page-header>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import SEOdescription from "./_components/SEOdescription.vue"
import Settings from "./_components/Settings.vue"

import { fetchSingleTag, editTagActivity, updateTag } from "@/modules/MPAdmin/services/tagsService"
import { message, Modal, notification } from "ant-design-vue"
import router from "@/router"
import notifyResponseError from "@/utils/notifyResponseError"
import { onMounted, ref } from "vue"

const settingsRef = ref()
const seoDescriptionRef = ref()

const title = ref("")
const slug = ref("")
const activeTab = ref("settings")
const tagInfo = ref(null)
const isLoading = ref(false)
const isFetching = ref(true)
const tagIsEdited = ref(false)
const activityIsisFetching = ref(false)

const getTag = async () => {
  isFetching.value = true

  try {
    const { data } = await fetchSingleTag(router.currentRoute.params.uuid)

    tagInfo.value = data
    title.value = data.title
    slug.value = data.slug
  } catch (e) {
    notification.error({
      message: e.response.data
    })
    router.push("/mp-admin/offer-tags")
  } finally {
    isFetching.value = false
    tagIsEdited.value = false
  }
}

const handleReturnToTags = () => {
  if (tagIsEdited.value) {
    Modal.confirm({
      title: i18n.t("changesNotSaved"),
      content: i18n.t("leavePage"),
      cancelText: i18n.t("no"),
      okText: i18n.t("yes"),
      onOk: returnToTagsPage
    })
  } else {
    returnToTagsPage()
  }
}

const returnToTagsPage = () => {
  if (/offer-tags\/?\?/.test(router.currentRoute.meta.previous || "")) {
    router.push(router.currentRoute.meta.previous)
  } else {
    router.push({ name: "TagsList" })
  }
}

const onSubmit = async () => {
  const settingsValues = await settingsRef.value.getSettingsFormValues()
  const seoValues = seoDescriptionRef.value.getSeoFormValues()

  if (!settingsValues) {
    return message.error(i18n.t("fillNameAndSlug"))
  }
  isLoading.value = true

  const data = {
    ...settingsValues,
    ...seoValues,
    _note: settingsValues._note || null,
    slug: slug.value === settingsValues.slug ? undefined : settingsValues.slug
  }

  try {
    await updateTag(data, router.currentRoute.params.uuid)

    notification.success({
      message: i18n.t("changesSaved")
    })
    tagIsEdited.value = false
    settingsRef.value.disableSlugInput()
    settingsRef.value.setWatcher()
    seoDescriptionRef.value.setWatcher()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isLoading.value = false
  }
}

const onActivityChange = async (e) => {
  activityIsisFetching.value = true

  try {
    await editTagActivity({ is_active: e.target.value }, tagInfo.value.uuid)

    tagInfo.value.is_active = e.target.value
    notification.success({
      message: i18n.t("tagActivityChanged")
    })
  } catch (error) {
    const isSeoError = error.response?.data?.detail?.some((error) => error.includes("seo"))

    if (isSeoError) {
      notification.error({
        message: i18n.t("seoBlankError")
      })
    } else {
      notifyResponseError({ error })
    }
  } finally {
    activityIsisFetching.value = false
  }
}

const setTagIsEdited = () => {
  tagIsEdited.value = true
}

onMounted(getTag)
</script>

<style lang="scss" scoped>
.activity-card {
  position: absolute;
  top: 64px;
  right: 0;

  width: 360px;
  margin-left: auto;
  height: fit-content;
  padding: 10px;

  border: 1px solid $border-color;
  border-radius: 2px;

  &__item {
    display: flex;
    align-items: center;
    height: fit-content;
    justify-content: space-between;
    margin: 0;
  }
}
</style>
