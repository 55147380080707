<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
  >
    <a-table
      :columns="columns"
      :dataSource="tableDataSource"
      :loading="isTableLoading"
      :pagination="false"
      :components="components"
      rowKey="uuid"
    >
      <template #handleRenderer="record">
        <a-icon
          v-if="!record.is_new"
          type="menu"
          class="handle"
          :class="{ 'handle--disabled': changedReplies.length }"
        />
      </template>

      <template #nameRenderer="record">
        <a-form-model-item
          v-if="record.is_new"
          prop="title"
        >
          <a-input v-model="record.title" />
        </a-form-model-item>
        <a-input
          v-else
          v-model="record.title"
          @change="onReplyChanged(record)"
        />
      </template>

      <template #responseRenderer="record">
        <a-form-model-item
          v-if="record.is_new"
          prop="response"
        >
          <a-textarea
            v-model="record.response"
            :autoSize="{ minRows: 3, maxRows: 8 }"
          />
        </a-form-model-item>
        <a-textarea
          v-else
          v-model="record.response"
          :autoSize="{ minRows: 3, maxRows: 8 }"
          @input="onReplyChanged(record)"
        />
      </template>

      <template #actions="record">
        <a-button
          :icon="record.is_new ? 'plus' : 'delete'"
          :type="record.is_new ? 'primary' : 'dashed'"
          shape="circle"
          ghost
          :disabled="disableCreateButton"
          :class="[record.is_new ? 'ant-btn-success' : 'ant-btn-danger']"
          @click="() => (record.is_new ? handleCreateReply() : handleDeleteReply(record))"
        />
      </template>
    </a-table>
  </a-form-model>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, provide, reactive, ref, shallowRef } from "vue"
import { message, Modal, notification } from "ant-design-vue"
import i18n from "@/i18n"

import TableGraggableWrapper from "@/ant-components/TableGraggableWrapper/TableGraggableWrapper.vue"

import {
  bulkRepliesUpdate,
  createMessengerReply,
  deleteMessengerReply,
  fetchMessengerReplies,
  updateMessengerReply
} from "@/modules/MPAdmin/services/messengerTagsService"

import useForm from "@/composables/useForm"
import useDebounce from "@/composables/useDebounce"
import useMessengerRepliesColumns from "./useMessengerRepliesColumns"

import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  tag: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(["update"])

const { debounce, resetDebounce } = useDebounce()

// TABLE
const components = {
  body: {
    wrapper: TableGraggableWrapper
  }
}
const columns = useMessengerRepliesColumns()
const tableDataSource = computed(() => dataSource.value.concat([form]))

// FETCH
const isFetching = shallowRef(false)
const dataSource = ref([])
const isTableLoading = computed(() => isFetching.value || isSaving.value || isCreating.value)

const getReplies = async () => {
  try {
    isFetching.value = true

    const { data } = await fetchMessengerReplies({
      queryParams: { tag: props.tag.uuid, pageSize: 100 }
    })
    dataSource.value.length = 0
    dataSource.value.push(...data.results)

    emit("update", dataSource.value.length)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

// CHANGE
const onReplyChanged = (record) => {
  resetDebounce("repliesSaving")
  debounce("repliesSaving", handleSaveChangedReplies, 1000)

  if (changedReplies.value.includes(record.uuid)) return
  changedReplies.value.push(record.uuid)
}

// UPDATE
const changedReplies = ref([])
const isSaving = shallowRef(false)

const resetChangedRecords = () => {
  changedReplies.value = []
}

const checkEmptyReplies = () => {
  return dataSource.value.some((item) => !item.title?.trim() || !item.response?.trim())
}
const checkIsRepliesChanged = () => Boolean(changedReplies.value.length)

const handleSaveChangedReplies = async () => {
  resetDebounce("repliesSaving")

  if (checkEmptyReplies()) {
    message.error({ content: i18n.t("quickRepliesSaveEmptyError"), key: "replies-save" })
    return Promise.resolve()
  }

  try {
    isSaving.value = true

    await bulkRepliesUpdate({
      replies: changedReplies.value,
      dataSource: dataSource.value,
      chat_tag_uuid: props.tag.uuid
    })
    notification.success({ message: i18n.t("updated") })
    resetChangedRecords()
    return Promise.resolve("saved")
  } catch (error) {
    notifyResponseError({ error })
    return Promise.resolve()
  } finally {
    isSaving.value = false
  }
}

// DELETE
const handleDeleteReply = (record) => {
  Modal.confirm({
    title: i18n.t("deletionWarningMessage"),
    okType: "danger",
    okText: i18n.t("delete"),
    onOk: () => onDeleteReply(record)
  })
}
const onDeleteReply = async (record) => {
  try {
    await deleteMessengerReply({ uuid: record.uuid })

    notification.success({ message: i18n.t("deleted") })

    getReplies()
  } catch (error) {
    notifyResponseError({ error })
  }
}

// CREATE
const formRef = ref()
const form = reactive({
  uuid: "new_reply_form",
  title: "",
  response: "",
  is_new: true
})
const validateString = (_, value, callback) => {
  if (!value || !value.trim()) {
    callback(new Error(i18n.t("emptyError")))
  } else {
    callback()
  }
}
const formRules = computed(() => ({
  title: [{ validator: validateString }],
  response: [{ validator: validateString }]
}))
const { isValidForm, validateForm } = useForm(formRef)

const isCreating = shallowRef(false)
const disableCreateButton = computed(() => {
  return Boolean(changedReplies.value.length || isFetching.value || isSaving.value)
})

const handleCreateReply = async () => {
  if (disableCreateButton.value) return

  await validateForm()
  if (!isValidForm.value) return

  try {
    isCreating.value = true

    const { is_new, ...payload } = form
    payload.chat_tag_uuid = props.tag.uuid
    payload.tag_order = dataSource.value.length

    const { data } = await createMessengerReply({ payload })
    dataSource.value.push(data)

    notification.success({ message: i18n.t("created") })

    form.title = ""
    form.response = ""
    emit("update", dataSource.value.length)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isCreating.value = false
  }
}

// SORT
const onSorted = async ({ newIndex, oldIndex }) => {
  if (newIndex >= dataSource.value.length) return

  try {
    isSaving.value = true
    const reply = dataSource.value[oldIndex]
    if (!reply?.uuid) return

    dataSource.value.splice(oldIndex, 1)
    dataSource.value.splice(newIndex, 0, reply)

    await updateMessengerReply({
      uuid: reply.uuid,
      payload: { tag_order: newIndex, chat_tag_uuid: props.tag.uuid }
    })
    notification.success({ message: i18n.t("updated") })
  } catch (error) {
    getReplies()
    notifyResponseError({ error })
  } finally {
    isSaving.value = false
  }
}

provide("appData", {
  dataSource: [...dataSource.value]
})

provide("onSort", onSorted)

onMounted(getReplies)
onBeforeUnmount(() => resetDebounce("repliesSaving"))
defineExpose({
  checkEmptyReplies,
  checkIsRepliesChanged,
  handleSaveChangedReplies
})
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: $grey-font;
  }

  &:active {
    cursor: grabbing;
  }
}

.ant-btn-success {
  color: $green-color;
  border-color: $green-color;
}
</style>
