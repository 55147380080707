import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo }) => {
  const columns = computed(() => [
    {
      key: "handle",
      scopedSlots: {
        customRender: "handleRenderer"
      },
      width: 65
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("dateCreated"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      width: 155
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("name"),
      width: 385
    },
    {
      key: "color",
      dataIndex: "color",
      title: i18n.t("color"),
      scopedSlots: {
        customRender: "colorRenderer"
      }
    },
    {
      key: "reply_count",
      dataIndex: "reply_count",
      title: i18n.t("quickRepliesCount"),
      width: 385
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("activity"),
      scopedSlots: {
        customRender: "statusRenderer"
      },
      width: 385
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      width: 65
    }
  ])

  return columns
}
