import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchReturnReasons = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/return-reasons${getFormatedQueryParams(queryParams)}`, {
    signal
  })
}

export const createReturnReason = (data) => {
  return API_V2.post(`${MPA_PREFIX}/return-reasons`, data)
}

export const updateReturnReason = (data, uuid) => {
  return API_V2.put(`${MPA_PREFIX}/return-reasons/${uuid}`, data)
}

export const deleteReturnReason = (uuid) => {
  return API_V2.delete(`${MPA_PREFIX}/return-reasons/${uuid}`)
}
