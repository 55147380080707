export const downloadCsv = (content, name = "example") => {
  if (Array.isArray(content)) {
    content = content.map((item) => item.join(",")).join("\n")
  }
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.href = url
  link.download = `${name}.csv`
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
