import { API_V2 } from "@/utils/HttpUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import buildTableQueryParams from "@/utils/buildTableQueryParams.js"
import simpleBuildQuery from "@/utils/simpleBuildQuery.js"
import { MODERATION_PREFIX } from "@/constants/apiPrefix.js"

export const fetchProductsForExport = ({ queryParams, requestParams, signal }) => {
  const query = getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)

  return API_V2.get(`${MODERATION_PREFIX}/products/${query}`, {
    signal
  })
}

export const exportProducts = ({ pagination, filters, sorter }) => {
  const tableQueryParams = buildTableQueryParams({ pagination, filters, sorter })

  const { page, pageSize, ...otherParams } = tableQueryParams
  const queryParamsForRequest = {
    ...otherParams
  }

  return API_V2.post(
    `${MODERATION_PREFIX}/products/export/${simpleBuildQuery(queryParamsForRequest)}`
  )
}
