import { render, staticRenderFns } from "./GeneralTab.vue?vue&type=template&id=05f8ee01&scoped=true"
import script from "./GeneralTab.vue?vue&type=script&setup=true&lang=js"
export * from "./GeneralTab.vue?vue&type=script&setup=true&lang=js"
import style0 from "./GeneralTab.vue?vue&type=style&index=0&id=05f8ee01&prod&lang=scss&scoped=true"
import style1 from "./GeneralTab.vue?vue&type=style&index=1&id=05f8ee01&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f8ee01",
  null
  
)

export default component.exports