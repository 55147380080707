<template>
  <a-drawer
    :visible="showModal"
    :title="prototype.id ? $t('editingDeliveryService') : $t('creatingDeliveryService')"
    closable
    :width="700"
    @close="$emit('close')"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      style="margin-bottom: 40px"
      :colon="false"
    >
      <a-form-model-item
        style="margin: 0"
        prop="name.uk"
      >
        <template #label>
          {{ $t("name") }}, UA ({{ form.name.uk?.length }}/{{ nameMaxLength }})
        </template>
        <a-input v-model="form.name.uk" />
      </a-form-model-item>

      <a-form-model-item
        style="margin: 0"
        prop="name.en"
      >
        <template #label>
          {{ $t("name") }}, EN ({{ form.name.en?.length }}/{{ nameMaxLength }})
        </template>
        <a-input v-model="form.name.en" />
      </a-form-model-item>

      <a-form-model-item
        style="margin: 0"
        prop="name.ru"
      >
        <template #label>
          {{ $t("name") }}, RU ({{ form.name.ru?.length }}/{{ nameMaxLength }})
        </template>

        <a-input v-model="form.name.ru" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('outputSortOrder')"
        style="margin: 0"
        prop="ordering"
      >
        <a-input-number
          v-model="form.ordering"
          :min="0"
          :max="999"
          :precision="0"
        />
      </a-form-model-item>

      <div
        v-if="imagePath"
        class="logo"
      >
        <img :src="imagePath" />
      </div>

      <FileLoader
        ref="fileInput"
        style="padding: 16px 12px"
        @change="handleChooseFiles"
        :limit="1"
        :allowedFormats="allowedFormats"
        :acceptTypes="acceptTypes"
        :maxKb="5 * 1024"
      />

      <a-form-model-item
        :label="$t('deliveryServiceActivity')"
        style="margin: 0"
        prop="is_active"
      >
        <a-switch
          v-model="form.is_active"
          :checkedChildren="$t('yes')"
          :unCheckedChildren="$t('no')"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('deliveryProvider')"
        prop="provider"
      >
        <a-select
          v-model="form.provider"
          :options="deliveryProviders"
        />
      </a-form-model-item>

      <div class="drawer-footer">
        <a-button
          :disabled="isFetching"
          @click="$emit('close')"
        >
          {{ $t("close") }}
        </a-button>
        <a-button
          type="primary"
          :loading="isFetching"
          @click="onSave"
        >
          {{ $t("apply") }}
        </a-button>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script setup>
import { computed, reactive, ref, shallowRef, watch } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import FileLoader from "@/components/FileLoader"
import { IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"

import useForm from "@/composables/useForm"

import {
  createDeliveryService,
  updateDeliveryService
} from "@/modules/MPAdmin/services/generalSettingService/deliveryService"
import notifyResponseError from "@/utils/notifyResponseError"
import { requiredRule } from "@/utils/validations"

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  prototype: {
    type: Object,
    default() {
      return {}
    }
  }
})
const emit = defineEmits(["createService", "updateService"])

const nameRules = computed(() => [
  requiredRule.value,
  { max: 200, message: i18n.t("maxLengthError", { length: 200 }) }
])

const formRef = ref()
const form = reactive({
  name: {
    uk: props.prototype?.name?.uk || "",
    ru: props.prototype?.name?.ru || "",
    en: props.prototype?.name?.en || ""
  },
  ordering: props.prototype?.ordering || 0,
  is_active: Boolean(props.prototype?.is_active),
  provider: props.prototype.provider
})
const formRules = computed(() => ({
  name: {
    uk: nameRules.value,
    ru: nameRules.value,
    en: nameRules.value
  },
  ordering: [requiredRule.value]
}))
const { isValidForm, validateForm } = useForm(formRef)

const images = ref([])
const allowedFormats = ["jpg", "jpeg", "png", "gif", "bmp"]
const acceptTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp"]
const nameMaxLength = 200

const handleChooseFiles = ({ files }) => {
  images.value = files
}

const imagePath = computed(() => {
  if (!props.prototype || !props.prototype.image) return ""
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(props.prototype.image, "full")}`
})

const deliveryProviders = computed(() => [
  {
    label: i18n.t("nova_poshta"),
    value: "NOVA_POSHTA"
  },
  {
    label: i18n.t("ukr_poshta"),
    value: "UKR_POSHTA"
  },
  {
    label: i18n.t("Synthetic"),
    value: "SYNTHETIC"
  }
])

const isFetching = shallowRef(false)
const onSave = async () => {
  await validateForm()
  if (!isValidForm.value) return

  if (images.value.length === 0 && !props.prototype.id) {
    notification.error({
      message: i18n.t("chooseLogo")
    })
    return
  }

  isFetching.value = true

  let actionData = new FormData()
  actionData.append("name", JSON.stringify(form.name))

  images.value.forEach((file) => {
    actionData.append("image", file)
  })
  actionData.append("ordering", form.ordering)
  actionData.append("is_active", form.is_active)
  actionData.append("provider", form.provider || null)

  try {
    if (props.prototype.id) {
      const { data } = await updateDeliveryService(actionData, props.prototype.id)
      emit("updateService", data)
    } else {
      await createDeliveryService(actionData)
      emit("createService")
    }

    notification.success({
      message: i18n.t(props.prototype.id ? "updated" : "created")
    })
  } catch (error) {
    notifyResponseError({
      error,
      message: i18n.t(props.prototype.id ? "updatingError" : "creatingError")
    })
  } finally {
    isFetching.value = false
  }
}

const fileInput = ref()

watch(
  () => props.showModal,
  (visible) => {
    if (visible && props.prototype.id) {
      form.name = { ...props.prototype.name }
      form.ordering = props.prototype.ordering
      form.is_active = Boolean(props.prototype.is_active)
      form.provider = props.prototype.provider || null
    } else {
      if (fileInput.value?.files?.length) {
        fileInput.value.removeFile(0)
      }

      form.name = { uk: "", en: "", ru: "" }
      form.ordering = 0
      form.is_active = false
    }
  }
)
</script>

<style lang="scss" scoped>
.logo {
  height: 120px;
  width: 120px;

  border: 1px solid #d9d9d9;
  border-radius: 2px;

  margin: 10px 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
