const UsersManagement = () => import("./UsersManagement") //lazy load

import Users from "./pages/Users/Users"
import Roles from "./pages/Roles/Roles"
import {
  pathForComponents,
  usersManagementComponents
} from "@/components/Layout/Navigation/navigationComponents"
import { rolesMeta, usersMeta } from "@/components/Layout/Navigation/constants/userManagement"

export const UserManagementRoutes = [
  {
    path: "users",
    fullPath: pathForComponents["USRCMP"],
    name: "Users",
    meta: {
      permissionType: "component",
      layout: "ant-layout",
      ...usersMeta
    }
  },
  {
    path: "roles",
    fullPath: pathForComponents["RLSCMP"],
    name: "Roles",
    meta: {
      permissionType: "component",
      layout: "ant-layout",
      ...rolesMeta
    }
  }
]

const components = {
  users: Users,
  roles: Roles
}

export default {
  path: "/users-management",
  component: UsersManagement,
  meta: {
    permissionType: "application",
    permissionCode: "UMGMT",
    code: "UMGMT"
  },
  children: UserManagementRoutes.map((route) => ({ ...route, component: components[route.path] }))
}
