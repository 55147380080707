import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchPages = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/pages${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const updatePage = ({ payload, id }) => {
  return API_V2.put(`${MPA_PREFIX}/pages/${id}`, payload)
}

export const createPage = ({ payload }) => {
  return API_V2.post(`${MPA_PREFIX}/pages`, payload)
}

export const fetchPagesActionsData = () => {
  return API_V2.get(`${MPA_PREFIX}/pages/actions-data`)
}
export const uploadPageImages = (fd) => {
  return API_V2.post(`${MPA_PREFIX}/pages/images`, fd)
}
export const fetchPageCategoryItems = () => {
  return API_V2.get(`${MPA_PREFIX}/page-categories`)
}
export const createPageCategoryItem = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/page-categories`, payload)
}
export const deletePageCategoryItem = (id) => {
  return API_V2.delete(`${MPA_PREFIX}/page-categories/${id}`)
}
export const updatePageCategoryItem = (data, id) => {
  return API_V2.patch(`${MPA_PREFIX}/page-categories/${id}`, data)
}
