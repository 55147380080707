export default {
  synthetic: "Synthetic Admin",
  authorization: "Authorization",
  signIn: "Sign in",
  login: "Login",
  userName: "Username",
  password: "Password",
  passwordConfirm: "Password confirm",
  loginError: "Login error, check your credentials and try later",
  forgotPass: "Forgot the password?",
  restorePass: "Restore",
  signOut: "Sign out",
  enterTwoFaCode: "2fa code",
  twoFaCodeOptional: "2fa code (optional)",
  qrModalTitle: "Scan code in 2fa app",
  invalidCodeInput: "Invalid code, try again",
  invalidCodeFormat: "Invalid code format"
}
