<template>
  <div
    id="app"
    style="height: 100%"
  >
    <a-config-provider :locale="uk_UA">
      <a-layout style="min-height: 100%">
        <Header :fetching="isFetching()" />

        <a-layout-content>
          <div :style="{ background: '#fff', padding: '20px', minHeight: '380px' }">
            <AntLayout>
              <Content :fetching="isFetching()">
                <div v-if="checkPermissions() === 'emptyPermissionsMeta'">
                  Metadata for route permissions is not set or wrong
                </div>
                <template v-else-if="!checkPermissions()">
                  <PageForbidden />
                </template>
                <template v-else>
                  <router-view />
                </template>
              </Content>

              <GlobalGallery ref="globalGalleryRef" />
            </AntLayout>
          </div>
        </a-layout-content>
      </a-layout>
    </a-config-provider>
  </div>
</template>

<script>
import { ref, provide } from "vue"
import { mapActions, mapState, mapGetters } from "vuex"
import uk_UA from "ant-design-vue/lib/locale-provider/uk_UA"

import Header from "@/components/Layout/Header"
import Content from "@/components/Layout/Content"
import AntLayout from "@/components/Layout/AntLayout"
import PageForbidden from "@/components/Layout/PageForbidden.vue"
import GlobalGallery from "@/components/GlobalGallery/index.vue"

import usePermissions from "@/composables/usePermissions.js"
import { API_V2 } from "./utils/HttpUtils"

export default {
  metaInfo: {
    title: "Synthetic ERP",
    titleTemplate: "%s - Synthetic ERP"
  },

  name: "App",

  components: {
    Header,
    Content,
    PageForbidden,
    GlobalGallery,
    AntLayout
  },

  setup() {
    const { checkApplicationPermission, checkComponentPermission, checkMessengerPermission } =
      usePermissions()

    const globalGalleryRef = ref()

    const handleOpenGallery = (payload) => {
      globalGalleryRef.value?.handleOpenGallery(payload)
    }

    provide("handleOpenGallery", handleOpenGallery)

    return {
      checkApplicationPermission,
      checkComponentPermission,
      checkMessengerPermission,

      globalGalleryRef,

      uk_UA
    }
  },

  computed: {
    ...mapState("Auth", {
      profileRequest: (state) => state.profileRequest,
      permissionsRequest: (state) => state.permissionsRequest,
      isLogged: (state) => state.isLogged
    }),
    ...mapGetters("Auth", ["permissions"])
  },
  beforeMount() {
    API_V2.interceptors.response.use((res) => res, this.responseInterseptorErrorToken)

    this.getProfile()
  },

  methods: {
    ...mapActions("Auth", ["getProfile", "logout", "sendRefreshToken"]),
    ...mapActions("Messenger", ["setUnreadMessagesTimeout", "resetUnreadMessagesTimeout"]),

    isFetching() {
      return this.profileRequest || this.permissionsRequest
    },

    checkPermissions() {
      if (Boolean(this.$route.meta.withoutChecking)) {
        return true
      } else if (!this.$route.meta.permissionType || !this.$route.meta.permissionCode) {
        return "emptyPermissionsMeta"
      } else if (!this.$route.meta) return false

      const { permissionType, permissionCode } = this.$route.meta

      if (permissionType === "application") {
        return this.checkApplicationPermission({
          code: permissionCode,
          permissions: this.permissions
        })
      }
      if (permissionType === "component") {
        return this.checkComponentPermission({
          code: permissionCode,
          permissions: this.permissions
        })
      }
    },

    responseInterseptorSuccess(response) {
      return response
    },

    async responseInterseptorErrorToken(error) {
      const originalRequest = error?.config
      const path = this.$route.path

      if (originalRequest?.url.endsWith("logout/")) {
        if (path !== "/login") {
          this.goToLoginPage()
        }

        return Promise.reject(error)
      }

      if (error?.config?._retry || originalRequest?.url.endsWith("refresh/")) {
        this.logout()

        return Promise.reject(error)
      }

      if (error?.response?.status === 401 && path !== "/login") {
        try {
          const { access_token } = await this.sendRefreshToken()
          const bearerToken = `Bearer ${access_token}`

          API_V2.defaults.headers.common.Authorization = bearerToken
          originalRequest._retry = true
          originalRequest.headers.Authorization = bearerToken

          return API_V2(originalRequest)
        } catch {
          return Promise.reject(error)
        }
      }

      return Promise.reject(error)
    },

    goToLoginPage() {
      const query = {
        next: this.$router.history.current.path
      }

      this.$router.push({
        path: "/login",
        query
      })
    }
  },

  mounted() {
    if (!this.isLogged) return
    if (!this.checkMessengerPermission(this.permissions)) return
    this.setUnreadMessagesTimeout()
  },

  watch: {
    isLogged: {
      handler(logged) {
        if (logged) {
          if (!this.checkMessengerPermission(this.permissions)) return
          this.setUnreadMessagesTimeout()
        } else {
          this.resetUnreadMessagesTimeout()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/main";
</style>
