<template>
  <router-view />
</template>

<script>
import { computed, onMounted, provide } from "vue"

import useCategories from "@/composables/useCategories.js"

export default {
  name: "Moderation",

  setup() {
    const { categoriesTree, isCategoriesFetching, getCategories } = useCategories()

    provide(
      "categoriesTree",
      computed(() => categoriesTree.value)
    )
    provide(
      "isCategoriesFetching",
      computed(() => isCategoriesFetching.value)
    )

    onMounted(getCategories)
  }
}
</script>

<style lang="scss">
.TRUSTED {
  color: $green-color;
  border-color: $green-color;
}

.UNTRUSTED {
  color: $dark-yellow-color;
  border-color: $dark-yellow-color;
}
</style>
