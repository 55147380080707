import { MSG_PREFIX } from "@/constants/apiPrefix.js"
import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"

export const fetchMessengerTags = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MSG_PREFIX}/chats/tags/${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const createTag = (payload) => {
  return API_V2.post(`${MSG_PREFIX}/chats/tags/`, payload)
}

export const updateTag = (uuid, payload) => {
  return API_V2.put(`${MSG_PREFIX}/chats/tags/${uuid}/`, payload)
}

export const fetchMessengerReplies = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MSG_PREFIX}/messages/quick-replies/${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const createMessengerReply = ({ payload }) => {
  return API_V2.post(`${MSG_PREFIX}/messages/quick-replies/`, payload)
}

export const updateMessengerReply = ({ payload, uuid }) => {
  return API_V2.put(`${MSG_PREFIX}/messages/quick-replies/${uuid}/`, payload)
}

export const deleteMessengerReply = ({ uuid }) => {
  return API_V2.delete(`${MSG_PREFIX}/messages/quick-replies/${uuid}/`)
}

export const bulkRepliesUpdate = async ({ replies, dataSource, chat_tag_uuid }) => {
  try {
    const replyPromises = replies.map((uuid) => {
      const record = dataSource.find((item) => item.uuid === uuid)
      if (!record) return null

      return updateMessengerReply({
        uuid,
        payload: { response: record.response, title: record.title, chat_tag_uuid }
      })
    })

    await Promise.all(replyPromises)

    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
