<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="items"
      row-key="id"
      :pagination="false"
    >
      <template #dateRenderer="text">
        <TableRendererDateTime :date="text" />
      </template>

      <template #editAuthorRenderer="{ created_by }">
        <div>{{ created_by.side }}</div>
        <div :style="{ opacity: '0.45' }">{{ created_by.name }}</div>
      </template>

      <template #editObjectRenderer="{ data_after, data_before }">
        <div v-for="item of getNewOfferValues(data_after, data_before)">
          <span :style="{ opacity: '0.45' }">{{ $t(item.key) }}:</span>
          {{ item.new_value }}

          <a-popover
            v-if="item.old_value"
            placement="top"
            :content="`${item.old_value} -> ${item.new_value}`"
          >
            <a-icon
              type="info-circle"
              theme="twoTone"
            />
          </a-popover>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import { useStatuses } from "@/composables/useStatuses.js"
import useOrderHistoryColumns from "./columns/useOrderHistoryColumns"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const { orderStatuses, paymentStatuses } = useStatuses()
const columns = useOrderHistoryColumns()

const getTranslatedHistoryValue = (key, value) => {
  if (!value) return ""
  if (key === "order_status") return orderStatuses.value[value].title
  if (key === "payment_status") return paymentStatuses.value[value].title
  return value
}

const getNewOfferValues = (values, oldValues) => {
  return Object.entries(values)
    .filter(([key, value]) => value && value !== oldValues[key])
    .map(([key, value]) => ({
      key,
      value,
      new_value: getTranslatedHistoryValue(key, value),
      old_value: getTranslatedHistoryValue(key, oldValues[key])
    }))
}
</script>
