export default {
  applications: "Застосунки",
  components: "Компоненти",
  views: "Сторінки",
  fields: "Поля",
  modelPermissions: "Дозволи моделей",
  createRole: "Створити роль",
  editRole: "Редагувати роль",
  roles: "Ролі",
  users: "Користувачі",
  isSuper: "Супер",
  createUser: "Створити користувача",
  editUser: "Редагувати користувача",
  userCreatedSuccessfully: "Користувача успішно створено",
  userUpdatedSuccessfully: "Користувача успішно оновлено",
  roleCreatedSuccessfully: "Роль успішно створено",
  roleUpdatedSuccessfully: "Роль успішно оновлено",
  permissions: "Дозволи",
  twoStepGoogleAuth: "Двофакторний вхід Google Authenticator",
  deactivateUser: "Деактивувати користувача",
  deleteRole: "Видалити роль",
  history: "Історія",
  roleDeleteHint: "Видалення ролі можливе лише коли вона не має активних дозволів",
  removeTwoFaWarning: "Вимкнення двуфакторного входу скине 2fa ключ. Продовжити?",
  deactivateSessionWarning: "Ви дійсно хочете деактивувати сесію?",
  sessionDeactivated: "Деактивовано"
}
