<template>
  <a-table
    :columns="columns"
    :dataSource="items"
    rowKey="uuid"
    :pagination="false"
  >
    <template #photoRenderer="images">
      <TableRendererPhotoGallery :images="images" />
    </template>

    <template #codeTitleRenderer>
      <div>CS-code</div>
      <div>P-code</div>
    </template>

    <template #codeRenderer="record">
      <div class="table-column">
        <div style="width: 90px">{{ record.item_in_order.offer.code }}</div>
        <a-icon
          style="color: #1890ff"
          type="copy"
          @click="handleCopy(record.item_in_order.offer.code)"
        />
        <a
          style="color: #1890ff"
          :href="getCsCodeLink(record.item_in_order.offer)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>

      <div class="table-column">
        <div style="width: 90px">{{ record.item_in_order.offer.product.p_code }}</div>
        <a-icon
          style="color: #1890ff"
          type="copy"
          @click="handleCopy(record.item_in_order.offer.product.p_code)"
        />
        <a
          style="color: #1890ff"
          :href="getPCodeLink(record.item_in_order.offer)"
          target="_blank"
        >
          <a-icon type="link" />
        </a>
      </div>
    </template>

    <template #productNameRenderer="record">
      <div>{{ record.item_in_order.offer.product.name }}</div>

      <div>
        {{ $t(`condition_${record.item_in_order.condition.key}`) }},
        <template
          v-if="
            record.item_in_order.condition.key !== 'w_undetermined' &&
            record.item_in_order.warranty > 0
          "
        >
          {{
            $tc("monthFull", +record.item_in_order.warranty, {
              count: record.item_in_order.warranty
            })
          }}
        </template>
      </div>
    </template>

    <template #discount="value, record">
      <div>{{ getFormatedSum(record.bonuses_amount + record.promo_code_amount, true) }}</div>
      <div
        v-if="record.promo_code_amount && promoCode"
        class="promo-code"
      >
        {{ promoCode }}
      </div>
    </template>
  </a-table>
</template>

<script setup>
import { message } from "ant-design-vue"
import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/index.js"

import { MARKETPLACE } from "@/constants/common.js"
import useReturnOffersColumns from "./useReturnOffersColumns.js"

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  promoCode: {
    type: String,
    required: false
  }
})

const columns = useReturnOffersColumns()

const handleCopy = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}

const getCsCodeLink = ({ product, code }) => {
  return `${MARKETPLACE}/product/${product?.slug}/${product?.p_code}/${code}`
}

const getPCodeLink = ({ product }) => {
  return `${MARKETPLACE}/product/${product?.slug}/${product?.p_code}`
}
</script>

<style lang="scss" scoped>
.table-column {
  display: flex;
  align-items: center;
  gap: 8px;
}

.promo-code {
  color: #e38800;
  background-color: #fffae7;
  align-self: flex-start;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 4px;
  margin-top: 6px;
  display: inline-block;
}
</style>
