<template>
  <div
    v-if="row.bill"
    class="note-renderer"
  >
    <div
      class="note"
      v-if="row.bill.note"
      @dblclick="openModal"
    >
      <span>{{ row.bill.note }}</span>
    </div>
    <a-button
      style="width: 32px"
      type="dashed"
      shape="circle"
      @click="openModal"
    >
      <a-icon type="edit" />
    </a-button>

    <a-drawer
      :visible="showModal"
      :title="`${$t('addNote')} (${row.slug})`"
      :width="600"
      @close="onClose"
    >
      <a-form-model
        ref="formRef"
        :model="form"
        :rules="formRules"
      >
        <a-form-model-item
          :label="$t('note')"
          prop="note"
        >
          <a-textarea
            v-model="form.note"
            :autoSize="{ minRows: 2, maxRows: 10 }"
          />
        </a-form-model-item>

        <div class="drawer-footer">
          <a-button
            :disabled="isFetching"
            @click="onClose"
          >
            {{ $t("cancel") }}
          </a-button>

          <a-button
            type="primary"
            :loading="isFetching"
            @click="onSubmit"
          >
            {{ $t("apply") }}
          </a-button>
        </div>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import { billingAddNote } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import useForm from "@/composables/useForm"

const props = defineProps({
  row: {
    type: Object,
    default: () => ({})
  },
  rowIndex: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(["close"])

const isFetching = shallowRef(false)
const showModal = shallowRef(false)

const formRef = ref()
const form = reactive({
  note: props.row.bill?.note?.toString() || ""
})
const formRules = computed(() => ({
  note: [{ max: 1000, message: i18n.t("maxLengthError", { length: 1000 }) }]
}))
const { isValidForm, validateForm, resetFields } = useForm(formRef)

const openModal = () => {
  showModal.value = true
  form.note = props.row.bill?.note?.toString() || ""
}

const onSubmit = async () => {
  if (isFetching.value || !showModal.value) return

  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    await billingAddNote(props.row.bill.id, form)

    notification.success({ message: i18n.t("updated") })
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => {
  showModal.value = false
  resetFields()
  emit("close")
}
</script>

<style lang="scss" scoped>
.note {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: calc(100% - 42px);

  span {
    white-space: pre-wrap;
  }
}

.note-renderer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
</style>
