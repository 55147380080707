// @ts-ignore
import Vue from "vue"
import Vuex from "vuex"

import Auth from "@/modules/Auth/store"
import Messenger from "@/modules/Messenger/store"

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    Auth,
    Messenger
  }
})
