<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="general-settings"
    :colon="false"
  >
    <a-form-model-item
      :label="$t('categoryState')"
      class="activity-card"
      prop="is_active"
    >
      <a-radio-group v-model="form.is_active">
        <a-radio-button :value="true">{{ $t("active") }}</a-radio-button>

        <a-radio-button :value="false">{{ $t("inactive") }}</a-radio-button>
      </a-radio-group>
    </a-form-model-item>

    <div class="general-settings__top">
      <div
        v-if="props.category.image || newImageFile"
        class="general-settings__top__image"
      >
        <img :src="parseFullImagePath" />
        <div class="general-settings__top__image__actions">
          <a-icon
            type="delete"
            @click="handleDeleteImage"
          />
          <a-icon
            type="edit"
            @click="chooseImage"
          />
        </div>
      </div>

      <!-- IMAGE -->
      <div
        class="general-settings__top__image"
        :style="{ display: props.category.image || newImageFile ? 'none' : 'block' }"
      >
        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          ref="fileInputRef"
          @change="handleAddImage"
        />
        <div
          class="general-settings__top__image__placeholder"
          @click="chooseImage"
        >
          <a-icon type="plus" />
          {{ $t("upload") }}
        </div>
      </div>

      <!-- NAME -->
      <div class="general-settings__top__names">
        <div>
          <a-form-model-item prop="name.uk">
            <template #label
              >{{ $t("name") }}, UA (<span
                :class="{ 'red-text': form.name.uk.length > MAX_NAME_LENGTH }"
                >{{ form.name.uk.length }}</span
              >/{{ MAX_NAME_LENGTH }})
            </template>

            <a-input v-model="form.name.uk" />
          </a-form-model-item>

          <a-form-model-item prop="name_sing.uk">
            <template #label>
              {{ $t("name_sing") }}, UA ({{ form.name_sing.uk?.length || 0 }}/{{ MAX_NAME_LENGTH }})
            </template>

            <a-input v-model="form.name_sing.uk" />
          </a-form-model-item>
        </div>

        <div>
          <a-form-model-item prop="name.en">
            <template #label
              >{{ $t("name") }}, EN (<span
                :class="{ 'red-text': form.name.en.length > MAX_NAME_LENGTH }"
                >{{ form.name.en.length }}</span
              >/{{ MAX_NAME_LENGTH }})
            </template>

            <a-input v-model="form.name.en" />
          </a-form-model-item>

          <a-form-model-item prop="name_sing.en">
            <template #label>
              {{ $t("name_sing") }}, EN ({{ form.name_sing.en?.length || 0 }}/{{ MAX_NAME_LENGTH }})
            </template>

            <a-input v-model="form.name_sing.en" />
          </a-form-model-item>
        </div>

        <div>
          <a-form-model-item prop="name.ru">
            <template #label>
              {{ $t("name") }}, RU (<span
                :class="{ 'red-text': form.name.ru.length > MAX_NAME_LENGTH }"
                >{{ form.name.ru.length }}</span
              >/{{ MAX_NAME_LENGTH }})
            </template>

            <a-input v-model="form.name.ru" />
          </a-form-model-item>

          <a-form-model-item prop="name_sing.ru">
            <template #label>
              {{ $t("name_sing") }}, RU ({{ form.name_sing.ru?.length || 0 }}/{{ MAX_NAME_LENGTH }})
            </template>

            <a-input v-model="form.name_sing.ru" />
          </a-form-model-item>
        </div>
      </div>

      <!-- SHOW VALUES -->
      <div class="general-settings__top__additional">
        <a-form-model-item
          v-for="(item, index) in showInCategoryOptions"
          :key="item.value"
          :label="index ? '' : $t('showValuesForThisCategory')"
          :prop="item.value"
          style="margin: 0"
        >
          <a-checkbox
            v-model="form[item.value]"
            style="margin: 0; width: 100%"
          >
            {{ item.label }}
          </a-checkbox>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('parentCategory')"
          prop="parent"
        >
          <a-tree-select
            :treeData="categoriesTree"
            v-model="form.parent"
            showSearch
            :dropdownStyle="{ maxHeight: '400px', paddingRight: '20px', overflowX: 'hidden' }"
            :placeholder="$t('category')"
            allowClear
            :replaceFields="{ value: 'id' }"
            treeNodeFilterProp="title"
          />
        </a-form-model-item>
      </div>
    </div>

    <!-- ICON AND NOTE -->
    <div class="general-settings__bottom">
      <a-form-model-item
        :label="$t('icon')"
        class="general-settings__bottom__icon"
        prop="icon"
      >
        <a-input v-model="form.icon" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('note')} (${form.description?.length}/${MAX_DESCRIPTION_LENGTH})`"
        prop="note"
      >
        <a-textarea
          v-model="form.description"
          :autoSize="{ minRows: 4, maxRows: 8 }"
        />
      </a-form-model-item>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, onMounted, watch } from "vue"
import router from "@/router"
import i18n from "@/i18n"

import getImagePath from "utils/getImagePath.js"
import { IMAGES_CDN } from "@/constants/common.js"
import useForm from "@/composables/useForm.js"
import { requiredRule } from "@/utils/validations.js"

const MAX_NAME_LENGTH = 200
const MAX_DESCRIPTION_LENGTH = 1000

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  category: {
    type: Object,
    default: () => ({})
  },
  categoriesTree: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(["formChanged", "deleteImage"])

const formRef = ref()
const form = reactive({
  is_active: Boolean(props.category.is_active),
  name: {
    uk: "",
    en: "",
    ru: "",
    ...props.category.name
  },
  name_sing: {
    uk: "",
    en: "",
    ru: "",
    ...props.category.name_sing
  },
  is_shown_condition: Boolean(props.category.is_shown_condition),
  is_shown_warranty: Boolean(props.category.is_shown_warranty),
  is_available_return: Boolean(props.category.is_available_return),
  ar_d: Boolean(props.category.ar_d),
  parent: +router.currentRoute.query?.parent || props.category.parent?.id,
  icon: props.category.icon,
  description: props.category.description || ""
})

const nameRules = computed(() => [
  requiredRule.value,
  {
    max: MAX_NAME_LENGTH,
    message: i18n.t("maxLengthError", { length: MAX_NAME_LENGTH })
  }
])
const formRules = computed(() => ({
  name: {
    uk: nameRules.value,
    ru: nameRules.value,
    en: nameRules.value
  },
  icon: iconRules.value
}))

const { isValidForm, validateForm } = useForm(formRef)

const formWatcher = ref()
const setWatcher = () => {
  formWatcher.value = watch(form, onValuesChange, { deep: true })
}
const onValuesChange = () => {
  emit("formChanged")
}

const newImageFile = ref()
const newImagePreview = ref()

const iconRules = computed(() => {
  return form.parent ? [] : [requiredRule.value]
})

const showInCategoryOptions = computed(() => {
  return [
    {
      label: i18n.t("productCondition"),
      value: "is_shown_condition"
    },
    {
      label: i18n.t("guaranteePeriod"),
      value: "is_shown_warranty"
    },
    {
      label: i18n.t("purchaseReturns"),
      value: "is_available_return"
    },
    {
      label: "18+",
      value: "ar_d"
    }
  ]
})

const parseFullImagePath = computed(() => {
  return props.category.image
    ? `${IMAGES_CDN}/media/assets/images/${getImagePath(props.category.image, "full")}`
    : newImagePreview.value
})

const fileInputRef = ref()
const chooseImage = () => {
  fileInputRef.value?.click()
}

const handleAddImage = (e) => {
  emit("formChanged")
  let file = e.target.files.item(0)

  newImageFile.value = file

  let fileReader = new FileReader()

  fileReader.addEventListener("load", (event) => {
    newImagePreview.value = event.target.result
  })

  fileReader.readAsDataURL(file)
}

const handleDeleteImage = () => {
  if (props.category.image) return emit("deleteImage")
  newImageFile.value = null
  newImagePreview.value = null
}

const getFields = async () => {
  await validateForm()

  if (!isValidForm.value) return null
  const payload = { ...form }

  if (newImageFile.value) {
    payload.image = { img: newImageFile.value }
  } else if (!props.category.image) {
    payload.image = { img: "" }
  }
  if (!form.parent) {
    delete payload.parent
  }

  return Promise.resolve(payload)
}

onMounted(setWatcher)

defineExpose({
  getFields,
  setWatcher
})
</script>

<style lang="scss" scoped>
.general-settings {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1420px;
  margin: 0 auto;

  & > .activity-card {
    position: absolute;
    width: 360px;
    margin-left: auto;
    border: 1px solid $border-color;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    top: -140px;
    right: 0;
    border-radius: 2px;
  }

  &__top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;

    &__image {
      position: relative;
      width: 285px;
      aspect-ratio: 1;
      border: 1px solid $border-color;
      border-radius: 4px;
      padding: 12px;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      & > input[type="file"] {
        opacity: 0;
      }

      &__actions {
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        flex-flow: row nowrap;
        gap: 8px;
        & > * {
          background-color: $font-default;
          color: $default-color;
          padding: 4px;
          border-radius: 2px;
          transition: 0.1s;
          opacity: 0.75;
          &:hover {
            opacity: 1;
          }
        }
      }

      &__placeholder {
        position: absolute;
        top: 12px;
        left: 12px;
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        z-index: 1;
        background: $default-color;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: $grey-font;
        border: 1px dashed $default-border;
        border-radius: 2px;
        cursor: pointer;
        & > :first-child {
          font-size: 24px;
          color: $font-default;
        }
      }
    }

    &__names {
      width: calc(75% - 285px - 80px);

      & > div {
        display: flex;
        gap: 16px;
        & > * {
          flex: 1;
        }
      }
    }

    &__additional {
      width: 25%;
      display: flex;
      flex-flow: column nowrap;
    }
  }
  &__bottom {
    &__icon {
      width: 220px;
    }
  }
  .red-text {
    color: $red-color;
    accent-color: $red-color;
  }
}
</style>
