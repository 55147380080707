import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "@/constants/apiPrefix.js"

export const fetchPaymentMethods = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/payment-methods${getFormatedQueryParams(queryParams)}`, {
    signal
  })
}

export const createPaymentMethod = (data) => {
  return API_V2.post(`${MPA_PREFIX}/payment-methods`, data)
}

export const updatePaymentMethod = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/payment-methods/${id}`, data)
}
