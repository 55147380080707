import { API_V2 } from "@/utils/HttpUtils"
import { jwtDecode } from "jwt-decode"

export const updateAccessToken = (token) => {
  return API_V2.post(
    "admin/auth/refresh/",
    {},
    {
      headers: {
        Authorization: "Bearer " + token
      }
    }
  )
}

export const getProfileInfo = () => {
  const accessToken = localStorage.getItem("access_token_mpa")
  const parsedToken = jwtDecode(accessToken)
  return { user_id: parsedToken?.user_id || null, username: parsedToken?.username || null }
}

export const sendLogoutRequest = async () => {
  try {
    await API_V2.post("admin/auth/logout/")
    return Promise.resolve()
  } catch {
    return Promise.resolve()
  }
}

export const handleLogin = (payload) => {
  return API_V2.post("admin/auth/login/", payload)
}

export const setApiAuthHeader = (token) => {
  API_V2.defaults.headers.common["Authorization"] = token
}

export const resetApiAuthHeader = (token) => {
  API_V2.defaults.headers.common["Authorization"] = undefined
}
