import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "handle",
      scopedSlots: {
        customRender: "handleRenderer"
      },
      width: 50
    },
    {
      key: "name",
      title: i18n.t("quickReplyTitle"),
      scopedSlots: {
        customRender: "nameRenderer"
      },
      width: 285
    },
    {
      key: "response",
      title: i18n.t("text"),
      scopedSlots: {
        customRender: "responseRenderer"
      },
      width: 450
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      width: 67
    }
  ])

  return columns
}
