import i18n from "@/i18n"
import { computed } from "vue"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => [
    {
      key: "date_joined",
      dataIndex: "date_joined",
      title: i18n.t("dateCreated"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "date_joined" && sortedInfo?.value?.order,
      fixed: "left",
      width: 160
    },
    {
      key: "last_login",
      dataIndex: "last_login",
      title: i18n.t("lastLogin"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "last_login" && sortedInfo?.value?.order,
      fixed: "left",
      width: 145
    },
    {
      key: "username",
      dataIndex: "username",
      title: i18n.t("userName"),
      width: 360
    },
    {
      key: "first_name",
      customRender: (record) => `${record.first_name} ${record.last_name}`,
      title: i18n.t("firstName")
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      scopedSlots: {
        customRender: "contactsRenderer"
      },
      width: 360
    },
    {
      key: "is_active",
      dataIndex: "is_active",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "isActiveRenderer"
      },
      width: 110
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      fixed: "right",
      width: 160
    }
  ])

  return columns
}
