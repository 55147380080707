<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="create-type-form"
  >
    <a-form-model-item
      :label="`${$t('typeName')} (UA)`"
      prop="full_name.uk"
    >
      <a-input v-model="form.full_name.uk" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('typeName')} (RU)`"
      prop="full_name.ru"
    >
      <a-input v-model="form.full_name.ru" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('typeName')} (EN)`"
      prop="full_name.en"
    >
      <a-input v-model="form.full_name.en" />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        :loading="isFetching"
        type="primary"
        icon="plus"
        @click="onSubmit"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import { createType, updateType } from "@/modules/Moderation/services/typesService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import useForm from "@/composables/useForm"
import { requiredRule } from "@/utils/validations"

const emit = defineEmits(["submit"])

const formRef = ref()
const form = reactive({
  full_name: {
    ru: "",
    uk: "",
    en: ""
  }
})
const nameRule = computed(() => [
  requiredRule.value,
  { max: 200, message: i18n.t("maxLengthError", { length: 200 }) }
])
const formRules = {
  full_name: {
    ru: nameRule.value,
    uk: nameRule.value,
    en: nameRule.value
  }
}
const { isValidForm, validateForm } = useForm(formRef)

const isFetching = shallowRef(false)
const onSubmit = async () => {
  await validateForm()
  if (!isValidForm.value) return

  try {
    isFetching.value = true

    const { data } = await createType({ payload: { full_name: form.full_name.ru } })

    if (!data.code) return

    await updateType({ code: data.code, payload: { full_name: form.full_name.uk }, language: "uk" })
    await updateType({ code: data.code, payload: { full_name: form.full_name.en }, language: "en" })

    notification.success({ message: i18n.t("created") })
    onClose()
    emit("submit", data)
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("createTypeError") })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>

<style lang="scss" scoped>
.create-type-form {
  padding-bottom: 50px;
}
</style>
