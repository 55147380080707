import { MSG_PREFIX } from "@/constants/apiPrefix.js"
import { MESSENGER_URL_XHR } from "@/constants/common"
import { API_V2 } from "@/utils/HttpUtils"

export const getModerationToken = () => {
  return API_V2.get(`/proxy/msg/support-auth`)
}

export const getUnreadMessagesCount = (token) => {
  return fetch(`${MESSENGER_URL_XHR}/api/v2/chats/messages/unread-count/?sender=moderator`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res) => res.json())
}

export const globalChatSearch = (params) => {
  return API_V2.get(`${MSG_PREFIX}/chats/`, {
    params
  })
}

export const getChatMessages = ({ chatUuid, limit, offset }) => {
  return API_V2.get(`${MSG_PREFIX}/messages/`, {
    params: { limit, offset, chat: chatUuid }
  })
}

export const getMessageHistory = ({ messageUuid, limit, offset }) => {
  return API_V2.get(`${MSG_PREFIX}/messages/${messageUuid}/history/`, {
    params: { limit, offset }
  })
}
