<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="edit-type__general-form"
  >
    <div class="edit-type__general-form__row">
      <a-form-model-item
        prop="full_name.uk"
        :label="`${$t('typeName')} (UA)`"
      >
        <a-input v-model="form.full_name.uk" />
      </a-form-model-item>

      <a-form-model-item
        prop="full_name.ru"
        :label="`${$t('typeName')} (RU)`"
      >
        <a-input v-model="form.full_name.ru" />
      </a-form-model-item>

      <a-form-model-item
        prop="full_name.en"
        :label="`${$t('typeName')} (EN)`"
      >
        <a-input v-model="form.full_name.en" />
      </a-form-model-item>
    </div>

    <div class="edit-type__general-form__row">
      <a-form-model-item
        prop="related_categories"
        :label="$t('attachedCategories')"
        class="edit-type__general-form__category"
      >
        <a-tree-select
          v-model="form.related_categories"
          :treeDefaultExpandedKeys="form.related_categories"
          :treeData="categoriesTree"
          multiple
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          showSearch
          treeNodeFilterProp="title"
        />
      </a-form-model-item>

      <a-form-model-item
        prop="note"
        :label="$t('tag._note')"
        class="edit-type__general-form__note"
      >
        <a-textarea
          v-model="form.note"
          :rows="4"
          :maxLength="500"
        />

        <template #extra>
          <div class="edit-type__general-form__note__counter">
            <span
              :class="{ 'edit-type__general-form__note__counter--error': form.note?.length > 500 }"
            >
              {{ form.note?.length || 0 }}
            </span>
            /500
          </div>
        </template>
        <template #help></template>
      </a-form-model-item>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, inject, reactive, ref, watch } from "vue"
import useForm from "@/composables/useForm"
import i18n from "@/i18n"

import { requiredRule } from "@/utils/validations"

const props = defineProps({
  typeInfo: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["change"])

const categoriesTree = inject("categoriesTree")
// const { trustOptions } = useModerationOptions()

const formRef = ref()
const form = reactive({
  full_name: {
    ru: "",
    uk: "",
    en: ""
  },
  note: "",
  related_categories: []
})
const formRules = computed(() => ({
  full_name: {
    ru: [requiredRule.value],
    uk: [requiredRule.value],
    en: [requiredRule.value]
  },
  note: [{ max: 500, message: i18n.t("maxLengthError", { length: 500 }) }]
}))
const { isValidForm, validateForm, resetValidation } = useForm(formRef)

const formWatcher = ref()

const setupForm = (payload = props.typeInfo) => {
  form.full_name = payload?.full_name ? { ...payload.full_name } : form.full_name
  form.note = payload?.note
  form.related_categories = payload.related_categories

  resetValidation()
  formWatcher.value = watch(() => form, onFormChanged, { deep: true })
}
const onFormChanged = () => {
  emit("change")
  formWatcher.value?.()
}

const getFormValues = async () => {
  await validateForm()
  return isValidForm.value ? form : null
}

defineExpose({
  setupForm,
  getFormValues
})
</script>

<style lang="scss" scoped>
.edit-type__general-form {
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  &__row {
    display: flex;

    gap: 16px;

    & > * {
      flex: 2;
    }
  }

  &__note,
  &__category {
    flex: 3;

    &__counter {
      display: flex;
      justify-content: flex-end;
      color: $dark;
      position: absolute;
      bottom: 0;
      right: 0;

      &--error {
        color: $red-color;
      }
    }
  }

  &__status {
    flex: 1;
  }
}
</style>

<style lang="scss">
.edit-type__general-form__category {
  .ant-select-selection {
    height: 94px;
    overflow-y: auto;
  }
}
</style>
