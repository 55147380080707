import { computed } from "vue"
import i18n from "@/i18n"

const languages = [
  { label: "UA", value: "uk" },
  { label: "EN", value: "en" },
  { label: "RU", value: "ru" }
]
export default () => {
  const columns = computed(() => [
    {
      title: "",
      dataIndex: "handle",
      scopedSlots: {
        customRender: "handleRenderer"
      },
      width: 55
    },
    {
      key: languages[0].value,
      title: `${i18n.t("filterName")} ${languages[0].label}`,
      scopedSlots: {
        customRender: languages[0].value
      },
      width: 240
    },
    {
      key: languages[1].value,
      title: `${i18n.t("filterName")} ${languages[1].label}`,
      scopedSlots: {
        customRender: languages[1].value
      },
      width: 240
    },
    {
      key: languages[2].value,
      title: `${i18n.t("filterName")} ${languages[2].label}`,
      scopedSlots: {
        customRender: languages[2].value
      },
      width: 240
    },
    {
      key: "type",
      title: i18n.t("filterType"),
      scopedSlots: {
        customRender: "filterTypeRender"
      },
      width: 240
    },
    {
      key: "values",
      dataIndex: "values",
      title: i18n.t("rangesCount"),
      scopedSlots: {
        customRender: "rangeRender"
      },
      width: 140
    },
    {
      key: "missing",
      title: i18n.t("notInFilter"),
      scopedSlots: {
        customRender: "rangeCalculating"
      },
      width: 125
    },
    {
      key: "action",
      scopedSlots: {
        customRender: "actions"
      },
      width: 140
    }
  ])

  return columns
}
